import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button } from 'antd'
import { requiredMessage, inputPlace, successMessage, errorMessage, assignLanguage, assignMessage } from '../../utils/helpers'
import { removeUser } from '../../Redux/actions/authActions'
import { AUTH } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import axios from 'axios'
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import EgyptLanguage from '../../Languages/EgyptLanguage.json'
import { Email } from '@mui/icons-material'
import LoginLanguageSelect from '../../Components/LoginLanguageSelect/LoginLanguageSelect'
import CitydealsLogo from "../../assets/CitydealsLogo.png"

const ForgotPassword = (props) => {
    const { history } = props
    const path = history?.location?.pathname
    const dispatch = useDispatch()
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = assignLanguage(language?.language)
    const [loading, setLoading] = useState(false)
    const [btnText, setBtnText] = useState('Search')
    const [heading, setHeading] = useState(path === allPaths?.BU_FORGOT ? 'Business User' : 'Guest')
    const isEnglish = language?.language === 'english'

    const onFinish = (values) => {
        setLoading(true)
        setBtnText('Searching...')

        values.userType = path === allPaths?.BU_FORGOT ? 'business' : 'guest'

        axios.post(AUTH.FORGOT_PASSWORD, values)
            .then((result) => {
                const { data } = result
                setLoading(false)
                setBtnText('Search')
                if (data.success) {
                    return successMessage(assignMessage(data?.message, language?.language))
                }
                errorMessage(assignMessage(data?.message, language?.language))
            })
            .catch((err) => {
                setLoading(false)
                setBtnText('Search')
                errorMessage(assignMessage(err?.message, language?.language))
            })
    }

    useEffect(() => {
        dispatch(removeUser())
    }, [])

    return (
        <div style={{ height: '100%', background: '#FBFBFB' }}>
            <LoginLanguageSelect />
            <div className='auth-screen-main-div'>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                    <img style={{ height: '110px', width: '135px' }} src={CitydealsLogo} />
                </div>
                <p className='heading head-center'>({heading === 'Guest' ? selectedLanguage?.Guest : selectedLanguage?.BusinessUser}) {selectedLanguage?.ResetPassword}</p>
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                    requiredMark={false}
                >
                    <div className='form-input-single-input'>
                        <Form.Item
                            name='email'
                            label={selectedLanguage?.Email}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourEmail,
                                },
                                {
                                    type: 'email',
                                    message: selectedLanguage?.EmailIsNotValid,
                                },
                            ]}
                        >
                            <Input
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.InputYourEmailHere}
                            />
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <Button
                            type='primary'
                            className='addproduct-text form-input-new-style'
                            style={{ height: '45px', width: '99%', marginTop: '10px' }}
                            htmlType='submit'
                            loading={loading}
                        >
                            {btnText === 'Search' ? selectedLanguage?.Search : selectedLanguage?.Searching}
                        </Button>
                        <br />
                        <br />
                        <div style={{ textAlign: 'center' }}>
                            {path === allPaths?.BU_FORGOT ? <p>
                                {selectedLanguage?.AlreadyHaveAccount} <Link to={allPaths?.BU_LOGIN}>{selectedLanguage?.BusinessLogin}</Link>
                                <br />
                                {selectedLanguage?.DontHaveAnAccount} <Link to={allPaths?.BU_SIGNUP}>{selectedLanguage?.BusinessSignUp}</Link>
                            </p> : <p>
                                {selectedLanguage?.AlreadyHaveAccount} <Link to={allPaths?.LOGIN}>{selectedLanguage?.Login}</Link>
                                <br />
                                {selectedLanguage?.DontHaveAnAccount} <Link to={allPaths?.SIGNUP}>{selectedLanguage?.SignUp}</Link>
                            </p>}
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default ForgotPassword