import { Button, DatePicker, Spin, Table } from 'antd'
import axios from 'axios'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { POST } from '../../utils/apis'
import NoData from "../../assets/noDataImae.png"

const LotteryWinners = (props) => {

    const { user, onCancel, selectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const [lotteryWinner, setlotteryWinner] = useState([])
    // const [dateRange, setDateRange] = useState({
    //     date1: moment().add(-1, 'months').format('YYYY-MM-DD'),
    //     date2: moment().format('YYYY-MM-DD')
    // })
    const [dateRange, setDateRange] = useState({
        date1: moment().format('YYYY-MM')
    })
    const customLocale = {
         emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle:selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    useEffect(() => {
        getLottery()
    }, [dateRange])

    const getLottery = () => {
        setLoading(true)
        let valObj = { userId: `${user?._id}`, date1: dateRange?.date1 } 
        axios.post(`${POST.GET_LOTTERY_PRIZE_WINNERS}`, valObj)
            .then((res) => {
                const { data } = res 
                setlotteryWinner(data?.data)
                setLoading(false)
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
            })
    }

    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_lotteryWinner}</p>,
            dataIndex: ['guest', 'fullName'],
            key: 'fullName',
            sorter: (a, b) => a.fullName - b.fullName,
            showSorterTooltip: false,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_WinnerEmail}</p>,
            dataIndex: ['guest', 'email'],
            key: 'Email',
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_LotteyCode}</p>,
            dataIndex: 'lotteryCode',
            key: 'lotteryCode',
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_Prize}</p>,
            dataIndex: 'prize',
            key: 'prize',
            render: (e) => <img src={`${e}`} style={{ marginRight: 10, width: 86, height: 60 }} />
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_DoubleChance}</p>,
            dataIndex: ['guest', 'doubleChances'],
            key: 'doubleChances',
            sorter: (a, b) => a.doubleChances - b.doubleChances,
            showSorterTooltip: false,
            render: (e) => <a>{e ? selectedLanguage?.True : selectedLanguage?.False}</a>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_WithdrawDate}</p>,
            dataIndex: 'date',
            key: 'date',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{moment(e).utcOffset(0, true).format('DD-MM-YYYY')}</p>,
        },

    ]
 

    const createReportRange = (d) => {
        setDateRange({
            date1: d?.format('YYYY-MM')
        })
    }
    let currentDate = moment().format('YYYY-MM')

    return (
        <>
            <div>
                <div className='flex-mobile' style={{ display: 'flex' }}>                    
                    <DatePicker
                        defaultValue={moment(currentDate, 'YYYY-MM')}
                        style={{
                            marginBottom: 10,
                            marginTop: 20,
                            width: '250px',
                            height: '45px',
                            borderRadius: '6px',
                            boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                            marginLeft: '8px'
                        }}
                        picker={'month'}
                        onChange={createReportRange}
                        allowClear={false}
                    />
                </div>            

                <div className='new-layout-table-upper-div'>
                    <Table
                        className='new-layout-table'
                        locale={customLocale}
                        loading={loading ? <Spin /> : ""}
                        columns={columns}
                        dataSource={lotteryWinner}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: 800 }}
                    />
                </div>

            </div>
        </>
    )
}

export default LotteryWinners