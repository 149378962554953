import { Table } from 'antd'
import React, { useState } from 'react'

const Payments = (props) => {
    const { user, userActions, history } = props
    const [loading, setLoading] = useState(false)
    const [showAddType, setShowAddType] = useState(false)
    const [productTypes, setProductTypes] = useState([])
    const [edit, setEdit] = useState({})
    const [spin, setSpin] = useState({})

    // useEffect(() => {
    //     getProductsTypes()
    // }, [])

    // const getProductsTypes = () => {
    //     setLoading(true)
    //     axios.get(`${ADMIN?.GET_PRODUCT_TYPES}/${user?._id}`)
    //         .then((res) => {
    //             const { data } = res
    //             console.log('data', data?.data)
    //             setProductTypes(data?.data || [])
    //             setLoading(false)
    //         })
    //         .catch((e) => {
    //             setLoading(false)
    //             console.log('e', e)
    //         })
    // }
    // const deleteType = (_id) => {
    //     setSpin({ [_id]: true })
    //     let deleteProduct = {
    //         _id,
    //         userId: user?._id
    //     }
    //     axios.post(`${ADMIN?.DELETE_PRODUCT_TYPES}`, deleteProduct)
    //         .then((res) => {
    //             const { data } = res
    //             setSpin({})
    //             getProductsTypes()
    //             if (data?.success) {
    //                 successNotification(data?.message)
    //             }
    //             else {
    //                 errorMessage(data?.message)
    //             }
    //         })
    //         .catch((e) => {
    //             setSpin({})
    //             console.log('e', e)
    //             errorMessage()
    //         })
    // }

    let columns = [
        {
            title: 'Name',
            dataIndex: 'typeName',
        },
        {
            title: 'Fee Cost',
            dataIndex: 'feeCost',
            sorter: (a, b) => a.feeCost - b.feeCost,
            showSorterTooltip: false,
        },
        // {
        //     title: 'Actions',
        //     key: 'actions',
        //     align: 'center',
        //     render: (e) => <span>
        //         <EditTwoTone onClick={() => setEdit(e)} />&nbsp;&nbsp;&nbsp;
        //         <Popconfirm
        //             title={`Delete ${convertTitle(e?.typeName)}?`}
        //             okText='Delete'
        //             cancelText='Cancel'
        //             okButtonProps={{
        //                 type: 'primary',
        //                 className: 'form-button'
        //             }}
        //             cancelButtonProps={{ style: { borderRadius: 4 } }}
        //             onConfirm={() => deleteType(e?._id)}
        //         >
        //             <DeleteOutlined style={{ color: 'red',cursor:'pointer' }} />
        //             {spin[e?._id] ? <span className='demo-loading-container'>
        //                 &nbsp;<Spin />
        //             </span> : null}
        //         </Popconfirm>
        //     </span>
        // }
    ]

    return (
        <div className='flex-column menu-main'>

            <div>
                {/* <div className='button-end button-end-scroll'>
                    <Button
                        type='primary'
                        className='btn-end'
                        onClick={() => setShowAddType(true)}
                    >
                        Add Type
                    </Button>
                </div> */}
                <div className='user-table'>
                    <Table
                        columns={columns}
                        // dataSource={productTypes}
                        loading={loading}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: 400 }}
                    />
                </div>
            </div>
        </div>
    )
}

export default Payments

