import { CheckCircleFilled } from '@ant-design/icons'
import { Button, Select, Spin, Table } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN } from '../../../utils/apis'
import { allAdminData, assignMessage, checkSelectOption, convertTitle, errorMessage, successNotification, validateTime } from '../../../utils/helpers'

const { Option } = Select


const AdminPayments = (props) => {
    const { user, adminActions, history, language, selectedLanguage } = props
    const buUsers = useSelector(state => state?.adminReducer?.buUsers || [])
    const [loading, setLoading] = useState(false)
    const [payments, setPayments] = useState([])
    const [spin, setSpin] = useState({})
    const [selectedUser, setSelectedUser] = useState(buUsers?.[0]?._id || null)
    const isEnglish = language?.language === 'english'
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };

    useEffect(() => {
        allAdminData?.getBusinessUser(adminActions, user?._id)
    }, [])
    useEffect(() => {
        getPayments()
    }, [selectedUser])

    const getPayments = () => {
        setLoading(true)
        axios.get(`${ADMIN.GET_ADMIN_PAYMENTS}/${selectedUser}`)
            .then((res) => {
                const { data } = res
                setPayments(data?.data || [])
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const changePaymentStatus = (_id, adminPaid) => {
        setSpin({ [_id]: true })
        let obj = {
            _id,
            adminPaid,
            userId: user?._id

        }
        axios.post(ADMIN?.CHANGE_PAYMENT_STATUS, obj)
            .then((res) => {
                const { data } = res
                setSpin({})
                getPayments()
                if (data?.success) {
                    successNotification(assignMessage(data?.message, language?.language))
                } else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                console.log('e', e)
                setSpin({})
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.ProductName}</p>,
            dataIndex: ['voucher', 'product', 'name'],
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.TotalAmount}</p>,
            dataIndex: ['voucher', 'invoice', 'totalAmount'],
            sorter: (a, b) => a?.voucher?.invoice?.totalAmount - b?.voucher?.invoice?.totalAmount,
            showSorterTooltip: false,
            render: (e) => <p style={{ marginBottom: 0 }}>€ {e}</p>

        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Quantity}</p>,
            dataIndex: ['voucher', 'invoice', 'quantity'],
            sorter: (a, b) => a?.voucher?.invoice?.quantity - b?.voucher?.invoice?.quantity,
            showSorterTooltip: false,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Status}</p>,
            width: 115,
            align: 'center',
            render: (e) => <> {e?.adminPaid ? <CheckCircleFilled style={{ color: 'green' }} /> :
                <>{spin[e?._id] ? <Spin /> :
                    <Button onClick={() => changePaymentStatus(e?._id, !e?.adminPaid)}>{selectedLanguage?.Pay}</Button>
                }</>
            }</>,
            sorter: (a, b) => a?.adminPaid - b?.adminPaid,
            showSorterTooltip: false,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.VoucherDate}</p>,
            dataIndex: ['voucher', 'voucherDate'],
            sorter: (a, b) => moment(a?.voucher?.voucherDate).unix() - moment(b?.voucher?.voucherDate).unix(),
            showSorterTooltip: false,
            render: (e) => <>{moment(e).format('YYYY-MM-DD')}</>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Vouchertime}</p>,
            dataIndex: ['voucher', 'voucherTime'],
            align: 'center',
            render: (e) => validateTime(e)
        },
    ]

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote7}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>
                    <h3 className='new-exam-title'>{selectedLanguage?.Payments}</h3>
                    <Select
                        showArrow
                        allowClear={false}
                        defaultValue={selectedUser}
                        onChange={(e) => setSelectedUser(e)}
                        className='form-input'
                        style={{ width: 200, margin: '12px', marginBottom: '0px', marginTop: '20px' }}
                        placeholder={selectedLanguage?.SelectUser}
                        showSearch
                        optionFilterProp='children'
                        filterOption={checkSelectOption}
                    >
                        {buUsers?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.fullName)} ${v?.email}`}</Option>)}
                    </Select>
                    <div className='new-layout-table-upper-div'>
                        <Table
                            style={{ marginTop: '30px' }}
                            className='new-layout-table'
                            locale={customLocale}
                            columns={columns}
                            dataSource={payments}
                            loading={loading}
                            pagination={{ hideOnSinglePage: true }}
                            scroll={{ x: 600 }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminPayments

