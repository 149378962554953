import { Image, Spin, Table } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { GET } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import NoData from "../../assets/noDataImae.png"

const MyGiftCards = (props) => {
    const { user, history, selectedLanguage, language } = props
    const [requests, setRequests] = useState([])
    const [loading, setLoading] = useState(false)
    const customLocale = {
         emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle:selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    useEffect(() => {
        getRequests()
        getPaymentStatus()
    }, [])
    const getPaymentStatus = () => {
        if (user?._id) {
            setLoading(true)
            axios.get(`${GET.PAYMENT_STATUS}/${user?._id}`)
                .then((res) => {
                    history?.replace(allPaths.GIFT_CARDS)
                })
                .catch((e) => {
                })
        }
    }

    const getRequests = () => {
        setLoading(true)
        axios.get(`${GET.GET_GUEST_UPDATED_GIFT_CARDS}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setRequests(data?.data)
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
            })
    }

    const columns = [
        {
            title:<p className='table-title-p'>{ selectedLanguage?.Image}</p>,
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            render: (e) => <Image src={e} style={{ marginRight: 10, width: 86, height: 60 }} />
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_SeriesName}</p>,
            dataIndex: 'seriesName',
            key: 'seriesName',
            sorter: (a, b) => a.seriesName.length - b.seriesName.length,
            showSorterTooltip: false,

        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Code}</p>,
            dataIndex: 'giftCardCode',
            key: 'giftCardCode',
        },
        {
            title:<p className='table-title-p'>{ selectedLanguage?.Amount}</p>,
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => a.amount - b.amount,
            showSorterTooltip: false,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {e}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_IsUsed}</p>,
            dataIndex: 'isUsed',
            key: 'isUsed',
            sorter: (a, b) => a.isUsed - b.isUsed,
            showSorterTooltip: false,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? selectedLanguage?.True : selectedLanguage?.False}</p>
        },
        {
            title:<p className='table-title-p'>{selectedLanguage?.title_isAdmin}</p>,
            dataIndex: 'isAdmin',
            key: 'isAdmin',
            sorter: (a, b) => a.isAdmin - b.isAdmin,
            showSorterTooltip: false,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? selectedLanguage?.True : selectedLanguage?.False}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Created}</p>,
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p>{moment(e).utc().format('YYYY-MM-DD')}</p>
        }
    ]

    return (
        <div className='new-layout-table-upper-div'>
            <Table
                className='new-layout-table'
                locale={customLocale}
                loading={loading}
                dataSource={requests}
                columns={columns}
                pagination={{ hideOnSinglePage: true }}
                scroll={{ x: 800 }}
            />
        </div>
        // {/* <div className='MobileTable sidebar-scroll_class' style={{ overflow: 'scroll', width: '100%' }} >
        //     <div style={{ marginTop: '10px', marginBottom: '5px', width: '100%', minWidth: '650px', height: `calc(100vh - 310px)` }}>
        //         <div style={{
        //             width: '100%',
        //             height: '60px',
        //             background: 'white',
        //             borderTopRightRadius: '4px',
        //             borderTopLeftRadius: '4px',
        //             display: 'flex',
        //             flexDirection: 'row',
        //             justifyContent: 'flex-start',
        //             padding: '5px',
        //             alignItems: 'center',
        //             border: '1px solid #C2C2C2',
        //             borderBottom: '0px solid '
        //         }}>
        //             <p style={{ fontWeight: '500', margin: '0px', width: '80px' }}>
        //                 {selectedLanguage?.Image}
        //             </p>
        //             <p style={{ fontWeight: '500', margin: '0px', width: '120px' }}>
        //                 {selectedLanguage?.title_SeriesName}
        //             </p>
        //             <p style={{ fontWeight: '500', margin: '0px', width: '100px' }}>
        //                 {selectedLanguage?.Code}
        //             </p>
        //             <p style={{ fontWeight: '500', margin: '0px', width: '80px' }}>
        //                 {selectedLanguage?.Amount}
        //             </p>
        //             <p style={{ fontWeight: '500', margin: '0px', width: '80px' }}>
        //                 {selectedLanguage?.title_IsUsed}
        //             </p>
        //             <p style={{ fontWeight: '500', margin: '0px', width: '80px' }}>
        //                 {selectedLanguage?.title_isAdmin}
        //             </p>
        //             <p style={{ fontWeight: '500', margin: '0px', width: '80px' }}>
        //                 {selectedLanguage?.Created}
        //             </p>
        //         </div>
        //         <div
        //             className='sidebar-scroll_class'
        //             style={{
        //                 width: '100%',
        //                 background: 'white',
        //                 borderBottomRightRadius: '4px',
        //                 borderBottomLeftRadius: '4px',
        //                 justifyContent: 'space-between',
        //                 padding: '5px',
        //                 alignItems: 'center',
        //                 border: '1px solid #C2C2C2',
        //                 height: `calc(100vh - 366px)`,
        //                 overflow: 'scroll'
        //             }}>
        //             {loading ?
        //                 <div style={{ marginTop: '15px', textAlign: 'center' }}>
        //                     <Spin />
        //                 </div>
        //                 :
        //                 requests?.length ?
        //                     requests?.map((v, i) => {
        //                         console.log(v, "====v")
        //                         return (
        //                             <div
        //                                 style={{
        //                                     // backgroundColor: i % 2 == 0 ? '#ffc4a2' : 'white',
        //                                     backgroundColor: 'white',
        //                                     width: '100%',
        //                                     height: '50px',
        //                                     display: 'flex',
        //                                     flexDirection: 'row',
        //                                     justifyContent: 'flex-start',
        //                                     padding: '5px',
        //                                     alignItems: 'center',
        //                                     borderBottom: '1px solid #C2C2C2',
        //                                     margin: '0px',
        //                                     padding: '0px',

        //                                 }}>
        //                                 <p className='tableValue' style={{ width: '80px' }}>
        //                                     <img src={`${v?.imageUrl}`} style={{ width: 70, height: 47 }} />
        //                                 </p>
        //                                 <p className='tableValue' style={{ width: '120px' }}>
        //                                     {v?.seriesName || '-----'}
        //                                 </p>
        //                                 <p className='tableValue' style={{ width: '100px' }}>
        //                                     {v?.giftCardCode || '-----'}
        //                                 </p>
        //                                 <p className='tableValue' style={{ width: '80px' }}>
        //                                     € {v?.amount || 0}
        //                                 </p>
        //                                 <p className='tableValue' style={{ width: '80px' }}>
        //                                     {v?.isUsed ? selectedLanguage?.True : selectedLanguage?.False}
        //                                 </p>
        //                                 <p className='tableValue' style={{ width: '80px' }}>
        //                                     {v?.isAdmin ? selectedLanguage?.True : selectedLanguage?.False}
        //                                 </p>
        //                                 <p className='tableValue' style={{ width: '80px' }}>
        //                                     {moment(v?.created).utc().format('YYYY-MM-DD')}
        //                                 </p>

        //                             </div>
        //                         )
        //                     }) :
        //                     <div style={{ height: '160px', textAlign: 'center' }}>
        //                         <img src={NoData} style={{ height: '150px', width: '150px' }} />
        //                     </div>
        //             }

        //         </div>
        //     </div>
        // </div> */}

    )
}

export default MyGiftCards