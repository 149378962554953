import { UploadOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, Upload } from 'antd'
import axios from 'axios'
import moment from 'moment'
import { useState } from 'react'
import DatePicker from 'react-multi-date-picker'
import { POST } from '../../utils/apis'
import { assignMessage, errorMessage, successMessage, successNotification, warningMessage } from '../../utils/helpers'
import UploadFile from './UploadFile'

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 20,
            offset: 4,
        },
    },
}

const format = 'YYYY-MM'

const UpdateLottery = (props) => {
    const { onCancel, user, edit, getLottery, selectedLanguage, language } = props
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [isPublic, setisPublic] = useState(edit?._id ? edit?.isPublic : false)
    let [dates, setDates] = useState([])
    let [ePrize, setePrize] = useState(edit?.prize)
    let [formObj, setFormObj] = useState({})
    let [finalObj, setfinalObj] = useState({})
    const [fileList, setFileList] = useState([])
    let myObj = formObj

    const onSubmit = async (values) => {
        let objKeys = Object.keys(myObj)
        console.log('values', values, 'myObj', myObj, objKeys)
        if (values?.date?.length != objKeys?.length) {
            return errorMessage('Add prizes for each month!')
        }

        for (let v of objKeys) {
            if (!myObj?.[v]?.prizeLength) {
                return errorMessage('Add prizes for each month!')
            }
        }
        let promise = []

        for (var i in myObj) {
            let formdata = new FormData()
            formdata.append('userId', user?._id)
            formdata.append('isPublic', isPublic)
            formdata.append('date', i)
            for (var j in myObj[i]) {
                if (Array.isArray(myObj[i][j])) {
                    for (var k of myObj[i][j]) {
                        formdata.append('files', k?.originFileObj)
                    }
                }
                else {
                    formdata.append(j, myObj[i][j])
                }
            }

            setLoading(true)
            promise.push(await axios.post(`${POST?.SET_LOTTERY_PRIZE}`, formdata, { headers: { 'Content-Type': 'multipart/form-data' } }))
        }
        Promise.all(promise)
            .then((res) => {
                const { data } = res[0] || res
                if (data?.success) {
                    setLoading(false)
                    onCancel()
                    form.resetFields()
                    successMessage(selectedLanguage?.successMessage_SuccessfullySetlotteryPrizes)
                    setTimeout(getLottery(), 3000)
                } else {
                    setLoading(false)
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })


    }

    const onChange = (value) => {
        setePrize(value)
    };
    // UPDATE PRIZE CONTROLLER
    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage(selectedLanguage?.YouCanOnlyUploadImages)
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    const onUpdatedFinish = (values) => {
        console.log('val', values)
        values.file = fileList
        edit?._id && (values._id = edit?._id)
        values.userId = user?._id

        if (!values._id && !values?.file?.length) {
            return warningMessage(selectedLanguage?.PleaseUploadImage)
        }
        setLoading(true)

        let formData = new FormData()
        if (values?.file?.length) {
            formData.append('file', values?.file[0])
        }
        if (values?._id) {
            formData.append('_id', values?._id)
        }
        formData.append('name', values?.name)
        formData.append('isPublic', isPublic)
        if (!fileList.length && edit?.prize) {
            formData.append('imageUrl', edit?.prize)
        }
        formData.append('userId', user?._id)
        // for (const value of formData.values()) {
        //     console.log('OBJ', value)
        // }
        axios.post(POST.UPDATE_LOTTERY_PRIZE, formData)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(assignMessage(data?.message, language?.language))
                    return setTimeout(() => {
                        onCancel()
                        getLottery()
                    }, 300)
                }
                errorMessage(assignMessage(data?.message, language?.language))
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }
    return (
        <div style={{ marginTop: '25px' }}>
            {/* <div className='card-form'>
                <div className='card2' style={{ minHeight: '500px' }}> */}
            {/* <p className='heading head-center'>{edit?._id ? selectedLanguage?.Update : selectedLanguage?.Create} {selectedLanguage?.title_LotteyPrizes} {edit?._id ? `of ${edit?.date?.slice(0, 7)}` : null}</p> */}
            {!edit?._id ?
                <Form
                    layout={'vertical'}
                    onFinish={onSubmit}
                    name='dynamic_form_item' /* {...formItemLayoutWithOutLabel} */>
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input rmdpInputHeight'>
                            <Form.Item
                                name='date'
                                label={selectedLanguage?.SelectDate}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.errorMessage_PleaseselectyourDate,
                                        type: 'array',
                                    },
                                ]}
                            >
                                <DatePicker
                                    style={{
                                        width: '100%',
                                        borderRadius: '4px',
                                        height: '60px',
                                        boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                    }}
                                    value={dates}
                                    onChange={setDates}
                                    multiple
                                    sort
                                    format={format}
                                    onlyMonthPicker
                                    calendarPosition='bottom-center'
                                    selected={new Date()}
                                    minDate={moment().toDate()}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <Form.List
                        name='price'
                        label={selectedLanguage?.label_AddPrice}
                    >
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                {dates.map((field, index) => (
                                    <Form.Item
                                        name='price'
                                        label={`${selectedLanguage?.label_AddPricesOf} ${field}`}
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: selectedLanguage?.placeholder_PleaseSetPrice,
                                        //     },
                                        // ]}
                                        key={index}
                                    > <div className='form-two-input-flex'>
                                            <div className='form-two-input-flex-inner-input'>
                                                <Form.Item
                                                    key={index}
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: selectedLanguage?.errorMessage_Pleasefillinputfield,
                                                        },
                                                    ]}
                                                >
                                                    <UploadFile
                                                        key={index}
                                                        field={field}
                                                        fields={fields}
                                                        myObj={myObj}
                                                        setFormObj={setFormObj}
                                                        setfinalObj={setfinalObj}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </Form.Item>
                                ))}
                                <Form.Item>
                                    <Form.ErrorList errors={errors} />
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input form-input-new-style'
                            style={{ marginBottom: '10px', paddingTop: '12px', paddingLeft: '10px' }}
                        >
                            <Form.Item name='checkbox-group' >
                                <Checkbox
                                    defaultChecked={isPublic}
                                    onChange={(e) => setisPublic(e.target.checked)}
                                    style={{
                                        lineHeight: '10px',
                                    }}
                                >{selectedLanguage?.label_Public}</Checkbox>
                            </Form.Item>
                        </div>
                    </div>
                    <Form.Item>
                        <Button
                            style={{ height: '40px', width: '140px', margin: '2px', marginTop: '10px' }}
                            className='addproduct-text'
                            type='primary'
                            // onClick={onSubmit}
                            htmlType='submit'
                            loading={loading}
                        >
                            {selectedLanguage?.button_Submit}
                        </Button>
                    </Form.Item>
                </Form>
                :
                <Form
                    layout={'vertical'}
                    name='dynamic_form_item'
                    onFinish={onUpdatedFinish}
                // {...formItemLayoutWithOutLabel}
                >
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                initialValue={edit?.name}
                                name='name'
                                label={selectedLanguage?.title_PrizeName}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.errorMessage_PleaseEnteryourPrizeName,
                                        // type: 'array',
                                    },
                                ]}
                            >
                                <Input type='text' className='form-input-new-style' />
                            </Form.Item>
                        </div>
                    </div>
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='file'
                                label={selectedLanguage?.File}
                            >
                                <Upload
                                    initialValue={edit?.prize}
                                    name='file'
                                    multiple={false}
                                    beforeUpload={() => false}
                                    accept='image/png, image/jpeg'
                                    onChange={normFile}
                                    fileList={fileList}
                                >
                                    <Button icon={<UploadOutlined />}>{selectedLanguage?.button_clickToUpload}</Button>
                                </Upload>
                                {edit?.prize && !fileList.length ? <span><img src={`${edit?.prize}`} style={{ marginRight: 10, width: 76, height: 60 }} /></span> : null}
                            </Form.Item>
                        </div>
                    </div>
                    <Form.Item>
                        <Button
                            style={{ height: '40px', width: '140px', margin: '2px', marginTop: '10px' }}
                            className='addproduct-text'
                            type='primary'
                            htmlType='submit'
                            loading={loading}
                        >
                            {selectedLanguage?.button_Submit}
                        </Button>
                    </Form.Item>
                </Form>
            }
        </div >
    )
}

export default UpdateLottery