import { Button, Table } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import NoData from "../../assets/noDataImae.png"
import { allBUserData, createBuInvoicePdf, createInvoicePdf } from '../../utils/helpers'
import ViewCard from '../Admin/Cards/ViewCard'
const BuInvoices = (props) => {
    const { user, userActions, language, selectedLanguage } = props
    const [showInvoice, setShowInvoice] = useState(false)
    const [showOtherInvoice, setShowOtherInvoice] = useState(false)
    const [invoice, setInvoice] = useState({})
    const bu_invoices = useSelector(state => state?.userReducer?.bu_invoices || [])
    useEffect(() => {
        allBUserData?.getAllBInvoice(userActions, user?._id)
    }, [])
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    console.log('bu_invoices', bu_invoices)

    const viewInvoice = (fileUrl) => {
        setInvoice({
            designName: 'Invoice',
            fileUrl
        })

        setShowInvoice(true)
    }

    const onCancel = () => {
        setInvoice({})
        setShowInvoice(false)
    }

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.ProductType}</p>,
            dataIndex: ['productType', 'typeName'],
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.TotalAmount}</p>,
            dataIndex: 'invoiceData',
            align: 'center',
            render: (e) => <>€ {(e?.reduce((sum, { quantity }) => sum + quantity, 0) * e[0]?.feeCost) || '-'}</>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.ViewInvoice}</p>,
            key: 'invoice',
            render: (e) => <a onClick={() => {
                createBuInvoicePdf(e, user, 'view', viewInvoice)
            }}>{selectedLanguage?.View}</a>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.DownloadInvoice}</p>,
            key: 'download',
            render: (e) => <a onClick={() => {
                createBuInvoicePdf(e, user, 'download', viewInvoice)
            }
            }>{selectedLanguage?.Download}</a>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.CreateDate}</p>,
            dataIndex: 'created',
            key: 'created'
        }
    ]

    let otherColumns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.InvoiceName}</p>,
            dataIndex: ['invoiceName'],
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.TotalAmount}</p>,
            dataIndex: 'totalAmount',
            align: 'center',
            render: (e) => <>€ {e}</>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.ViewInvoice}</p>,
            key: 'invoice',
            render: (e) => <a onClick={() => {
                createInvoicePdf(e, user, 'view', viewInvoice)
            }}>{selectedLanguage?.View}</a>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.DownloadInvoice}</p>,
            key: 'download',
            render: (e) => <a onClick={() => {
                createInvoicePdf(e, user, 'download', viewInvoice)
            }}>{selectedLanguage?.Download}</a>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.CreateDate}</p>,
            dataIndex: 'created',
            key: 'created'
        }
    ]

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote7}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>
                    <div className='main-div-new-style'>
                        <p className='new-exam-title'>{selectedLanguage?.Invoices}</p>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '99%' }}>
                            {!showOtherInvoice ?
                                <Button
                                    style={{ height: '40px', width: '160px', marginTop: '10px' }}
                                    className='addproduct-text'
                                    onClick={() => setShowOtherInvoice(true)}
                                    type='primary'
                                >
                                    {selectedLanguage?.OtherInvoices}
                                </Button>
                                :
                                <Button
                                    type='primary'
                                    style={{ height: '40px', width: '160px', marginTop: '10px' }}
                                    className='addproduct-text'
                                    onClick={() => setShowOtherInvoice(false)}
                                >
                                    {selectedLanguage?.WeeklyInvoices}
                                </Button>
                            }
                        </div>
                        <br />
                        <div className='new-layout-table-upper-div'>
                            <Table
                                className='new-layout-table'
                                locale={customLocale}
                                columns={showOtherInvoice ? otherColumns : columns}
                                dataSource={showOtherInvoice ? (bu_invoices?.otherInvoices || []) : (bu_invoices?.data || [])}
                                pagination={{ hideOnSinglePage: true }}
                                scroll={{ x: 800 }}
                            />
                        </div>
                        {showInvoice ?
                            <ViewCard card={invoice} onCancel={onCancel} showData={showInvoice} {...props}/>
                            : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuInvoices