import { Image, Spin, Table } from 'antd';
import moment from 'moment-timezone';
import React from 'react';
import NoData from "../../assets/noDataImae.png"

const GuestPrizes = (props) => {
    const { guestPrizesData, loading, selectedLanguage } = props
    const customLocale = {
         emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle:selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.BusinessName}</p>,
            key: 'userId',
            render: (e) => <p>{e?.userId?.businessName}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.label_Public}</p>,
            key: 'isPublic',
            sorter: (a, b) => a.isPublic - b.isPublic,
            showSorterTooltip: false,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? selectedLanguage?.True : selectedLanguage?.False}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Created}</p>,
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{moment(e).utcOffset(0, true).format('DD-MM-YYYY')}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_PrizeName}</p>,
            key: 'name',
            render: (e) => <p>{e?.name}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_Prize}</p>,
            dataIndex: 'prize',
            key: 'prize',
            // render: (e) => <img src={`${e}`} style={{ marginRight: 10, width: 86, height: 60 }} />
            render: (e) => {
                return <Image src={`${e}`} style={{ marginRight: 10, width: 86, height: 60 }} />
            }
        }
    ]
    return (
        <div className='new-layout-table-upper-div'>
            <Table
                className='new-layout-table'
                locale={customLocale}
                loading={loading}
                dataSource={guestPrizesData}
                columns={columns}
                pagination={{ hideOnSinglePage: true }}
                scroll={{ x: 800 }}
            />
        </div>
    )
}

export default GuestPrizes