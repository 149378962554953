import React, { useState, useRef } from 'react'
import { Form, Input, Button } from 'antd'
import { requiredMessage, inputPlace, successMessage, errorMessage, assignMessage } from '../../utils/helpers'
import { AUTH } from '../../utils/apis'
import axios from 'axios'

const ChangePassword = (props) => {
    const { history, user, authActions, language, selectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const [btnText, setBtnText] = useState(selectedLanguage?.UpdatePassword)
    const isEnglish = language?.language === 'english'
    const antdForm = useRef(null)

    const onFinish = (values) => {
        values = { ...values, ...user }
        setLoading(true)
        setBtnText(selectedLanguage?.Updating)
        axios.post(AUTH.CHANGE_PASSWORD, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setBtnText(selectedLanguage?.UpdatePassword)
                if (data.success) {
                    successMessage(selectedLanguage?.txt_PasswordUpdatedSuccessfully)
                    antdForm.current.resetFields(['password', 'newPassword', 'confirm'])
                    return authActions.loginUser(data.user)
                }
                errorMessage(assignMessage(data?.message, language?.language))
            })
            .catch((e) => {
                setLoading(false)
                setBtnText(selectedLanguage?.UpdatePassword)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    return (
        <div>
            <Form
                name='form'
                onFinish={onFinish}
                layout={'vertical'}
                requiredMark={false}
                ref={antdForm}
            >

                <div className='form-two-input-flex'>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='password'
                            label={selectedLanguage?.CurrentPassword}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourCurrentPassword
                                },
                                {
                                    min: 6,
                                    message: selectedLanguage?.PasswordMustBeAtLeastCharacters
                                }
                            ]}
                        >
                            <Input.Password
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.InputYourCurrentPasswordHere}
                            />
                        </Form.Item>
                    </div>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='newPassword'
                            label={selectedLanguage?.NewPassword}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourNewPassword
                                },
                                {
                                    min: 6,
                                    message: selectedLanguage?.PasswordMustBeAtLeastCharacters
                                }
                            ]}
                        >
                            <Input.Password
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.InputYourNewPasswordHere}
                            />
                        </Form.Item>
                    </div>
                </div>
                <div className='form-input-single-input'>
                    <Form.Item
                        name='confirm'
                        label={selectedLanguage?.ConfirmPassword}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.PleaseInputYourConfirmpassword,
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(selectedLanguage?.TheTwoPasswordsThatYouEntereddoNotMatch)
                                },
                            })
                        ]}
                    >
                        <Input.Password
                            placeholder={selectedLanguage?.InputYourConfirmPasswordHere}
                            className='form-input-new-style'
                        />
                    </Form.Item>
                </div>
                <Form.Item>
                    <Button
                        style={{ height: '40px', width: '160px', marginTop: '10px' }}
                        className='addproduct-text'
                        type='primary'
                        htmlType='submit'

                        loading={loading}
                    >
                        {btnText}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default ChangePassword