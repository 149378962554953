import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import axios from 'axios';
import { ADMIN } from '../../utils/apis';

const UserDetail = (props) => {
    const { user, adminActions, onCancel, userDetail, language, selectedLanguage } = props
    const [userData, setuserData] = useState({})

    useEffect(() => {
        getDetail()
    }, [])

    const getDetail = () => {
        axios.get(`${ADMIN.GET_USER_DETAILS}/${userDetail?._id}`)
            .then((res) => {
                const { data } = res
                setuserData(data)
            })
            .catch((e) => {
                console.log('e', e)
            })
    }


    return (
        <>
            <Modal
                open={userDetail?._id}
                onOk={onCancel}
                onCancel={onCancel}
                okText={selectedLanguage?.Ok}
                cancelText={selectedLanguage?.Cancel}
                className='pop-info-modal'
                style={{ top: '10px' }}
            >
                <p className='new-p-question'>{selectedLanguage?.UserDetail}</p>
                <div>{selectedLanguage?.TotalProductsCreated} : {userData?.success ? userData?.totalProductsCreated : <Spin />}</div>
                <div>{selectedLanguage?.TotalPrivate} : {userData?.success ? userData?.totalPrivateProducts : <Spin />}</div>
                <div>{selectedLanguage?.TotalActivated}: {userData?.success ? userData?.totalActiveApprovedProducts : <Spin />}</div>
            </Modal>
        </>
    );
};

export default UserDetail;