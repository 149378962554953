import { Button, Image, Modal, Popconfirm, Table } from 'antd'
import axios from 'axios'
import HTML2Canvas from 'html2canvas'
import jsPDF from 'jspdf'
import moment from 'moment'
import QRCode from 'qrcode.react'
import React, { useEffect, useState } from 'react'
import { IoIosArrowBack } from "react-icons/io"
import { useSelector } from 'react-redux'
import NoData from "../../assets/noDataImae.png"
import { POST } from '../../utils/apis'
import { allUserData, assignMessage, errorMessage, successMessage, validateTime } from '../../utils/helpers'
import ReviewForm from "../GuestReview/ReviewForm"
import Details from './Details'

const Vouchers = (props) => {
    const { user, userActions, language, selectedLanguage } = props
    const [visible, setVisible] = useState(false)
    const [details, setDetails] = useState({})
    const [productDetails, setProductDetails] = useState({})
    const vouchers = useSelector(state => state?.userReducer?.vouchers || [])
    const [reviewData, setReviewData] = useState(null)
    const [download, setDownload] = useState(true)
    const [cancelSpin, setCancelSpin] = useState({})
    const [downloadLoading, setDownloadLoading] = useState(false)
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    useEffect(() => {
        allUserData?.getAllVoucher(userActions, user?._id)
    }, [])

    const downloadFunc = (e) => {
        setProductDetails(e)
        setDownload(true)
        setTimeout(() => {
            downloadQR1()
        }, 1000)
    }
    const getDateDiff = (voucherDate, voucherTime, cancelHours) => {
        let format = 'YYYY-MM-DD HH:mm'
        let voucherDateTime = moment(voucherDate).format('YYYY-MM-DD') + ' ' + voucherTime
        let currentDateAndTime = moment().format('YYYY-MM-DD HH:mm')
        let momentvoucherDateTime = moment(voucherDateTime, format)
        let momentcurrentDateAndTime = moment(currentDateAndTime, format)
        let diff = momentvoucherDateTime.diff(momentcurrentDateAndTime, 'h')

        return diff >= cancelHours
    }

    const cancelReservation = (e) => {
        setCancelSpin({ [e?._id]: true })
        let obj = {
            userId: user?._id,
            cancelHours: e?.product?.cancelHours,
            quantity: e?.invoice?.quantity,
            voucherDate: e?.voucherDate,
            voucherTime: validateTime(e?.voucherTime),
            reservationId: e?.invoice?.reservationId,
            settingsId: e?.invoice?.settingsId,
            shiftsId: e?.invoice?.shiftsId,
            voucherId: e?._id,
            voucherCode: e?.voucherCode,
            invoiceId: e?.invoice?._id
        }
        // return true
        axios.post(POST?.VOUCHER_CANCELLATION, obj)
            .then((res) => {
                const { data } = res
                setCancelSpin({})
                if (data?.success) {
                    allUserData?.getAllVoucher(userActions, user?._id)
                    successMessage(assignMessage(data?.message, language?.language))
                } else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setCancelSpin({})
                console.log('e', e)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.Image}</p>,
            align: 'center',
            dataIndex: ['product', 'imageUrl'],
            render: (e) =>
                <Image src={e} style={{ marginRight: 10, width: 'auto', height: 60 }} shape='square' />
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.ProductName}</p>,
            dataIndex: ['product', 'name'],
            key: 'voucherName',
            // sorter: true,
            sorter: (a, b) => a?.product?.name?.length - b?.product?.name?.length,
            showSorterTooltip: false,
            // ...getColumnSearchProps(['product', 'name'])
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.TotalAmount}</p>,
            dataIndex: ['invoice', 'totalAmount'],
            align: 'center',
            sorter: (a, b) => a?.invoice?.totalAmount - b?.invoice?.totalAmount,
            showSorterTooltip: false,
            render: (e) => <p style={{ marginBottom: 0 }}>€ {e}</p>
        },

        {
            title: <p className='table-title-p'>{selectedLanguage?.QRCode}</p>,
            align: 'center',
            render: (e) => (
                <div>
                    <Button onClick={() => (setDownload(false), setProductDetails(e), setVisible(true))}>{selectedLanguage?.View}</Button>
                    &nbsp;
                    <Button onClick={() => { setProductDetails(e); downloadFunc(e) }}>{selectedLanguage?.Download}</Button>
                </div>
            )
        },
        // {
        //     title: selectedLanguage?.Claimed,
        //     dataIndex: 'isClaim',
        //     sorter: (a, b) => a.isClaim - b.isClaim,
        //     render: (e) => <>{e ? 'True' : 'False'}</>
        // },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Status}</p>,
            // dataIndex: 'isClaim',
            // sorter: (a, b) => a.isClaim - b.isClaim,
            align: 'center',
            render: (e) => <>{e?.isCancel ?
                <p style={{ color: 'red', marginBottom: 0 }}>{selectedLanguage?.status_CANCELLED}</p>
                : e?.isClaim ?
                    <p style={{ color: '#4CAF50', marginBottom: 0 }}>{selectedLanguage?.Claimed}</p> :
                    <p style={{ color: '#4CAF50', marginBottom: 0 }}>{selectedLanguage?.Reserved}</p>}</>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.VoucherDate}</p>,
            dataIndex: 'voucherDate',
            sorter: (a, b) => a?.voucherDate?.length - b?.voucherDate?.length,
            showSorterTooltip: false,
            render: (e) => <>{moment(e).format('YYYY-MM-DD')}</>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.VoucherTime}</p>,
            dataIndex: 'voucherTime',
            align: 'center',
            render: (e) => validateTime(e)
        },
        {
            key: 'action',
            render: (e) => (
                <div style={{ display: 'flex' }}>
                    {e?.isClaim ? <Button onClick={() => { setReviewData(e?.product) }} >{selectedLanguage?.WriteAReview}</Button> : null}
                    &nbsp;
                    {e?.isCancel || e?.isClaim ?
                        <Button disabled={e?.isCancel || e?.isClaim} danger loading={cancelSpin[e?._id]} >{selectedLanguage?.Cancel}</Button>
                        : <Popconfirm
                            title={getDateDiff(e?.voucherDate, validateTime(e?.voucherTime), e?.product?.cancelHours) ? selectedLanguage?.popupMessage_AreYouSureYouWantToCancel : `${selectedLanguage?.popupMessage_IfYouCancelThisReservation} ${e?.product?.cancelHours} hr `}
                            okText={selectedLanguage?.Yes}
                            cancelText={selectedLanguage?.No}
                            okButtonProps={{
                                type: 'primary',
                                className: 'form-button'
                            }}
                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                            onConfirm={() => cancelReservation(e)}
                        >
                            <Button disabled={e?.isCancel || e?.isClaim} danger loading={cancelSpin[e?._id]} >{selectedLanguage?.Cancel}</Button>
                        </Popconfirm >}
                    &nbsp;
                    <Button onClick={() => setDetails(e)}>{selectedLanguage?.Details}</Button>
                </div>)
        }

    ]

    const disableModal = () => setVisible(false)

    const downloadQR = async () => {
        downloadFunc(productDetails)
    }

    const downloadQR1 = async () => {
        setDownloadLoading(true)
        let id1 = 'qrCodeDownload'
        const canvas = document.getElementById(id1)

        await HTML2Canvas(canvas, { allowTaint: true, useCORS: true })
            .then((data) => {
                const pngUrl = data.toDataURL()
                let downloadLink = document.createElement('a')
                downloadLink.href = pngUrl
                var doc = new jsPDF('p', 'mm');
                doc.addImage(pngUrl, 'PNG', 10, 10);
                doc.save(`Voucher.pdf`);
                setDownload(false)
                setDownloadLoading(false)
            })
            .catch((e) => {
                setDownloadLoading(false)
                setDownload(false)
                console.log('e', e)
                errorMessage(selectedLanguage?.txt_downloaderror)
            })
    }

    const showQR = () => {
        return (
            <Modal
                open={visible}
                // title={selectedLanguage?.VoucherCode}
                onCancel={disableModal}
                className='pop-info-modal'
                style={{ top: '10px' }}
                footer={null/* [
                    <Button key='back'
                        style={{ height: '40px', width: '120px', marginTop: '10px' }}
                        className='addproduct-text'
                        type='primary'
                        onClick={disableModal}>
                        {selectedLanguage?.Close}
                    </Button>
                ] */}
            >
                <p className='new-p-question'>{selectedLanguage?.VoucherCode}</p>
                <div
                    id='qrCode' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: 10 }}
                >
                    <div id='productImage' style={{
                        textAlign: 'center',
                        width: '90%',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                        marginBottom: '10px',
                        paddingTop: '10px',
                        borderRadius: '8px'
                    }}>
                        <img src={productDetails?.product?.imageUrl} style={{ maxWidth: '100%', height: 200 }} />
                        <p className='heading head-center' style={{ marginBottom: 0 }}>{productDetails?.product?.name}</p>
                        <p className='product_desc'>{productDetails?.product?.description}</p>
                    </div>
                    <div className='voucherDetailVeiw'>
                        <div>
                            <QRCode
                                value={productDetails?.voucherCode}
                                size={200}
                                level={'H'}
                                includeMargin={true}
                            />
                            <p style={{ marginTop: -10 }}>{productDetails?.voucherCode}</p>
                        </div>
                        <div style={{ textAlign: 'start', marginTop: 'auto', marginBottom: 'auto' }}>
                            <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.UserName}:</span> {productDetails?.buUser?.businessName}</div>
                            <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.TelNo}:</span> {productDetails?.buUser?.telNo}</div>
                            <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.ReservedDate}:</span> {moment(productDetails?.voucherDate).format('YYYY-MM-DD')}</div>
                            <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.ReservedTime}:</span> {validateTime(productDetails?.voucherTime)}</div>
                            <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.Quantity}:</span> {productDetails?.invoice?.quantity}</div>
                            <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.TotalAmount}:</span>€ {productDetails?.invoice?.totalAmount}</div>
                            <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.GuestName}:</span> {productDetails?.userId?.fullName}</div>
                        </div>
                    </div>
                    <br />
                </div>
                {/* <center><Button type='primary' style={{backgroundColor:'#e39161'}} loading={ downloadLoading} onClick={() => downloadQR(productDetails)}> {selectedLanguage?.DownloadQR} </Button></center> */}
                <center><p
                    style={{ height: '40px', width: '160px', marginTop: '10px', paddingTop: '10px' }}
                    className='addproduct-text'
                    type='primary'
                    onClick={() => downloadQR(productDetails)}> {selectedLanguage?.DownloadQR} </p></center>
            </Modal>
        )
    }

    console.log('vouchers', vouchers)
    const onCancel = () => {
        setReviewData(null)
    }
    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote6}</p>
            </div>

            <div style={{ background: 'white', padding: '10px 0px 10px 0px' }}>
                <p className='new-exam-title'>{details?._id ? selectedLanguage?.Details : selectedLanguage?.Vouchers}</p>
                {/* {details?._id ?
                    <div style={{ textAlign: 'right', width: '100%' }}>
                        <Button
                            style={{ height: '40px', width: '160px', marginTop: '10px' }}
                            className='addproduct-text'
                            type='primary'
                            onClick={
                                () => setDetails({})
                            }
                        >
                            {selectedLanguage?.Cancel}
                        </Button>
                    </div> : null} */}
                {showQR()}
                <div className='fixed-qr' >
                    <div
                        id='qrCodeDownload' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: 10, width: 490 }}
                    >
                        <div id='productImage' style={{ textAlign: 'center', width: 480, border: '2px solid black', padding: 20/* , borderRight:'3px solid black' */ }}>
                            <img src={productDetails?.product?.imageUrl} style={{ maxWidth: '100%', height: 200 }} />
                            <p className='heading head-center' style={{ marginBottom: 0 }}>{productDetails?.product?.name}</p>
                            <p className='product_desc'>{productDetails?.product?.description}</p>
                        </div>
                        <br />
                        <div style={{
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            border: '2px solid black',
                            width: 480
                        }}>
                            <div>
                                <QRCode
                                    value={productDetails?.voucherCode}
                                    size={200}
                                    level={'H'}
                                    includeMargin={true}
                                />
                                <p style={{ marginTop: -10 }}>{productDetails?.voucherCode}</p>
                            </div>
                            <div style={{ textAlign: 'start', marginTop: 'auto', marginBottom: 'auto' }}>
                                <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.UserName}:</span> {productDetails?.buUser?.businessName}</div>
                                <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.TelNo}:</span> {productDetails?.buUser?.telNo}</div>
                                <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.ReservedDate}:</span> {moment(productDetails?.voucherDate).format('YYYY-MM-DD')}</div>
                                <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.ReservedTime}:</span> {validateTime(productDetails?.voucherTime)}</div>
                                <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.Quantity}:</span> {productDetails?.invoice?.quantity}</div>
                                <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.TotalAmount}:</span>€ {productDetails?.invoice?.totalAmount}</div>
                                <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.GuestName}:</span> {productDetails?.userId?.fullName}</div>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
                {details?._id ?
                    <Details onBack={() => setDetails({})} details={details} buUser={details?.buUser} invoice={details?.invoice} product={details?.product}  {...props} />
                    :
                    <>
                        <ReviewForm reviewData={reviewData} onCancel={onCancel}  {...props} />
                        <br />
                        <div className='new-layout-table-upper-div'>
                            <Table
                                className='new-layout-table'
                                locale={customLocale}
                                columns={columns}
                                dataSource={vouchers}
                                pagination={{ hideOnSinglePage: true }}
                                scroll={{ x: 1000 }}
                            />
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default Vouchers