import { StarOutlined, StarTwoTone } from '@ant-design/icons'
import { Button, Form, Input, Modal, Radio, Result, Select, Tooltip } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import LottieLoading from '../../assets/LottieLoading.gif'
import logo from '../../assets/worldImg.png'
import { GET, POST } from '../../utils/apis'
import { allPaths, thesisOptions } from '../../utils/constants'
import { assignMessage, errorMessage, successMessage, } from '../../utils/helpers'
import { loginUser } from '../../Redux/actions/authActions'
import NewTimerComponent from '../TimerComponent/NewTimerComponent'
import TimerComponent from '../TimerComponent/TimerComponent'
import PreviewMaths from '../PreviewMaths'
import DragSequence from '../DragDrop/DragSequence'
import DragDropStudent from '../DragDrop/DragDropStudent'
import OverviewBoxes from '../Overview/OverviewBox'


const { Option } = Select

const NewFormTest = (props) => {

    const history = useHistory()
    const { formData, selectedLanguage, handleSuccess, user, language } = props
    const isEnglish = language?.language === 'english'
    const [selectedAns, setSelectedAns] = useState(null)
    const [allQuestions, setAllQuestions] = useState(formData || [])
    const [form] = Form.useForm()
    const [currentQuestion, setCurrentQuestion] = useState(0)
    const [currentQuestionObj, setCurrentQuestionObj] = useState({})
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [currentQuestionIsWithImg, setCurrentQuestionIsWithImg] = useState('')
    const [loading, setLoading] = useState(false)
    const [nextLoading, setNextLoading] = useState(true)
    const [resultModal, setResultModal] = useState(false)
    const [quesChng, setQuesChng] = useState(false)
    const [points, setPoints] = useState(0)
    const [chPoints, setChPoints] = useState({})
    const [chResults, setChResults] = useState({})
    const [paragraphResults, setparagraphResults] = useState({})
    const [passed, setPassed] = useState(false)
    const [examInProgress, setExamInProgress] = useState(true)
    const [timeEnd, setTimeEnd] = useState(false)
    const dispatch = useDispatch()


    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])
    useEffect(() => {
        setCurrentQuestionObj(allQuestions || {})
        setCurrentQuestionIsWithImg(allQuestions?.file?.baseUrl || allQuestions?.file?.url || '')
    }, [currentQuestion, allQuestions])


    const handleBeforeUnload = (event) => {
        event.returnValue = 'You have an ongoing exam. Are you sure you want to leave?'
        event.preventDefault()
    }

    const gotoNextQuestion = () => {
        declareAnswer(currentQuestionObj, form.getFieldValue(currentQuestionObj?._id))
        onfinish()
    }


    // const gotoNextQuestion = (timeFinished = false) => {
    //     // console.log('hello ====>')
    //     // setSelectedAns(null)
    //     setQuesChng(!quesChng)

    //     if (currentQuestion + 1 === formData?.questions?.length) {

    //         if (currentQuestionObj?.answerType !== 'dragDrop' && currentQuestionObj?.answerType !== 'dragDropSequence') {
    //             declareAnswer(currentQuestionObj, form.getFieldValue(currentQuestionObj?._id))
    //         }
    //         onfinish()
    //         setCurrentQuestion(currentQuestion + 1)
    //     } else if (currentQuestion < formData?.questions?.length) {
    //         setNextLoading(true)

    //         if (currentQuestionObj?.answerType !== 'dragDrop' && currentQuestionObj?.answerType !== 'dragDropSequence') {
    //             declareAnswer(currentQuestionObj, form.getFieldValue(currentQuestionObj?._id))
    //         }

    //         for (let i = currentQuestion + 1; i < formData?.questions?.length; i++) {
    //             let nextQ = allQuestions[i]
    //             if (i + 1 > allQuestions?.length) {
    //                 onfinish()
    //                 setCurrentQuestion(currentQuestion + 1)
    //                 return
    //             } else if (!(nextQ?.answerGiven && nextQ?.fixTime)) {
    //                 setCurrentQuestion(i)
    //                 break
    //             }
    //         }

    //         if (timeFinished) {
    //             onfinish()
    //         }
    //     }
    // }

    // const previousQuestion = () => {

    //     if (currentQuestionObj?.answerType !== 'dragDrop' && currentQuestionObj?.answerType !== 'dragDropSequence') {
    //         declareAnswer(currentQuestionObj, form.getFieldValue(currentQuestionObj?._id))
    //     }
    //     setQuesChng(!quesChng)
    //     for (let i = currentQuestion - 1; i >= 0; i--) {
    //         let previous = allQuestions[i]
    //         if (!previous?.fixTime) {
    //             setCurrentQuestion(i)
    //             break
    //         } else if (i === 0) {
    //             errorMessage(selectedLanguage?.txt_PreviousOnehadaFixedTime)
    //         }
    //     }
    // }
    const getUser = () => {
        axios.get(`${GET?.USER_BY_ID}/${user._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    dispatch(loginUser(data.user))
                }
            })
            .catch((e) => {
                console.log('error', e)
            })
    }


    const declareAnswer = (currQues, givenResponse) => {
        if (currQues?.answerType === 'radio' || currQues?.answerType === 'single') {

            let answerGiven = givenResponse || null

            currQues.answerGiven = givenResponse || null
            currQues.correct = (currQues?.answerList?.find((x) => x?.option === answerGiven))?.isTrue || false


        } else if (currQues?.answerType === 'hotspot') {
            let answerGiven = givenResponse || null

            currQues.answerGiven = givenResponse || null
            currQues.correct = (currQues?.answerList?.find((x) => x?.file?.perUrl === answerGiven))?.isTrue || false

        } else if (currQues?.answerType === 'inputNumber') {

            let answerGiven = givenResponse || null

            currQues.answerGiven = answerGiven
            currQues.correct = Number(answerGiven) === currQues?.answerList?.[0]?.option

        } else if (currQues?.answerType === 'multiple') {

            let answerGiven = givenResponse || []
            let trueAns = currQues?.answerList?.filter((x) => x?.isTrue) || []
            let answerLength = trueAns?.length

            currQues.answerGiven = answerGiven
            currQues.correct = trueAns.filter((x) => answerGiven.includes(x?.option)).length === answerLength && answerLength === answerGiven?.length


        } else if (currQues?.answerType === 'thesis') {
            let answerGiven = givenResponse || null
            currQues.answerGiven = givenResponse || null
            let firstTrue = currQues?.answerList?.[0]?.isTrue;
            let secondTrue = currQues?.answerList?.[1]?.isTrue;
            let result
            if (firstTrue && !secondTrue) {
                result = "first";
            } else if (!firstTrue && secondTrue) {
                result = "second";
            } else if (firstTrue && secondTrue) {
                result = "both";
            } else {
                result = "none";
            }



            if (answerGiven === 'first') {
                currQues.correct = result === 'first' ? true : false
            }
            else if (answerGiven === 'second') {
                currQues.correct = result === 'second' ? true : false
            }
            else if (answerGiven === 'both') {
                currQues.correct = result === 'both' ? true : false
            }
            else if (answerGiven === 'none') {
                currQues.correct = result === 'none' ? true : false
            }
        }
        setAllQuestions(currQues)
    }

    const handleChangeName = (e) => {
        let value = e.target.value
        setName(value)
    }

    const handleChangeEmail = (e) => {
        let value = e.target.value
        setEmail(value)
    }

    const onfinish = () => {
        setExamInProgress(false)
        setResultModal(true)
    }

    const saveResult = () => {
        if (user?.userType === 'guest') {

            let obj = {
                questionId: allQuestions?._id,
                buserId: allQuestions?.userId?._id,
                userId: user?._id,
                correct: allQuestions?.correct,
                productId: allQuestions?.product?._id
            }

            axios.post(POST?.ADD_QUESTION_RESULT_GET_PRODUCT, obj)
                .then((res) => {
                    const { data } = res
                    console.log('data=273', data?.data)
                    if (data?.success) {
                        successMessage(assignMessage(data?.message, language?.language))
                        history.replace({
                            pathname: allPaths?.GUEST_DAYDEALS_PRODUCT,
                            state: { data: data?.data }
                        })
                    } else {
                        errorMessage(assignMessage(data?.message, language?.language))
                    }
                })
                .catch((e) => {
                    errorMessage(assignMessage(e?.message, language?.language))
                })
        } else {
            setResultModal(false)
            return history.push({ pathname: allPaths?.BU_DAYDEALS_QUESTION })
        }



    }

    const saveHotspotResponse = (response) => {
        setSelectedAns(response)
    }

    const changeQuestion = (data) => {
        let index = allQuestions.findIndex((v) => v?._id === data?._id)
        if (timeEnd) {
            return errorMessage(selectedLanguage?.txt_Thetimehasended)
        } else if (data?.fixTime) {
            return errorMessage(selectedLanguage?.txt_YouretryQuestionwithFixTime)
        }
        setCurrentQuestion(index)
        setResultModal(false)
        setExamInProgress(true)
    }

    const Loginuser = () => {
        history.push(allPaths.LOGIN)
    }

    return (
        <div>
            <div>
                <div className='header-with-text'>
                    <div>
                        <img src={logo} style={{ height: '30px' }} alt='Logo' />
                    </div>
                    <NewTimerComponent
                        triggerFunc={() => (examInProgress && gotoNextQuestion(true), setTimeEnd(true))}
                        formData={formData}
                        {...props}
                    />
                </div>

                <br />
                <div className='student-main-div student-part-scroll_class'>
                    <p className={currentQuestionIsWithImg ? 'heading padd-left-21' : 'heading head-center'}>{formData?.title}</p>
                    <center>
                        <div>
                            <h2 className={'head-center'}>
                                {selectedLanguage?.title_Question} : {currentQuestionObj?.question}
                            </h2>
                            <Form
                                name='form'
                                form={form}
                                layout='vertical'
                            >
                                {currentQuestionObj?.answerType === 'radio' ? (
                                    <div className='question_div'>
                                        {currentQuestionIsWithImg &&
                                            <>
                                                <div className='form-div-img1'>
                                                    <img className='question-img1' src={currentQuestionIsWithImg} />
                                                </div>
                                            </>
                                        }
                                        <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                            <Form.Item
                                                name={`${currentQuestionObj?._id}`}
                                                label={selectedLanguage?.title_Answer}
                                            >
                                                <Radio.Group>
                                                    {(currentQuestionObj?.answerList || [])?.map((radioAnswerlist, radioI) => (
                                                        <Radio
                                                            className='student_radio'
                                                            value={radioAnswerlist?.option}
                                                            key={radioI}
                                                        >
                                                            <div style={{ display: 'flex' }}>
                                                                <p className='normalP'>{currentQuestionObj?.subject?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={radioAnswerlist?.option || ' '} /> : radioAnswerlist?.option}</p>
                                                                {(radioAnswerlist?.file?.url || radioAnswerlist?.file?.preview) && <img style={{ marginLeft: 10 }} width={100} src={(radioAnswerlist?.file?.baseUrl || radioAnswerlist?.file?.url || radioAnswerlist?.file?.preview)} alt='' />}
                                                            </div>
                                                        </Radio>
                                                    ))}
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>
                                    </div>
                                ) : currentQuestionObj?.answerType === 'hotspot' ? (
                                    <div className='question_div'>
                                        {currentQuestionIsWithImg &&
                                            <div className='form-div-img1'>
                                                <img className='question-img1' src={currentQuestionIsWithImg} />
                                            </div>
                                        }
                                        <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                            <Form.Item
                                                name={`${currentQuestionObj?._id}`}
                                                label={selectedLanguage?.title_Answer}
                                            >
                                                <Radio.Group onChange={e => saveHotspotResponse(e?.target?.value)}>
                                                    {(currentQuestionObj?.answerList || [])?.map((radioAnswerlist, radioI) => (
                                                        <Radio
                                                            className='hotspot_radio'
                                                            value={radioAnswerlist?.file?.perUrl}
                                                            key={radioI}
                                                        >
                                                            <div style={((selectedAns === radioAnswerlist?.file?.perUrl) || (selectedAns === radioAnswerlist?.file?.preview)) ? { backgroundColor: 'lightsalmon', padding: 5 } : { padding: 5 }}>
                                                                {(radioAnswerlist?.file?.url) && <img className='hotspot_image' src={radioAnswerlist?.file?.baseUrl || radioAnswerlist?.file?.url} alt='' />}
                                                            </div>
                                                        </Radio>
                                                    ))}
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>
                                    </div>
                                ) : currentQuestionObj?.answerType === 'multiple' ? (
                                    <div className='question_div'>
                                        {currentQuestionIsWithImg &&
                                            <div className='form-div-img1'>
                                                <img className='question-img1' src={currentQuestionIsWithImg} />
                                            </div>
                                        }
                                        <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                            <Form.Item
                                                name={`${currentQuestionObj?._id}`}
                                                label={selectedLanguage?.title_Answer}
                                            >
                                                <Select
                                                    style={{ minWidth: 200 }}
                                                    className='form-input-new-style'
                                                    showArrow
                                                    allowClear
                                                    mode='multiple'
                                                    placeholder={selectedLanguage?.placeholder_SelectMultipleOption}
                                                >
                                                    {(currentQuestionObj?.answerList || []).map((multipleAnswerlist, MultipleI) => (
                                                        <Option value={multipleAnswerlist?.option} key={MultipleI}>
                                                            <div style={{ display: 'flex' }}>
                                                                <p className='normalP'>{currentQuestionObj?.subject?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={multipleAnswerlist?.option || ' '} /> : multipleAnswerlist?.option}</p>
                                                                {(multipleAnswerlist?.file?.preview || multipleAnswerlist?.file?.url) && <img style={{ marginLeft: 10 }} width={50} src={(multipleAnswerlist?.file?.preview || multipleAnswerlist?.file?.baseUrl || multipleAnswerlist?.file?.url)} alt='' />}
                                                            </div>
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                ) : currentQuestionObj?.answerType === 'single' ? (
                                    <div className='question_div'>
                                        {currentQuestionIsWithImg &&
                                            <div className='form-div-img1'>
                                                <img className='question-img1' src={currentQuestionIsWithImg} />
                                            </div>
                                        }
                                        <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                            <Form.Item
                                                name={`${currentQuestionObj?._id}`}
                                                label={selectedLanguage?.title_Answer}
                                            >
                                                <Select
                                                    style={{ minWidth: 200 }}
                                                    className='form-input-new-style'
                                                    showArrow
                                                    allowClear
                                                    placeholder={selectedLanguage?.placeholder_SelectSingleOption}
                                                >
                                                    {(currentQuestionObj?.answerList || []).map((singleAnswerlist, SingleI) => (
                                                        <Option value={singleAnswerlist?.option} key={SingleI}>
                                                            <div style={{ display: 'flex' }}>
                                                                <p className='normalP'>{currentQuestionObj?.subject?.toLowerCase()?.includes('math') ? <PreviewMaths show={true} sentence={singleAnswerlist?.option || ' '} /> : singleAnswerlist?.option}</p>
                                                                {(singleAnswerlist?.file?.preview || singleAnswerlist?.file?.url) && <img style={{ marginLeft: 10 }} width={50} src={(singleAnswerlist?.file?.preview || singleAnswerlist?.file?.baseUrl || singleAnswerlist?.file?.url)} alt='' />}
                                                            </div>
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                ) : currentQuestionObj?.answerType === 'thesis' ? (
                                    <div className='question_div'>
                                        {currentQuestionIsWithImg &&
                                            <div className='form-div-img1'>
                                                <img className='question-img1' src={currentQuestionIsWithImg} />
                                            </div>
                                        }
                                        <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                            <div>
                                                {currentQuestionObj?.answerList?.map((v, i) => {
                                                    return (
                                                        <>
                                                            <p style={{ margin: '0px', width: '250px', textAlign: 'start' }}>Thesis {i + 1} : {v?.option}</p>
                                                        </>
                                                    )
                                                })}
                                                <br />
                                                <div className='question_div'>
                                                    <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                                        <Form.Item
                                                            name={`${currentQuestionObj?._id}`}
                                                            label={selectedLanguage?.title_Answer}
                                                        >
                                                            <Radio.Group>
                                                                {(thesisOptions || [])?.map((radioAnswerlist, radioI) => (
                                                                    <Radio
                                                                        className='student_radio'
                                                                        value={radioAnswerlist?.value}
                                                                        key={radioI}
                                                                    >{radioAnswerlist?.OptionEnglish}
                                                                    </Radio>
                                                                ))}
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='question_div'>
                                        {currentQuestionIsWithImg &&
                                            <div className='form-div-img1'>
                                                <img className='question-img1' src={currentQuestionIsWithImg} />
                                            </div>
                                        }
                                        <div className={!currentQuestionIsWithImg ? '' : 'form-div1Image'}>
                                            <Form.Item
                                                name={`${currentQuestionObj?._id}`}
                                                label={selectedLanguage?.title_Answer}
                                            >
                                                {currentQuestionObj?.answerType === 'inputNumber' ?
                                                    <Input
                                                        className='form-input-new-style'
                                                        placeholder='Numeric Answer'
                                                        type='number'
                                                        pattern='[0-9]*'
                                                    />
                                                    :
                                                    <Input
                                                        className='form-input-new-style'
                                                    />
                                                }
                                            </Form.Item>
                                        </div>
                                    </div>
                                )}
                            </Form>
                        </div>
                    </center>
                    <div className='test-footer'>
                        <p style={{ margin: 0, marginRight: 4, fontWeight: 600 }}>
                            We have only one question in a day
                        </p>
                        <Button
                            type='primary'
                            onClick={() => gotoNextQuestion(false)}
                        >
                            {'Submit >'}
                        </Button>
                    </div>
                </div>
            </div >

            {resultModal &&
                <Modal footer={null} closable={false} title='' open={resultModal}>
                    <Result
                        status={'success'}
                        title={'Successfully Submited'}
                        extra={[
                            <Button type='primary' key='result' loading={loading} onClick={saveResult}>
                                View to linked product
                            </Button>
                        ]}
                    >
                    </Result>
                </Modal>}
        </div >
    )
}

export default NewFormTest
