import { Button, Modal, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ADMIN } from '../../../utils/apis'
import { assignMessage, successMessage } from '../../../utils/helpers'

const GiftRequests = (props) => {
    const { user, selectedLanguage, language } = props
    const [requests, setRequests] = useState([])
    const [loading, setLoading] = useState(false)
    const [detail, setDetail] = useState({})
    const [showDetails, setShowDetails] = useState(false)
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    useEffect(() => {
        getRequests()
    }, [])

    const getRequests = () => {
        setLoading(true)
        axios.get(`${ADMIN.GET_ALL_GIFT_CARD_REQUEST}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setRequests(data?.data)
            }).catch((err) => {
                setLoading(false)
            })
    }

    const handleOk = () => {
        setShowDetails(false)
    }

    const acceptRequests = (e) => {
        let obj = { _id: e?._id, adminId: user?._id }
        axios.post(`${ADMIN.ACCEPT_GIFT_CARD_REQUET}`, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                successMessage(assignMessage(data?.message, language?.language))
                getRequests()
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
            })
    }
    
    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.txtGiftCardCode}</p>,
            dataIndex: 'giftCardCode',
            key: 'giftCardCode',
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_RequesterEmail}</p>,
            dataIndex: ['requesterId', 'email'],
            key: 'UserEmail',
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_RequesterName}</p>,
            dataIndex: ['requesterId', 'fullName'],
            key: 'fullName',
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Price}</p>,
            dataIndex: ['giftCard', 'amount'],
            key: 'amount',
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Actions}</p>,
            key: 'actions',
            render: (e) => (
                <>
                    <Button onClick={() => {
                        setDetail(e?.requesterId)
                        setShowDetails(true)
                    }} > {selectedLanguage?.txt_ViewDetail}</Button >
                    &nbsp; &nbsp; &nbsp;
                    {
                        e?.isApproved == true ?
                            <Button disabled> {selectedLanguage?.txt_Accepted}</Button>
                            :
                            <Button onClick={() => acceptRequests(e)}>{selectedLanguage?.txt_Accept}</Button>
                    }
                </>
            )
        }
    ]

    return (
        <div>
            <div className='new-layout-table-upper-div'>
                <Table
                    style={{ marginTop: '30px' }}
                    className='new-layout-table'
                    locale={customLocale}
                    loading={loading}
                    dataSource={requests}
                    columns={columns}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 800 }}
                />
            </div>
            <Modal
                open={showDetails}
                onOk={handleOk}
                onCancel={handleOk}
                footer={null}
                className='pop-info-modal'
                style={{ top: '10px' }}
            >
                <p className='new-p-question'>{selectedLanguage?.UserDetail}</p>
                <ul>
                    <li>{selectedLanguage?.txt_fullName}: {detail?.fullName ? detail?.fullName : '-------'} </li>
                    <li>{selectedLanguage?.txt_email}: {detail?.email ? detail?.email : '-------'} </li>
                    <li>{selectedLanguage?.txt_bankAccount}: {detail?.bankAccount ? detail?.bankAccount : '-------'}</li>
                    <li>{selectedLanguage?.txt_IBAN}: {detail?.IBAN ? detail?.IBAN : '-------'}</li>
                    <li>{selectedLanguage?.txt_Address}: {detail?.streetNumber ? detail?.streetNumber : '-------'}</li>
                </ul>
            </Modal>
        </div>
    )
}

export default GiftRequests