import { Button, Spin, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { GET } from '../../utils/apis'
import { assignMessage, createGiftCard, errorMessage, successNotification } from '../../utils/helpers'
import AcceptGiftCards from './AcceptGiftCards'
import AddGiftCards from './AddGiftCards'
import MyGiftCards from './MyGiftCards'
import SettledUsers from './SettledUsers'
import { IoIosArrowBack } from "react-icons/io"
import { allPaths } from '../../utils/constants'
import NoData from "../../assets/noDataImae.png"

const GuestGiftCards = (props) => {
    const { user, selectedLanguage, getUser, history, location, language } = props
    const [showCreate, setShowCreate] = useState(false)
    const [loading, setLoading] = useState(false)
    const [cards, setCards] = useState([])
    const [showAcceptGiftCards, setShowAcceptGiftCards] = useState(false)
    const [remainingCards, setRemainingCards] = useState(0)
    const [showMyGiftCards, setShowMyGiftCards] = useState(false)
    const [showSettledUsers, setShowSettledUsers] = useState(false)
    const [spin, setSpin] = useState({})
    const userId = new URLSearchParams(location.search).get('userId') || null
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    useEffect(() => {
        getCards()
        getPaymentStatus()
    }, [])

    const getPaymentStatus = () => {
        if (userId) {
            setLoading(true)
            axios.get(`${GET.PAYMENT_STATUS}/${userId}`)
                .then((res) => {
                    const { data } = res
                    getUser()
                    setLoading(false)
                    history?.replace(allPaths.GIFT_CARDS)
                    if (data.success) {
                        getCards()
                        return successNotification(assignMessage(data?.message, language?.language))
                    } else {
                        console.log('e', data)
                        return errorMessage(assignMessage(data?.message, language?.language))
                    }
                })
                .catch((e) => {
                    console.log('e', e)
                    setLoading(false)
                    errorMessage(assignMessage(e?.message, language?.language))
                })
        }

    }
    const allStatesFalse = () => {
        setShowCreate(false)
        setShowAcceptGiftCards(false)
        setShowMyGiftCards(false)
        setShowSettledUsers(false)
    }

    const getCards = () => {
        setLoading(true)
        axios.get(`${GET.GET_ALL_USER_GIFT_CARDS}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setRemainingCards(data?.remainingCards)
                let tableData = Object.entries(data?.data)
                tableData = tableData?.map((v) => {
                    return {
                        id: v[0],
                        value: v[1]
                    }
                })
                setCards([...tableData])
            })
            .catch((err) => {
                console.log('err', err)
                setLoading(false)
            })
    }

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_SeriesName}</p>,
            key: 'id',
            dataIndex: 'id',
            ellipsis: true,
            sorter: (a, b) => a.id.length - b.id.length,
            showSorterTooltip: false,
        },
        {
            _key: 'actions',
            get key() {
                return this._key
            },
            set key(value) {
                this._key = value
            },
            render: (e) => {
                const spinFunc = () => {
                    let newSpin = { ...spin }
                    newSpin[e?.id] = false
                    setSpin(newSpin)
                }
                return (<>
                    <div style={{ paddingTop: 15, marginTop: -5, display: 'flex', flexDirection: 'column' }}>
                        {spin?.[e?.id] ? <Spin /> :
                            <a onClick={() => (setSpin({ [e?.id]: true, ...spin }), createGiftCard(e?.value, spinFunc))}>{selectedLanguage?.ShowTotalPdfs}</a>
                        }
                    </div>
                </>)
            }
        }
    ]

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote7}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>
                    <div className='div-flex-back-name'>

                        <h3 className='new-exam-title'>{
                            showSettledUsers ?
                                selectedLanguage?.title_settlement :
                                showMyGiftCards ?
                                    selectedLanguage?.button_MyGiftCards :
                                    showCreate ?
                                        selectedLanguage?.button_CreateGiftCards :
                                        selectedLanguage?.title_GiftCards}</h3>

                    </div>
                    {!showAcceptGiftCards && !showMyGiftCards && !showSettledUsers && !showCreate ?
                        null
                        :
                        <div style={{ textAlign: 'right', width: '99.5%', marginRight: '10px' }}>
                            <Button
                                style={{ height: '40px', width: '140px', margin: '2px', marginTop: '10px' }}
                                type='primary'
                                onClick={allStatesFalse}>
                                {selectedLanguage?.Back}
                            </Button>
                        </div>
                    }
                    <div >
                        {showCreate ?
                            <AddGiftCards getCards={getCards} remainingCards={remainingCards} onCancel={() => setShowCreate(false)} {...props} />
                            :
                            <>
                                {!showAcceptGiftCards && !showMyGiftCards && !showSettledUsers ?
                                    <div
                                        className='button-flex-box' style={{ width: '99.5%' }}>
                                        <Button
                                            style={{ height: '40px', width: '140px', margin: '2px', marginTop: '10px' }}
                                            className='addproduct-text'
                                            type='primary'
                                            onClick={() => setShowSettledUsers(true)}
                                        >
                                            {selectedLanguage?.title_settlement}
                                        </Button>
                                        <Button
                                            style={{ height: '40px', width: '140px', margin: '2px', marginTop: '10px' }}
                                            className='addproduct-text'
                                            type='primary'
                                            onClick={() => setShowMyGiftCards(true)}
                                        >
                                            {selectedLanguage?.button_MyGiftCards}
                                        </Button>
                                        < AcceptGiftCards {...props} />
                                        <Button
                                            style={{ height: '40px', width: '140px', margin: '2px', marginTop: '10px' }}
                                            className='addproduct-text'
                                            type='primary'
                                            onClick={() => setShowCreate(true)}
                                        >
                                            {selectedLanguage?.button_CreateGiftCards}
                                        </Button>
                                    </div>
                                    : null
                                }
                                <div style={{ marginTop: '15px' }}>
                                    {showMyGiftCards ?
                                        <MyGiftCards {...props} />
                                        :
                                        showSettledUsers ?
                                            <SettledUsers {...props} />
                                            :
                                            <div className='new-layout-table-upper-div'>
                                                <Table
                                                    className='new-layout-table'
                                                    locale={customLocale}
                                                    loading={loading}
                                                    dataSource={cards}
                                                    columns={columns}
                                                    pagination={{ hideOnSinglePage: true }}
                                                    scroll={{ x: 800 }}
                                                />
                                            </div>
                                    }
                                </div >
                            </>
                        }
                    </div >
                </div>
            </div>
        </div>
    )
}

export default GuestGiftCards