import { ALL_USERS, ALL_SEGMENTS, ALL_CARDS, PROVISION_REPORTS, USERS_INVOICES, MESSAGES_LIST, REPORTS, FINANCIAL_REPORTS, STOCK_REPORTS, ALL_PUBLIC_CARDS, POSTAL_CARDS, ALL_BUSINESS_USERS, ALL_GROUPS, ADMIN_FORM_REPORTS, VOUCHERS, ALL_GUEST_USERS, ALL_SUB_ADMINS, RATES, ADMIN_FAQ } from '../types'

const addAllUsers = (users) => {
    return {
        type: ALL_USERS,
        users
    }
}

const addAllBusinessUsers = (buUsers) => {
    return {
        type: ALL_BUSINESS_USERS,
        buUsers
    }
}

const addAllVouchers = (voucher) => {
    return {
        type: VOUCHERS,
        voucher
    }
}

const addAllPayments = (payments) => {
    return {
        type: ALL_BUSINESS_USERS,
        payments
    }
}

const addAllGuestUsers = (gUsers) => {
    return {
        type: ALL_GUEST_USERS,
        gUsers
    }
}
const addAllSubAdmins = (subAdmins) => {
    return {
        type: ALL_SUB_ADMINS,
        subAdmins
    }
}
const setRates = (rates) => {
    return {
        type: RATES,
        rates
    }
}

const addAllGroups = (groups) => {
    return {
        type: ALL_GROUPS,
        groups
    }
}

const addAdminFaq = (adminFaq) => {
    return {
        type: ADMIN_FAQ,
        adminFaq
    }
}

export {
    addAllUsers,
    addAllBusinessUsers,
    addAllVouchers,
    addAllPayments,
    addAllGuestUsers,
    addAllSubAdmins,
    setRates,
    addAllGroups,
    addAdminFaq
}