import { CITIES, COLLAPSED, RATES, TAXES } from '../types'

const setCollapsed = (inlineCollapsed) => {
    return {
        type: COLLAPSED,
        inlineCollapsed
    }
}

const setCities = (cities) => {
    return {
        type: CITIES,
        cities
    }
}
const setTaxes = (taxes) => {
    return {
        type: TAXES,
        taxes
    }
}

export {
    setCollapsed,
    setCities,
    setTaxes
}