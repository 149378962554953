import { ALL_USERS, ALL_SEGMENTS, ALL_CARDS, USERS_INVOICES, MESSAGES_LIST, REPORTS, FINANCIAL_REPORTS, STOCK_REPORTS, ALL_PUBLIC_CARDS, POSTAL_CARDS, ALL_BUSINESS_USERS, ALL_GROUPS, ADMIN_FORM_REPORTS, PROVISION_REPORTS, PAYMENTS, VOUCHERS, ALL_GUEST_USERS, ALL_SUB_ADMINS, RATES, ADMIN_FAQ, REMOVE_USER } from '../types'

const reducer = (state = {}, action) => {
    switch (action.type) {
        case ALL_USERS: {
            return { ...state, users: action.users }
        }
        case ALL_BUSINESS_USERS: {
            return { ...state, buUsers: action.buUsers }
        }
        case PAYMENTS: {
            return { ...state, payments: action.payments }
        }
        case VOUCHERS: {
            return { ...state, vouchers: action.vouchers }
        }
        case ALL_GUEST_USERS: {
            return { ...state, gUsers: action.gUsers }
        }
        case ALL_SUB_ADMINS: {
            return { ...state, subAdmins: action.subAdmins }
        }
        case RATES: {
            return { ...state, rates: action.rates }
        }
        case ALL_GROUPS: {
            return { ...state, groups: action.groups }
        } 
        case ADMIN_FAQ: {
            return { ...state, adminFaq: action.adminFaq }
        }
        case REMOVE_USER: {
            return {}
        }
        default: {
            return state
        }
    }
}

export default reducer