import { Modal } from "antd";
import React, { useState } from "react";
import { allPaths, contentType, drawerAdminRoutes, drawerGuestRoutes, drawerRoutes } from '../../utils/constants'
import { TfiMenu } from 'react-icons/tfi'
import { MdOutlineClose } from 'react-icons/md'
import { Link } from "react-router-dom"; 

const AdminMenuModal = (props) => {
    const { user, language, selectedLanguage } = props 
    const isEnglish = language?.language === 'english'
    const isEgypt = language?.language === 'egypt' 
    const currentPage = window?.location?.pathname
    const [menu, setMenu] = useState(false)



    const handleClose = () => {
        setMenu(false)
    }

    return (
        <>
            <div className="new-header-men-icon" onClick={() => setMenu(true)}>
                <TfiMenu size={20} />
            </div>
            <Modal
                className="my-modal-for-menu"
                open={menu}
                onCancel={() => setMenu(false)}
                footer={null}
            >
                <div className="cloxe-div-end">
                    <p className="upper-header-txt-logo-menu">
                        CITY-DEALS
                        <span>
                            {selectedLanguage?.text_EASYLEARNINGBYSMARTTESTING}
                        </span>
                    </p>
                    <div className="cloxe-div" onClick={() => setMenu(false)}>
                        <MdOutlineClose size={35} />
                    </div>
                </div>

                <div className="my-modal-menu-div new-layout-scroll-class">
                    {(drawerAdminRoutes)?.map((v, i) => {
                        if (v?.isSubMenu && v?.children?.length) {
                            return (
                                <div key={v?.route}>
                                    <span style={{ color: 'white' }}
                                        className={false ? "modal-menu-itm-active" : "modal-menu-itm"}
                                    >{isEnglish ? v.title : isEgypt ? v?.titleEgypt : v.titleNl}</span>
                                    <br />
                                    <br />
                                    {v?.children?.map((y, j) => {
                                        return (
                                            <p key={y?.route}
                                                onClick={handleClose}
                                            >
                                                <Link to={y?.route} >
                                                    <span className={y?.route === currentPage ?
                                                        "modal-menu-itm-child-active" : "modal-menu-itm-child"} style={{ color: 'white' }} >◉{' '}{isEnglish ? y.title : isEgypt ? y?.titleEgypt : y.titleNl}</span>
                                                </Link>
                                            </p>
                                        )
                                    })}
                                </div>

                            )
                        }
                        return (
                            <p key={v.route}
                                onClick={handleClose}
                            >
                                <Link to={v?.route} >
                                    <span className={v?.route === currentPage ? "modal-menu-itm-active" : "modal-menu-itm"} style={{ color: 'white' }} >{isEnglish ? v.title : isEgypt ? v?.titleEgypt : v.titleNl}</span>
                                </Link>
                            </p>
                        )
                    })}
                </div>
            </Modal>
        </>
    )
}

export default AdminMenuModal