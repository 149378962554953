import { EuroCircleOutlined, GiftOutlined, HomeOutlined, ProjectOutlined, QuestionOutlined } from '@ant-design/icons'
import { CardGiftcard, Group } from '@mui/icons-material';
import { AiFillSetting } from "react-icons/ai"
import { FaQuestionCircle } from "react-icons/fa"
import SettingsIcon from '@mui/icons-material/Settings';
import Reviews from '@mui/icons-material/Reviews';
import apiUrl from '../Config/api'
import allPaths from '../Config/paths'
import ReceiptIcon from '@mui/icons-material/Receipt'
import GroupsIcon from '@mui/icons-material/Groups';
import MoneyIcon from '@mui/icons-material/Money';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard'

const bgColor = '#0adc00'
const googleClientId = '202056254581-atll6adadjh6hpum280usr41a6o7uvhc.apps.googleusercontent.com'
// const pdfURL = 'https://res.cloudinary.com/dklfq58uq/image/upload/v1657027850/name_zfttup.pdf'
const pdfURL = 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'

const drawerRoutes = [
    {
        title: 'Product',
        titleNl: 'Producten',
        titleEgypt: 'منتجات',
        route: allPaths?.BU_PRODUCT,
        icon: <ProjectOutlined />
    },
    {
        title: 'Financial',
        titleNl: 'Financieel',
        titleEgypt: 'مالي',
        route: allPaths?.BUFINANCIAL,
        isSubMenu: true,
        icon: <EuroCircleOutlined />,
        key: allPaths?.BUFINANCIAL,
        children: [
            {
                title: 'Vouchers',
                titleNl: 'Vouchers',
                titleEgypt: 'قسائم',
                route: allPaths?.BUVOUCHERS,
            },
            {
                title: 'Invoices',
                titleNl: 'Facturen',
                titleEgypt: 'فواتير',
                route: allPaths?.BUINVOICES,
            },
            {
                title: 'Payments',
                titleNl: 'Betalingen',
                titleEgypt: 'دفعات',
                route: allPaths?.BUPAYMENTS,
            }
        ]
    },
    {
        title: 'DayDeals',
        titleNl: 'DayDeals',
        titleEgypt: 'DayDeals',
        route: allPaths?.BU_DAYDEALS,
        isSubMenu: true,
        icon: <EuroCircleOutlined />,
        key: allPaths?.BU_DAYDEALS,
        children: [
            {
                title: 'DayDeals Products',
                titleNl: 'DayDeals Products',
                titleEgypt: 'DayDeals Products',
                route: allPaths?.BU_DAYDEALS_PRODUCTS,
            },
            {
                title: 'Add DayDeals Question',
                titleNl: 'Add DayDeals Question',
                titleEgypt: 'Add DayDeals Question',
                route: allPaths?.BU_DAYDEALS_CREATE_QUESTION,
            },
            {
                title: 'DayDeals Question',
                titleNl: 'DayDeals Question',
                titleEgypt: 'DayDeals Question',
                route: allPaths?.BU_DAYDEALS_QUESTION,
            },
        ]
    },
    {
        title: 'Coupons',
        titleNl: 'Coupons',
        titleEgypt: 'قسائم',
        route: allPaths?.COUPON,
        icon: <CardGiftcard />
    },
    {
        title: 'Settings',
        titleNl: 'Instellingen',
        titleEgypt: 'الإعدادات',
        route: allPaths?.SIDE_SETTING,
        isSubMenu: true,
        icon: <AiFillSetting />,
        key: allPaths?.SIDE_SETTING,
        children: [
            {
                title: 'Profile',
                titleNl: 'Profiel',
                titleEgypt: 'الملف الشخصي',
                route: allPaths?.PROFILE_SETTINGS,
            },
            {
                title: 'Subscription',
                titleNl: 'Abonnement',
                titleEgypt: 'اشتراك',
                route: allPaths?.SUBSCRIPTION,
            },
            {
                title: 'Reviews',
                titleNl: 'Recensies',
                titleEgypt: 'التقييمات',
                route: allPaths?.BU_REVIEW,
            },
        ]
    },
    {
        title: 'FAQ',
        titleNl: 'FAQ',
        titleEgypt: 'الأسئلة الشائعة',
        route: allPaths?.FAQ,
        icon: <FaQuestionCircle />
    },
    {
        title: 'Lottery',
        titleNl: 'Loterij',
        titleEgypt: 'اليانصيب',
        route: allPaths.LOTTERY,
        icon: <CardGiftcardIcon />
    },
    {
        title: 'Gift Cards',
        titleNl: 'Cadeaubonnen',
        titleEgypt: 'بطاقات الهدايا',
        route: allPaths.GIFT_CARDS,
        icon: <CardGiftcardIcon />
    },
    {
        title: 'Group',
        titleNl: 'Groep',
        titleEgypt: 'المجموعة',
        route: allPaths.GROUP,
        icon: <GroupsIcon />
    }
]

const drawerGuestRoutes = [
    {
        title: 'Home',
        titleNl: 'Home',
        titleEgypt: 'الرئيسية',
        route: allPaths?.HOME,
        icon: <HomeOutlined />
    },
    {
        title: 'Private Products',
        titleNl: 'Privéproducten',
        titleEgypt: 'المنتجات الخاصة',
        route: allPaths?.PRIV_PRODUCT,
        icon: <GiftOutlined />
    },
    {
        title: 'DayDeals',
        titleNl: 'DayDeals',
        titleEgypt: 'DayDeals',
        route: allPaths?.GUEST_DAYDEALS,
        icon: <GiftOutlined />
    },
    {
        title: 'Financial',
        titleNl: 'Financieel',
        titleEgypt: 'مالي',
        route: allPaths?.FINANCIAL,
        isSubMenu: true,
        icon: <EuroCircleOutlined />,
        key: allPaths?.FINANCIAL,
        children: [
            {
                title: 'Vouchers',
                titleNl: 'Vouchers',
                titleEgypt: 'قسائم',
                route: allPaths?.VOUCHERS,
            },
            {
                title: 'Invoices',
                titleNl: 'Facturen',
                titleEgypt: 'فواتير',
                route: allPaths?.INVOICES,
            }
        ]
    },
    {
        title: 'Settings',
        titleNl: 'Instellingen',
        titleEgypt: 'الإعدادات',
        route: allPaths?.PROFILE_SETTINGS,
        isSubMenu: true,
        icon: <AiFillSetting />,
        key: allPaths?.PROFILE_SETTINGS,
    },
    {
        title: 'Gift Cards',
        titleNl: 'Cadeaubonnen',
        titleEgypt: 'بطاقات الهدايا',
        route: allPaths.GIFT_CARDS,
        icon: <CardGiftcardIcon />
    },
    {
        title: 'Lottery',
        titleNl: 'Loterij',
        titleEgypt: 'اليانصيب',
        route: allPaths.LOTTERY,
        icon: <CardGiftcardIcon />
    },
    {
        title: 'FAQ',
        titleNl: 'FAQ',
        titleEgypt: 'الأسئلة الشائعة',
        route: allPaths?.FAQ,
        icon: <FaQuestionCircle />
    },
]

const drawerAdminRoutes = [
    {
        title: 'Sub Admins',
        titleNl: 'Leveranciers',
        titleEgypt: 'المسؤولون الفرعيون',
        route: allPaths?.ADMIN_SUBADMINS,
        icon: <Group />
    },
    {
        title: 'Users',
        titleNl: 'Gebruikers',
        titleEgypt: 'المستخدمين',
        route: allPaths?.ADMIN_USERS,
        icon: <Group />
    },
    {
        title: 'Financial',
        titleNl: 'Financieel',
        titleEgypt: 'المالية',
        route: allPaths?.ADMIN_FINANCIAL,
        isSubMenu: true,
        icon: <EuroCircleOutlined />,
        key: allPaths?.ADMIN_FINANCIAL,
        children: [
            {
                title: 'Vouchers',
                titleNl: 'Vouchers',
                titleEgypt: 'القسائم',
                route: allPaths?.ADMINVOUCHERS,
            },
            {
                title: 'Payments',
                titleNl: 'Betalingen',
                titleEgypt: 'المدفوعات',
                route: allPaths?.ADMINPAYMENTS,
            },
            {
                title: 'Guest Invoices',
                titleNl: 'Consument facturen',
                titleEgypt: 'فواتير الزوار',
                route: allPaths?.ADMINGUESTINVOICES,
            },
            {
                title: 'User Invoices',
                titleNl: 'Leveranciers facturen',
                titleEgypt: 'فواتير المستخدمين',
                route: allPaths?.ADMINBUINVOICES,
            }
        ]
    },
    {
        title: 'Settings',
        titleNl: 'Instellingen',
        titleEgypt: 'الإعدادات',
        route: allPaths?.ADMIN_SETTINGS,
        isSubMenu: true,
        icon: <SettingsIcon />,
        key: allPaths?.ADMIN_SETTINGS,
        children: [
            {
                title: 'Types',
                titleNl: 'Typen',
                titleEgypt: 'الأنواع',
                route: allPaths?.ADMIN_TYPES,
            },
            {
                title: 'Products',
                titleNl: 'Producten',
                titleEgypt: 'المنتجات',
                route: allPaths?.ADMIN_PRODUCT,
            },
            {
                title: 'Price List',
                titleNl: 'Prijslijst',
                titleEgypt: 'قائمة الأسعار',
                route: allPaths?.ADMIN_PRICE_SETTINGS,
            },
            {
                title: 'Tax Settings',
                titleNl: 'Belastinginstellingen',
                titleEgypt: 'إعدادات الضرائب',
                route: allPaths?.ADMIN_TAX_SETTINGS,
            },
            {
                title: "Faq's",
                titleNl: "Veelgestelde vragen",
                titleEgypt: "الأسئلة الشائعة",
                route: allPaths?.ADMIN_FAQS,
            },
            {
                title: 'Add faq',
                titleNl: 'Faq toevoegen',
                titleEgypt: 'إضافة سؤال شائع',
                route: allPaths?.ADMIN_ADD_FAQ,
            },
        ]
    },
    {
        title: 'Report',
        titleNl: 'Rapport',
        titleEgypt: 'تقرير',
        route: allPaths?.REPORT,
        isSubMenu: true,
        icon: <ReceiptIcon />,
        key: allPaths?.REPORT,
        children: [
            {
                title: 'Payment',
                titleNl: 'Betalingen',
                titleEgypt: 'دفعات',
                route: allPaths?.PAYMENT_REPORT,
            },
            {
                title: 'Coupon',
                titleNl: 'Coupon',
                titleEgypt: 'قسيمة',
                route: allPaths?.COUPONS_REPORT,
            },
            {
                title: 'Subscription',
                titleNl: 'Aanschaf',
                titleEgypt: 'اشتراك',
                route: allPaths?.SUBSCRIPTION_REPORT,
            },
            {
                title: 'Fee',
                titleNl: 'Tarief',
                titleEgypt: 'رسوم',
                route: allPaths?.FEE_REPORT,
            },
        ]
    },
    {
        title: 'Coupons',
        titleNl: 'Coupons',
        titleEgypt: 'قسائم',
        route: allPaths?.ADMIN_COUPON,
        icon: <CardGiftcard />
    },
    {
        title: 'Reviews',
        titleNl: 'Reviews',
        titleEgypt: 'تقييمات',
        route: allPaths?.ADMIN_REVIEWS,
        icon: <Reviews />
    },
    {
        title: 'Lottery',
        titleNl: 'Loterij',
        titleEgypt: 'اليانصيب',
        route: allPaths.ADMIN_LOTTERY,
        icon: <MoneyIcon />
    },
    {
        title: 'Gift Cards',
        titleNl: 'Cadeaubonnen',
        titleEgypt: 'بطاقات الهدايا',
        route: allPaths.ADMIN_GIFT_CARDS,
        icon: <CardGiftcard />
    },
    {
        title: 'Groups',
        titleNl: 'Groepen',
        titleEgypt: 'مجموعات',
        route: allPaths.ADMIN_GROUP,
        icon: <GroupsIcon />
    }
];


const zeroString = '00'

var timeArray = [zeroString, ...Array.from(Array(96).keys()).map(v => String((v + 1) * 15))]

const constraints = {
    // facingMode: { exact: 'environment' }
    facingMode: { exact: 'user' }
}

const productTimeRange = ['morning', 'afternoon', 'evening']
const productTimeRangeLanguage = [{ name: 'morning', nameNl: 'Ochtend', nameAr: 'صباح' }, { name: 'afternoon', nameNl: 'Middag', nameAr: 'بعد الظهر' }, { name: 'evening', nameNl: 'Avond', nameAr: 'مساء' }]
const provincie = [
    'Noord-Holland',
    'Zuid-Holland',
    'Zeeland',
    'Noord-Brabant',
    'Utrecht',
    'Flevoland',
    'Friesland',
    'Groningen',
    'Drenthe',
    'Overijssel',
    'Gelderland',
    'Limburg'
]
const ageOptions = [
    '0-3',
    '4-12',
    '13-65',
    '66 and older'
]
const provincieLanguage = [
    { name: 'Noord-Holland', nameNl: 'Noord-Holland' },
    { name: 'Zuid-Holland', nameNl: 'Zuid-Holland' },
    { name: 'Zeeland', nameNl: 'Zeeland' },
    { name: 'Noord-Brabant', nameNl: 'Noord-Brabant' },
    { name: 'Utrecht', nameNl: 'Utrecht' },
    { name: 'Flevoland', nameNl: 'Flevoland' },
    { name: 'Friesland', nameNl: 'Friesland' },
    { name: 'Groningen', nameNl: 'Groningen' },
    { name: 'Drenthe', nameNl: 'Drenthe' },
    { name: 'Overijssel', nameNl: 'Overijssel' },
    { name: 'Gelderland', nameNl: 'Gelderland' },
    { name: 'Limburg', nameNl: 'Limburg' }
]

const intervalOptions = [
    {
        value: 'none',
        label: 'none',
    },
    // {
    //     value: '10',
    //     label: '10',
    // },
    {
        value: '15',
        label: '15',
    },
    // {
    //     value: '20',
    //     label: '20',
    // },
    {
        value: '30',
        label: '30',
    },
    // {
    //     value: '45',
    //     label: '45',
    // },
    {
        value: '60',
        label: '60',
    }
]

const supplierType = [
    { name: 'Restaurant', nameNl: 'Restaurant', val: 'restaurant' },
    { name: 'Hotel', nameNl: 'Hotel', val: 'hotel' },
    { name: 'Attractions', nameNl: 'Attracties', val: 'attractions' },
    { name: 'Shops', nameNl: 'Winkels', val: 'shops' }
]

const thesisOptions = [
    { value: 'first', OptionEnglish: 'Only thesis 1 is true.' },
    { value: 'second', OptionEnglish: 'Only thesis 2 is true.' },
    { value: 'both', OptionEnglish: 'Both thesises are true.' },
    { value: 'none', OptionEnglish: 'None of both thesises are true.' },
]

export {
    bgColor,
    drawerRoutes,
    drawerAdminRoutes,
    drawerGuestRoutes,
    googleClientId,
    allPaths,
    apiUrl,
    timeArray,
    constraints,
    productTimeRange,
    intervalOptions,
    pdfURL,
    provincie,
    provincieLanguage,
    productTimeRangeLanguage,
    supplierType,
    ageOptions,
    thesisOptions
}