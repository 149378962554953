import { Button, Form } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import voucher_codes from 'voucher-code-generator'
import { loginUser } from '../../Redux/actions/authActions'
import { POST } from '../../utils/apis'
import { assignMessage, errorMessage, successNotification } from '../../utils/helpers'

const ChangeCode = (props) => {
    const { onCancel, user, language, selectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const [code, setCode] = useState(user?.productCode[0] || null)
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const onFinish = () => {
        setLoading(true)
        let obj = {
            _id: user?._id,
            productCode: [code]
        }
        axios.post(POST?.CHANGE_PRIVATE_CODE, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    successNotification(assignMessage(data?.message, language?.language))
                    data.data.productCode = [code]
                    dispatch(loginUser(data?.data))
                    return setTimeout(() => {
                        onCancel()
                    }, 300)
                } else {
                    return errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log('e', e)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const generateCoupon = () => {
        let GenerateCoupon = voucher_codes.generate({
            count: 1,
            pattern: '###-###-###',
        })
        setCode(GenerateCoupon[0])
    }
    return (
        <div className='main-div-new-style'>
            <p className='new-exam-title'>{user?.productCode?.length ? selectedLanguage?.UpdatePrivateCode : selectedLanguage?.CreatePrivateCode}</p>

            <div style={{ textAlign: 'right', width: '98.5%' }}>
                <Button
                    style={{ height: '40px', width: '160px', marginTop: '10px' }}
                    className='addproduct-text'
                    type='primary'
                    onClick={onCancel}
                >
                    {selectedLanguage?.Cancel}
                </Button>
            </div>
            <div className='form-two-input-flex' style={{ marginTop: '20px' }}>

                <div className='form-two-input-flex-inner-input' style={{width: '100%' }} >
                    <div className='form-input-new-style' style={{ padding: '15px', }} >
                        {selectedLanguage?.Code}: {code}
                    </div>
                </div>
            </div>
            <div style={{ margin: '4px' }}>
                <Button
                    style={{ height: '40px', width: '160px', marginTop: '2px' }}
                    className='addproduct-text'
                    type='primary'
                    onClick={generateCoupon}
                >
                    {selectedLanguage?.Generate}
                </Button>
                <div style={{ marginTop: '20px' }}>
                    <Form
                        name='form'
                        form={form}
                        onFinish={onFinish}
                        layout={'vertical'}
                    >
                        <Form.Item>
                            <Button
                                style={{ height: '40px', width: '160px', marginTop: '2px' }}
                                className='addproduct-text'
                                type='primary'
                                htmlType='submit'
                                loading={loading}
                            >
                                {user?.productCode?.length ? selectedLanguage?.Update : selectedLanguage?.Create}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div >
    )
}

export default ChangeCode