import React from 'react'
import BusinessLottery from './BusinessLottery'
import GuestLottery from './GuestLottery'

const Lottery = (props) => {
    const { user, selectedLanguage } = props

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote1}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>
                    {user?.userType === 'business' ?
                        <BusinessLottery {...props} />
                        :
                        <GuestLottery {...props} />}
                </div>
            </div>
        </div>
    )
}

export default Lottery