import React, { useState } from 'react'
import { Button, Modal, Spin } from 'antd'
import { Document, Page } from 'react-pdf'

const ViewCard = (props) => {
    const { card, showData, onCancel, selectedLanguage } = props
    const [numPages, setNumPages] = useState(null)

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages)
    }

    return (
        <div className='flex-column menu-main'>
            <Modal
                title={card?.designName}
                open={showData}
                onCancel={onCancel}
                className='modal-view'
                footer={[
                    <Button
                        key='back'
                        onClick={onCancel}
                        className='form-input'
                    >
                        {selectedLanguage?.Return}
                    </Button>
                ]}
            >
                <Spin spinning={numPages ? false : true}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Document
                            file={card?.fileUrl}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={(e) => console.log('e', e)}
                            className='pdf-modal'
                        >
                            <Page pageNumber={numPages} />
                        </Document>
                    </div>
                </Spin>
            </Modal>
        </div>
    )
}

export default ViewCard