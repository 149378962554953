import { Button, Form, Input } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { IoIosArrowBack } from "react-icons/io"
import { useDispatch } from 'react-redux'
import { loginUser } from '../../Redux/actions/authActions'
import { POST } from '../../utils/apis'
import { assignMessage, errorMessage, successNotification, warningMessage } from '../../utils/helpers'

const AddSecretCode = (props) => {
    const { onCancel, user, getProducts, setProductCode, language, selectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const isEnglish = language?.language === 'english'
    const [lottery, setLottery] = useState('')

    const onFinish = (values) => {

        if (user?.productCode?.includes(values?.secretCode)) {
            return warningMessage(selectedLanguage?.Thiscodeisalreadyinserted)
        }

        setLoading(true)

        let obj = {
            productCode: [...user?.productCode, values?.secretCode],
            _id: user?._id
        }
        axios.post(POST?.ADD_SECRET_CODE, obj)
            .then((res) => {
                const { data } = res
                console.log('data', data)
                setLoading(false)
                if (data?.success) {
                    setProductCode(obj.productCode)
                    data.data.productCode = obj?.productCode
                    dispatch(loginUser(data?.data))
                    successNotification(assignMessage(data?.message, language?.language))
                    return setTimeout(() => {
                        setProductCode(obj.productCode)
                        getProducts()
                        onCancel()
                    }, 300)
                } else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log('e', e)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }
    const handlelotteryInput = (e) => {

        let newCode = e?.target?.value?.replace(/[^A-Za-z0-9]/g, '')
        newCode = newCode?.replace(/(.{3})/g, '$1-')
        newCode = newCode?.replace(/-$/, '')
        setLottery(newCode)
        form.setFieldsValue({
            secretCode: newCode
        })
    }
    return (
        <div className='main-div-new-style'>

            <h3 className='new-exam-title'>{selectedLanguage?.SecretCode}</h3>

            <div style={{ textAlign: 'right', width: '98.5%' }}>
                <Button
                    style={{ height: '40px', width: '160px', marginTop: '10px' }}
                    className='addproduct-text'
                    type='primary'
                    onClick={onCancel}
                >
                    {selectedLanguage?.Cancel}
                </Button>
            </div>

            <div style={{ marginTop: '20px' }}>
                <Form
                    name='form'
                    form={form}
                    onFinish={onFinish}
                    layout={'vertical'}
                >
                    <div className='form-input-single-input'>
                        <Form.Item
                            name='secretCode'
                            label={selectedLanguage?.AddSecretCode}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourSecretCode
                                }
                            ]}
                        >
                            <Input
                                className='form-input-new-style'
                                autoFocus
                                required
                                maxLength={11}
                                value={lottery}
                                onChange={handlelotteryInput}
                                placeholder='e.g: 456-786-ab6'
                            />
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <Button
                            style={{ height: '40px', width: '160px', marginTop: '10px' }}
                            className='addproduct-text'
                            type='primary'
                            htmlType='submit'
                            loading={loading}
                        >
                            {selectedLanguage?.Add}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
export default AddSecretCode