import { Popconfirm, Spin } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { AiFillDelete } from 'react-icons/ai'
import { FaEdit } from 'react-icons/fa'
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"
import { useSelector } from 'react-redux'
import { ADMIN } from '../../../../utils/apis'
import { assignMessage, errorMessage, getAdminFaq, successMessage } from "../../../../utils/helpers"
import AddAdminFaq from './AddAdminFaq'

const AdminFaq = (props) => {
    const { selectedLanguage, user, language, adminActions } = props
    const isEnglish = language?.language === 'english'
    const isEgypt = language?.language === 'egypt'
    const faqsObj = useSelector(state => state.adminReducer?.adminFaq)
    const [deleteLoading, setDeleteLoading] = useState({})
    const [readingHow, setReadingHow] = useState('')
    const [readingFaq, setReadingFaq] = useState('')
    const [edit, setEdit] = useState({})
    let FaqGroup = faqsObj?.[1] || {}
    const keys = Object.keys(FaqGroup)
    useEffect(() => {
        getAdminFaq(adminActions)
    }, [])


    console.log('isEgypt', '=================isEgypt', isEgypt)


    const deleteFaq = (id) => {
        setDeleteLoading({ [id]: true })
        let obj = {
            adminId: user?._id,
            id: id
        }
        axios.post(ADMIN?.DELETE_ADMIN_FAQ, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setDeleteLoading({})
                    successMessage(assignMessage(data?.message, language?.language))
                    getAdminFaq(adminActions)
                } else {

                    setDeleteLoading({})
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setDeleteLoading({})
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    return (
        <>

            {
                edit?._id ?
                    <AddAdminFaq onCancel={() => setEdit({})} edit={edit} {...props} />
                    :
                    <div className='new-exam-main-div'>
                        <div className='exam-transaprent-div'>
                            <p className='screen-qoute'> {selectedLanguage?.Quote3}</p>
                        </div>
                        <div className='new-exam-screen-upper'>
                            <div style={{ background: 'white', padding: '15px' }}>
                                <div className='main-div-new-style'>
                                    <div className='div-flex-back-name'>
                                        <div className='height-35'>
                                            <p className='new-screen-heading'>{selectedLanguage?.txt_HowitsWork}</p>
                                        </div>
                                    </div>

                                    <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                                        {faqsObj?.[0]?.length && faqsObj?.[0]?.map((v, i) => {
                                            return (
                                                <>
                                                    <div style={{
                                                        padding: '20px',
                                                        width: '100%',
                                                        marginTop: '20px',
                                                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                                        borderRadius: '8px',
                                                        paddingTop: '30px'
                                                    }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                            <p style={{ width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere' }}>{isEnglish ?
                                                                v?.questionEng : isEgypt ? v?.questionEgp : v?.questionNl}</p>
                                                            {readingHow !== v?._id ?
                                                                <IoIosArrowDown color='#ec671b' style={{ cursor: 'pointer' }} size={35} onClick={() => {
                                                                    setReadingHow(v?._id)
                                                                }} /> :
                                                                <IoIosArrowUp color='#ec671b' style={{ cursor: 'pointer' }} size={35} onClick={() => {
                                                                    setReadingHow('')
                                                                }} />}
                                                        </div>
                                                        {readingHow === v?._id ?
                                                            <div>
                                                                <br />
                                                                <p style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere' }}>{isEnglish ? v?.answerEng : isEgypt ? v?.answerEgp : v?.answerEng}</p>
                                                            </div> : null}
                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>

                                                            <Popconfirm
                                                                title={selectedLanguage?.txt_Areyousuredeletethisfaq}
                                                                okText={selectedLanguage?.txt_Delete}
                                                                cancelText={selectedLanguage?.txt_Cancel}
                                                                okButtonProps={{
                                                                    type: 'primary',
                                                                    className: 'form-button'
                                                                }}
                                                                cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                                onConfirm={() => {
                                                                    deleteFaq(v?._id)
                                                                }}
                                                            >
                                                                {deleteLoading[v?._id] ?
                                                                    <Spin />
                                                                    :
                                                                    < AiFillDelete
                                                                        color='#ec671b'
                                                                        size={20}
                                                                        style={{
                                                                            margin: '5px',
                                                                        }}
                                                                    />}
                                                            </Popconfirm>
                                                            < FaEdit
                                                                onClick={() => setEdit(v)}
                                                                color='#ec671b'
                                                                size={20}
                                                                style={{
                                                                    margin: '5px',
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                        }
                                    </div>
                                    <div className='div-flex-back-name'>
                                        <div className='height-35'>
                                            <p className='new-screen-heading'>{selectedLanguage?.txt_FAQs}</p>
                                        </div>
                                    </div>
                                    <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                                        {keys?.length ? keys?.map((v, i) => {
                                            let faqArr = FaqGroup?.[v]
                                            return (
                                                <>
                                                    <div className='height-35'>
                                                        <p className='new-screen-heading' style={{ textAlign: 'center' }}>{isEnglish ? faqArr?.[0].titleEng : isEgypt ? faqArr?.[0].titleEgp : faqArr?.[0].titleNl}</p>
                                                    </div>
                                                    {faqArr?.map((vv, ii) => {
                                                        return (
                                                            <>
                                                                <div
                                                                    key={vv + 1}
                                                                    style={{
                                                                        padding: '20px',
                                                                        width: '100%',
                                                                        marginTop: '20px',
                                                                        marginBottom: '20px',
                                                                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                                                        borderRadius: '8px',
                                                                        paddingTop: '30px'
                                                                    }}>
                                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                        <p style={{ width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere' }}>{isEnglish ? vv?.questionEng : isEgypt ? vv?.questionEgp : vv?.questionNl}</p>
                                                                        {readingFaq !== vv?._id ?
                                                                            <IoIosArrowDown color='#ec671b' style={{ cursor: 'pointer' }} size={35} onClick={() => {
                                                                                setReadingFaq(vv?._id)
                                                                            }} /> :
                                                                            <IoIosArrowUp color='#ec671b' style={{ cursor: 'pointer' }} size={35} onClick={() => {
                                                                                setReadingFaq('')
                                                                            }} />}
                                                                    </div>
                                                                    {readingFaq === vv?._id ?
                                                                        <div>
                                                                            <br />
                                                                            <p style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere' }}>{isEnglish ? vv?.answerEng : isEgypt ? vv?.answerEgp : vv?.answerEng}</p>
                                                                        </div> : null}
                                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                                                        <Popconfirm
                                                                            title={selectedLanguage?.txt_Areyousuredeletethisfaq}
                                                                            okText={selectedLanguage?.txt_Delete}
                                                                            cancelText={selectedLanguage?.txt_Cancel}
                                                                            okButtonProps={{
                                                                                type: 'primary',
                                                                                className: 'form-button'
                                                                            }}
                                                                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                                            onConfirm={() => {
                                                                                deleteFaq(vv?._id)
                                                                            }}
                                                                        >
                                                                            {deleteLoading[v?._id] ? <Spin /> :
                                                                                < AiFillDelete
                                                                                    color='#ec671b'
                                                                                    size={20}
                                                                                    style={{
                                                                                        margin: '5px',
                                                                                    }}
                                                                                />}
                                                                        </Popconfirm>
                                                                        < FaEdit
                                                                            onClick={() => setEdit(vv)}
                                                                            color='#ec671b'
                                                                            size={20}
                                                                            style={{
                                                                                margin: '5px',
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                </>

                                            )
                                        })
                                            :
                                            <div className='nodata-center-text'>
                                                <p>{selectedLanguage?.txt_NoFaqsaddedyet}</p>
                                            </div>}
                                    </div>
                                </div>
                            </div >
                        </div >
                    </div >
            }
        </>
    )
}

export default AdminFaq