import { UploadOutlined } from '@ant-design/icons'
import { Button, Form, Input, Select, Upload } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { GET, POST } from '../../utils/apis'
import { allUserData, assignMessage, checkSelectOption, errorMessage, warningMessage } from '../../utils/helpers'
const { Option } = Select

const AddCoupons = (props) => {
    const { onCancel, edit, user, userActions, language, selectedLanguage, rates } = props
    const isEnglish = language?.language === 'english'
    const [loading, setLoading] = useState(false)
    const [products, setproducts] = useState([])
    const [fileList, setFileList] = useState([])
    let couponRate = rates?.filter((v) => v?.subscriptionName === 'Coupons')?.[0]
    useEffect(() => {
        getProducts()
        allUserData?.getRateList(userActions, user?._id)
    }, [])

    const onFinish = (values) => {
        values.file = fileList
        edit?._id && (values._id = edit?._id)
        values.userId = user?._id

        if (!values._id && !values?.file?.length) {
            return warningMessage(selectedLanguage?.PleaseUploadImage)
        }
        if (!values.quantity) {
            return warningMessage(selectedLanguage?.QuantityCannotbeZero)
        }
        setLoading(true)

        let formData = new FormData()
        if (values?.file?.length) {
            formData.append('file', values?.file[0])
        }
        if (values?._id) {
            formData.append('_id', values?._id)
        }
        formData.append('description', values?.description)
        formData.append('quantity', Number(values?.quantity))
        formData.append('couponAmount', Number(values?.couponAmount))
        formData.append('userId', values?.userId)
        formData.append('price', couponRate?.amount)
        for (let v of values?.products) {
            formData.append('products[]', v)
        }
        console.log('values', values)
        axios.post(POST.USER_BUY_COUPONS, formData)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    window.location.href = data?.redirectUrl
                    return
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
                console.log('e', e)
            })
    }

    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage(selectedLanguage?.PleaseUploadImageonly)
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    const getProducts = () => {
        axios.get(`${GET.GET_ALL_PRODUCTS}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setproducts(data?.data?.filter((v) => v?.userId?.supplierType !== 'shops') || [])
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }
    return (
        <div  >
            <p className=''>€ {couponRate?.amount} /{selectedLanguage?.Piece}</p>
            <br />
            <Form
                name='form'
                onFinish={onFinish}
                layout={'vertical'}
                requiredMark={false}
            >
                <div className='form-two-input-flex'>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='description'
                            label={selectedLanguage?.Description}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputDescription
                                }
                            ]}
                        >
                            <Input
                                maxLength={40}
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.InputYourDescriptionHere}
                            />
                        </Form.Item>
                    </div>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='quantity'
                            label={selectedLanguage?.Quantity}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourQuantity
                                }
                            ]}
                        >
                            <div>
                                <Input
                                    type='number'
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.InputYourQuantityHere}
                                    min={1}
                                    step={1}
                                    precision={0}
                                />
                            </div>
                        </Form.Item>
                    </div>
                </div>

                <div className='form-two-input-flex'>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='couponAmount'
                            label={selectedLanguage?.Amount}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputAmount
                                }
                            ]}
                            initialValue={0}
                        >
                            <div>
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.InputAmountHere}
                                    defaultValue={0}
                                    type='number'
                                    step={0.01}
                                    min={0}
                                    precision={2}
                                />
                            </div>
                        </Form.Item>
                    </div>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='products'
                            label={selectedLanguage?.SelectProducts}
                            hasFeedback
                            initialValue={[]}
                        >
                            <Select
                                className='form-input-new-style'
                                mode='multiple'
                                placeholder={selectedLanguage?.SelectProducts}
                                showSearch
                                optionFilterProp='children'
                                filterOption={checkSelectOption}
                            >
                                {products?.map((v, i) => <Option value={v?._id} key={i}>{`${v?.name} € ${v?.price?.[0]?.price || 0}`}</Option>)}
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <div className='form-two-input-flex'>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='file'
                            label={selectedLanguage?.Image}
                        >
                            <Upload
                                name='file'
                                multiple={false}
                                beforeUpload={() => false}
                                accept='image/png, image/jpeg'
                                onChange={normFile}
                                fileList={fileList}
                            >
                                <Button icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                            </Upload>
                        </Form.Item>
                    </div>
                </div>

                <Form.Item>
                    <Button
                        style={{ height: '45px', width: '160px', marginTop: '10px', marginLeft: '2px' }}
                        className='addproduct-text'
                        htmlType='submit'
                        loading={loading}
                        type='primary'
                    >
                        {selectedLanguage?.Create}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default AddCoupons