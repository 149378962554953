import { HeartFilled, HeartOutlined } from '@ant-design/icons';
import { Card, Input, Spin } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AiFillStar } from 'react-icons/ai';
import { TbAdjustmentsHorizontal } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../Redux/actions/authActions';
import NoCards from '../../assets/NoCards.png';
import { GET, POST } from '../../utils/apis';
import { ageOptions, allPaths } from '../../utils/constants';
import { assignMessage, calculateReviewAverage, convertTitle, errorMessage, stringLimiter, successMessage, successNotification } from '../../utils/helpers';
import ProductReview from '../GuestReview/ProductReview';
import BuyProduct from './BuyProduct';
import FilterModal from './FilterModal';
import ShowShops from './ShowShops';
const GuestHome = (props) => {
    const { user, filter, history, getUser, location, language, selectedLanguage } = props
    const isEnglish = language?.language === 'english'
    const userId = new URLSearchParams(location.search).get('userId') || null
    const [loading, setLoading] = useState(false)
    const [recommendloading, setRecommendloading] = useState(false)
    const [promotionloading, setPromotionloading] = useState(false)
    const [shopsloading, setShopsloading] = useState(false)
    const [allProduct, setAllProduct] = useState([])
    const [filteredProduct, setFilteredProduct] = useState([])
    const [filterOpen, setFilterOpen] = useState(false)
    const [filterValue, setFilterValue] = useState(filter)
    const [buyProduct, setBuyProduct] = useState({})
    const [favouriteSpin, setFavouriteSpin] = useState({})
    const [reviewModal, setReviewModal] = useState({})
    const [ProgressLoading, setProgressLoading] = useState(false)
    const [userData, setUserData] = useState(user)
    const [recommendedProduct, setRecommendedProduct] = useState([])
    const [promotionProduct, setPromotionProduct] = useState([])
    const [shops, setShops] = useState([])
    const [recommendedCity, setRecommendedCity] = useState(user?.city)
    const [shopData, setShopData] = useState({})
    const cities = useSelector(state => state.generalReducer?.cities)
    const dispatch = useDispatch()
    const { Search } = Input;
    
    useEffect(() => {
        if (userId) {
            setLoading(true)
            axios.get(`${GET.PAYMENT_STATUS}/${userId}`)
                .then((res) => {
                    const { data } = res
                    getUser()
                    setLoading(false)
                    history?.replace(allPaths.HOME)
                    if (data?.success) {
                        getProducts()
                        return successNotification(assignMessage(data?.message, language?.language))
                    }
                    errorMessage(assignMessage(data?.message, language?.language))
                })
                .catch((e) => {
                    setLoading(false)
                    console.log('e', e)
                    errorMessage(assignMessage(e?.message, language?.language))
                })
        }
    }, [])

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            getProducts()
        }, 500)
    }, [filterValue])

    useEffect(() => {
        setShopsloading(true)
        setRecommendloading(true)
        setPromotionloading(true)
        setLoading(true)
        setTimeout(() => {
            getRecommendations()
            getPromotions()
            getShops()
            getProducts()
        }, 500)
    }, [recommendedCity])

    const checkDateofReservation = (settings) => {
        let lastSetting = settings[settings?.length - 1]
        let date = moment(lastSetting?.date).utcOffset(0, true)
        return !date.isBefore(moment().add(1, 'days'), 'day')
    }

    const gotoReview = (v) => {
        setProgressLoading(true)
        setReviewModal(v)
        setTimeout(() => {
            setProgressLoading(false)
        }, 2000);
    }
    const closeReviewModal = (v) => {
        setReviewModal({})
    }

    const getProducts = () => {
        let obj = {
            provincie: user?.provincie,
            productCode: user?.productCode,
            city: filterValue?.city,
            timeRange: filterValue?.timeRange,
            productType: filterValue?.productType,
        }
        axios.post(POST?.GET_PRODUCT_FOR_GUEST, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)

                if (filterValue?.favourites) {
                    setAllProduct(data?.data?.filter((v) => v?.reservation?.created && userData?.favourites?.includes(v?._id)) || [])
                    setFilteredProduct(data?.data?.filter((v) => v?.reservation?.created && checkDateofReservation(v?.reservation?.settings) && userData?.favourites?.includes(v?._id)) || [])
                } else {
                    setAllProduct(data?.data?.filter((v) => v?.reservation?.created))
                    setFilteredProduct(data?.data?.filter((v) => v?.reservation?.created && checkDateofReservation(v?.reservation?.settings)))
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log('e', e)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const changeSearch = (value) => {
        if (value?.trim()) {
            setFilteredProduct(allProduct?.filter((v) => v?.name?.toLowerCase()?.includes(value?.toLowerCase())))
        } else {
            setFilteredProduct(allProduct)
        }
    }

    const calcSoldProducts = (arr) => {
        return arr?.reduce((sum, { soldProduct }) => sum + soldProduct, 0)
    }

    const changeFav = (_id, add) => {
        setFavouriteSpin({ [_id]: true })
        let tempData = { ...userData }
        let obj = {
            favourites: [...userData?.favourites, _id],
            _id: user?._id
        }
        if (!add) {
            obj.favourites = userData?.favourites?.filter((v) => v !== _id)
        }
        tempData.favourites = obj.favourites
        axios.post(POST?.ADD_FAVOURITE_PRODUCT, obj)
            .then((res) => {
                const { data } = res
                setFavouriteSpin({})
                if (data?.success) {
                    setUserData(tempData)
                    dispatch(loginUser(tempData))
                    getProducts()
                    getRecommendations()
                    getShops()
                    successMessage(add ? selectedLanguage?.SuccessfullyAddedToFavourites : selectedLanguage?.Successfullyremovedfromfavourites)
                } else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setFavouriteSpin({})
                console.log('e', e)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const optionsCities = cities?.length && cities?.map((v, i) => {
        return {
            value: convertTitle(v)
        }
    })

    const getRecommendations = () => {
        let obj = {
            city: recommendedCity || user?.city,
            userId: user?._id
        }
        axios.post(POST?.GET_RECOMMENDATIONS, obj)
            .then((res) => {
                const { data } = res
                setRecommendloading(false)
                setRecommendedProduct(data?.data?.filter((v) => v?.reservation?.created && checkDateofReservation(v?.reservation?.settings)))
            })
            .catch((e) => {
                setRecommendloading(false)
                console.log('e', e)
            })
    }
    const getPromotions = () => {
        setPromotionloading(true)
        let obj = {
            city: recommendedCity || user?.city
        }
        axios.post(POST?.GET_PROMOTIONS, obj)
            .then((res) => {
                const { data } = res
                setPromotionloading(false)
                setPromotionProduct(data?.data?.filter((v) => v?.reservation?.created))
            })
            .catch((e) => {
                setRecommendloading(false)
                console.log('e', e)
            })
    }

    const getShops = () => {
        let obj = {
            city: recommendedCity || user?.city,
            productCode: user?.productCode,
            userId: user?._id
        }
        axios.post(POST?.GET_SHOPS, obj)
            .then((res) => {
                const { data } = res
                setShopsloading(false)
                setShops(data?.data)
            })
            .catch((e) => {
                setShopsloading(false)
                console.log('e', e)
            })
    }

    const getPrice = (product) => {
        if (!product?.agePrice) {
            return product?.price?.[0]?.price
        } else {
            return product?.price?.[0]?.[ageOptions[2]]?.price
        }
    }
    return (
        <>
            <div className='home-div-bg-img'>
                <div className='home-txt-div'>
                    <p className='new-home-first-p'>{selectedLanguage?.txt_WELCOMETOCITYDEALS} </p>
                    <p className='new-home-snd-p'>{selectedLanguage?.txt_EXPERIENCEYOULLNEVERFORGET} </p>
                    <div style={{ marginTop: '20px', width: '98%', marginTop: '10px' }}>
                        <div className='div-flex-back-name'>
                            <div>
                                <Search
                                    className='new-input-search-guest'
                                    placeholder={selectedLanguage?.New_SearchProductName || 'SearchProductName'}
                                    onSearch={(e) => setRecommendedCity(e)}
                                    enterButton />
                            </div>
                            <div
                                style={{ width: '50px', justifyContent: 'center',cursor:'pointer'  }}
                                className='new-filter-btn'
                                type={(filter?.city?.length || filter?.timeRange?.length || filter?.productType?.length || filter?.favourites)
                                    ? 'primary' : 'default'} onClick={() => setFilterOpen(true)}
                            >
                                <TbAdjustmentsHorizontal size={20}
                                    color={'rgba(0, 0, 0, 0.4)'}
                                    style={{ marginRight: '5px', marginLeft: '4px' }} />
                            </div>
                        </div>
                    </div>
                    <br />
                </div>
            </div>
            <div style={{ background: 'white' }}>
                {filterOpen && <FilterModal cities={cities} setFilterValue={setFilterValue} getProducts={getProducts} filterOpen={filterOpen} onCancel={() => setFilterOpen(false)} setFilterOpen={setFilterOpen} {...props} />}
                {!buyProduct?._id && !shopData?._id ?
                    <div>
                        <div className='cards-main-div-guest-all-crds' >
                            <div style={{ marginTop: '20px', marginBottom: '10px' }}>
                                <h1 className='new-exam-title'>{selectedLanguage?.Text_promotion}</h1>
                                <div
                                    className='sidebar-scroll_class'
                                    style={{
                                        marginTop: 8,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        overflowX: 'scroll'
                                    }}>
                                    {promotionloading ?
                                        [1, 2, 3, 4].map((v, i) => {
                                            return (<Card
                                                key={i}
                                                hoverable
                                                loading={loading}
                                                bordered={false}
                                                className='loading-card-style'
                                            />)
                                        }) :
                                        promotionProduct?.length ?
                                            promotionProduct?.map((v, i) => {
                                                return (
                                                    <div
                                                        key={i}
                                                        style={{
                                                            height: 285,
                                                            width: '290px',
                                                            minWidth: '290px',
                                                            borderRadius: '4px',
                                                            margin: '4px',
                                                            marginRight: '10px',
                                                            boxShadow: ' 0 4px 5px 0 rgba(0, 0, 0, 0.3)',
                                                            cursor: 'pointer'
                                                        }}>
                                                        <div style={{ textAlign: 'center', margin: '8px', position: 'relative' }}                                                >
                                                            <img onClick={() => setBuyProduct(v)} src={v?.imageUrl} style={{ height: '130px', width: '100%', maxWidth: '100%', borderRadius: '4px' }} />
                                                            <p style={{ position: 'absolute', top: '10px', left: '10px', background: 'white', height: '20px', width: '20px', borderRadius: '100px' }}>
                                                                {favouriteSpin[v?._id] ?
                                                                    <Spin /> :
                                                                    !userData?.favourites?.includes(v?._id) ?
                                                                        <HeartOutlined onClick={() => changeFav(v?._id, true)} style={{ color: 'red' }} />
                                                                        : <HeartFilled onClick={() => changeFav(v?._id, false)} style={{ color: 'red' }} />
                                                                }
                                                            </p>
                                                        </div>
                                                        <div style={{ textAlign: 'center', margin: '8px' }}>
                                                            <div className='div-flex-back-name' style={{ justifyContent: 'space-between', height: '30px' }}>
                                                                <div onClick={() => setBuyProduct(v)} >
                                                                    <p className='guest-card-tect-string'
                                                                        style={{
                                                                            fontSize: 18,
                                                                            color: 'black',
                                                                        }}>{stringLimiter(v?.name, 14) || '------'}</p>
                                                                    <p className='guest-card-tect-string'
                                                                        style={{
                                                                            fontSize: 13,
                                                                            color: '#7A7A7A',
                                                                            width: '100px',
                                                                        }}>{v?.userId?.city || '------'}</p>

                                                                    <p className='guest-card-tect-string'
                                                                        style={{
                                                                            fontSize: 13,
                                                                            color: '#7A7A7A',
                                                                            width: '100px'
                                                                        }}>{v?.promote ? selectedLanguage?.text_Sponsored : ''}</p>

                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        alignItems: 'flex-end',
                                                                        height: '100px',
                                                                        marginTop: '-8px',
                                                                    }}
                                                                    onClick={() => { gotoReview(v) }} >
                                                                    <button className='guest-card-review-box'>
                                                                        {v?.review?.length ?
                                                                            <AiFillStar size={15} color='white' />
                                                                            : null}
                                                                        {!v?.isReviewHide ? v?.review?.length ? calculateReviewAverage(v?.review) : 0 : 0}
                                                                    </button>
                                                                    <p className='card_desc1' style={{ textAlign: 'end' }}>
                                                                        {!v?.isReviewHide ?
                                                                            v?.review?.length ?
                                                                                calculateReviewAverage(v?.review) < 4.5 ?
                                                                                    selectedLanguage?.Bad + ',' :
                                                                                    calculateReviewAverage(v?.review) < 5.9 ?
                                                                                        selectedLanguage?.Poor + ',' :
                                                                                        calculateReviewAverage(v?.review) < 6.9 ?
                                                                                            selectedLanguage?.Adeqate + ',' :
                                                                                            calculateReviewAverage(v?.review) < 8.9 ?
                                                                                                selectedLanguage?.Good + ',' :
                                                                                                selectedLanguage?.Excellent + ',' : null : null}
                                                                        {!v?.isReviewHide ?
                                                                            v?.review?.length ?
                                                                                v?.review?.length !== 1 ?
                                                                                    v?.review?.length > 999 ?
                                                                                        ' ' +
                                                                                        `${v?.review?.length?.slice}`(0, 1) +
                                                                                        '.' +
                                                                                        `${v?.review?.length}`?.slice(1, 2) +
                                                                                        'k' + ' ' + selectedLanguage?.Reviews :
                                                                                        ' ' + v?.review?.length + ' ' + selectedLanguage?.Reviews :
                                                                                    ' ' + v?.review?.length + ' ' + selectedLanguage?.Review :
                                                                                selectedLanguage?.NoReviewesYet :
                                                                            selectedLanguage?.NoReviewesYet}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <br />
                                                            <div onClick={() => setBuyProduct(v)}>
                                                                <p style={{
                                                                    color: 'grey',
                                                                    textAlign: 'start',
                                                                    lineHeight: 1.4,
                                                                    height: '40px',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    lineBreak: 'anywhere',
                                                                    marginBottom: 0
                                                                }}
                                                                    className='card_sold'
                                                                >
                                                                    {v?.description || '-------------------------------'}
                                                                </p>
                                                            </div>
                                                            <span onClick={() => setBuyProduct(v)}>
                                                                <div className='card_sold_price'>
                                                                    <p className='card_sold' style={{ color: '#ec671b' }}>{selectedLanguage?.Sold}:{calcSoldProducts(v?.reservation?.settings || [])}</p>
                                                                    <p className='card_sold' style={{ color: '#ec671b' }}>€{' ' + getPrice(v)}</p>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div style={{ height: '280px', width: '100%' }}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <img style={{ height: '200px', width: '200px', padding: '20px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }} src={NoCards} />
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>



                            <div style={{ marginTop: '20px', marginBottom: '10px' }}>
                                <h1 className='new-exam-title'>{selectedLanguage?.text_Recommendations}</h1>
                                <div
                                    className='sidebar-scroll_class'
                                    style={{
                                        marginTop: 8,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        overflowX: 'scroll'
                                    }}>
                                    {recommendloading ?
                                        [1, 2, 3, 4].map((v, i) => {
                                            return (<Card
                                                key={i}
                                                loading={recommendloading}
                                                bordered={false}
                                                className='loading-card-style'
                                            />)
                                        }) :
                                        recommendedProduct?.length ?
                                            recommendedProduct?.map((v, i) => {
                                                return (
                                                    <div
                                                        key={i}
                                                        style={{
                                                            height: 285,
                                                            width: '290px',
                                                            minWidth: '290px',
                                                            borderRadius: '4px',
                                                            margin: '4px',
                                                            marginRight: '10px',
                                                            boxShadow: ' 0 4px 5px 0 rgba(0, 0, 0, 0.3)',
                                                            cursor: 'pointer'
                                                        }}>
                                                        <div style={{ textAlign: 'center', margin: '8px', position: 'relative' }}                                                >
                                                            <img onClick={() => setBuyProduct(v)} src={v?.imageUrl} style={{ height: '130px', width: '100%', maxWidth: '100%', borderRadius: '4px' }} />
                                                            <p style={{ position: 'absolute', top: '10px', left: '10px', background: 'white', height: '20px', width: '20px', borderRadius: '100px' }}>
                                                                {favouriteSpin[v?._id] ?
                                                                    <Spin /> :
                                                                    !userData?.favourites?.includes(v?._id) ?
                                                                        <HeartOutlined onClick={() => changeFav(v?._id, true)} style={{ color: 'red' }} />
                                                                        : <HeartFilled onClick={() => changeFav(v?._id, false)} style={{ color: 'red' }} />
                                                                }
                                                            </p>
                                                        </div>
                                                        <div style={{ textAlign: 'center', margin: '8px' }}>
                                                            <div className='div-flex-back-name' style={{ justifyContent: 'space-between', height: '30px' }}>
                                                                <div onClick={() => setBuyProduct(v)} >
                                                                    <p className='guest-card-tect-string'
                                                                        style={{
                                                                            fontSize: 18,
                                                                            color: 'black',
                                                                        }}>{stringLimiter(v?.name, 14) || '------'}</p>
                                                                    <p className='guest-card-tect-string'
                                                                        style={{
                                                                            fontSize: 13,
                                                                            color: '#7A7A7A',
                                                                            width: '100px',
                                                                        }}>{v?.userId?.city || '------'}</p>

                                                                    <p className='guest-card-tect-string'
                                                                        style={{
                                                                            fontSize: 13,
                                                                            color: '#7A7A7A',
                                                                            width: '100px'
                                                                        }}>{v?.promote ? selectedLanguage?.text_Sponsored : ''}</p>

                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        alignItems: 'flex-end',
                                                                        height: '100px',
                                                                        marginTop: '-8px',
                                                                    }}
                                                                    onClick={() => { gotoReview(v) }} >
                                                                    <button className='guest-card-review-box'>
                                                                        {v?.review?.length ?
                                                                            <AiFillStar size={15} color='white' />
                                                                            : null}
                                                                        {!v?.isReviewHide ? v?.review?.length ? calculateReviewAverage(v?.review) : 0 : 0}
                                                                    </button>
                                                                    <p className='card_desc1' style={{ textAlign: 'end' }}>
                                                                        {!v?.isReviewHide ?
                                                                            v?.review?.length ?
                                                                                calculateReviewAverage(v?.review) < 4.5 ?
                                                                                    selectedLanguage?.Bad + ',' :
                                                                                    calculateReviewAverage(v?.review) < 5.9 ?
                                                                                        selectedLanguage?.Poor + ',' :
                                                                                        calculateReviewAverage(v?.review) < 6.9 ?
                                                                                            selectedLanguage?.Adeqate + ',' :
                                                                                            calculateReviewAverage(v?.review) < 8.9 ?
                                                                                                selectedLanguage?.Good + ',' :
                                                                                                selectedLanguage?.Excellent + ',' : null : null}
                                                                        {!v?.isReviewHide ?
                                                                            v?.review?.length ?
                                                                                v?.review?.length !== 1 ?
                                                                                    v?.review?.length > 999 ?
                                                                                        ' ' +
                                                                                        `${v?.review?.length?.slice}`(0, 1) +
                                                                                        '.' +
                                                                                        `${v?.review?.length}`?.slice(1, 2) +
                                                                                        'k' + ' ' + selectedLanguage?.Reviews :
                                                                                        ' ' + v?.review?.length + ' ' + selectedLanguage?.Reviews :
                                                                                    ' ' + v?.review?.length + ' ' + selectedLanguage?.Review :
                                                                                selectedLanguage?.NoReviewesYet :
                                                                            selectedLanguage?.NoReviewesYet}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <br />
                                                            <div onClick={() => setBuyProduct(v)}>
                                                                <p style={{
                                                                    color: 'grey',
                                                                    textAlign: 'start',
                                                                    lineHeight: 1.4,
                                                                    height: '40px',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    lineBreak: 'anywhere',
                                                                    marginBottom: 0
                                                                }}
                                                                    className='card_sold'
                                                                >
                                                                    {v?.description || '-------------------------------'}
                                                                </p>
                                                            </div>
                                                            <span onClick={() => setBuyProduct(v)}>
                                                                <div className='card_sold_price'>
                                                                    <p className='card_sold' style={{ color: '#ec671b' }}>{selectedLanguage?.Sold}:{calcSoldProducts(v?.reservation?.settings || [])}</p>
                                                                    <p className='card_sold' style={{ color: '#ec671b' }}>€{' ' + getPrice(v)}</p>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div style={{ height: '280px', width: '100%' }}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <img style={{ height: '200px', width: '200px', padding: '20px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }} src={NoCards} />
                                                </div>
                                            </div>}
                                </div>
                            </div>


                            <div style={{ marginTop: '20px', marginBottom: '10px' }}>
                                <h1 className='new-exam-title'>{selectedLanguage?.text_Shops}</h1>
                                <div
                                    className='sidebar-scroll_class'
                                    style={{
                                        marginTop: 8,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        overflowX: 'scroll'
                                    }}>
                                    {shopsloading ?
                                        [1, 2, 3, 4].map((v, i) => {
                                            return (<Card
                                                key={i}
                                                hoverable
                                                loading={shopsloading}
                                                bordered={false}
                                                className='loading-card-style'
                                            />)
                                        }) :
                                        shops?.length ?
                                            shops?.map((v, i) => {
                                                return (
                                                    <div
                                                        key={i}
                                                        style={{
                                                            height: 265,
                                                            width: '290px',
                                                            minWidth: '290px',
                                                            borderRadius: '4px',
                                                            margin: '4px',
                                                            marginRight: '10px',
                                                            boxShadow: ' 0 4px 5px 0 rgba(0, 0, 0, 0.3)',
                                                            cursor: 'pointer'
                                                        }}>
                                                        <div style={{ textAlign: 'center', margin: '8px', position: 'relative' }}                                                >
                                                            <img onClick={() => setShopData(v)} src={v?.imageUrl} style={{ height: '130px', width: '100%', maxWidth: '100%', borderRadius: '4px' }} />
                                                            <p style={{ position: 'absolute', top: '10px', left: '10px', background: 'white', height: '20px', width: '20px', borderRadius: '100px' }}>
                                                                {favouriteSpin[v?._id] ?
                                                                    <Spin /> :
                                                                    !userData?.favourites?.includes(v?._id) ?
                                                                        <HeartOutlined onClick={() => changeFav(v?._id, true)} style={{ color: 'red' }} />
                                                                        : <HeartFilled onClick={() => changeFav(v?._id, false)} style={{ color: 'red' }} />
                                                                }
                                                            </p>
                                                        </div>
                                                        <div style={{ textAlign: 'center', margin: '8px' }}>
                                                            <div className='div-flex-back-name' style={{ justifyContent: 'space-between', height: '30px' }}>
                                                                <div onClick={() => setShopData(v)} >
                                                                    <p className='guest-card-tect-string'
                                                                        style={{
                                                                            fontSize: 18,
                                                                            color: 'black',
                                                                        }}>{stringLimiter(v?.name, 14) || '------'}</p>
                                                                    <p className='guest-card-tect-string'
                                                                        style={{
                                                                            fontSize: 13,
                                                                            color: '#7A7A7A',
                                                                            width: '100px',
                                                                        }}>{v?.userId?.city || '------'}</p>

                                                                    <p className='guest-card-tect-string'
                                                                        style={{
                                                                            fontSize: 13,
                                                                            color: '#7A7A7A',
                                                                            width: '100px'
                                                                        }}>{v?.promote ? selectedLanguage?.text_Sponsored : ''}</p>

                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        alignItems: 'flex-end',
                                                                        height: '100px',
                                                                        marginTop: '-8px',
                                                                    }}
                                                                    onClick={() => { gotoReview(v) }} >
                                                                    <button className='guest-card-review-box'>
                                                                        {v?.review?.length ?
                                                                            <AiFillStar size={15} color='white' />
                                                                            : null}
                                                                        {!v?.isReviewHide ? v?.review?.length ? calculateReviewAverage(v?.review) : 0 : 0}
                                                                    </button>
                                                                    <p className='card_desc1' style={{ textAlign: 'end' }}>
                                                                        {!v?.isReviewHide ?
                                                                            v?.review?.length ?
                                                                                calculateReviewAverage(v?.review) < 4.5 ?
                                                                                    selectedLanguage?.Bad + ',' :
                                                                                    calculateReviewAverage(v?.review) < 5.9 ?
                                                                                        selectedLanguage?.Poor + ',' :
                                                                                        calculateReviewAverage(v?.review) < 6.9 ?
                                                                                            selectedLanguage?.Adeqate + ',' :
                                                                                            calculateReviewAverage(v?.review) < 8.9 ?
                                                                                                selectedLanguage?.Good + ',' :
                                                                                                selectedLanguage?.Excellent + ',' : null : null}
                                                                        {!v?.isReviewHide ?
                                                                            v?.review?.length ?
                                                                                v?.review?.length !== 1 ?
                                                                                    v?.review?.length > 999 ?
                                                                                        ' ' +
                                                                                        `${v?.review?.length?.slice}`(0, 1) +
                                                                                        '.' +
                                                                                        `${v?.review?.length}`?.slice(1, 2) +
                                                                                        'k' + ' ' + selectedLanguage?.Reviews :
                                                                                        ' ' + v?.review?.length + ' ' + selectedLanguage?.Reviews :
                                                                                    ' ' + v?.review?.length + ' ' + selectedLanguage?.Review :
                                                                                selectedLanguage?.NoReviewesYet :
                                                                            selectedLanguage?.NoReviewesYet}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <br />
                                                            <div onClick={() => setShopData(v)}>
                                                                <p style={{
                                                                    color: 'grey',
                                                                    textAlign: 'start',
                                                                    lineHeight: 1.4,
                                                                    height: '40px',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    lineBreak: 'anywhere',
                                                                    marginBottom: 0
                                                                }}
                                                                    className='card_sold'
                                                                >
                                                                    {v?.description || '-------------------------------'}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div style={{ height: '280px', width: '100%' }}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <img style={{ height: '200px', width: '200px', padding: '20px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }} src={NoCards} />
                                                </div>
                                            </div>}
                                </div>
                            </div>


                            <div style={{ marginTop: '20px', marginBottom: '10px' }}>
                                <h1 className='new-exam-title'>{selectedLanguage?.Products}</h1>
                                <div
                                    className='sidebar-scroll_class'
                                    style={{
                                        marginTop: 8,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        overflowX: 'scroll'
                                    }}>
                                    {loading ?
                                        [1, 2, 3, 4].map((v, i) => {
                                            return (<Card
                                                key={i}
                                                hoverable
                                                loading={loading}
                                                bordered={false}
                                                className='loading-card-style'
                                            />)
                                        })
                                        :
                                        filteredProduct?.length ?
                                            filteredProduct.map((v, i) => {
                                                return (
                                                    <div
                                                        key={i}
                                                        style={{
                                                            height: 285,
                                                            width: '290px',
                                                            minWidth: '290px',
                                                            borderRadius: '4px',
                                                            margin: '4px',
                                                            marginRight: '10px',
                                                            boxShadow: ' 0 4px 5px 0 rgba(0, 0, 0, 0.3)',
                                                            cursor: 'pointer'
                                                        }}>
                                                        <div style={{ textAlign: 'center', margin: '8px', position: 'relative' }}                                                >
                                                            <img onClick={() => setBuyProduct(v)} src={v?.imageUrl} style={{ height: '130px', width: '100%', maxWidth: '100%', borderRadius: '4px' }} />
                                                            <p style={{ position: 'absolute', top: '10px', left: '10px', background: 'white', height: '20px', width: '20px', borderRadius: '100px' }}>
                                                                {favouriteSpin[v?._id] ?
                                                                    <Spin /> :
                                                                    !userData?.favourites?.includes(v?._id) ?
                                                                        <HeartOutlined onClick={() => changeFav(v?._id, true)} style={{ color: 'red' }} />
                                                                        : <HeartFilled onClick={() => changeFav(v?._id, false)} style={{ color: 'red' }} />
                                                                }
                                                            </p>
                                                        </div>
                                                        <div style={{ textAlign: 'center', margin: '8px' }}>
                                                            <div className='div-flex-back-name' style={{ justifyContent: 'space-between', height: '30px' }}>
                                                                <div onClick={() => setBuyProduct(v)} >
                                                                    <p className='guest-card-tect-string'
                                                                        style={{
                                                                            fontSize: 18,
                                                                            color: 'black',
                                                                        }}>{stringLimiter(v?.name, 14) || '------'}</p>
                                                                    <p className='guest-card-tect-string'
                                                                        style={{
                                                                            fontSize: 13,
                                                                            color: '#7A7A7A',
                                                                            width: '100px',
                                                                        }}>{v?.userId?.city || '------'}</p>

                                                                    <p className='guest-card-tect-string'
                                                                        style={{
                                                                            fontSize: 13,
                                                                            color: '#7A7A7A',
                                                                            width: '100px'
                                                                        }}>{v?.promote ? selectedLanguage?.text_Sponsored : ''}</p>

                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        alignItems: 'flex-end',
                                                                        height: '100px',
                                                                        marginTop: '-8px',
                                                                    }}
                                                                    onClick={() => { gotoReview(v) }} >
                                                                    <button className='guest-card-review-box'>
                                                                        {v?.review?.length ?
                                                                            <AiFillStar size={15} color='white' />
                                                                            : null}
                                                                        {!v?.isReviewHide ? v?.review?.length ? calculateReviewAverage(v?.review) : 0 : 0}
                                                                    </button>
                                                                    <p className='card_desc1' style={{ textAlign: 'end' }}>
                                                                        {!v?.isReviewHide ?
                                                                            v?.review?.length ?
                                                                                calculateReviewAverage(v?.review) < 4.5 ?
                                                                                    selectedLanguage?.Bad + ',' :
                                                                                    calculateReviewAverage(v?.review) < 5.9 ?
                                                                                        selectedLanguage?.Poor + ',' :
                                                                                        calculateReviewAverage(v?.review) < 6.9 ?
                                                                                            selectedLanguage?.Adeqate + ',' :
                                                                                            calculateReviewAverage(v?.review) < 8.9 ?
                                                                                                selectedLanguage?.Good + ',' :
                                                                                                selectedLanguage?.Excellent + ',' : null : null}
                                                                        {!v?.isReviewHide ?
                                                                            v?.review?.length ?
                                                                                v?.review?.length !== 1 ?
                                                                                    v?.review?.length > 999 ?
                                                                                        ' ' +
                                                                                        `${v?.review?.length?.slice}`(0, 1) +
                                                                                        '.' +
                                                                                        `${v?.review?.length}`?.slice(1, 2) +
                                                                                        'k' + ' ' + selectedLanguage?.Reviews :
                                                                                        ' ' + v?.review?.length + ' ' + selectedLanguage?.Reviews :
                                                                                    ' ' + v?.review?.length + ' ' + selectedLanguage?.Review :
                                                                                selectedLanguage?.NoReviewesYet :
                                                                            selectedLanguage?.NoReviewesYet}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <br />
                                                            <div onClick={() => setBuyProduct(v)}>
                                                                <p style={{
                                                                    color: 'grey',
                                                                    textAlign: 'start',
                                                                    lineHeight: 1.4,
                                                                    height: '40px',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    lineBreak: 'anywhere',
                                                                    marginBottom: 0
                                                                }}
                                                                    className='card_sold'
                                                                >
                                                                    {v?.description || '-------------------------------'}
                                                                </p>
                                                            </div>
                                                            <span onClick={() => setBuyProduct(v)}>
                                                                <div className='card_sold_price'>
                                                                    <p className='card_sold' style={{ color: '#ec671b' }}>{selectedLanguage?.Sold}:{calcSoldProducts(v?.reservation?.settings || [])}</p>
                                                                    <p className='card_sold' style={{ color: '#ec671b' }}>€{' ' + getPrice(v)}</p>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div style={{ height: '280px', width: '100%' }}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <img style={{ height: '200px', width: '200px', padding: '20px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }} src={NoCards} />
                                                </div>
                                            </div>
                                    }
                                </div >
                            </div >
                        </div >
                    </div >
                    :
                    shopData?._id ?
                        <div className='flex-column menu-main'>
                            <ShowShops product={shopData} onCancel={() => setShopData({})} {...props} />
                        </div> :
                        <div className='flex-column menu-main'>
                            <BuyProduct calcSoldProducts={calcSoldProducts} product={buyProduct} getProducts={getProducts} onCancel={() => setBuyProduct({})} {...props} />
                        </div>
                }
                <ProductReview ProgressLoading={ProgressLoading} reviewModal={reviewModal} closeReviewModal={closeReviewModal}  {...props} />
            </div>
        </>

    )
}

export default GuestHome