import { Button, Form, Input, InputNumber } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { ADMIN } from '../../../../utils/apis'
import { assignMessage, errorMessage, successNotification } from '../../../../utils/helpers'

const AddTypes = (props) => {
    const { onCancel, edit, user, getProductsTypes, language, selectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const isEnglish = language?.language === 'english'
    const onFinish = (values) => {
        setLoading(true)
        values.userId = user?._id

        if (!edit?._id) {
            axios.post(ADMIN.CREATE_PRODUCT_TYPES, values)
                .then((res) => {
                    const { data } = res
                    setLoading(false)
                    if (data?.success) {
                        successNotification(assignMessage(data?.message, language?.language))
                        getProductsTypes()
                        onCancel()
                    }
                    else {
                        errorMessage(assignMessage(data?.message, language?.language))
                    }
                })
                .catch((e) => {
                    console.log('e', e)
                    setLoading(false)
                    errorMessage(assignMessage(e?.message, language?.language))
                })
        } else {
            values._id = edit?._id
            axios.post(ADMIN.EDIT_PRODUCT_TYPES, values)
                .then((res) => {
                    const { data } = res
                    setLoading(false)
                    if (data?.success) {
                        successNotification(assignMessage(data?.message, language?.language))
                        getProductsTypes()
                        onCancel()
                    }
                    else {
                        errorMessage(assignMessage(data?.message, language?.language))
                    }
                })
                .catch((e) => {
                    console.log('e', e)
                    setLoading(false)
                    errorMessage(assignMessage(e?.message, language?.language))
                })
        }
    }

    return (
        <div style={{ background: 'white', padding: '15px' }}>
            <h3 className='new-exam-title'>
                {edit?._id ? selectedLanguage?.UpdateProductType : selectedLanguage?.CreateProductType}
            </h3>
            <div style={{ textAlign: 'right', width: '98.5%' }}>
                <Button
                    type='primary'
                    className='btn-end'
                    onClick={onCancel}
                >
                    {selectedLanguage?.Cancel}
                </Button>
            </div>

            <div style={{padding:'15px'}}>
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                >
                    <Form.Item
                        name='typeName'
                        label={selectedLanguage?.TypeName}
                        initialValue={edit?.typeName}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.PleaseInputYourTypeName
                            }
                        ]}
                    >
                        <Input
                            className='form-input'
                            placeholder={selectedLanguage?.txt_EXample_Restaurant}
                        />
                    </Form.Item>
                    <Form.Item
                        name='feeCost'
                        label={selectedLanguage?.FeeCost}
                        initialValue={edit?.feeCost}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.PleaseInputYourFeeCost
                            }
                        ]}
                    >
                        <InputNumber
                            style={{ width: '100%' }}
                            className='form-input'
                            placeholder={0}
                            type='number'
                            min={0}
                            precision={2}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type='primary'
                            className='form-button'
                            block
                            htmlType='submit'
                            loading={loading}
                        >
                            {edit?._id ? selectedLanguage?.Update : selectedLanguage?.Create}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default AddTypes