import React, { useState, useEffect } from 'react'
import { secondsToMinutesAndSeconds } from '../../utils/helpers'

const NewTimerComponent = (props) => {
    const { triggerFunc, formData, selectedLanguage } = props

    const [remainingTime, setRemainingTime] = useState(formData?.time)

    useEffect(() => {
        const timer = setInterval(() => {
            setRemainingTime((prevTime) => prevTime ? prevTime - 1 : prevTime)
        }, 1000)

        return () => clearInterval(timer)
    }, [])


    useEffect(() => {
        if (remainingTime === 0) {
            triggerFunc()
        }
    }, [remainingTime])


    return (
        <div>
            <p style={{ margin: 0, marginRight: 4, fontWeight: 600 }}>{selectedLanguage?.txt_TestTimeRemaining} : {secondsToMinutesAndSeconds(remainingTime)}s</p>
        </div>
    )

}

export default NewTimerComponent
