import { Button, Result } from 'antd'
import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { AdminNewMenuLayout, NewLayout } from '../NewComponents'
import { AddBuDayDealsQuestion, AdminBuInvoices, AdminCoupons, AdminGuestInvoices, AdminLogin, AdminPayments, AdminProducts, AdminVouchers, BuDayDealsProduct, BuDayDealsQuestions, BuInvoices, BuPayments, BuVouchers, BuserReview, CouponReport, Coupons, Faq, FeeReport, ForgotPassword, GiftCards, Group, GuestDayDealsProduct, GuestDaydeals, GuestPrivProducts, GuestReviewForm, Home, Invoices, Login, Lottery, PaymentReport, PriceSettings, Products, Settings, Signup, SubAdmins, Subscription, SubscriptionReport, TaxSettings, Types, UpdatePassword, Users, VerifyEmail, VisiterFaq, VisiterHome, Vouchers } from '../Screens'
import { AddAdminFaq, AdminFaq, AdminGiftCards, AdminGroup, AdminLottery } from '../Screens/Admin'
import AdminReview from '../Screens/Admin/Review/Reviews'
import allPaths from './paths'
import { NewQuestionForm } from '../Components'

const Page404 = (props) => {
    const { history } = props
    return (
        <Result
            status='404'
            title='404'
            subTitle='Sorry, the page you visited does not exist.'
            extra={<Button
                type='primary'
                className='form-button'
                onClick={() => history.push('/')}
            >Back Home</Button>}
        />
    )
}

const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route path={allPaths?.VISITER_HOME} exact component={VisiterHome} />
                <Route path={allPaths?.VISITER_FAQ} exact component={VisiterFaq} />
                <Route path={allPaths?.LOGIN} exact component={Login} />
                <Route path={allPaths?.SIGNUP} exact component={Signup} />
                <Route path={allPaths?.BU_LOGIN} exact component={Login} />
                <Route path={allPaths?.BU_SIGNUP} exact component={Signup} />
                <Route path={allPaths?.FORGOT} exact component={ForgotPassword} />
                <Route path={allPaths?.BU_FORGOT} exact component={ForgotPassword} />
                <Route path={allPaths?.FORM} exact component={NewQuestionForm} />

                <NewLayout path={allPaths?.HOME} exact component={Home} />
                <NewLayout path={allPaths?.BU_PRODUCT} exact component={Products} />
                <NewLayout path={allPaths?.PRIV_PRODUCT} exact component={GuestPrivProducts} />
                <NewLayout path={allPaths?.VOUCHERS} exact component={Vouchers} />
                <NewLayout path={allPaths?.INVOICES} exact component={Invoices} />
                <NewLayout path={allPaths?.FAQ} exact component={Faq} />
                <NewLayout path={allPaths?.LOTTERY} exact component={Lottery} />
                <NewLayout path={allPaths?.BUVOUCHERS} exact component={BuVouchers} />
                <NewLayout path={allPaths?.BUINVOICES} exact component={BuInvoices} />
                <NewLayout path={allPaths?.BUPAYMENTS} exact component={BuPayments} />
                <NewLayout path={allPaths?.GROUP} exact component={Group} />
                <NewLayout path={allPaths?.COUPON} exact component={Coupons} />
                <NewLayout path={allPaths?.PROFILE_SETTINGS} exact component={Settings} />
                <NewLayout path={allPaths?.SUBSCRIPTION} exact component={Subscription} />
                <NewLayout path={allPaths?.BU_REVIEW} exact component={BuserReview} />
                <NewLayout path={allPaths?.GIFT_CARDS} exact component={GiftCards} />
                <NewLayout path={allPaths?.BU_DAYDEALS_PRODUCTS} exact component={BuDayDealsProduct} />
                <NewLayout path={allPaths?.BU_DAYDEALS_CREATE_QUESTION} exact component={AddBuDayDealsQuestion} />
                <NewLayout path={allPaths?.BU_DAYDEALS_QUESTION} exact component={BuDayDealsQuestions} />
                <NewLayout path={allPaths?.GUEST_DAYDEALS} exact component={GuestDaydeals} />
                <NewLayout path={allPaths?.GUEST_DAYDEALS_PRODUCT} exact component={GuestDayDealsProduct} />

                <AdminNewMenuLayout path={allPaths?.ADMIN_SUBADMINS} exact component={SubAdmins} />
                <AdminNewMenuLayout path={allPaths?.ADMIN_USERS} exact component={Users} />
                <AdminNewMenuLayout path={allPaths?.ADMINVOUCHERS} exact component={AdminVouchers} />
                <AdminNewMenuLayout path={allPaths?.ADMINPAYMENTS} exact component={AdminPayments} />
                <AdminNewMenuLayout path={allPaths?.ADMINGUESTINVOICES} exact component={AdminGuestInvoices} />
                <AdminNewMenuLayout path={allPaths?.ADMINBUINVOICES} exact component={AdminBuInvoices} />
                <AdminNewMenuLayout path={allPaths?.ADMIN_TYPES} exact component={Types} />
                <AdminNewMenuLayout path={allPaths?.ADMIN_PRODUCT} exact component={AdminProducts} />
                <AdminNewMenuLayout path={allPaths?.ADMIN_PRICE_SETTINGS} exact component={PriceSettings} />
                <AdminNewMenuLayout path={allPaths?.ADMIN_TAX_SETTINGS} exact component={TaxSettings} />
                <AdminNewMenuLayout path={allPaths?.ADMIN_ADD_FAQ} exact component={AddAdminFaq} />
                <AdminNewMenuLayout path={allPaths?.ADMIN_FAQS} exact component={AdminFaq} />
                <AdminNewMenuLayout path={allPaths?.PAYMENT_REPORT} exact component={PaymentReport} />
                <AdminNewMenuLayout path={allPaths?.COUPONS_REPORT} exact component={CouponReport} />
                <AdminNewMenuLayout path={allPaths?.SUBSCRIPTION_REPORT} exact component={SubscriptionReport} />
                <AdminNewMenuLayout path={allPaths?.FEE_REPORT} exact component={FeeReport} />
                <AdminNewMenuLayout path={allPaths?.ADMIN_COUPON} exact component={AdminCoupons} />
                <AdminNewMenuLayout path={allPaths?.ADMIN_REVIEWS} exact component={AdminReview} />
                <AdminNewMenuLayout path={allPaths?.ADMIN_LOTTERY} exact component={AdminLottery} />
                <AdminNewMenuLayout path={allPaths?.ADMIN_GIFT_CARDS} exact component={AdminGiftCards} />
                <AdminNewMenuLayout path={allPaths?.ADMIN_GROUP} exact component={AdminGroup} />

                <Route path={allPaths?.ADMIN_LOGIN} exact component={AdminLogin} />
                <Route path={`${allPaths?.UPDATE_PASSWORD}/:token`} exact component={UpdatePassword} />
                <Route path={`${allPaths?.BU_UPDATE_PASSWORD}/:token`} exact component={UpdatePassword} />
                <Route path={`${allPaths?.REVIEW}`} exact component={GuestReviewForm} />
                <Route path={`${allPaths?.VERIFY_EMAIL}/:token`} exact component={VerifyEmail} />

                <Route path='/:page404' exact component={Page404} />
                <Route path={`/:page404/:token`} exact component={Page404} />
                <Route path='admin/:page404' exact component={Page404} />
            </Switch>
        </Router>
    )
}

export {
    Page404, Routes
}

