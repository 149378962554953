import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Form, Input, Button } from 'antd'
import { assignLanguage, assignMessage, errorMessage, inputPlace, requiredMessage, successNotification } from '../../utils/helpers'
import axios from 'axios'
import { POST } from '../../utils/apis'
import { allPaths } from '../../utils/constants'


const GuestReviewForm = () => {
    const history = useHistory()
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const userId = new URLSearchParams(location.search).get('userId')
    const productId = new URLSearchParams(location.search).get('productId')
    const { TextArea } = Input

    const language = useSelector(state => state?.languageReducer)

    const selectedLanguage = assignLanguage(language?.language)

    const isEnglish = language?.language === 'english'

    console.log(language, ' language')

    const onFinish = (values) => {

        let cleanliness = + values?.cleanliness
        let comfort = + values?.comfort
        let facilities = + values?.facilities
        let freeWifi = + values?.freeWifi
        let locationReview = + values?.location
        let staff = + values?.staff
        let valueForMoney = + values?.valueForMoney
        let avrageReview = cleanliness + comfort + facilities + freeWifi + locationReview + staff + valueForMoney
        values.userId = userId
        values.productId = productId
        values.average = avrageReview / 7
        console.log(avrageReview / 7, "=avrageReview / 7")

        setLoading(true)
        axios.post(POST?.CREATE_REVIEW, values)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setLoading(false)
                    successNotification(assignMessage(data?.message, language?.language))
                    form.resetFields()
                }
                else {
                    setLoading(false)
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }



    return (
        <div style={{ minWidth: '300px', maxWidth: '500px', background: 'white', margin: '10px auto' }}>
            <div className='card-form'>
                <div className='form-card-inner'>
                    <p className='heading head-center'>Write a review</p>
                    <p className='review-text head-center' style={{ fontWeight: 600, color: '#ec671b', marginTop: -20 }}>{'we aim for 100% real review'}</p>

                    <Form
                        name='form'
                        onFinish={onFinish}
                        form={form}
                        layout={'vertical'}
                        requiredMark={false}
                    >
                        <Form.Item
                            name='staff'
                            label='Staff Review'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Staff Review')
                                }
                            ]}
                        >
                            <Input
                                min={1}
                                max={10}
                                className='form-input'
                                type='number'
                                step={0.01}
                                precision={2}
                                pattern='[0-9]*'
                                placeholder={inputPlace('Staff Review')}
                            />
                        </Form.Item>
                        <Form.Item
                            name='facilities'
                            label='Facilities Review'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Facilities Review')
                                }
                            ]}
                        >
                            <Input
                                min={1}
                                max={10}
                                className='form-input'
                                type='number'
                                step={0.01}
                                precision={2}
                                pattern='[0-9]*'
                                placeholder={inputPlace('Facilities Review')}
                            />
                        </Form.Item>
                        <Form.Item
                            name='cleanliness'
                            label='Cleanliness Review'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Cleanliness Review')
                                }
                            ]}
                        >
                            <Input
                                min={1}
                                max={10}
                                className='form-input'
                                type='number'
                                step={0.01}
                                precision={2}
                                pattern='[0-9]*'
                                placeholder={inputPlace('Cleanliness Review')}
                            />
                        </Form.Item>
                        <Form.Item
                            name='comfort'
                            label='Comfort Review'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Comfort Review')
                                }
                            ]}
                        >
                            <Input
                                min={1}
                                max={10}
                                className='form-input'
                                type='number'
                                step={0.01}
                                precision={2}
                                pattern='[0-9]*'
                                placeholder={inputPlace('Comfort Review')}
                            />
                        </Form.Item>
                        <Form.Item
                            name='valueForMoney'
                            label='Value for Money Review'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Value for Money Review')
                                }
                            ]}
                        >
                            <Input
                                min={1}
                                max={10}
                                className='form-input'
                                type='number'
                                step={0.01}
                                precision={2}
                                pattern='[0-9]*'
                                placeholder={inputPlace('Value for Money Review')}
                            />
                        </Form.Item>
                        <Form.Item
                            name='location'
                            label='Location Review'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Location Review')
                                }
                            ]}
                        >
                            <Input
                                min={1}
                                max={10}
                                className='form-input'
                                type='number'
                                step={0.01}
                                precision={2}
                                pattern='[0-9]*'
                                placeholder={inputPlace('Location Review')}
                            />
                        </Form.Item>
                        <Form.Item
                            name='freeWifi'
                            label='Free Wifi Review'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Free Wifi Review')
                                }
                            ]}
                        >
                            <Input
                                min={1}
                                max={10}
                                className='form-input'
                                type='number'
                                step={0.01}
                                precision={2}
                                pattern='[0-9]*'
                                placeholder={inputPlace('Free Wifi Review')}
                            />
                        </Form.Item>
                        <Form.Item
                            name='comment'
                            label='Review message'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Review message')
                                }
                            ]}
                        >
                            <TextArea
                                className='form-input'
                                showCount
                                maxLength={250}
                                style={{ height: 80 }}
                                placeholder={inputPlace('Review message')}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type='primary'
                                className='form-button'
                                block
                                htmlType='submit'
                                loading={loading}
                            >
                                Submit Your Review

                            </Button>
                        </Form.Item>
                    </Form>
                    {/* <p>
                    <Link to={allPaths.FORGOT}>Forgot Password</Link>
                </p> */}
                </div>
            </div>
        </div>
    )
}

export default GuestReviewForm