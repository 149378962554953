import { UploadOutlined } from '@ant-design/icons'
import { Button, Form, Input, Select, Upload } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ADMIN } from '../../../utils/apis'
import { assignMessage, checkSelectOption, errorMessage, successNotification, warningMessage } from '../../../utils/helpers'
const { Option } = Select

const AddCoupons = (props) => {
    const { onCancel, edit, user, getAdminCoupons, language, selectedLanguage } = props
    const isEnglish = language?.language === 'english'
    const [loading, setLoading] = useState(false)
    const [products, setproducts] = useState([])
    const [fileList, setFileList] = useState([])

    useEffect(() => {
        getProducts()
    }, [])

    const onFinish = (values) => {
        values.file = fileList
        edit?._id && (values._id = edit?._id)
        values.adminId = user?._id

        if (!values._id && !values?.file?.length) {
            return warningMessage(selectedLanguage?.PleaseUploadImage)
        }
        if (!values.quantity) {
            return warningMessage(selectedLanguage?.QuantityCannotbeZero)
        }
        setLoading(true)

        let formData = new FormData()
        if (values?.file?.length) {
            formData.append('file', values?.file[0])
        }
        if (values?._id) {
            formData.append('_id', values?._id)
        }
        formData.append('description', values?.description)
        formData.append('quantity', Number(values?.quantity))
        formData.append('amount', Number(values?.amount))
        formData.append('adminId', values?.adminId)
        if (values?.products?.length) {
            for (let v of values?.products) {
                formData.append('products[]', v)
            }
        }
        console.log('values', values)
        axios.post(ADMIN.CREATE_ADMIN_COUPONS, formData)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    getAdminCoupons()
                    successNotification(assignMessage(data?.message, language?.language))
                    onCancel()
                }
                // errorMessage(data.message)
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
                console.log('e', e)
            })
    }


    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage(selectedLanguage?.PleaseUploadImageonly)
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    const getProducts = () => {
        axios.get(`${ADMIN.GET_ALL_PRODUCTS}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setproducts(data?.data?.filter((v) => v?.userId?.supplierType !== 'shops') || [])
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    return (
        <div >

            <h3 className='new-exam-title'>{selectedLanguage?.AddCoupons}</h3>
            <div style={{ background: 'white', padding: '15px' }}>
                <div style={{ textAlign: 'right', width: '98.5%' }}>
                    <Button
                        style={{ borderRadius: 4, marginTop: 6 }}
                        type='primary'
                        onClick={onCancel}
                    >
                        {selectedLanguage?.Cancel}
                    </Button>
                </div>
                <br />
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                    requiredMark={false}
                >
                    <Form.Item
                        name='description'
                        label={selectedLanguage?.Description}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.PleaseInputDescription
                            }
                        ]}
                    >
                        <Input
                            maxLength={40}
                            className='form-input'
                            placeholder={selectedLanguage?.InputYourDescriptionHere}
                        />
                    </Form.Item>
                    <Form.Item
                        name='quantity'
                        label={selectedLanguage?.Quantity}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.PleaseInputYourQuantity
                            }
                        ]}
                    >
                        <div>
                            <Input
                                type='number'
                                className='form-input'
                                placeholder={selectedLanguage?.InputYourQuantityHere}
                                min={1}
                                step={1}
                                precision={0}

                            />
                        </div>
                    </Form.Item>
                    <Form.Item
                        name='amount'
                        label={selectedLanguage?.Amount}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.PleaseInputAmount
                            }
                        ]}
                        initialValue={0}
                    >
                        <div>
                            <Input
                                className='form-input'
                                placeholder={selectedLanguage?.InputAmountHere}
                                defaultValue={0}
                                type='number'
                                step={0.01}
                                min={0}
                                precision={2}
                            />
                        </div>
                    </Form.Item>
                    <Form.Item
                        name='products'
                        label={selectedLanguage?.SelectProducts}
                        hasFeedback
                    >
                        <Select
                            mode='multiple'
                            placeholder={selectedLanguage?.SelectProducts}
                            showSearch
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                        >
                            {products?.map((v, i) => <Option value={v?._id} key={i}>{`${v?.name} € ${v?.price?.[0]?.price || 0}`}</Option>)}

                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='file'
                        label={selectedLanguage?.Image}
                    >
                        <Upload
                            name='file'
                            multiple={false}
                            beforeUpload={() => false}
                            accept='image/png, image/jpeg'
                            onChange={normFile}
                            fileList={fileList}
                        >
                            <Button icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                        </Upload> 
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type='primary'
                            className='form-button'
                            block
                            htmlType='submit'
                            loading={loading}
                        >
                            {selectedLanguage?.Create}
                        </Button>

                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default AddCoupons