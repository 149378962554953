import { Button, InputNumber, Table } from 'antd'
import 'antd/dist/antd.css'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { GET, POST } from '../../utils/apis'
import { allBUserData, assignMessage, errorMessage, errorMessageWithDuration, splitDate, successNotification, validateTime } from '../../utils/helpers'
const ReservationSetting = (props) => {
    const { setReservationsetting, resId, user, language, selectedLanguage, userActions } = props
    let tableColumn = []
    const [tableColumnState, setTableColumnState] = useState([])
    const [tableData, setTableData] = useState([])
    const [reservationData, setReservationData] = useState({})
    const [tableNewData, setTableNewData] = useState([])
    const [loading, setLoading] = useState(false)
    const [history, setHistory] = useState(false)
    const [copyLoading, setCopyLoading] = useState(false)
    console.log('reservationData', reservationData)
    useEffect(() => {
        updateColumns(tableNewData)
    }, [tableNewData])
    useEffect(() => {
        getReservation()
    }, [history])

    const updateColumns = (tableData) => {
        let data = []
        for (let v of tableData) {
            let dataRow = {}
            dataRow['date'] = v?.date
            dataRow['maximumProducts'] = v
            dataRow['soldProduct'] = v?.soldProduct
            dataRow['dateId'] = v?._id
            dataRow['timeData'] = {}
            for (let s of v?.shifts) {
                s.maximumProducts = v?.maximumProduct
                dataRow[`${s?.startTime}`] = s
            }
            data?.push(dataRow)
        }

        if (tableData?.length) {
            let dynamicColumns = reservationData && tableData?.[0]?.shifts
            for (let d of dynamicColumns) {
                let columnObj = {
                    title: validateTime(`${d?.startTime}`),
                    dataIndex: d?.startTime,
                    width: 80,
                    fixed: 'center',

                    render: (v, record) => {
                        const anotherDate = moment(splitDate(v?.date), 'YYYY-MM-DD')
                        return (
                            <InputNumber
                                style={{ width: 60 }}
                                controls={false}
                                maxLength={4}
                                pattern='[0-9]*'
                                precision={0}
                                // type='number'
                                step={1}
                                min={0}
                                // max={v?.maximumProducts}
                                max={v?.maximumProducts}
                                disabled={(moment().isAfter(anotherDate)) ? true : false}
                                defaultValue={v?.maxProduct}
                                // defaultValue={v?.maxProduct > v?.maximumProducts ? v?.maximumProducts : v?.maxProduct}
                                onChange={(e) => {
                                    onInputChange('maxProduct', e, record, v?._id)
                                }} />
                        )
                    }
                }

                tableColumn.push(columnObj)
            }

            setTableData([...data])
            let tableColumns = [
                {
                    title: '',
                    width: 150,
                    dataIndex: 'date',
                    fixed: 'left',
                    render: (date) => {
                        return {
                            props: {
                                style: {
                                    background: '#ec671b',
                                    border: '2px solid #ec671b',
                                    color: 'white'
                                },
                            },
                            children: <div>{moment(splitDate(date), 'YYYY-MM-DD')?.format('ddd, DD MMM YYYY')}</div>,
                            // Mon, 08 Mar 2023
                        };
                    }
                },
                ...tableColumn,
                {
                    title: selectedLanguage?.Max,
                    width: 100,
                    dataIndex: 'maximumProducts',
                    render: (v, record) => {
                        const givenDate = moment(splitDate(v?.date), 'YYYY-MM-DD')
                        return (
                            <div style={{ width: 120 }}>
                                <InputNumber
                                    pattern='[0-9]*'
                                    type='number'
                                    min={0}
                                    precision={0}
                                    max={9999}
                                    disabled={moment().isAfter(givenDate) ? true : false}
                                    defaultValue={v?.maximumProduct}
                                    onChange={(e) => {
                                        onMaxProductChange(e, v?._id)
                                    }} />
                            </div>
                        )
                    }
                },
                {
                    title: selectedLanguage?.Total,
                    width: 100,
                    align: 'center',
                    dataIndex: 'soldProduct'
                }
            ]

            setTableColumnState([...tableColumns])
        }
    }

    const getReservation = () => {
        setLoading(true)
        axios.get(`${GET?.GET_RESERVATION}/${resId}?history=${history}`)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setReservationData(data?.data || {})
                    setTableNewData(data?.data?.settings || [])
                }
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const onInputChange = (key, text, record, id) => {
        const newData = JSON.parse(JSON.stringify(tableNewData))
        let indexDate = _.findIndex(newData, { _id: record?.dateId })
        let indexShift = _.findIndex(newData[indexDate]?.shifts, { _id: id })
        if (text > newData[indexDate]?.maximumProduct) {
            return errorMessageWithDuration(selectedLanguage?.valueCannotBbeGreaterthanMaxofTheDay)
        }
        newData[indexDate].shifts[indexShift][key] = Number(text)

        setTableNewData(newData)
    }

    const onMaxProductChange = (text, id) => {
        const newData = JSON.parse(JSON.stringify(tableNewData))
        let indexDate = _.findIndex(newData, { _id: id })
        newData[indexDate].maximumProduct = Number(text)
        setTableNewData(newData)
    }
    const onConfirm = () => {
        let obj = {
            settings: tableNewData,
            _id: resId,
            userId: user?._id
        }
        console.log('OBJ', obj)

        if (reservationData?.interval != 'none') {
            for (let v of tableNewData) {
                for (let x of v?.shifts) {
                    if (x?.maxProduct > x?.maximumProducts) {
                        return errorMessage(`Time Slot value for ${moment(splitDate(v?.date), 'YYYY-MM-DD')?.format('ddd, DD MMM YYYY')} cannot be greater than Max of the day!`)
                    }
                }
            }
        }

        setLoading(true)
        axios.post(POST?.UPDATE_RESERVATION_SETTING, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    allBUserData?.getAllBuProducts(userActions, user?._id)
                    successNotification(assignMessage(data?.message, language?.language))
                    setReservationsetting(false)
                } else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                console.log('err', e)
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const copySetting = () => {
        let obj = {
            _id: resId,
        }
        setCopyLoading(true)
        axios.post(POST?.COPY_RESERVATION_SETTIN, obj)
            .then((res) => {
                const { data } = res
                setCopyLoading(false)
                if (data?.success) {
                    successNotification(assignMessage(data?.message, language?.language))
                    allBUserData?.getAllBuProducts(userActions, user?._id)
                    setReservationsetting(false)
                } else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setCopyLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    let dateCond = moment(reservationData?.dateRange?.date2, 'YYYY-MM-DD').isAfter(moment()) && !(moment(reservationData?.dateRange?.date1, 'YYYY-MM-DD').isAfter(moment()))

    return (
        <div style={{ paddingLeft: '22px', paddingRight: '22px' }}>
            <p className='new-exam-title'>{selectedLanguage?.ReservationTool}</p>
            <div style={{ textAlign: 'right', width: '98.5%' }}>
                {dateCond && <Button
                    style={{ height: '40px', width: '160px', marginTop: '10px', marginRight: 10 }}
                    className='addproduct-text'
                    type='primary'
                    onClick={() => { setHistory(state => !state) }}
                >
                    {history ? selectedLanguage?.txt_Current : selectedLanguage?.txt_History}
                </Button>}
                <Button
                    style={{ height: '40px', width: '160px', marginTop: '10px' }}
                    className='addproduct-text'
                    type='primary'
                    onClick={() => {
                        setReservationsetting(false)
                        allBUserData?.getAllBuProducts(userActions, user?._id)
                    }}
                >
                    {selectedLanguage?.Back}
                </Button>
                <br />
            </div>
            <div >
                <Table
                    className='new-reservation-setting-form sidebar-scroll_class'
                    rowKey='id'
                    columns={tableColumnState}
                    dataSource={loading ? [] : tableData}
                    pagination={false}
                    scroll={{ x: 800, y: 'calc(100vh - 320px)' }}
                    loading={loading}
                />
            </div>
            <br />
            <div className='div-flex-back-name' style={{ marginTop: '-15px' }}>
                <Button loading={loading}
                    style={{ height: '45px', width: '180px' }}
                    className='addproduct-text' type='primary' onClick={onConfirm}>
                    {selectedLanguage?.ConfirmChanges}
                </Button>
            </div>
            &nbsp;&nbsp;
            <div className='screen-back-icion'>
                {tableData?.length > 7
                    ? <Button
                        style={{ height: '45px', width: '180px' }}
                        type='primary' className='addproduct-text' loading={copyLoading} onClick={copySetting}>{selectedLanguage?.CopySetting}</Button>
                    : null}
            </div>
            <br />
        </div >
    )
}

export default ReservationSetting