import { Button, Spin, Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { GET } from '../../utils/apis';
import { createGiftCard } from '../../utils/helpers';
import GuestGiftCards from '../GuestGiftCards/GuestGiftCards';
import AcceptGiftCards from './AcceptGiftCards';
import AddGiftCards from './AddGiftCards';
import MyGiftCards from './MyGiftCards';
import Requests from './Requests';
import { IoIosArrowBack } from "react-icons/io"
import NoData from "../../assets/noDataImae.png"

const GiftCards = (props) => {
    const { user, userActions, selectedLanguage, language } = props
    const [showCreate, setShowCreate] = useState(false)
    const [loading, setLoading] = useState(false)
    const [cards, setCards] = useState([])
    const [showAcceptGiftCards, setShowAcceptGiftCards] = useState(false)
    const [remainingCards, setRemainingCards] = useState(0)
    const [showRequests, setShowRequests] = useState(false)
    const [showMyGiftCards, setShowMyGiftCards] = useState(false)
    const [spin, setSpin] = useState({})
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    useEffect(() => {
        getCards()
    }, [])
    const allStatesFalse = () => {
        setShowCreate(false)
        setShowAcceptGiftCards(false)
        setShowRequests(false)
        setShowMyGiftCards(false)
    }
    const getCards = () => {
        setLoading(true)
        axios.get(`${GET.GET_ALL_USER_GIFT_CARDS}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setRemainingCards(data?.remainingCards)
                let tableData = Object.entries(data?.data)
                tableData = tableData?.map((v) => {
                    return {
                        id: v[0],
                        value: v[1]
                    }
                })
                setCards([...tableData])
            })
            .catch((err) => {
                console.log('err', err)
                setLoading(false)
            })
    }

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_SeriesName}</p>,
            key: 'id',
            dataIndex: 'id',
            ellipsis: true,
            sorter: (a, b) => a.id.length - b.id.length,
            showSorterTooltip: false,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Actions}</p>,
            key: 'actions',
            render: (e) => {
                const spinFunc = () => {
                    let newSpin = { ...spin }
                    newSpin[e?.id] = false
                    setSpin(newSpin)
                }
                return (<>
                    <div style={{ paddingTop: 15, marginTop: -5, display: 'flex', flexDirection: 'column' }}>
                        {spin?.[e?.id] ? <Spin /> :
                            <a onClick={() => (setSpin({ [e?.id]: true, ...spin }), createGiftCard(e?.value, spinFunc))}>{selectedLanguage?.ShowTotalPdfs}</a>
                        }
                    </div>
                </>)
            }
        }
    ]

    return (
        <>
            {user?.userType === 'business' ?
                <div className='new-exam-main-div'>
                    <div className='exam-transaprent-div'>
                        <p className='screen-qoute'> {selectedLanguage?.Quote7}</p>
                    </div>
                    <div className='new-exam-screen-upper'>
                        <div style={{ background: 'white', padding: '15px' }}>

                            <h3 className='new-exam-title'>{
                                showRequests ?
                                    selectedLanguage?.button_Request :
                                    showMyGiftCards ?
                                        selectedLanguage?.button_MyGiftCards :
                                        showCreate ?
                                            selectedLanguage?.button_CreateGiftCards :
                                            selectedLanguage?.title_GiftCards}</h3>

                            {showRequests || showMyGiftCards || showCreate ?
                                <div style={{ textAlign: 'right', width: '99.5%', marginRight: '10px' }}>
                                    <Button
                                        type='primary'
                                        style={{ height: '40px', width: '180px', margin: '2px', marginTop: '10px' }}
                                        onClick={allStatesFalse}>
                                        {selectedLanguage?.Back}
                                    </Button>
                                </div>
                                : null}

                            <div>
                                {showCreate ?
                                    <AddGiftCards getCards={getCards} remainingCards={remainingCards} onCancel={() => setShowCreate(false)} {...props} />
                                    :
                                    <>
                                        {!showAcceptGiftCards && !showRequests && !showMyGiftCards ?
                                            <div className='button-flex-box' style={{ width: '99.5%' }}>
                                                <Button
                                                    type='primary'
                                                    style={{ height: '40px', width: '180px', margin: '2px', marginTop: '10px' }}
                                                    className='addproduct-text'
                                                    onClick={() => setShowRequests(true)}
                                                >
                                                    {selectedLanguage?.button_Request}
                                                </Button>
                                                <Button
                                                    type='primary'
                                                    style={{ height: '40px', width: '180px', margin: '2px', marginTop: '10px' }}
                                                    className='addproduct-text'
                                                    onClick={() => setShowMyGiftCards(true)}
                                                >
                                                    {selectedLanguage?.button_MyGiftCards}
                                                </Button>
                                                < AcceptGiftCards {...props} />
                                                <Button
                                                    type='primary'
                                                    style={{ height: '40px', width: '180px', margin: '2px', marginTop: '10px' }}
                                                    className='addproduct-text'
                                                    onClick={() => setShowCreate(true)}
                                                >
                                                    {selectedLanguage?.button_CreateGiftCards}
                                                </Button>
                                            </div>
                                            :
                                            null}
                                        <div>
                                            {
                                                showRequests ?
                                                    <Requests {...props} />
                                                    :
                                                    showMyGiftCards ?
                                                        <MyGiftCards {...props} />
                                                        :
                                                        <div className='new-layout-table-upper-div'>
                                                            <Table
                                                                className='new-layout-table'
                                                                locale={customLocale}
                                                                loading={loading}
                                                                dataSource={cards}
                                                                columns={columns}
                                                                pagination={{ hideOnSinglePage: true }}
                                                                scroll={{ x: 800 }}
                                                            />
                                                        </div>}
                                        </div >
                                    </>
                                }
                            </div >
                        </div >
                    </div >
                </div >
                :
                <GuestGiftCards {...props} />
            }
        </>
    )
}

export default GiftCards