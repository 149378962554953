import { Button, DatePicker, Select, Table } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN } from '../../../utils/apis'
import { allAdminData, checkSelectOption, convertTitle, createLotteryCard } from '../../../utils/helpers'
import ViewCard from '../Cards/ViewCard'
const { RangePicker } = DatePicker
const { Option } = Select

const LotteryCards = (props) => {
    const { user, adminActions, setLotteryCardssss, selectedLanguage } = props
    const buUsers = useSelector(state => state?.adminReducer?.buUsers || [])
    const [card, setCard] = useState({})
    const [loading, setLoading] = useState(false)
    const [dateRange, setDateRange] = useState({
        date1: moment.utc().format('YYYY-MM-DD'),
        date2: moment.utc().add(7, 'days').format('YYYY-MM-DD')
    })
    const [showData, setShowData] = useState(false)
    const [selectedUser, setSelectedUser] = useState(buUsers?.[0]?._id || null)
    const [lotteryCards, setLotteryCards] = useState([])
    const [loyaltyAdvertisment, setLoyaltyAdvertisment] = useState({})
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    useEffect(() => {
        allAdminData?.getBusinessUser(adminActions, user?._id)


        if (buUsers?.length) {
            getLotteryCards()
        }
    }, [])

    useEffect(() => {
        if (buUsers?.length) {
            getLotteryCards()
        }
    }, [selectedUser, dateRange])

    const getLotteryCards = () => {
        setLoading(true)
        let obj = {
            ...dateRange,
            userId: selectedUser
        }
        axios.post(`${ADMIN.GET_LOTTERY_CARDS}`, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                let tableData = Object.entries(data?.data)
                tableData = tableData?.map((v) => {
                    return {
                        id: v[0],
                        value: v[1]
                    }
                })
                console.log('TABLE DATA', tableData)
                setLotteryCards([...tableData])
                setLoyaltyAdvertisment(data?.loyaltyAdvertisment)
            })
    }

    const getLatestData = (d) => {
        let obj = {
            date1: d[0].format('YYYY-MM-DD'),
            date2: d[1].format('YYYY-MM-DD')
        }

        setDateRange(obj)
    }

    const onCancel = () => {
        setCard({})
        setShowData(false)
    }

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_UserInvoiceId}</p>,
            key: 'id',
            dataIndex: 'id',
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Actions}</p>,
            key: 'actions',
            render: (e) => <div style={{ paddingTop: 15, marginTop: -5, display: 'flex', flexDirection: 'column' }}>
                <a onClick={() => createLotteryCard(e?.value, loyaltyAdvertisment)}>{selectedLanguage?.ShowTotalPdfs}</a>
            </div>
        }
    ]

    return (
        <div >
            <div className='teacher-button-menu-end'>
                <Button
                    type='primary'
                    style={{ width: '100px' }}
                    onClick={() => {
                        setLotteryCardssss()
                    }}
                >
                    {selectedLanguage?.Back}
                </Button>
            </div>

            <h3 className='new-exam-title'>{selectedLanguage?.button_LotteryCards}</h3>
            <RangePicker
                style={{ width: '200px', margin: '15px', marginBottom: '0px' }}
                defaultValue={[moment(), moment().add(7, 'days')]}
                onChange={getLatestData}
                allowClear={false}
            />
            <br />
            <Select
                // showSearch
                showArrow
                allowClear
                className='form-input'
                defaultValue={buUsers[0]?._id}
                onChange={(e) => setSelectedUser(e)}
                style={{ width: '200px', margin: '15px', }}
                placeholder={selectedLanguage?.SelectUser}
                showSearch
                optionFilterProp='children'
                filterOption={checkSelectOption}
            >
                {buUsers?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.fullName)} ${v?.email}`}</Option>)}
            </Select>

            <div className='new-layout-table-upper-div'>
                <Table
                    style={{ marginTop: '30px' }}
                    locale={customLocale}
                    className='new-layout-table'
                    columns={columns}
                    dataSource={lotteryCards}
                    loading={loading}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 800 }}
                />
            </div>
            {showData ? <ViewCard card={card} onCancel={onCancel} showData={showData} {...props} /> : null}
        </div>
    )
}

export default LotteryCards