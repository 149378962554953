import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { ADMIN } from '../../../utils/apis'
import countryCodes from '../../../utils/countryCodes'
import { allAdminData, assignMessage, checkSelectOption, errorMessage, successNotification } from '../../../utils/helpers'

const { Option } = Select

const AddSubAdmins = (props) => {
    const { onCancel, edit, user, adminActions, language, selectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const [formComplete, setformComplete] = useState(false)
    const [secondRange, setsecondRange] = useState(edit?.postalCode?.length > 1 ? true : false)
    const [startPostal, setstartPostal] = useState(edit?._id ? edit?.postalCode[0]?.postalcodeStart : '')
    const [endPostal, setendPostal] = useState(edit?._id ? edit?.postalCode[0]?.postalcodeEnd : '')
    const [startPostal2, setstartPostal2] = useState(edit?.postalCode?.length > 1 ? edit?.postalCode[1]?.postalcodeStart : '')
    const [endPostal2, setendPostal2] = useState(edit?.postalCode?.length > 1 ? edit?.postalCode[1]?.postalcodeEnd : '')
    const [form] = Form.useForm();
    const isEnglish = language?.language === 'english'

    const onFinish = (values) => {
        delete values?.postalCode
        delete values?.postalCode2
        values.userName = values?.userName?.trim()
        if (secondRange) {
            values.postalCode = [{ postalcodeStart: startPostal, postalcodeEnd: endPostal }, { postalcodeStart: startPostal2, postalcodeEnd: endPostal2 }]
        } else {
            values.postalCode = [{ postalcodeStart: startPostal, postalcodeEnd: endPostal }]
        }
        values.adminId = user?._id
        values.subAdminId = edit?._id
        setLoading(true)

        let ROUTE = !edit?._id ? ADMIN?.CREATESUBADMIN : ADMIN?.EDITSUBADMIN

        axios.post(ROUTE, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    allAdminData.getSubAdmins(adminActions, user?._id)
                    successNotification(assignMessage(data?.message, language?.language))
                    onCancel()
                } else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log('e', e)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }
    console.log('edit', edit)
    return (
        <div style={{ paddingBottom: 40 }}>
            <div style={{ background: 'white' }}>
                <p className='new-exam-title'>{edit?._id ? selectedLanguage?.title_updateSubAdmin : selectedLanguage?.title_createSubAdmin} </p>
                <div style={{ textAlign: 'right', width: '99%', marginTop: '10px' }}>
                    <Button
                        type='primary'
                        onClick={onCancel}
                    >
                        {selectedLanguage?.Cancel}
                    </Button>
                </div>
                <div style={{ padding: '10px', paddingTop: '20px' }}>
                    <Form
                        name='form'
                        form={form}
                        onFinish={onFinish}
                        layout={'vertical'}
                        autoComplete='off'
                    >
                        <Form.Item
                            name='userName'
                            label={selectedLanguage?.label_userName}
                            hasFeedback
                            initialValue={edit?.userName}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.requiredMessage_pleaseInputUserName
                                }
                            ]}
                        >
                            <Input
                                className='form-input'
                                placeholder={selectedLanguage?.placeholder_pleaseInputUserName}
                                min={0}
                                maxLength={25}
                            />
                        </Form.Item>
                        {!edit?._id &&
                            <>
                                <Form.Item
                                    name='password'
                                    label={selectedLanguage?.Password}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.PleaseInputYourPassword,
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item
                                    name='confirm'
                                    label={selectedLanguage?.label_confirmPassword}
                                    dependencies={['password']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.requiredMessage_Pleaseconfirmyourpassword,
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error(selectedLanguage?.error_Pleaseconfirmyourpassword));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </>
                        }

                        <Form.Item
                            name='country'
                            label={selectedLanguage?.Country}
                            initialValue={edit?.country}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseSelectCountry
                                }
                            ]}
                        >
                            <Select
                                showArrow
                                allowClear={false}
                                // className='form-input-new-style'
                                placeholder={selectedLanguage?.SelectCountry}
                                showSearch
                                optionFilterProp='children'
                                filterOption={checkSelectOption}
                            >
                                {countryCodes?.map((v, i) => <Option value={v?.code} key={i}>{language?.language === 'english' ? v?.name : v?.nameNL}</Option>)}
                            </Select>
                        </Form.Item>
                        <label>{selectedLanguage?.label_PostalCode}</label>
                        <Form.Item
                            name='postalCode'
                            label={selectedLanguage?.label_FirstRange}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    validator: async (_, names) => {
                                        if (formComplete && startPostal.length != 6) {
                                            return Promise.reject(new Error(selectedLanguage?.errorMessage_LengthofPostalCode));
                                        }
                                        if (formComplete && endPostal.length != 6) {
                                            return Promise.reject(new Error(selectedLanguage?.errorMessage_LengthofPostalCode));
                                        }
                                        if (formComplete && startPostal.localeCompare(endPostal, undefined, { numeric: true, sensitivity: 'base' }) != -1) {
                                            return Promise.reject(new Error(selectedLanguage?.errorMessage_StartoftheRange));
                                        }
                                    },
                                },
                            ]}
                        >
                            <Row gutter={24}>

                                <Col className='gutter-row' span={8}>
                                    <center><label >{selectedLanguage?.Start}</label></center>
                                    <Input
                                        disabled={edit?.invitedUsersId?.length ? true : false}
                                        value={startPostal}
                                        onChange={(e) => setstartPostal(e.target.value.toUpperCase().replace(/[^a-z0-9]/gi, ''))}
                                        maxLength={6}
                                        // pattern= 
                                        className='form-input gutter-box'
                                        placeholder={selectedLanguage?.placeholder_StartRange}
                                    // style='text-transform:uppercase'
                                    />
                                </Col>
                                <Col className='gutter-row' span={8}>
                                    <center><label >{selectedLanguage?.label_End}</label></center>
                                    <Input
                                        disabled={edit?.invitedUsersId?.length ? true : false}
                                        onChange={(e) => setendPostal(e.target.value.toUpperCase().replace(/[^a-z0-9]/gi, ''))}
                                        value={endPostal}
                                        maxLength={6}
                                        className='form-input gutter-box'
                                        placeholder={selectedLanguage?.label_EndRange}
                                    />
                                </Col>
                                {!secondRange && !edit?.invitedUsersId?.length ? <PlusCircleOutlined
                                    style={{ marginTop: 35 }}
                                    className='dynamic-delete-button'
                                    onClick={() => setsecondRange(true)}
                                /> : null}

                            </Row>
                        </Form.Item>

                        {secondRange ?
                            <Form.Item
                                name='postalCode2'
                                label={selectedLanguage?.label_secondRange}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        validator: async (_, names) => {

                                            let checkStart = (startPostal2.localeCompare(startPostal, undefined, { numeric: true, sensitivity: 'base' }) == 1 && startPostal2.localeCompare(endPostal, undefined, { numeric: true, sensitivity: 'base' }) == -1) || (startPostal.localeCompare(startPostal2, undefined, { numeric: true, sensitivity: 'base' }) == 1 && startPostal.localeCompare(endPostal2, undefined, { numeric: true, sensitivity: 'base' }) == -1);

                                            let checkEnd = (endPostal2.localeCompare(startPostal, undefined, { numeric: true, sensitivity: 'base' }) == 1 && endPostal2.localeCompare(endPostal, undefined, { numeric: true, sensitivity: 'base' }) == -1) || (endPostal.localeCompare(startPostal2, undefined, { numeric: true, sensitivity: 'base' }) == 1 && endPostal.localeCompare(endPostal2, undefined, { numeric: true, sensitivity: 'base' }) == -1);

                                            if (formComplete && (startPostal2 == startPostal || startPostal2 == endPostal || checkStart || checkEnd)) {
                                                return Promise.reject(new Error(selectedLanguage?.errorMessage_BothRangesMustNotIntersect));
                                            }
                                            if (formComplete && (endPostal2 == startPostal || endPostal2 == endPostal)) {
                                                return Promise.reject(new Error(selectedLanguage?.errorMessage_BothRangesMustNotIntersect));
                                            }
                                            if (formComplete && startPostal2.length != 6) {
                                                return Promise.reject(new Error(selectedLanguage?.errorMessage_LengthofPostalCode));
                                            }
                                            if (formComplete && endPostal2.length != 6) {
                                                return Promise.reject(new Error(selectedLanguage?.errorMessage_LengthofPostalCode));
                                            }
                                            if (formComplete && startPostal2.localeCompare(endPostal2, undefined, { numeric: true, sensitivity: 'base' }) != -1) {
                                                return Promise.reject(new Error(selectedLanguage?.errorMessage_StartoftheRange));
                                            }
                                        }
                                    }
                                ]}
                            >
                                <Row gutter={24}>
                                    <Col className='gutter-row' span={8}>
                                        <center><label >{selectedLanguage?.Start}</label></center>
                                        <Input
                                            disabled={edit?.invitedUsersId?.length ? true : false}
                                            value={startPostal2}
                                            onChange={(e) => setstartPostal2(e.target.value.toUpperCase().replace(/[^a-z0-9]/gi, ''))}
                                            maxLength={6} 
                                            className='form-input gutter-box'
                                            placeholder={selectedLanguage?.placeholder_StartRange}
                                        />
                                    </Col>
                                    <Col className='gutter-row' span={8}>
                                        <center><label >{selectedLanguage?.label_End}</label></center>
                                        <Input
                                            disabled={edit?.invitedUsersId?.length ? true : false}
                                            onChange={(e) => setendPostal2(e.target.value.toUpperCase().replace(/[^a-z0-9]/gi, ''))}
                                            value={endPostal2}
                                            maxLength={6}
                                            className='form-input gutter-box'
                                            placeholder={selectedLanguage?.label_EndRange}
                                        />
                                    </Col>
                                    {secondRange && !edit?.invitedUsersId?.length ?
                                        <MinusCircleOutlined
                                            // disabled={true}
                                            style={{ marginTop: 35 }}
                                            className='dynamic-delete-button'
                                            onClick={() => {
                                                setsecondRange(false);
                                                setstartPostal2();
                                                setendPostal2()
                                            }}
                                        /> : null}
                                </Row>
                            </Form.Item>
                            : null
                        }

                        <Form.Item
                            label={selectedLanguage?.txt_Shareper}
                            name='share'
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.txt_InputShareperforRevenue,
                                }
                            ]}
                            initialValue={edit?._id ? edit?.share : 0.5}
                        >
                            <InputNumber
                                className='form-input'
                                placeholder={selectedLanguage?.txt_InputPercentage}
                                min={0.5}
                                max={100}
                                style={{ width: '100%' }}
                                step={0.1}
                                precision={1}
                                type='number'
                                pattern='[0-9]*'
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type='primary'
                                className='form-button'
                                block
                                htmlType='submit'
                                onClick={() => setformComplete(true)}
                                loading={loading}
                            >
                                {edit?._id ? selectedLanguage?.Update : selectedLanguage?.Create}
                            </Button>

                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default AddSubAdmins