import { Button, Table } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GET } from '../../utils/apis'
import { allBUserData, assignMessage, createPaymentReportPdf, errorMessage, validateTime, warningMessage } from '../../utils/helpers'
import { IoIosArrowBack } from "react-icons/io"
import NoData from "../../assets/noDataImae.png"

const BuPayments = (props) => {
    const { user, userActions, language, selectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const [paymentHistory, setPaymentHistroy] = useState(false)
    const [reports, setReports] = useState([])
    const payments = useSelector(state => state?.userReducer?.payments || [])
    const isEnglish = language?.language === 'english'
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };

    useEffect(() => {
        allBUserData?.getAllBuPayment(userActions, user?._id)
        getPaymentReport()
    }, [])


    const getPaymentReport = () => {
        setLoading(true)

        axios.get(`${GET.GET_BU_PAYMENT_REPORTS}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setReports(data?.data || [])
                setLoading(false)
            })
            .catch((e) => {
                console.log('e', e)
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const checkDate = (e) => {
        const filteredReport = reports?.filter((v, i) => {
            let date1 = moment(v?.date1)?.format('YYYY-MM-DD')
            let date2 = moment(v?.date2)?.format('YYYY-MM-DD')
            return moment(e).isBetween(date2, date1)
        })
        if (filteredReport?.length) {
            createPaymentReportPdf(filteredReport[0], user, 'download')
        } else {
            warningMessage(selectedLanguage?.ReportIsNotGeneratedForThisPayment)
        }
    }

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.ProductName}</p>,
            dataIndex: ['voucher', 'product', 'name'],
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.TotalAmount}</p>,
            dataIndex: ['voucher', 'invoice', 'totalAmount'],
            align: 'center',
            render: (e) => <p style={{ marginBottom: 0 }}>€ {e}</p>

        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Quantity}</p>,
            dataIndex: ['voucher', 'invoice', 'quantity'],
            align: 'center'
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Status}</p>,
            dataIndex: 'adminPaid',
            sorter: (a, b) => a?.adminPaid - b?.adminPaid,
            showSorterTooltip: false,
            render: (e) => <>{e ? selectedLanguage?.Paid : selectedLanguage?.Unpaid}</>,
            align: 'center'
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Report}</p>,
            dataIndex: ['voucher', 'voucherDate'],
            key: 'action',
            render: (e) => (<Button onClick={() => checkDate(e)}>{selectedLanguage?.Report}</Button>),
            align: 'center'
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.VoucherDate}</p>,
            dataIndex: ['voucher', 'voucherDate'],
            sorter: (a, b) => a?.voucherDate?.length - b?.voucherDate?.length,
            showSorterTooltip: false,
            render: (e) => <>{moment(e).format('YYYY-MM-DD')}</>,
            align: 'center'
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.VoucherTime}</p>,
            dataIndex: ['voucher', 'voucherTime'],
            align: 'center',
            render: (e) => validateTime(e)
        }
    ]

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote3}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>
                    <div className='main-div-new-style'>
                        <p className='new-exam-title'>{paymentHistory ? selectedLanguage?.label_PaymentsHistory : selectedLanguage?.Payments}</p>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '99%' }}>
                            {paymentHistory ?
                                <Button
                                    style={{ height: '40px', width: '160px', marginTop: '10px' }}
                                    className='addproduct-text'
                                    onClick={() => {
                                        setPaymentHistroy(false)
                                    }}
                                    type='primary'
                                >{selectedLanguage?.Back}</Button>
                                : null}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '99%' }}>
                            {paymentHistory ?
                                null :
                                <Button
                                    style={{ height: '40px', width: '160px', marginTop: '10px' }}
                                    className='addproduct-text'
                                    onClick={() => {
                                        payments?.length ?
                                            setPaymentHistroy(true)
                                            : setPaymentHistroy(false)
                                    }}
                                    type='primary'
                                >
                                    {selectedLanguage?.label_PaymentsHistory}
                                </Button>}
                        </div>
                        <br />

                        {!paymentHistory ?
                            <div className='new-layout-table-upper-div'>
                                <Table
                                    className='new-layout-table'
                                    locale={customLocale}
                                    columns={columns}
                                    dataSource={payments}
                                    pagination={{ hideOnSinglePage: true }}
                                    scroll={{ x: 400 }}
                                />
                            </div>
                            :
                            <div style={{ marginTop: '20px' }}>
                                <div
                                    className='payment-history-cards-div'
                                    style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                    {
                                        payments?.length ? payments?.map((v, i) => {
                                            return (
                                                <div
                                                    key={1 + i}
                                                    className='payment-history-card'
                                                    style={{
                                                        margin: '1%',
                                                        background: 'white',
                                                        borderBottomRightRadius: '6px',
                                                        borderTopRightRadius: '6px',
                                                        borderLeft: '4px solid #ec671b',
                                                        boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%)',
                                                        padding: '10px',
                                                        paddingTop: '4px'
                                                    }}>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        height: '24px'
                                                    }}>
                                                        <div style={{ width: '75%', }}>
                                                            <p
                                                                style={{
                                                                    fontSize: 16,
                                                                    fontWeight: '500',
                                                                    color: 'black',
                                                                    width: '100%',
                                                                    textAlign: 'start',
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis'
                                                                }}>{v?.voucher?.product?.name}</p>
                                                        </div>
                                                        <div>
                                                            <p
                                                                style={{
                                                                    fontSize: 16,
                                                                    fontWeight: '500',
                                                                    color: 'black',
                                                                }}
                                                            >€{v?.voucher?.invoice?.totalAmount}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <div style={{ width: '100%' }}>
                                                            <p
                                                                style={{
                                                                    fontSize: 13,
                                                                    fontWeight: '400',
                                                                }}>{selectedLanguage?.ProductType}:{v?.voucher?.product?.name}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'flex-end',
                                                        height: '23px'
                                                    }}>
                                                        <div>
                                                            <p
                                                                style={{
                                                                    fontSize: 13,
                                                                    fontWeight: '400',
                                                                }}
                                                            >
                                                                <span>{`${v?.voucher?.voucherTime?.length === 4 ? v?.voucher?.voucherTime + '0' : v?.voucher?.voucherTime} | ${moment(v?.voucher?.voucherDate).format('DD-MM-YY')}`}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        height: '23px'
                                                    }}>
                                                        <div style={{ width: '40%', }}>
                                                            <p
                                                                style={{
                                                                    fontSize: 13,
                                                                    fontWeight: '400',
                                                                }}>{selectedLanguage?.Price}:{v?.voucher?.product?.price[0].price || 0}</p>
                                                        </div>
                                                        <div>
                                                            <p
                                                                style={{
                                                                    fontSize: 13,
                                                                    fontWeight: '400',
                                                                }}
                                                            >{'john smith'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : null}
                                </div>
                            </div >
                        }
                    </div >
                </div >
            </div >
        </div >
    )
}

export default BuPayments

