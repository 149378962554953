import { Button, Modal, Popconfirm } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useState } from 'react'
import { POST } from '../../utils/apis'
import ReviewForm from '../GuestReview/ReviewForm'
import { assignMessage, convertTitle, errorMessage, successMessage, validateTime } from '../../utils/helpers'
import QRCode from 'qrcode.react'
import HTML2Canvas from 'html2canvas'
import jsPDF from 'jspdf'

const Details = (props) => {
    const { product, user, details, buUser, invoice, selectedLanguage, language, onBack } = props
    let ages = invoice?.age ? Object.keys(invoice?.ageQuantity) : []
    const [reviewData, setReviewData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [productDetails, setProductDetails] = useState({})
    const [download, setDownload] = useState(true)
    const onCancel = () => {
        setReviewData(null)
    }
    const getDateDiff = (voucherDate, voucherTime, cancelHours) => {
        let format = 'YYYY-MM-DD HH:mm'
        let voucherDateTime = moment(voucherDate).format('YYYY-MM-DD') + ' ' + voucherTime
        let currentDateAndTime = moment().format('YYYY-MM-DD HH:mm')
        let momentvoucherDateTime = moment(voucherDateTime, format)
        let momentcurrentDateAndTime = moment(currentDateAndTime, format)
        let diff = momentvoucherDateTime.diff(momentcurrentDateAndTime, 'h')

        return diff >= cancelHours
    }
    const cancelReservation = (e) => {
        setLoading(true)
        let obj = {
            userId: user?._id,
            cancelHours: e?.product?.cancelHours,
            quantity: e?.invoice?.quantity,
            voucherDate: e?.voucherDate,
            voucherTime: validateTime(e?.voucherTime),
            reservationId: e?.invoice?.reservationId,
            settingsId: e?.invoice?.settingsId,
            shiftsId: e?.invoice?.shiftsId,
            voucherId: e?._id,
            voucherCode: e?.voucherCode,
            invoiceId: e?.invoice?._id
        }
        // return true
        axios.post(POST?.VOUCHER_CANCELLATION, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                } else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })

            .catch((e) => {
                setLoading(false)
                console.log('e', e)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }
    const disableModal = () => setVisible(false)

    const downloadQR = async () => {
        let id1 = 'qrCode'
        const canvas = document.getElementById(id1)

        await HTML2Canvas(canvas, { allowTaint: true, useCORS: true })
            .then((data) => {
                const pngUrl = data.toDataURL()
                let downloadLink = document.createElement('a')
                downloadLink.href = pngUrl
                var doc = new jsPDF('p', 'mm')
                doc.addImage(pngUrl, 'PNG', 10, 10)
                doc.save(`${productDetails?.product?.name || 'Voucher'}.pdf`)
                setDownload(false)
            })
            .catch((e) => {
                setDownload(false)
                console.log('e', e)
                errorMessage(selectedLanguage?.txt_downloaderror)
            })
    }
    const showQR = () => {
        return (
            <Modal
                open={visible}
                onCancel={disableModal}
                className='pop-info-modal'
                style={{ top: '10px' }}
                footer={null}
            >
                <p new-p-question>{selectedLanguage?.VoucherCode}</p>
                <div
                    id='qrCode' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: 10 }}
                >
                    <div id='productImage' style={{
                        textAlign: 'center',
                        width: '90%',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                        marginBottom: '10px',
                        paddingTop: '10px',
                        borderRadius: '8px'
                    }}>
                        <img src={productDetails?.product?.imageUrl} style={{ maxWidth: '100%', height: 200 }} />
                        <p className='heading head-center' style={{ marginBottom: 0 }}>{productDetails?.product?.name}</p>
                        <p className='product_desc'>{productDetails?.product?.description}</p>
                    </div>
                    <div
                        className='voucherDetailVeiw'
                    >
                        <div>
                            <QRCode
                                value={productDetails?.voucherCode}
                                size={200}
                                level={'H'}
                                includeMargin={true}
                            />
                            <p style={{ marginTop: -10 }}>{productDetails?.voucherCode}</p>
                        </div>
                        <div style={{ textAlign: 'start', marginTop: 'auto', marginBottom: 'auto' }}>
                            <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.UserName}:</span> {productDetails?.buUser?.businessName}</div>
                            <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.TelNo}:</span> {productDetails?.buUser?.telNo}</div>
                            <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.ReservedDate}:</span> {moment(productDetails?.voucherDate).format('YYYY-MM-DD')}</div>
                            <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.ReservedTime}:</span> {validateTime(productDetails?.voucherTime)}</div>
                            <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.Quantity}:</span> {productDetails?.invoice?.quantity}</div>
                            <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.TotalAmount}:</span>€ {productDetails?.invoice?.totalAmount}</div>
                            <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.GuestName}:</span> {productDetails?.userId?.fullName}</div>
                        </div>
                    </div>
                    <br />
                </div>
                <center><p
                    style={{ height: '40px', width: '160px', marginTop: '10px', paddingTop: '10px' }}
                    className='addproduct-text'
                    type='primary'
                    onClick={() => downloadQR(productDetails)}
                > {selectedLanguage?.DownloadQR} </p></center>
            </Modal>
        )
    }

    return (
        <div style={{ paddingBottom: 40, paddingTop: '20px' }}>

            <ReviewForm reviewData={reviewData} onCancel={onCancel}  {...props} />
            {showQR()}
            <div className='detail-card-inner-div'>
                <div style={{ textAlign: 'right', width: '98%', paddingTop: 10 }}>
                    <Button
                        type='primary'
                        onClick={onBack}
                    >
                        {selectedLanguage?.Cancel}
                    </Button>
                </div>
                <div className='MobileTable  detail-card-main-div' style={{ padding: 10 }}>
                    <div style={{
                        width: '100%',
                        marginBottom: '20px',
                        textAlign: 'start',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
                        <Button
                            style={{ height: '40px', width: '60px', margin: '0px', padding: '0px', marginTop: '10px' }}
                            className='addproduct-text'
                            type='primary'
                            onClick={() => (setProductDetails(details), setVisible(true))}
                        >
                            {selectedLanguage?.QRCode}
                        </Button>
                        {details?.isClaim ?
                            <Button
                                style={{ height: '40px', width: '100px', margin: '0px', padding: '0px', marginTop: '10px' }}
                                className='addproduct-text'
                                type='primary'
                                onClick={() => { setReviewData(details?.product) }}
                            >
                                {selectedLanguage?.WriteAReview}
                            </Button> :
                            <Button
                                disabled
                                style={{ height: '40px', width: '100px', margin: '0px', padding: '0px', marginTop: '10px', color: 'grey' }}
                                className='addproduct-text'
                                type='primary'
                            >
                                {selectedLanguage?.WriteAReview}
                            </Button>}
                        {details?.isCancel || details?.isClaim ?
                            <Button disabled={details?.isCancel || details?.isClaim} danger loading={loading}
                                style={{ height: '40px', width: '125px', margin: '0px', padding: '0px', marginTop: '10px' }}
                                className='addproduct-text'
                                type='primary'
                            >{details?.isClaim ?
                                selectedLanguage?.Claimed :
                                details?.isCancel ?
                                    selectedLanguage?.status_CANCELLED :
                                    selectedLanguage?.Canceled}</Button>
                            : <Popconfirm
                                title={getDateDiff(details?.voucherDate,
                                    validateTime(details?.voucherTime),
                                    details?.product?.cancelHours)
                                    ? selectedLanguage?.popupMessage_AreYouSureYouWantToCancel :
                                    `${selectedLanguage?.popupMessage_IfYouCancelThisReservation}
                                     ${details?.product?.cancelHours} hr `}
                                okText={selectedLanguage?.Yes}
                                cancelText={selectedLanguage?.No}
                                okButtonProps={{
                                    type: 'primary',
                                    className: 'form-button'
                                }}
                                cancelButtonProps={{ style: { borderRadius: 4 } }}
                                onConfirm={() => cancelReservation(details)}
                            >
                                <Button
                                    style={{ height: '40px', width: '125px', margin: '0px', padding: '0px', marginTop: '10px' }}
                                    className='addproduct-text'
                                    type='primary'
                                    disabled={details?.isCancel || details?.isClaim} danger loading={loading} >
                                    {selectedLanguage?.CancelReservation}</Button>
                            </Popconfirm >}
                    </div>
                </div>
                <div className='detail-card-inner-div'>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }} >
                        <img style={{ height: '40vh', maxWidth: '100%' }}
                            alt='example' src={product?.imageUrl} />
                    </div>
                </div>
                <div className='detail-card-inner-div'>
                    <div style={{ borderBottom: '2px solid rgba(0, 0, 0, 0.3)' }}>
                        <div style={{ padding: '20px', paddingBottom: '0px' }}>
                            <div className='div-flex-back-name' style={{ justifyContent: 'space-between', height: '30px' }}>
                                <div>
                                    <p style={{
                                        fontSize: 18,
                                        fontWeight: 'bold',
                                        color: 'black',
                                        width: '100%',
                                        textAlign: 'start',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>{product?.name || '------'}</p>
                                </div>
                                <div>
                                    <p style={{ color: '#ec671b' }}>{selectedLanguage?.Tax}: {product?.tax}%</p>
                                </div>
                            </div>
                            <div>
                                <p style={{
                                    color: 'grey',
                                    textAlign: 'start',
                                    lineHeight: 1.4,
                                    height: '42px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    lineBreak: 'anywhere',
                                    marginBottom: 0
                                }}>
                                    {product?.description || '-------------------------------'}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{ padding: '20px', textAlign: 'left' }}>
                        <h3
                            style={{
                                fontSize: 18,
                                fontWeight: 'bold',
                                color: 'black',
                            }}
                        >{selectedLanguage?.Text_OtherDetails}</h3>
                        {invoice?.age ?
                            <div>
                                <div>{selectedLanguage?.Price}:
                                    <ul>
                                        {ages?.map((v, i) => {
                                            return (<li> {`(${v}) yrs: € ${invoice?.agePrice?.[0]?.[v]}`} </li>)
                                        })}
                                    </ul>
                                </div>
                                <div>{selectedLanguage?.Quantity}:
                                    <ul>
                                        {ages?.map((v, i) => {
                                            return (<li> {`(${v}) yrs: ${invoice?.ageQuantity?.[v]}`} </li>)
                                        })}
                                    </ul>
                                </div>
                            </div>

                            :
                            <>
                                <div>{selectedLanguage?.Price}: €{' ' + invoice?.price}</div>
                                <div>{selectedLanguage?.Quantity}: {' ' + invoice?.quantity}</div>
                            </>
                        }
                        {invoice?.couponId?._id &&
                            <div>
                                <div>{selectedLanguage?.Coupon + ' ' + selectedLanguage?.Amount}: €{' ' + invoice?.couponId?.amount}</div>
                                <div>{selectedLanguage?.CouponCode}: {invoice?.couponId?.couponCode}</div>
                            </div>
                        }

                        <div>{selectedLanguage?.AmountPaid}: €{' ' + invoice?.totalAmount}</div>
                        <div>{selectedLanguage?.ReservedDate}: {' ' + moment(details?.voucherDate).format('YYYY-MM-DD')}</div>
                        <div>{selectedLanguage?.ReservedTime}: {' ' + validateTime(details?.voucherTime)}</div>
                        <div>{selectedLanguage?.GuestName}: {details?.userId?.fullName}</div>
                        <div>{selectedLanguage?.GuestTelNo}: {details?.userId?.telNo}</div>
                        <div>{selectedLanguage?.Country}: {buUser?.country}</div>
                        <div>{selectedLanguage?.Provincie}: {buUser?.provincie}</div>
                        <div>{selectedLanguage?.City}: {buUser?.city}</div>
                        <div>{selectedLanguage?.BusinessName}: {buUser?.businessName}</div>
                        <div>{selectedLanguage?.BusinessTelNo}: {buUser?.telNo}</div>
                        <div>{selectedLanguage?.VoucherCode}: {details?.voucherCode}</div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Details