import React, { useEffect, useState } from "react";
import { FaFacebookF, FaHome, FaTwitter, FaInstagram } from 'react-icons/fa'
import { IoIosArrowDown } from 'react-icons/io'
import { TbWorldWww } from 'react-icons/tb'
import { allPaths, drawerGuestRoutes, drawerRoutes } from '../../../utils/constants'
import { Menu, Tooltip } from "antd";
import { setKeyMenu } from "../../../utils/helpers";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { TfiMenu } from "react-icons/tfi";
import { setLanguage } from "../../../Redux/actions/languageAction";
import ReactFlagsSelect from "react-flags-select";
import { FaQuestion } from "react-icons/fa6";
import LoginAlert from "./LoginAlert";
import { BsTwitterX } from "react-icons/bs";

const NewVisiterHeader = (props) => {
    const { user, language, selectedLanguage } = props
    const { SubMenu } = Menu
    const [selected, setSelected] = useState('GB');
    let studentRoutes = drawerGuestRoutes
    const isEnglish = language?.language === 'english'
    const isEgypt = language?.language === 'egypt'
    const dispatch = useDispatch()
    const history = useHistory()
    const [modal, setModal] = useState(false);
    let hideTabs = [allPaths?.FAQ, allPaths.GUEST_HOME,]
    const currentPage = window?.location?.pathname

    const openModalForLogin = () => {
        setModal(true)
    }
    const gotoLogin = () => {
        history?.push(allPaths?.LOGIN)
    }
    useEffect(() => {
        language?.language === 'english' ? setSelected('GB') :
            language?.language === 'egypt' ? setSelected('EG') :
                setSelected('NL')
    })

    const onchangeLanguage = (v) => {
        setSelected(v)
        if (v === 'GB') {
            dispatch(setLanguage('english'))
        }
        else if (v === 'EG') {
            dispatch(setLanguage('egypt'))
        }
        else {
            dispatch(setLanguage('netherlands'))
        }
    }

    return (
        <div className="new-main-header-div" >
            <div className="new-main-header-inner-top">
                <div className="new-upper-header">
                    <div className="upper-header-left">
                        <p className='upper-header-txt-logo'>
                            CITY-DEALS
                            <span>
                                {selectedLanguage?.txt_EXPERIENCEYOULLNEVERFORGET}
                            </span>
                        </p>
                    </div>
                    <div className="upper-header-right">
                        <p className="uppr-icon-main">
                            <span className="upper-header-icon"><FaFacebookF size={18} /></span>
                            <span className="upper-header-icon"><BsTwitterX size={18} /></span>
                            <span className="upper-header-icon"><FaInstagram size={18} /></span>
                            <span
                                onClick={() => history?.push(currentPage === allPaths?.VISITER_HOME ? allPaths?.VISITER_FAQ : allPaths?.VISITER_HOME)}
                                className="upper-header-icon" >
                                {currentPage === allPaths?.VISITER_HOME ?
                                    <FaQuestion size={18} /> :
                                    <FaHome size={18} />}
                            </span>
                        </p>
                        <span className="round-lang-upper-header-icon">
                            <ReactFlagsSelect
                                className='round-countries-select-update'
                                fullWidth={false}
                                showOptionLabel={false}
                                showSelectedLabel={false}
                                countries={["NL", "GB", "EG"]}
                                selected={selected}
                                onSelect={(v) => onchangeLanguage(v)}
                            />
                        </span>
                    </div>
                </div>
            </div>
            <div className="new-main-header-inner-bottom">
                <div className="new-lower-nav">
                    <div className="lower-nav-left">
                        <span className="new-menu-for-web" style={{ display: 'flex' }}>
                            <Menu
                                defaultOpenKeys={[`${setKeyMenu(window?.location?.pathname)}`]}
                                defaultSelectedKeys={[`${window?.location?.pathname}`]}
                                mode="horizontal"
                                className="me-new-top-menu-home"
                            // style={{width:'700px'}}
                            >
                                {(studentRoutes)?.map((v, i) => {
                                    if (hideTabs?.includes?.(v.route)) {
                                        return null
                                    }
                                    return (
                                        <SubMenu
                                            key={v.route}
                                            className="new-header-sub-menu"
                                            title={
                                                <span className="my-menu-txtt"
                                                    onClick={openModalForLogin}
                                                    style={{ color: 'white' }} >
                                                    {isEnglish ? v.title : isEgypt ? v?.titleEgypt : v.titleNl}
                                                    {v?.isSubMenu && v?.children?.length &&
                                                        <span style={{ paddingLeft: '5px', paddingTop: '3px' }}>
                                                            {<IoIosArrowDown color={'white'} />}
                                                        </span>}
                                                </span>
                                            }>
                                        </SubMenu>
                                    );
                                })}
                            </Menu>
                        </span>
                        <span className="new-menu-for-mobile" onClick={openModalForLogin}>
                            <div className="new-header-men-icon">
                                <TfiMenu size={20} />
                            </div>
                        </span>
                    </div>
                    <div className="lower-nav-right">
                        <p className="button-triangle"></p>
                        <p className="lower-nav-right-bton" onClick={gotoLogin} >{selectedLanguage?.Login}</p>
                    </div>
                </div>
            </div>
            <LoginAlert history={history} allPaths={allPaths} selectedLanguage={selectedLanguage} open={modal} onCancel={() => setModal(false)} {...props} />
        </div >
    )
}

export default NewVisiterHeader