import { Button, Spin, Table } from 'antd';
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ADMIN } from '../../../utils/apis';
import { createGiftCard } from '../../../utils/helpers';
import AddGiftCardImages from './AddGiftCardImages';
import AddGiftCards from './AddGiftCards';
import BuGiftCards from './BuGiftCards';
import GiftCardImages from './GiftCardImages';
import GiftCardUsers from './GiftCardUsers';
import GiftNotifications from './GiftNotifications';
import GiftRequests from './GiftRequests';
import { TfiMenu } from 'react-icons/tfi';

const AdminGiftCards = (props) => {
    const { user, selectedLanguage } = props
    const [showCreate, setShowCreate] = useState(false)
    const [loading, setLoading] = useState(false)
    const [cards, setCards] = useState([])
    const [showRequests, setShowRequests] = useState(false)
    const [showNotifications, setShowNotifications] = useState(false)
    const [showBuGiftCards, setShowBuGiftCards] = useState(false)
    const [showGiftCardImages, setShowGiftCardImages] = useState(false)
    const [showGiftCardUsers, setShowGiftCardUsers] = useState(false)
    const [addGiftCardImages, setAddGiftCardImages] = useState(false)
    const [spin, setSpin] = useState({})
    const [anchorEl, setAnchorEl] = useState(null)
    const open = anchorEl;
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    useEffect(() => {
        getCards()
    }, [])
    const allStatesFalse = () => {
        setShowCreate(false)
        setShowRequests(false)
        setShowNotifications(false)
        setShowBuGiftCards(false)
        setShowGiftCardImages(false)
        setShowGiftCardUsers(false)
    }
    const getCards = () => {
        setLoading(true)
        axios.get(`${ADMIN.GET_ALL_ADMIN_GIFT_CARDS}/${user?._id}`)
            .then((res) => {
                const { data } = res
                console.log('data', data)
                setLoading(false)
                let tableData = Object.entries(data?.data)
                tableData = tableData?.map((v) => {
                    return {
                        id: v[0],
                        value: v[1]
                    }
                })
                setCards([...tableData])
            })
            .catch((err) => {
                console.log('err', err)
                setLoading(false)
            })
    }

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_SeriesName}</p>,
            key: 'id',
            dataIndex: 'id',
            ellipsis: true,
            sorter: (a, b) => a.id.length - b.id.length,
            showSorterTooltip: false,

        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Actions}</p>,
            key: 'actions',
            render: (e) => {
                const spinFunc = () => {
                    let newSpin = { ...spin }
                    newSpin[e?.id] = false
                    setSpin(newSpin)
                }
                return (<>
                    <div style={{ paddingTop: 15, marginTop: -5, display: 'flex', flexDirection: 'column' }}>
                        {spin?.[e?.id] ? <Spin /> :
                            <a onClick={() => (setSpin({ [e?.id]: true, ...spin }), createGiftCard(e?.value, spinFunc))}>{selectedLanguage?.ShowTotalPdfs}</a>
                        }
                    </div>
                </>)
            }
        }
    ]

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote7}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>

                    {
                        showCreate ?
                            <AddGiftCards getCards={getCards} onCancel={() => setShowCreate(false)} {...props} />
                            :
                            addGiftCardImages ?
                                <AddGiftCardImages onCancel={() => setAddGiftCardImages(false)} {...props} />
                                :
                                <div>
                                    {!showRequests && !showNotifications && !showBuGiftCards && !showGiftCardImages && !showGiftCardUsers ?
                                        <div className='teacher-button-menu-end'>
                                            <IconButton
                                                aria-label='more'
                                                id='long-button'
                                                aria-controls={open ? 'long-menu' : undefined}
                                                aria-expanded={open ? 'true' : undefined}
                                                aria-haspopup='true'
                                                onClick={handleClick}
                                            >
                                                <TfiMenu size={28} color='#ec671b' />
                                            </IconButton>

                                            <Menu
                                                id='long-menu'
                                                MenuListProps={{
                                                    'aria-labelledby': 'long-button',
                                                }}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                            >
                                                <MenuItem key={1}>
                                                    <Button
                                                        style={{ width: '185px' }}
                                                        type='primary'
                                                        onClick={() => { setShowNotifications(true); handleClose() }}
                                                    >
                                                        {selectedLanguage?.txt_Notifications}
                                                    </Button>
                                                </MenuItem>

                                                <MenuItem key={2}>
                                                    <Button
                                                        style={{ width: '185px' }}
                                                        type='primary'
                                                        onClick={() => { setShowRequests(true); handleClose() }}
                                                    >
                                                        {selectedLanguage?.txt_Requests}
                                                    </Button>
                                                </MenuItem >

                                                <MenuItem key={3}>
                                                    <Button
                                                        style={{ width: '185px' }}
                                                        type='primary'
                                                        onClick={() => { setShowBuGiftCards(true); handleClose() }}
                                                    >
                                                        {selectedLanguage?.txt_BUGiftCards}
                                                    </Button>
                                                </MenuItem>

                                                <MenuItem key={4}>
                                                    <Button
                                                        style={{ width: '185px' }}
                                                        type='primary'
                                                        onClick={() => { setShowGiftCardUsers(true); handleClose() }}
                                                    >
                                                        {selectedLanguage?.txt_GiftCardUsers}
                                                    </Button>
                                                </MenuItem>

                                                <MenuItem key={5}>
                                                    <Button
                                                        style={{ width: '185px' }}
                                                        type='primary'
                                                        onClick={() => { setShowCreate(true); handleClose() }}
                                                    >
                                                        {selectedLanguage?.txt_CreateGiftCards}
                                                    </Button>
                                                </MenuItem >

                                                <MenuItem key={6}>
                                                    <Button
                                                        style={{ width: '185px' }}
                                                        type='primary'
                                                        onClick={() => { setShowGiftCardImages(true); handleClose() }}
                                                    >
                                                        {selectedLanguage?.txt_GiftCardImages}
                                                    </Button>
                                                </MenuItem>
                                            </Menu>
                                        </div>
                                        :
                                        <div style={{ textAlign: 'right', width: '99%' }}>
                                            {showGiftCardImages ?
                                                <Button
                                                    type='primary'
                                                    style={{ marginTop: 10 }}
                                                    onClick={() => setAddGiftCardImages(true)}
                                                >
                                                    {selectedLanguage?.txt_AddGiftCardImage}
                                                </Button>
                                                : null
                                            }
                                            &nbsp;
                                            <Button
                                                style={{ marginTop: 10 }}
                                                type='primary'
                                                onClick={allStatesFalse}
                                            >
                                                {selectedLanguage?.txt_Back}
                                            </Button>
                                        </div>
                                    }

                                    <div>
                                        {showNotifications ?
                                            <GiftNotifications {...props} />
                                            : showRequests ?
                                                <GiftRequests {...props} />
                                                : showBuGiftCards ?
                                                    <BuGiftCards {...props} />
                                                    : showGiftCardImages ?
                                                        <GiftCardImages {...props} />
                                                        : showGiftCardUsers ?
                                                            <GiftCardUsers {...props} />
                                                            : <div className='new-layout-table-upper-div'>
                                                                <Table
                                                                    style={{ marginTop: '30px' }}
                                                                    className='new-layout-table'
                                                                    locale={customLocale}
                                                                    loading={loading}
                                                                    dataSource={cards}
                                                                    columns={columns}
                                                                    pagination={{ hideOnSinglePage: true }}
                                                                    scroll={{ x: 800 }}
                                                                />
                                                            </div>
                                        }
                                    </div >
                                </div>}
                </div >
            </div >
        </div >
    )
}

export default AdminGiftCards