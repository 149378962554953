import { CheckCircleTwoTone } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

const SimpleDragDrop = (props) => {
    const { imgUrl, spots, currentQuestionObj, quesChng, declareAnswer } = props

    const [draggedSpots, setDraggedSpots] = useState({})

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        handleResize()
        setTimeout(() => {
            handleResize()
        }, 1000)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [imgUrl, spots, draggedSpots])

    useEffect(() => {

        if (currentQuestionObj?._id) {
            createResult()
        }
    }, [quesChng])

    useEffect(() => {
        if (currentQuestionObj?._id) {
            setDraggedSpots(currentQuestionObj?.answerGiven || {})
        }

    }, [])
    const createResult = () => {

        declareAnswer(currentQuestionObj, draggedSpots)
        setDraggedSpots({})
    }

    const handleResize = () => {
        const image = document.getElementById('image1')
        console.log('image--->',image)

        const imageWidth = image?.clientWidth || 10
        const circleHeight = (imageWidth) * 0.1

        const dragCircle = document.getElementById(`dragCircle`)
        const checkIcon = document.getElementById(`checkIcon`)
        if (dragCircle) {
            dragCircle.style.fontSize = circleHeight + 'px'
        }
        if (checkIcon) {
            checkIcon.style.fontSize = circleHeight + 'px'
        }

        spots.forEach((v, i) => {
            const circle = document.getElementById(`spot${v?.id}`)
            if (circle) {
                circle.style.height = circleHeight + 'px'
            }

            const seqdragCircle = document.getElementById(`dragCircle${i + 1}`)

            if (seqdragCircle) {
                seqdragCircle.style.height = circleHeight + 'px'
                seqdragCircle.style.width = circleHeight + 'px'
            }
        })
    }

    const onDragEnd = (result) => {
        const { destination } = result
        if (!destination) return

        if (destination.droppableId === 'circleItems') {
            setDraggedSpots({})
        } else {
            setDraggedSpots(spots?.find((v) => v?.id === Number(destination.droppableId)))
        }
    }
    return (
        <DragDropContext onDragEnd={onDragEnd}>

            <div style={{ width: window.innerWidth < 700 ? '100%' : '30%', position: 'relative' }}>
                <img src={imgUrl} id='image1' width={'100%'} alt='' />
                {spots.map((spot, i) => (
                    <Droppable key={spot.id} droppableId={`${spot.id}`}>
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                style={{
                                    position: 'absolute',
                                    left: spot.x,
                                    top: spot.y,
                                    width: '10%',
                                    backgroundColor: 'orange',
                                    color: 'white',
                                    borderRadius: '50%',
                                }}
                                id={`spot${spot?.id}`}
                                ref={provided.innerRef}
                            >
                                {draggedSpots?.id === spot.id && (
                                    <Draggable key='circle' draggableId='circle' index={i}>
                                        {(provided) => (
                                            <CheckCircleTwoTone
                                                twoToneColor={'orange'}
                                                style={{
                                                    cursor: 'move',
                                                    position: 'absolute',
                                                    borderRadius: '50%',
                                                    transform: 'translate(0%, 0%)',
                                                    left: '10%',
                                                    zIndex: 3
                                                }}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                id={'dragCircle'}
                                            />
                                        )}
                                    </Draggable>
                                )}
                            </div>
                        )}
                    </Droppable>
                ))
                }
            </div>

            {!draggedSpots?.x && (
                <Droppable droppableId='circleItems'>
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            <Draggable key='circle' draggableId='circle' index={0}>
                                {(provided) => (
                                    <CheckCircleTwoTone
                                        twoToneColor={'orange'}
                                        style={{
                                            cursor: 'move',
                                            position: 'absolute',
                                            borderRadius: '50%',
                                            transform: 'translate(0%, 0%)',
                                            left: '10%',
                                            zIndex: 3
                                        }}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        id={'dragCircle'}
                                    />
                                )}
                            </Draggable>
                        </div>
                    )}
                </Droppable>
            )}
        </DragDropContext>
    )
}

export default SimpleDragDrop
