import { Button, DatePicker, Select, Spin, Table } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN } from '../../../utils/apis'
import { allAdminData, assignMessage, checkSelectOption, convertTitle, createFeeReport, errorNotification } from '../../../utils/helpers'
const { RangePicker } = DatePicker
const { Option } = Select

const FeeReport = (props) => {
    const { user, adminActions, selectedLanguage, language } = props
    const isEnglish = language?.language === 'english'

    const [spinLoad, setSpinLoad] = useState(false)
    const [reports, setReports] = useState([])
    const subAdmins = useSelector(state => state?.adminReducer?.subAdmins || [])
    const [selectedUser, setSelectedUser] = useState('all' || null)
    const [selectedUserDetail, setSelectedUserDetail] = useState(null)
    const [sharePercentage, setSharePercentage] = useState(0)
    const [dateRange, setDateRange] = useState({
        date1: moment().add(-1, 'months').format('YYYY-MM-DD'),
        date2: moment().format('YYYY-MM-DD')
    })

    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };

    useEffect(() => {
        allAdminData?.getSubAdmins(adminActions, user?._id)
    }, [])
    useEffect(() => {
        createReport()
        if (user?.adminType === 'sub') {
            setSelectedUser(user?._id)
        }
    }, [])

    useEffect(() => {
        let detail = subAdmins?.filter((v) => v?._id === selectedUser)?.[0]
        setSelectedUserDetail(subAdmins?.filter((v) => v?._id === selectedUser)?.[0])
        if (detail?.adminType === 'sub') {
            setSharePercentage(detail?.share)
        } else {
            let totalShare = subAdmins?.reduce((c, { share }) => c + share, 0)
            setSharePercentage(100 - totalShare)
        }
    }, [selectedUser])

    const createReport = () => {
        setSpinLoad(true)
        let obj = {
            adminId: selectedUser === 'all' ? user?._id : selectedUser,
            date1: dateRange?.date1,
            date2: dateRange?.date2,
            postalCode: selectedUserDetail?.postalCode || null
        }
        axios.post(`${ADMIN.GET_FEE_REPORT}`, obj)
            .then((res) => {
                const { data } = res
                setSpinLoad(false)
                if (data?.success) {
                    setReports(data?.data || [])
                }
                else {
                    errorNotification(assignMessage(data?.message, language?.language))
                }
            })

            .catch((e) => {
                errorNotification(assignMessage(e?.message, language?.language))
                setSpinLoad(false)
                console.log('e', e)
            })
    }

    const createReportRange = (d) => {
        let obj = {
            date1: d[0].format('YYYY-MM-DD'),
            date2: d[1].format('YYYY-MM-DD')
        }

        setDateRange(obj)
    }

    const disabledDate = (current) => {
        let customDate = moment().format('YYYY-MM-DD')
        return current && current > moment(customDate, 'YYYY-MM-DD').add(1, 'day')
    }

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_ReportRange}</p>,
            key: 'sendingDate',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{selectedLanguage?.txt_Report}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Actions}</p>,
            key: 'actions',
            render: (e) => <div>
                <a onClick={() => createFeeReport(e, selectedUserDetail || user, dateRange, sharePercentage)}>{selectedLanguage?.txt_download}</a>
            </div>
        }
    ]

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote3}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>
                    <h3 className='new-exam-title'>{selectedLanguage?.txt_FeeReport}</h3>
                    <Spin spinning={spinLoad}>
                        {user?.adminType !== 'sub' &&
                            <Select
                                style={{ width: 200, margin: '15px', marginBottom: '0px' }}
                                showArrow
                                allowClear={false}
                                defaultValue={selectedUser}
                                onChange={setSelectedUser}
                                className='form-input'
                                placeholder={selectedLanguage?.SelectUser}
                                showSearch
                                optionFilterProp='children'
                                filterOption={checkSelectOption}
                            >
                                <>
                                    <Option value={'all'} key={'all'}>{selectedLanguage?.txt_All}</Option>
                                    {subAdmins?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.userName)}`}</Option>)}
                                </>
                            </Select>}
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <RangePicker
                                style={{ width: 200, margin: '15px', }}
                                defaultValue={[moment().add(-7, 'days'), moment()]}
                                onChange={createReportRange}
                                disabledDate={disabledDate}
                            />
                            <Button type='primary' onClick={createReport}>{selectedLanguage?.txt_CreateReport}</Button>
                        </div>

                        <div className='new-layout-table-upper-div'>
                            <Table
                                style={{ marginTop: '30px' }}
                                className='new-layout-table'
                                locale={customLocale}
                                columns={columns}
                                dataSource={reports}
                                pagination={{ hideOnSinglePage: true }}
                                scroll={{ x: 800 }}
                            />
                        </div>
                    </Spin>
                </div>
            </div >
        </div >
    )
}

export default FeeReport