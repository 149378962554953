import { Button, Card, Modal, Popconfirm, Select, Spin, Tooltip } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GET, POST } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import { allUserData, assignMessage, checkSelectOption, convertNumber, errorMessage, setHoursMinutes, stringLimiter, successNotification, warningMessage } from '../../utils/helpers'
const { Option } = Select

const Subscription = (props) => {
    const { user, userActions, location, history, getUser, selectedLanguage, language } = props
    const subscriptions = useSelector((state) => state?.userReducer?.subscriptions || [])
    const lotteryDetail = useSelector(state => state?.userReducer?.lotteryDetail || {})
    const giftCardDetail = useSelector(state => state?.userReducer?.giftCardDetail || {})
    const [subscription, setSubscription] = useState({})
    const [loading, setLoading] = useState(false)
    const [upgrade, setUpgrade] = useState(false)
    const [buyCardLottery, setBuyCardLottery] = useState(false)
    const [lottery, setLottery] = useState({})
    const [buyGiftCards, setBuyGiftCards] = useState(false)
    const [upgradeSubscriptionList, setUpgradeSubscription] = useState([])
    const [giftCardSubscription, setGiftCardSubscription] = useState({})
    const [lotteryCardSubscriptions, setLotteryCardSubscriptions] = useState([])
    const [lotteryLoading, setLotteryLoading] = useState(false)
    const [filteredSubscriptions, setFilteredSubscriptions] = useState([])
    const selectQuantities = [5, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100]
    const [giftCardsQuantity, setGiftCardsQuantity] = useState(null)
    const [quantityAmount, setQuantityAmount] = useState({})
    const [selectedSubscription, setselectedSubscription] = useState({})
    const [selectedQuantityOption, setSelectedQuantityOption] = useState(null)
    let [isExpired, setIsExpired] = useState(setHoursMinutes(user?.subscription?.expiry).diff(setHoursMinutes(moment()), 'days') > 0 ? false : true)
    const userId = new URLSearchParams(location.search).get('userId') || null
    const userType = user?.userType
    const supplierType = user?.supplierType
    console.log('filteredSubscriptions', filteredSubscriptions)

    useEffect(() => {
        allUserData.getActiveSubscription(userActions, user?._id)
        getUser()
        getLotterySubscription()
        getAllLotteryDetail()
        getAllGiftCardDetail()
        getGiftCardsSubscription()

        if (userId) {
            setLoading(true)
            axios.get(`${GET.PAYMENT_STATUS}/${userId}`)
                .then((res) => {
                    const { data } = res
                    setLoading(false)
                    allUserData.getActiveSubscription(userActions, user?._id)
                    getUser()
                    history?.replace(allPaths.SUBSCRIPTION)
                    if (data.success) {
                        getAllLotteryDetail()
                        getAllGiftCardDetail()

                        return successNotification(assignMessage(data?.message, language?.language))
                    }
                    errorMessage(assignMessage(data?.message, language?.language))
                })
                .catch((e) => {
                    setLoading(false)
                    errorMessage(assignMessage(e?.message, language?.language))
                })
        }
    }, [])

    useEffect(() => {
        subscriptionWRTUser()
    }, [subscriptions])

    useEffect(() => {
        setIsExpired(setHoursMinutes(user?.subscription?.expiry).diff(setHoursMinutes(moment()), 'days') > 0 ? false : true)

    }, [user?.subscription?.expiry])

    const settingNewSubscription = (id, index) => {
        let selectedSubscription = _.find(subscriptions, { _id: id })
        selectedSubscription['key'] = index
        setselectedSubscription(selectedSubscription)
    }
    const subscriptionWRTUser = () => {
        if (supplierType === 'shops') {
            let filterofSubscriptions = subscriptions.filter((v) => v?.subscriptionTypes.includes('shopOwner'))

            setFilteredSubscriptions(_.uniqBy(filterofSubscriptions, 'subscriptionName'))

            let groupedData = _.groupBy(filterofSubscriptions?.map((v) => { return { monthDuration: v?.monthDuration, amount: v?.amount, _id: v?._id, subscriptionName: v?.subscriptionName } }), 'subscriptionName')

            setQuantityAmount(groupedData)

        } else {
            let filterofSubscriptions = subscriptions.filter((v) => !v?.subscriptionTypes.includes('shopOwner'))

            setFilteredSubscriptions(_.uniqBy(filterofSubscriptions, 'subscriptionName'))

            let groupedData = _.groupBy(filterofSubscriptions?.map((v) => { return { monthDuration: v?.monthDuration, amount: v?.amount, _id: v?._id, subscriptionName: v?.subscriptionName } }), 'subscriptionName')

            setQuantityAmount(groupedData)

        }
    }

    const getAllLotteryDetail = () => {
        axios.get(`${GET.ALL_LOTTERY_DETAIL}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLotteryLoading(false)
                userActions.addAllLotterySubscriptionDetail(data?.data || {})
            })
            .catch((e) => {
                setLotteryLoading(false)
                console.log('e', e)
            })
    }
    const getAllGiftCardDetail = () => {
        axios.get(`${GET.ALL_GIFTCARD_DETAIL}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLotteryLoading(false)
                userActions.addAllGiftCardSubscriptionDetail(data?.data || {})
            })
            .catch((e) => {
                setLotteryLoading(false)
                console.log('e', e)
            })
    }
    const getLotterySubscription = () => {
        axios.get(GET.LOTTERY_SUBSCRIPTION)
            .then((res) => {
                const { data } = res
                let lotteryCards = data?.data?.filter(v => v?.subscriptionTypes?.indexOf('physicalLottery') !== -1)
                setLotteryCardSubscriptions(lotteryCards || [])
            })
            .catch((e) => {
                console.log('e', e)
            })
    }
    const getGiftCardsSubscription = () => {
        axios.get(GET.GIFT_CARD_SUBSCRIPTION)
            .then((res) => {
                const { data } = res
                let giftCards = data?.data[0]
                setGiftCardSubscription(giftCards || {})
            })
            .catch((e) => {
                console.log('e', e)
            })
    }

    const addMoreCardLotteries = (c) => {
        // setLoading(true)
        let obj = {}
        obj.userId = user?._id
        obj._id = c
        setBuyCardLottery(false)
        axios.post(POST.ADD_CARD_LOTTERIES, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    window.location.href = data?.redirectUrl
                    return
                }
                errorMessage(assignMessage(data?.message, language?.language))
            })
            .catch((e) => {
                console.log('e', e)
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }
    const addMoreGiftCards = () => {
        setLoading(true)
        let obj = { ...giftCardSubscription }
        obj.userId = user?._id

        obj.giftCardsRate = giftCardSubscription?.amount || 0.01
        obj.giftCardsQuantity = giftCardsQuantity

        setGiftCardsQuantity(null)


        axios.post(POST.ADD_MORE_GIFTCARDS, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    window.location.href = data?.redirectUrl
                    return
                }
                errorMessage(assignMessage(data?.message, language?.language))
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const modalCancel = () => {
        setBuyCardLottery(false)
        setBuyGiftCards(false)
    }
    const checkQuantity = () => {
        if (buyCardLottery && !lottery) {
            return warningMessage(selectedLanguage?.txt_PleaseSelectLotteriesSubscription)
        }
        if (buyGiftCards && !giftCardsQuantity) {
            return warningMessage(selectedLanguage?.txt_PleaseSelectGiftCardsSubscription)
        }

        modalCancel()

        if (buyCardLottery) {
            return addMoreCardLotteries(lottery)
        }
        if (buyGiftCards) {
            return addMoreGiftCards(giftCardsQuantity)
        }

        // if (subscription?.subscriptionTypes?.indexOf('loyalty') !== -1 && couponsCardSubscription) {
        //     subscription.couponsCardSubscription = couponsCardSubscription
        // }
        setSubscription({ ...subscription })
        createSubscription(subscription)
    }
    const extendSubscription = () => {
        setLoading(true)
        let obj = { userId: user?._id, subscriptionId: user?.subscription?._id }
        axios.post(POST.EXTEND_SUBSCRIPTION_EXPIRY, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    window.location.href = data?.redirectUrl
                    return
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const createSubscription = (obj) => {
        setLoading(true)

        obj.userId = user?._id
        axios.post(POST.CREATE_SUBSCRIPTION, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    window.location.href = data?.redirectUrl
                    return
                }
                errorMessage(assignMessage(data?.message, language?.language))
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }
    const checkPostalCard = () => {

        setSubscription(selectedSubscription)
        createSubscription(selectedSubscription)
    }


    const convertDate = (val) => moment.utc(val).set('hour', 0).set('minute', 0).set('second', 0).toString()
    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote10}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>
                    <h3 className='new-exam-title'>{selectedLanguage?.sideMenu_subscriptions}</h3>
                    <br />
                    <div style={{ marginTop: '10px' }}>
                        {user?.subscription?.active && !upgrade && !isExpired ? (
                            <Spin spinning={loading}>
                                <div
                                //  className='card-form'
                                >
                                    <div
                                        // className='user-card'
                                        style={{ minHeight: 'calc(100vh - 200px)' }}
                                    >
                                        <p className='heading leftClass' style={{ color: '#ec671b', marginTop: '10px' }}>
                                            {selectedLanguage?.label_YourCurrentSubscription}
                                        </p>
                                        <div className='well'>{user?.subscription?.invoiceName}</div>
                                        <div className='alert alert-success'>
                                            € {user?.subscription?.price} / year ({selectedLanguage?.label_YourSubscriptionwillExpireat}{' '}
                                            {convertDate(user?.subscription?.expiry)})
                                            <Popconfirm
                                                title={selectedLanguage?.popupMessage_WanttoExtendSubscription}
                                                okText={selectedLanguage?.Yes}
                                                cancelText={selectedLanguage?.Cancel}
                                                okButtonProps={{
                                                    type: 'primary',
                                                    className: 'form-button'
                                                }}
                                                cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                onConfirm={() => extendSubscription()}
                                            >
                                                <a>&nbsp; {selectedLanguage?.button_Extendsubscription}</a>
                                            </Popconfirm>
                                        </div>
                                        <hr />

                                        {userType === 'business'
                                            && user?.subscription?.subscriptionTypes?.indexOf('lottery') !== -1 ?
                                            <div>
                                                <Spin spinning={lotteryLoading}>
                                                    <div className='well'>
                                                        {selectedLanguage?.Text_TotalCardLotterypurchased}:{' '}
                                                        {lotteryDetail?.physicalLottery?.total || 0}
                                                    </div>
                                                    <div className='well'>
                                                        {selectedLanguage?.Text_TotalCardCouponsUsed}:{' '}
                                                        {lotteryDetail?.physicalLottery?.used || 0}
                                                    </div>
                                                    <div className='well'>
                                                        {selectedLanguage?.Text_TotalCardLotteryClaimed}:{' '}
                                                        {lotteryDetail?.physicalLottery?.claimed || 0}
                                                    </div>
                                                    <div className='well'>
                                                        {selectedLanguage?.Text_TotalCardLotteryStock}:{' '}
                                                        {lotteryDetail?.physicalLottery?.stock || 0}
                                                    </div>
                                                </Spin>
                                            </div>
                                            : null}
                                        {userType === 'business' && user?.subscription?.subscriptionTypes?.indexOf('giftCard') !== -1 ?
                                            <div>
                                                <Spin spinning={lotteryLoading}>
                                                    <div className='well'>
                                                        {selectedLanguage?.Text_TotalGiftCardPurchased}:{' '}
                                                        {giftCardDetail?.total || 0}
                                                    </div>
                                                    <div className='well'>
                                                        {selectedLanguage?.Text_TotalGiftCardClaimed}:{' '}
                                                        {giftCardDetail?.claimed || 0}
                                                    </div>
                                                    <div className='well'>
                                                        {selectedLanguage?.Text_TotalGiftCardStock}:{' '}
                                                        {(giftCardDetail?.total - giftCardDetail?.claimed) || 0}
                                                    </div>
                                                </Spin>
                                            </div>
                                            : null}

                                        <Button
                                            style={{ height: '40px', width: '160px', margin: '10px' }}
                                            className='addproduct-text'
                                            type='primary'
                                            onClick={() => {
                                                setBuyCardLottery(true)
                                            }}
                                        >
                                            {selectedLanguage?.button_BuyLottery}
                                        </Button>
                                        <Button
                                            style={{ height: '40px', width: '160px', margin: '10px', }}
                                            className='addproduct-text'
                                            type='primary'
                                            onClick={() => {
                                                setBuyGiftCards(true)
                                            }}
                                        >
                                            {selectedLanguage?.button_BuyGiftCards}
                                        </Button>
                                        {!upgrade ?
                                            <Button
                                                style={{ height: '40px', width: '160px', margin: '10px' }}
                                                className='addproduct-text'
                                                type='primary'
                                                onClick={() => setUpgrade(true)}
                                            >
                                                {selectedLanguage?.button_UpgradeSubscription}
                                            </Button>
                                            : null}
                                    </div>
                                </div>
                            </Spin>
                        ) : (
                            <Spin spinning={loading}>
                                <div /* className='card-form' */>
                                    <div
                                        // className='user-card'
                                        style={{ minHeight: 'calc(100vh - 200px)', padding: '8px' }}
                                    >
                                        {upgrade ? (
                                            <p className='heading leftClass' style={{ marginTop: '20px', color: '#ec671b' }}>
                                                {selectedLanguage?.label_UpgradeSubscription}
                                            </p>)
                                            : (<p className='heading head-center'>
                                                {selectedLanguage?.label_SubscriptionPricing}
                                            </p>)}
                                        <div className='sidebar-scroll_class subscription-cards-main'>
                                            {(filteredSubscriptions)?.map((v, i) => {
                                                return (
                                                    <div
                                                        className='subscription-cards'
                                                        style={{

                                                        }}
                                                        key={i}>
                                                        <Card style={{ borderRadius: '6px', }}
                                                            bordered={false}>
                                                            <Tooltip placement='top' title={v?.subscriptionName}>
                                                                <div className='subscription-title-new'>
                                                                    {stringLimiter(v?.subscriptionName)}
                                                                </div>
                                                                <br />
                                                                <div style={{ marginTop: '10px' }}>
                                                                    <Select
                                                                        className='form-input-new-style select-inner-padding-nonr serchSe'
                                                                        key={i}
                                                                        style={{
                                                                            minWidth: '100%',
                                                                        }}
                                                                        placeholder={selectedLanguage?.text_SelectMonthDuration}
                                                                        onChange={(e) => (setSelectedQuantityOption(e), settingNewSubscription(e, i))}
                                                                        showSearch
                                                                        optionFilterProp='children'
                                                                        filterOption={checkSelectOption}
                                                                    >
                                                                        {quantityAmount?.[v?.subscriptionName]?.map((x, i) =>
                                                                            <Option value={x?._id} key={i}>{'Month: ' + x?.monthDuration + ' Amount: ' + ' € ' + ' ' + x?.amount}</Option>
                                                                        )}
                                                                    </Select>
                                                                </div>
                                                            </Tooltip>
                                                            {selectedSubscription?.key === i ?
                                                                <div className='subscription-title-new' style={{ height: '90px', marginTop: '10px', fontSize: '18px' }}>
                                                                    {selectedLanguage?.text_SubscribeAt} € {selectedSubscription?.amount} {selectedLanguage?.textFor} {selectedSubscription?.monthDuration} {selectedLanguage?.text_Months}
                                                                </div>
                                                                :
                                                                <div style={{ padding: '50px' }}>
                                                                </div>}
                                                            <Button
                                                                disabled={selectedSubscription?.key !== i}
                                                                style={{ height: '40px', width: '100%', marginTop: '10px' }}
                                                                className='addproduct-text'
                                                                type='primary'
                                                                block
                                                                htmlType='submit'
                                                                onClick={checkPostalCard}
                                                            >
                                                                {selectedLanguage?.button_SubscribeNow}
                                                            </Button>
                                                        </Card>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </Spin>
                        )
                        }

                        <Modal
                            // title={<p style={{ color: '#ec671b' }}>{selectedLanguage?.Text_BuyGiftCards}</p>}
                            open={buyGiftCards}
                            onCancel={modalCancel}
                            className='pop-info-modal'
                            style={{ width: 350, top: '10px' }}
                            footer={null/* [
                                <Button key='back' onClick={modalCancel}
                                    style={{ height: '40px', width: '80px', marginTop: '10px' }}
                                    className='cancel-btn'>
                                    {selectedLanguage?.Cancel}
                                </Button>,
                                <Button
                                    key='ok'
                                    onClick={checkQuantity}
                                    style={{ height: '40px', width: '90px', marginTop: '10px' }}
                                    className='addproduct-text'
                                    type='primary'>
                                    {selectedLanguage?.Button_Subscribe}
                                </Button>
                            ] */}
                        >
                            <p className='new-p-question'>{selectedLanguage?.Text_BuyGiftCards}</p>
                            <div className='well postal-card' style={{ color: '#ec671b' }}>
                                {selectedLanguage?.Text_GiftCardPrice}: € {convertNumber(giftCardSubscription?.amount)} / {selectedLanguage?.Piece}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div className='form-input-single-input'>
                                    <Select
                                        showArrow
                                        allowClear
                                        style={{
                                            marginLeft: 15,
                                            marginRight: 15,
                                            width: 'calc(100% - 28px)',
                                        }}
                                        className='form-input-new-style select-inner-padding-nonr serchSe'
                                        onSelect={(e) => setGiftCardsQuantity(e)}
                                        value={giftCardsQuantity}
                                        placeholder={selectedLanguage?.Text_SelectGiftCardQuantity}
                                        showSearch
                                        optionFilterProp='children'
                                        filterOption={checkSelectOption}
                                    >
                                        {selectQuantities?.map((v, i) => (
                                            <Option
                                                value={v}
                                                key={i}
                                            >{`${v}`}</Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <Button
                                key='ok'
                                onClick={checkQuantity}
                                style={{ height: '40px', width: '100%', marginTop: '10px' }}
                                className='addproduct-text'
                                type='primary'>
                                {selectedLanguage?.Button_Subscribe}
                            </Button>
                        </Modal>

                        <Modal
                            title={<p style={{ color: '#ec671b' }}>{selectedLanguage?.Text_BuyLotteryCards}</p>}
                            open={buyCardLottery}
                            onCancel={modalCancel}
                            className='pop-info-modal'
                            style={{ width: 350, top: '10px' }}
                            // footer={null}
                            footer={[
                                <Button key='back' onClick={modalCancel}
                                    style={{ height: '40px', width: '80px', marginTop: '10px' }}
                                    className='cancel-btn'
                                >
                                    {selectedLanguage?.Cancel}
                                </Button>,
                                <Button
                                    key='ok'
                                    onClick={checkQuantity}
                                    style={{ height: '40px', width: '90px', marginTop: '10px' }}
                                    className='addproduct-text'
                                    type='primary'
                                >
                                    {selectedLanguage?.Button_Subscribe}
                                </Button>
                            ]}
                        >
                            <p className='new-p-question'>{selectedLanguage?.Text_BuyLotteryCards}</p>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div className='form-input-single-input'>
                                    <Select
                                        showArrow
                                        allowClear
                                        style={{
                                            marginLeft: 15,
                                            marginRight: 15,
                                            width: 'calc(100% - 28px)',
                                        }}
                                        className='form-input-new-style select-inner-padding-nonr serchSe'
                                        onSelect={(e) => setLottery(e)}
                                        placeholder={selectedLanguage?.placeHolder_SelectCardLotterySubscription}
                                        showSearch
                                        optionFilterProp='children'
                                        filterOption={checkSelectOption}
                                    >
                                        {lotteryCardSubscriptions?.map((v, i) => (
                                            <Option
                                                value={v?._id}
                                                key={i}
                                            >{`${v?.subscriptionName} / € ${v?.amount}`}</Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </Modal>
                    </div >
                </div >
            </div >
        </div >
    )
}

export default Subscription