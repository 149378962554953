import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Input, Popconfirm, Select, Space, Spin, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useSelector } from 'react-redux'
import { AUTH } from '../../utils/apis'
import { supplierType } from '../../utils/constants'
import { allAdminData, checkSelectOption, convertRoundNumber, convertTitle, successNotification } from '../../utils/helpers'
import UserDetail from './UserDetail'
const { Option } = Select


const Users = (props) => {
    const { user, adminActions, language, selectedLanguage } = props
    const [spin, setSpin] = useState({})
    const [userDetail, setuserDetail] = useState({})
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const [selectedType, setSelectedType] = useState(supplierType?.[0]?.val || null)
    const searchInput = useRef(null)
    const buUsers = useSelector(state => state?.adminReducer?.buUsers) || []
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };

    useEffect(() => {
        allAdminData?.getBusinessUser(adminActions, user?._id)
    }, [])

    const activateUser = (_id, activate) => {
        setSpin({ [_id]: true })
        let obj = {
            _id,
            activate
        }
        axios.post(`${AUTH.ACTIVATE_USER}/${user?._id}`, obj)
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successNotification(activate ? selectedLanguage?.Activate : selectedLanguage?.Deactivate)
                    allAdminData?.getBusinessUser(adminActions, user?._id)
                }
            })
            .catch((e) => {
                console.log('e', e)
                setSpin({})
            })
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`${selectedLanguage?.Search} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        {selectedLanguage?.Search}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                        {selectedLanguage?.Reset}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100)
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.FullName}</p>,
            dataIndex: 'fullName',
            key: 'fullName',
            sorter: (a, b) => a?.fullName?.length - b?.fullName?.length,
            showSorterTooltip: false,
            width: 180,
            ...getColumnSearchProps('fullName')
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Email}</p>,
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a?.email?.length - b?.email?.length,
            width: 180,
            showSorterTooltip: false,
            // ellipsis: true,
            ...getColumnSearchProps('email')
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.TelNo}</p>,
            dataIndex: 'telNo',
            key: 'telNo',
            sorter: (a, b) => a?.telNo?.length - b?.telNo?.length,
            ellipsis: true,
            showSorterTooltip: false,
            // ...getColumnSearchProps(selectedLanguage?.TelNo)
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.City}</p>,
            dataIndex: 'city',
            key: 'city',
            sorter: (a, b) => a?.city?.length - b?.city?.length,
            ellipsis: true,
            showSorterTooltip: false,
            align: 'center',
            ...getColumnSearchProps('city')
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.TotalSold}</p>,
            dataIndex: 'soldProducts',
            key: 'soldProducts',
            align: 'center',
            sorter: (a, b) => a?.soldProducts - b?.soldProducts,
            showSorterTooltip: false,
            // ellipsis: true,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.TotalSales}</p>,
            dataIndex: 'totalSales',
            key: 'totalSales',
            align: 'center',
            sorter: (a, b) => a?.totalSales - b?.totalSales,
            showSorterTooltip: false,
            render: (e) => <>€ {convertRoundNumber(e)}</>
            // ellipsis: true,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.TotalFee}</p>,
            dataIndex: 'totalFee',
            key: 'totalFee',
            align: 'center',
            sorter: (a, b) => a?.totalFee - b?.totalFee,
            showSorterTooltip: false,
            render: (e) => <>€ {convertRoundNumber(e)}</>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Actions}</p>,
            key: 'actions',
            sorter: (a, b) => a?.activate - b?.activate,
            showSorterTooltip: false,
            align: 'center',
            width: 180,
            render: (e) =>
                // <Space wrap>
                <>
                    <Button onClick={() => setuserDetail(e)} icon={<InfoCircleOutlined />} shape="circle" />
                    &nbsp;&nbsp;
                    <Popconfirm
                        title={(!e?.activate ? selectedLanguage?.Activate : selectedLanguage?.Deactivate) + ' ' + `${convertTitle(e?.fullName)}?`}
                        okText={selectedLanguage?.Yes}
                        cancelText={selectedLanguage?.No}
                        okButtonProps={{
                            type: 'primary',
                            className: 'form-button'
                        }}
                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                        onConfirm={() => activateUser(e?._id, !e?.activate)}
                    >
                        {spin[e?._id] ? <span className='demo-loading-container'>
                            &nbsp;<Spin />
                        </span> : e?.activate ?
                            <Button type='primary' danger>{selectedLanguage?.Deactivate}</Button> :
                            <Button type='primary'>{selectedLanguage?.Activate}</Button>
                        }
                    </Popconfirm >
                </>
        }
    ]

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote7}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>

                    {userDetail?._id ?
                        < UserDetail userDetail={userDetail} onCancel={() => setuserDetail({})} {...props} />
                        : null}
                    <br />
                    <h3 className='new-exam-title'>{selectedLanguage?.BusinessUser}</h3>

                    <Select
                        showSearch
                        optionFilterProp='children'
                        filterOption={checkSelectOption}
                        style={{ width: 200, margin: '12px', marginBottom: '0px', marginTop: '20px' }}
                        // showSearch
                        showArrow
                        allowClear={false}
                        defaultValue={selectedType}
                        onChange={(e) => setSelectedType(e)}
                        placeholder={selectedLanguage?.SelectUser}
                    >
                        {supplierType?.map((v, i) => <Option value={v?.val} key={i}>{`${language?.language === 'english' ? v?.name : v?.nameNl}`}</Option>)}
                    </Select>
                    <div className='new-layout-table-upper-div'>
                        <Table
                            style={{ marginTop: '30px' }}
                            className='new-layout-table'
                            locale={customLocale}
                            columns={columns}
                            dataSource={buUsers.filter((v) => v?.supplierType === selectedType)}
                            pagination={{ hideOnSinglePage: true }}
                            scroll={{ x: 800 }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Users