import { Tabs } from 'antd';
import React from 'react';
import JoinedTable from './JoinedTable';
import PendingTable from './PendingTable';
const { TabPane } = Tabs;
const Group = (props) => {
  const { selectedLanguage } = props
  return (
    <div className='new-exam-main-div'>
      <div className='exam-transaprent-div'>
        <p className='screen-qoute'> {selectedLanguage?.Quote5}</p>
      </div>
      <div className='new-exam-screen-upper'>
        <div style={{ background: 'white', padding: '15px' }}>
          <div className='main-div-new-style'>
            <div className='flex-column menu-main card-container'>
              <div className='card-container card-container-new' >
                <Tabs type='card'  >
                  <TabPane tab={selectedLanguage?.tag_joined} key='1' >
                    <JoinedTable {...props} />
                  </TabPane>
                  <TabPane tab={selectedLanguage?.tag_pending} key='2'>
                    <PendingTable {...props} />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Group