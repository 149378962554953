import { Button, DatePicker, Select, Spin, Table } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN } from '../../../utils/apis'
import { allAdminData, assignMessage, checkSelectOption, convertTitle, createCouponReport, errorNotification } from '../../../utils/helpers'
const { RangePicker } = DatePicker
const { Option } = Select

const CouponReport = (props) => {
    const { user, adminActions, selectedLanguage, language } = props
    const isEnglish = language?.language === 'english'

    const [spin, setSpin] = useState({})
    const [spinLoad, setSpinLoad] = useState(false)
    const [reports, setReports] = useState([])
    const [adminCoupons, setAdminCoupons] = useState(0)
    const subAdmins = useSelector(state => state?.adminReducer?.subAdmins || [])
    const [selectedUser, setSelectedUser] = useState('all' || null)
    const [selectedUserDetail, setSelectedUserDetail] = useState(null)
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    const [dateRange, setDateRange] = useState({
        date1: moment().add(-1, 'months').format('YYYY-MM-DD'),
        date2: moment().format('YYYY-MM-DD')
    })
    useEffect(() => {
        allAdminData?.getSubAdmins(adminActions, user?._id)
    }, [])

    useEffect(() => {
        createReport()
    }, [dateRange, selectedUserDetail])

    useEffect(() => {
        setSelectedUserDetail(subAdmins?.filter((v) => v?._id === selectedUser)?.[0])
    }, [selectedUser])

    const createReport = () => {
        setSpinLoad(true)
        let obj = {
            adminId: selectedUser === 'all' ? user?._id : selectedUser,
            date1: dateRange?.date1,
            date2: dateRange?.date2,
            postalCode: selectedUserDetail?.postalCode || null
        }

        axios.post(`${ADMIN.GET_COUPON_REPORT}`, obj)
            .then((res) => {
                const { data } = res
                setSpinLoad(false)
                if (data?.success) {
                    setReports(data?.data || [])
                    setAdminCoupons(data?.adminCoupons || 0)
                }
                else {
                    errorNotification(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                errorNotification(assignMessage(e?.message, language?.language))
                setSpinLoad(false)
                console.log('e', e)
            })
    }

    const createReportRange = (d) => {
        let obj = {
            date1: d[0].format('YYYY-MM-DD'),
            date2: d[1].format('YYYY-MM-DD')
        }

        setDateRange(obj)
    }

    const disabledDate = (current) => {
        let customDate = moment().format('YYYY-MM-DD')
        return current && current > moment(customDate, 'YYYY-MM-DD').add(1, 'day')
    }

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_ReportRange}</p>,
            key: 'sendingDate',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{selectedLanguage?.txt_Report}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Actions}</p>,
            key: 'actions',
            render: (e) => <div>
                <a onClick={() => createCouponReport(e, selectedUserDetail || user, dateRange, adminCoupons)}>{selectedLanguage?.txt_download}</a>
            </div>
        }
    ]

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote4}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>
                    <h3 className='new-exam-title'>{selectedLanguage?.txt_coupon_reports}</h3>
                    <Spin spinning={spinLoad}>
                        <Select
                            style={{ width: 200, margin: '15px', marginBottom: '0px' }}
                            showArrow
                            allowClear={false}
                            defaultValue={selectedUser}
                            onChange={(e) => setSelectedUser(e)}
                            className='form-input'
                            placeholder={selectedLanguage?.SelectUser}
                            showSearch
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                        >
                            <>
                                <Option value={'all'} key={'all'}>{selectedLanguage?.txt_All}</Option>
                                {subAdmins?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.userName)}`}</Option>)}
                            </>
                        </Select>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <RangePicker
                                style={{ width: 200, margin: '15px', marginRight: '0px' }}
                                defaultValue={[moment().add(-7, 'days'), moment()]}
                                onChange={createReportRange}
                                disabledDate={disabledDate}
                            />
                            <Button type='primary' style={{ marginLeft: '15px' }} onClick={createReport}>{selectedLanguage?.txt_CreateReport}</Button>
                        </div>
                        <div className='new-layout-table-upper-div'>
                            <Table
                                style={{ marginTop: '30px' }}
                                className='new-layout-table'
                                locale={customLocale}
                                columns={columns}
                                dataSource={reports}
                                pagination={{ hideOnSinglePage: true }}
                                scroll={{ x: 800 }}
                            />
                        </div>
                    </Spin>
                </div>
            </div>
        </div>
    )
}

export default CouponReport