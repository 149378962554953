import { CITIES, COLLAPSED, RATES, TAXES } from '../types'

const initialState = {
    isTourOpen: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case COLLAPSED: {
            return { ...state, inlineCollapsed: action.inlineCollapsed }
        }
        case CITIES: {
            return { ...state, cities: action.cities }
        }
        case TAXES: {
            return { ...state, taxes: action.taxes }
        }
        default: {
            return state
        }
    }
}

export default reducer