import React, { useEffect, useState } from 'react'
import { Table, Form, Input, Button, Popconfirm, Spin, Modal } from 'antd'
import { useSelector } from 'react-redux'
import { convertTitle, successNotification, errorMessage, allUserData, assignMessage } from '../../utils/helpers'
import { POST } from '../../utils/apis'
import axios from 'axios'
import NoData from "../../assets/noDataImae.png"


// joined
const PendingTable = (props) => {

    const { user, userActions, selectedLanguage, language } = props

    const pending = useSelector(state => state?.userReducer?.pending || [])

    const [spin, setSpin] = useState({})
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [newData, setnewData] = useState({})
    const [loading, setLoading] = useState(false)
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };

    const showModal = () => {
        setIsModalVisible(true)
    }

    useEffect(() => {
        allUserData?.getPendingGroups(userActions, user?._id)
    }, [spin])

    const joinGroup = (e) => {
        setSpin({ [e?._id]: true })

        let obj = {
            _id: e?._id,
            groupId: e?.groupId,
            userId: e?.userId,
            password: null,
            private: e?.privateGroup
        }
        setnewData(obj)
        e?.privateGroup ? showModal() : postFunction(obj)
    }

    const postFunction = (obj) => {
        setLoading(true)
        axios.post(`${POST.JOIN_GROUP}`, obj)
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    setLoading(false)
                    successNotification(assignMessage(data?.message, language?.language))
                    setIsModalVisible(false)
                } else {
                    errorMessage(assignMessage(data?.message, language?.language))
                    setLoading(false)
                }
            })
            .catch((e) => {
                setSpin({})
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
                console.log('error', e)
            })
    }

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.tableColumn_Group_Name}</p>,
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            showSorterTooltip: false,
            ellipsis: true,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Private}</p>,
            dataIndex: 'privateGroup',
            key: 'privateGroup',
            sorter: true,
            showSorterTooltip: false,
            ellipsis: true,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}> {e ? selectedLanguage?.True : selectedLanguage?.False}</p>

        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.table_Restaurant}</p>,
            dataIndex: 'postalCode',
            key: 'postalCode',
            ellipsis: true,
            render: (e) => {
                return (<>
                    <p>{e[0]?.postalcodeStart}-{e[0]?.postalcodeEnd}</p>
                    {e[1] ? <> <br />
                        <p>{e[1]?.postalcodeStart}-{e[1]?.postalcodeEnd}</p> </> : null}
                </>)
            }
        },
        {
            // title:<p className='table-title-p'>{ 'Actions',
            key: 'actions',
            ellipsis: true,
            render: (e) => <span>
                <Popconfirm
                    title={`${selectedLanguage?.popupMessage_WantToJoin} ${convertTitle(e?.name)}?`}
                    okText={selectedLanguage?.Yes}
                    cancelText={selectedLanguage?.No}
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => joinGroup(e)}
                >
                    <Button style={{ color: 'blue' }}>{selectedLanguage?.button_Join}</Button>
                    {spin[e?._id] ? <span className='demo-loading-container'>
                        &nbsp;<Spin />
                    </span> : null}
                </Popconfirm>
            </span>
        }
    ]

    const data = pending?.map((v, i) => {
        return {
            _id: v?._id,
            groupId: v?.groupId?._id,
            userId: v?.userId?._id,
            name: v?.groupId?.name,
            privateGroup: v?.privateGroup,
            postalCode: v?.groupId?.postalCode,
        }
    })


    const handleCancel = () => {
        setIsModalVisible(false)
        setSpin({})
    }

    const onFinished = (values) => {
        let obj = {
            _id: newData?._id,
            groupId: newData?.groupId,
            userId: newData?.userId,
            password: values?.password,
            private: newData?.private
        }
        postFunction(obj)
    }
    return (
        <div style={{ width: '100%' }}>
            <div className='new-layout-table-upper-div' style={{ marginLeft: '0px', marginRight: '0px' }}>
                <Table
                    className='new-layout-table'
                    locale={customLocale}
                    columns={columns}
                    dataSource={data}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 800 }}
                />
            </div>

            <Modal title={<p style={{ color: '#ec671b' }}>{selectedLanguage?.Password}</p>} open={isModalVisible} footer={null} onCancel={handleCancel}>

                <Form
                    name='basic'
                    onFinish={onFinished}
                    layout={'vertical'}
                    autoComplete='off'
                >
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input '>
                            <Form.Item
                                label={selectedLanguage?.Password}
                                name='password'
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourPassword,
                                    },
                                ]}
                            >
                                <Input.Password className='form-input-new-style' />
                            </Form.Item>
                        </div>
                    </div>
                    <Form.Item>
                        <Button
                            loading={loading}
                            style={{ height: '40px', width: '160px', marginTop: '10px' }}
                            className='addproduct-text'
                            type='primary'
                            htmlType='submit'>
                            {selectedLanguage?.button_Submit}
                        </Button>
                    </Form.Item>
                </Form>

            </Modal>

            {/* <div className='MobileTable sidebar-scroll_class' style={{ overflow: 'scroll', width: '100%' }} >
                <div style={{ marginTop: '10px', marginBottom: '5px', width: '100%', minWidth: '700px', height: `calc(100vh - 310px)` }}>
                    <div style={{
                        width: '100%',
                        height: '60px',
                        background: 'white',
                        borderTopRightRadius: '4px',
                        borderTopLeftRadius: '4px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: '5px',
                        alignItems: 'center',
                        border: '1px solid #C2C2C2',
                        borderBottom: '0px solid '

                    }}>

                        <p className='tableValue' style={{ margin: '0px', width: '20%' }}>
                            {selectedLanguage?.tableColumn_Group_Name}
                        </p>
                        <p className='tableValue' style={{ margin: '0px', width: '15%' }}>
                            {selectedLanguage?.Private}
                        </p>
                        <p className='tableValue' style={{ margin: '0px', width: '20%' }}>
                            {selectedLanguage?.table_Restaurant}
                        </p>
                        <p className='tableValue' style={{ margin: '0px', width: '15%' }}>
                            {''}
                        </p>
                    </div>
                    <div
                        className='sidebar-scroll_class'
                        style={{
                            width: '100%',
                            background: 'white',
                            borderBottomRightRadius: '4px',
                            borderBottomLeftRadius: '4px',
                            justifyContent: 'space-between',
                            padding: '5px',
                            alignItems: 'center',
                            border: '1px solid #C2C2C2',
                            height: `calc(100vh - 366px)`,
                            overflow: 'scroll'
                        }}>
                        {
                            pending?.length ?
                                pending?.map((v, i) => {
                                    return (
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '50px',
                                                background: 'white',
                                                borderTopRightRadius: '4px',
                                                borderTopLeftRadius: '4px',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                padding: '5px',
                                                alignItems: 'center',
                                                borderBottom: '1px solid #C2C2C2',
                                                margin: '0px',
                                                padding: '0px'
                                            }}>
                                            <p className='tableValue' style={{ width: '20%' }}>
                                                {v?.groupId?.name || '-----'}
                                            </p>
                                            <p className='tableValue' style={{ width: '15%' }}>
                                                {v?.privateGroup ? selectedLanguage?.True : selectedLanguage?.False || '-----'}
                                            </p>
                                            <p className='tableValue' style={{ width: '20%' }}>
                                                {v?.groupId?.postalCode[0]?.postalcodeStart}-{v?.groupId?.postalCode[0]?.postalcodeEnd}
                                                <br />
                                                {v?.groupId?.postalCode[1] ?
                                                    v?.groupId?.postalCode[1]?.postalcodeStart + '-' + v?.groupId?.postalCode[1]?.postalcodeEnd
                                                    : null}
                                            </p>
                                            <p className='tableValue' style={{ width: '20%' }}>
                                                <Popconfirm
                                                    title={`${selectedLanguage?.popupMessage_WantToJoin} ${convertTitle(v?.name)}?`}
                                                    okText={selectedLanguage?.Yes}
                                                    cancelText={selectedLanguage?.No}
                                                    okButtonProps={{
                                                        type: 'primary',
                                                        className: 'form-button'
                                                    }}
                                                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                    onConfirm={() => joinGroup(v)}
                                                >
                                                    <Button style={{ color: 'blue' }}>{selectedLanguage?.button_Join}</Button>
                                                    {spin[v?._id] ? <span className='demo-loading-container'>
                                                        &nbsp;<Spin />
                                                    </span> : null}
                                                </Popconfirm>
                                            </p>

                                        </div>
                                    )
                                }) :
                                <div style={{ height: '160px', textAlign: 'center' }}>
                                    <img src={NoData} style={{ height: '150px', width: '150px' }} />
                                </div>
                        }

                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default PendingTable