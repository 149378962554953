import { Button, Form, Input } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import { ADMIN } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import { assignLanguage, assignMessage, errorMessage, successNotification } from '../../utils/helpers'
import LoginLanguageSelect from '../../Components/LoginLanguageSelect/LoginLanguageSelect'

const Login = (props) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = assignLanguage(language?.language)

    const onFinish = (values) => {
        const { history } = props
        setLoading(true)
        axios.post(ADMIN.LOGIN, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) { 
                    dispatch(loginUser(data.admin))
                    return setTimeout(() => {
                        history.replace(allPaths?.ADMIN_SUBADMINS)
                    }, 300)
                }
                errorMessage(assignMessage(data?.message, language?.language))
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    useEffect(() => {
        dispatch(removeUser())
    }, [])

    return (
        <>
            <LoginLanguageSelect />
            <div className='card-parent background-layout'>
                <div className='card1' style={{ marginTop: -100 }}>
                    <p className='heading head-center'>{selectedLanguage?.AdminPortal}</p>
                    <Form
                        name='form'
                        onFinish={onFinish}
                        layout={'vertical'}
                        requiredMark={false}
                    >
                        <Form.Item
                            name='userName'
                            label={selectedLanguage?.UserName}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourUserName
                                }
                            ]}
                        >
                            <Input
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.InputyourUserNameHere}
                            />
                        </Form.Item>
                        <Form.Item
                            name='password'
                            label={selectedLanguage?.Password}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourPassword
                                },
                                {
                                    min: 6,
                                    message: selectedLanguage?.PasswordMustBeAtLeastCharacters
                                }
                            ]}
                        >
                            <Input.Password
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.InputYourPasswordHere}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type='primary'
                                className='addproduct-text form-input-new-style'
                                style={{ height: '45px', width: '99%', marginTop: '10px' }}
                                block
                                htmlType='submit'
                                loading={loading}
                            >
                                {selectedLanguage?.Login}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default Login