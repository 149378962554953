import { Button, Image, Modal, Table } from 'antd'
import HTML2Canvas from 'html2canvas'
import QRCode from 'qrcode.react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { allBUserData, errorMessage, validateTime } from '../../utils/helpers'
import NoData from "../../assets/noDataImae.png"
import jsPDF from 'jspdf'
import moment from 'moment'
import { IoIosArrowBack } from "react-icons/io"
import AcceptVouchers from './AcceptVouchers'
import Details from './BuDetails'

const BuVouchers = (props) => {
    const { user, userActions, language, selectedLanguage } = props
    const [visible, setVisible] = useState(false)
    const [details, setDetails] = useState({})
    const [productDetails, setProductDetails] = useState({})
    const vouchers = useSelector(state => state?.userReducer?.vouchers || [])
    const [download, setDownload] = useState(true)
    const [downloadLoading, setDownloadLoading] = useState(false)
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    useEffect(() => {
        allBUserData?.getAllVoucher(userActions, user?._id)
    }, [])

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.Image}</p>,
            align: 'center',
            dataIndex: ['product', 'imageUrl'],
            render: (e) =>
                <Image src={e} style={{ marginRight: 10, width: 'auto', height: 60 }} shape='square' />
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.ProductName}</p>,
            dataIndex: ['product', 'name'],
            key: 'voucherName',
            sorter: (a, b) => a?.product?.name?.length - b?.product?.name?.length,
            showSorterTooltip: false,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.TotalAmount}</p>,
            dataIndex: ['invoice', 'totalAmount'],
            align: 'center',
            sorter: (a, b) => a?.invoice?.totalAmount - b?.invoice?.totalAmount,
            showSorterTooltip: false,
            render: (e) => <p style={{ marginBottom: 0 }}>€ {e}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Status}</p>,
            align: 'center',
            render: (e) => <>{e?.isCancel ? <p style={{ color: 'red', marginBottom: 0 }}>{selectedLanguage?.status_CANCELLED}</p> : e?.isClaim ? <p style={{ color: '#4CAF50', marginBottom: 0 }}>{selectedLanguage?.Claimed}</p> : <p style={{ color: '#007AFF', marginBottom: 0 }}>{selectedLanguage?.Reserved}</p>}</>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.VoucherDate}</p>,
            dataIndex: 'voucherDate',
            sorter: (a, b) => a?.voucherDate?.length - b?.voucherDate?.length,
            showSorterTooltip: false,
            render: (e) => <>{moment(e).format('YYYY-MM-DD')}</>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.VoucherTime}</p>,
            dataIndex: 'voucherTime',
            align: 'center',
            render: (e) => validateTime(e)
        },
        {
            key: 'action',
            render: (e) => (<Button onClick={() => setDetails(e)}>{selectedLanguage?.Details}</Button>)
        }

    ]

    const disableModal = () => setVisible(false)

    const downloadQR = async () => {
        setDownloadLoading(true)
        let id1 = 'qrCode'
        const canvas = document.getElementById(id1)

        await HTML2Canvas(canvas, { allowTaint: true, useCORS: true })
            .then((data) => {
                const pngUrl = data.toDataURL()
                let downloadLink = document.createElement('a')
                downloadLink.href = pngUrl
                var doc = new jsPDF('p', 'mm');
                doc.addImage(pngUrl, 'PNG', 10, 10);
                doc.save(`${productDetails?.product?.name || 'Voucher'}.pdf`);
                setDownload(false)
                setDownloadLoading(false)
            })
            .catch((e) => {
                setDownloadLoading(false)
                setDownload(false)
                errorMessage(selectedLanguage?.txt_downloaderror)
                console.log('e', e)
            })
    }

    const showQR = () => {
        return (
            <Modal
                open={visible}
                onCancel={disableModal}
                footer={null}
                className='pop-info-modal'
                style={{ top: '10px' }}
            >
                <p className='new-p-question'>{selectedLanguage?.VoucherCode}</p>
                <div
                    id='qrCode' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: 10 }}
                >
                    <div id='productImage' style={{ textAlign: 'center', width: '95%' }}>
                        <img src={productDetails?.product?.imageUrl} style={{ maxWidth: '100%', height: 200 }} />
                        <p className='heading head-center' style={{ marginBottom: 0 }}>{productDetails?.product?.name}</p>
                        <p className='product_desc'>{productDetails?.product?.description}</p>
                    </div>
                    <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'row', border: '2px solid', width: '90%' }}>
                        <div>
                            <QRCode
                                value={productDetails?.voucherCode}
                                size={200}
                                level={'H'}
                                includeMargin={true}
                            />
                            <p style={{ marginTop: -10 }}>{productDetails?.voucherCode}</p>
                        </div>
                        <div style={{ textAlign: 'start', marginTop: 'auto', marginBottom: 'auto' }}>
                            <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.UserName}:</span> {productDetails?.buUser?.businessName}</div>
                            <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.TelNo}:</span> {productDetails?.buUser?.telNo}</div>
                            <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.ReservedDate}:</span> {moment(productDetails?.voucherDate).format('YYYY-MM-DD')}</div>
                            <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.ReservedTime}:</span> {validateTime(productDetails?.voucherTime)}</div>
                            <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.Quantity}:</span> {productDetails?.invoice?.quantity}</div>
                            <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.TotalAmount}:</span> €{productDetails?.invoice?.totalAmount}</div>
                            <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.GuestName}:</span> {productDetails?.userId?.fullName}</div>
                        </div>
                    </div>
                    <br />
                </div>
                <center><a onClick={() => downloadQR(productDetails)}> {selectedLanguage?.DownloadQR} </a></center>
            </Modal>
        )
    }

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote1}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>
                    <div className='main-div-new-style'>
                        <p className='new-exam-title'>{details?._id ? selectedLanguage?.Details : selectedLanguage?.Vouchers}</p>
                        {details?._id ?
                            <div style={{ textAlign: 'right', width: '100%' }}>
                                <Button
                                    style={{ height: '40px', width: '160px', marginTop: '10px' }}
                                    className='addproduct-text'
                                    type='primary'
                                    onClick={() => setDetails({})}
                                >
                                    {selectedLanguage?.Back}
                                </Button>
                            </div>
                            : null}
                        {showQR()}
                        <div className='fixed-qr'>
                            <div id='qrCodeDownload' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: 10, width: '100%' }}>
                                <div id='productImage' style={{ textAlign: 'center', width: 480 }}>
                                    <img src={productDetails?.product?.imageUrl} style={{ maxWidth: '100%', height: 200 }} />
                                    <p className='heading head-center' style={{ marginBottom: 0 }}>{productDetails?.product?.name}</p>
                                    <p className='product_desc'>{productDetails?.product?.description}</p>
                                </div>
                                <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'row', border: '2px solid', width: 480 }}>
                                    <div>
                                        <QRCode
                                            value={productDetails?.voucherCode}
                                            size={200}
                                            level={'H'}
                                            includeMargin={true}
                                        />
                                        <p style={{ marginTop: -10 }}>{productDetails?.voucherCode}</p>
                                    </div>
                                    <div style={{ textAlign: 'start', marginTop: 'auto', marginBottom: 'auto' }}>
                                        <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.UserName}:</span> {productDetails?.buUser?.businessName}</div>
                                        <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.TelNo}:</span> {productDetails?.buUser?.telNo}</div>
                                        <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.ReservedDate}:</span> {moment(productDetails?.voucherDate).format('YYYY-MM-DD')}</div>
                                        <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.ReservedTime}:</span> {validateTime(productDetails?.voucherTime)}</div>
                                        <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.Quantity}:</span> {productDetails?.invoice?.quantity}</div>
                                        <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.TotalAmount}:</span> €{productDetails?.invoice?.totalAmount}</div>
                                        <div><span style={{ fontWeight: 700 }}> {selectedLanguage?.GuestName}:</span> {productDetails?.userId?.fullName}</div>
                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>

                        {details?._id ?
                            <Details onCancel={() => setDetails({})} details={details} buUser={details?.buUser} invoice={details?.invoice} product={details?.product}  {...props} />
                            :
                            <>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                                    < AcceptVouchers {...props} />
                                </div>
                                <br />
                                <div className='new-layout-table-upper-div'>
                                    <Table
                                        className='new-layout-table'
                                        locale={customLocale}
                                        columns={columns}
                                        dataSource={vouchers}
                                        pagination={{ hideOnSinglePage: true }}
                                        scroll={{ x: 800 }}
                                    />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuVouchers