import { FileOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { AutoComplete, Button, Checkbox, Form, Input, InputNumber, Radio, Select, Space, Upload } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import axios from 'axios'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { GET, POST } from '../../../../utils/apis'
import { ageOptions, productTimeRangeLanguage } from '../../../../utils/constants'
import { assignMessage, checkSelectOption, convertTitle, errorMessage, successNotification, warningMessage } from '../../../../utils/helpers'
const { Option } = Select

const AddProducts = (props) => {
    const { onCancel, edit, user, selectedUser, getProducts, language, selectedLanguage, userDetail } = props
    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState([])
    const [otherFileList, setOtherFileList] = useState([])
    const [productTypes, setProductTypes] = useState([])
    const [showGroupPrice, setShowGroupPrice] = useState(edit?.groupPrice || false)
    const [showMaxDiscount, setShowMaxDiscount] = useState(edit?.joinDiscount || false)
    const [showagePrice, setShowAgePrice] = useState(edit?.agePrice || false)
    const [form] = Form.useForm();
    const [allTopics, setAllTopics] = useState([])
    const isEnglish = language?.language === 'english'
    useEffect(() => {
        getProductTypes()
        getAllTopics()

    }, [])

    useEffect(() => {
        let topicVal = optionsTopic?.filter((v, i) => v?.value?.toLowerCase()?.trim() === edit?.topicId?.topicName)?.[0]?.value

        form.setFieldValue('topic', topicVal || null)
        // setTopicValue(topicVal||null)
    }, [allTopics])

    const getAllTopics = () => {
        axios.get(GET.GET_TOPICS)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setAllTopics(data?.data || [])
                }
            })
            .catch((e) => {
                console.log('e', e)
            })
    }

    const onFinish = (values) => {
        let originalFileList = otherFileList?.map((vv, ii) => {
            return vv?.originFileObj
        })
        values.file = fileList
        values.otherFiles = originalFileList
        edit?._id && (values._id = edit?._id)
        values.userId = selectedUser
        values.adminId = user?._id

        let expiryDate = moment(userDetail?.subscription?.expiry).utcOffset(0, true)
        let todayDate = moment().utcOffset(0, true)
        let difference = expiryDate.diff(todayDate, 'h')
        if (userDetail?.supplierType === 'shops' && (!userDetail?.subscription?.expiry || difference <= 0)) {
            return warningMessage(selectedLanguage?.errorMessage_buySubscription)
        }

        if (!values._id && !values?.file?.length) {
            return warningMessage(selectedLanguage?.PleaseUploadImage)
        }
        let formData = new FormData()
        // if (values?.file?.length) {
        //     formData.append('file', values?.file[0])
        // }
        if (values?.file?.length) {
            values.file[0].oldfile = true
            let obj = values.file[0]
            formData.append('files', obj)
        }
        if (!values._id && values.otherFiles?.length < 3) {
            return warningMessage(selectedLanguage?.Product_PleaseUploadMinimumThreeOtherImages)
        }
        if (values?.otherFiles?.length) {
            for (let fileNew of values?.otherFiles) {
                formData.append('newFiles', fileNew)
            }
        }

        if (values?._id) {
            formData.append('_id', values?._id)
        }
        formData.append('topic', values.topic)
        formData.append('agePrice', values.agePrice || false)

        formData.append('userId', values.userId)
        formData.append('adminId', values.adminId)
        formData.append('name', values?.name)
        formData.append('description', values?.description)
        formData.append('doubleChances', values?.doubleChances || false)

        if (userDetail?.supplierType !== 'shops') {
            formData.append('cancelHours', values.cancelHours)
            formData.append('productType', values?.productType)
            formData.append('groupPrice', values.groupPrice)
            let newPrice = values?.price
            formData.append('price', JSON.stringify(newPrice))
            formData.append('tax', values?.tax)
            formData.append('joinDiscount', values.joinDiscount)
            formData.append('maxDiscount', values?.maxDiscount || 0)
        }

        if (values?.timeRange?.length) {
            for (let v of values?.timeRange) {
                formData.append('timeRange[]', v)
            }
        }
        setLoading(true)

        let ROUTE = !edit?._id ? POST?.CREATE_PRODUCT : POST?.EDIT_PRODUCT

        axios.post(ROUTE, formData)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(assignMessage(data?.message, language?.language))
                    getProducts()
                    return setTimeout(() => {
                        onCancel()
                    }, 300)
                } else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage(selectedLanguage?.YouCanOnlyUploadImages)
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    const normOtherFile = (e) => {
        setOtherFileList([])
        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage(selectedLanguage?.YouCanOnlyUploadImages)
            return []
        }

        if (Array.isArray(e)) {
            return e
        }
        e?.fileList?.length ? setOtherFileList(e?.fileList || []) : setOtherFileList([])
        return e && e.fileList
    }


    const getProductTypes = () => {
        axios.get(`${GET?.GET_PRODUCT_TYPES}`)
            .then((res) => {
                const { data } = res
                setProductTypes(data?.data || [])
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                console.log('e', e)
            })
    }
    const optionsTopic = allTopics?.map((v, i) => {
        return {
            value: convertTitle(v?.topicName)
        }
    })

    const setInputValue = (index, name, value) => {
        let tempValue = [...form.getFieldValue('price')]
        if (!tempValue?.[index]?.[ageOptions[0]] || !tempValue?.[index]?.[ageOptions[1]] || !tempValue?.[index]?.[ageOptions[2]] || !tempValue?.[index]?.[ageOptions[3]]) {

            tempValue[index] = { [ageOptions[0]]: { start: value, last: value + 1, price: 0.9 }, [ageOptions[1]]: { start: value, last: value + 1, price: 0.9 }, [ageOptions[2]]: { start: value, last: value + 1, price: 0.9 }, [ageOptions[3]]: { start: value, last: value + 1, price: 0.9 } }

        }
        tempValue[index][ageOptions[1]][name] = value
        tempValue[index][ageOptions[2]][name] = value
        tempValue[index][ageOptions[3]][name] = value
        form.setFieldValue('price', tempValue)
    }

    const checkPriceField = (group, age) => {

        if (age && group) {
            let tempArr = [{ [ageOptions[0]]: { start: 1, last: 3, price: 0.9 }, [ageOptions[1]]: { start: 1, last: 3, price: 0.9 }, [ageOptions[2]]: { start: 1, last: 3, price: 0.9 }, [ageOptions[3]]: { start: 1, last: 3, price: 0.9 } }]

            form.setFieldValue('price', tempArr)
        } else if (!age && group) {

            let tempArr = [{ start: 1, last: 3, price: 0.5 }]

            form.setFieldValue('price', tempArr)

        } else if (age && !group) {
            let tempArr = [{ [ageOptions[0]]: { start: 1, last: 3, price: 0.5 }, [ageOptions[1]]: { start: 1, last: 3, price: 0.5 }, [ageOptions[2]]: { start: 1, last: 3, price: 0.5 }, [ageOptions[3]]: { start: 1, last: 3, price: 0.5 } }]
            form.setFieldValue('price', tempArr)
        } else {
            let tempArr = [{ price: 0.5 }]

            form.setFieldValue('price', tempArr)
        }

    }

    return (
        <div style={{ background: 'white', padding: '15px' }}>
            <h3 className='new-exam-title'>
                {edit?._id ? selectedLanguage?.UpdateProduct : selectedLanguage?.CreateProduct}
            </h3>

            <div style={{ textAlign: 'right', width: '99%' }}>
                <Button
                    type='primary'
                    className='btn-end'
                    onClick={onCancel}
                >
                    {selectedLanguage?.Cancel}
                </Button>
            </div>

            <div style={{ background: 'white', padding: '15px' }}>
                <Form
                    name='form'
                    form={form}
                    onFinish={onFinish}
                    layout={'vertical'}
                >
                    <Form.Item
                        name='name'
                        label={selectedLanguage?.ProductName}
                        hasFeedback
                        initialValue={edit?.name}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.PleaseInputYourProductName
                            }
                        ]}
                    >
                        <Input
                            className='form-input'
                            placeholder={selectedLanguage?.InputYourProductNameHere}
                        />
                    </Form.Item>
                    <Form.Item
                        name='description'
                        label={selectedLanguage?.ProductDescription}
                        hasFeedback
                        initialValue={edit?.description}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.PleaseInputDescription
                            },
                        ]}
                    >
                        <TextArea
                            className='form-input'
                            placeholder={selectedLanguage?.InputYourDescriptionHere}
                        />
                    </Form.Item>

                    {userDetail?.supplierType !== 'shops' ?
                        <Form.Item
                            name='productType'
                            label={selectedLanguage?.ProductType}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourProductType
                                }
                            ]}
                            initialValue={edit?.productType?._id}
                        >
                            <Select
                                placeholder={selectedLanguage?.SelectProductType}
                                showSearch
                                optionFilterProp='children'
                                filterOption={checkSelectOption}>
                                {productTypes?.map((v, i) => {
                                    return (
                                        <Option value={v?._id} key={i}>{v?.typeName + ' ' + '€' + v?.feeCost}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        : null
                    }
                    <Form.Item
                        name='topic'
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.PleaseInputYourProducttopic,
                            }
                        ]}
                        required
                        label={selectedLanguage?.Topic}
                    >
                        <AutoComplete
                            options={optionsTopic}
                            placeholder={selectedLanguage?.EnterTopic}
                            filterOption={(inputValue, option) =>
                                option.value?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1
                            }
                        />
                    </Form.Item>



                    {userDetail?.supplierType !== 'shops' ?
                        <>
                            <Form.Item
                                name='timeRange'
                                label={selectedLanguage?.TimeRange}
                                hasFeedback
                                rules={[
                                    {
                                        type: 'array',
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourTimeRange
                                    }
                                ]}
                                initialValue={edit?.timeRange}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}
                                    mode='multiple' placeholder={selectedLanguage?.SelectTimeRange}>
                                    {productTimeRangeLanguage?.map((v, i) =>
                                        <Option value={v?.name} key={i}>{language?.language === 'english' ? v?.name : language?.language === 'egypt' ? v?.nameAr : v?.nameNl}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='agePrice'
                                valuePropName='checked'
                                initialValue={edit?.agePrice || false}
                                onChange={(e) => { setShowAgePrice(e?.target?.checked); checkPriceField(showGroupPrice, e?.target?.checked) }}
                            >
                                <Checkbox>
                                    {selectedLanguage?.label_agePrice}
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name='groupPrice'
                                valuePropName='checked'
                                initialValue={edit?.groupPrice || false}
                                onChange={(e) => { setShowGroupPrice(e?.target?.checked); checkPriceField(e?.target?.checked, showagePrice) }}
                            >
                                <Checkbox>
                                    {selectedLanguage?.GroupPrice}
                                </Checkbox>
                            </Form.Item>

                            {showGroupPrice && showagePrice ?
                                <Form.List name='price' initialValue={edit?.price || [{ [ageOptions[0]]: { start: 1, last: 3, price: 0.9 }, [ageOptions[1]]: { start: 1, last: 3, price: 0.9 }, [ageOptions[2]]: { start: 1, last: 3, price: 0.9 }, [ageOptions[3]]: { start: 1, last: 3, price: 0.9 } }]}>
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }, index) => (
                                                ageOptions?.map((v, i) => (
                                                    <>
                                                        <label>{`${selectedLanguage?.label_age}: ${v}`}</label>
                                                        <Space
                                                            style={{
                                                                display: 'flex',
                                                                marginBottom: 8,
                                                            }}
                                                            align='baseline'
                                                        >
                                                            <Form.Item
                                                                label={index === 0 && i === 0 ? selectedLanguage?.Start : ''}
                                                                {...restField}
                                                                name={[name, v, 'start']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        validator: async (_, names) => {
                                                                            if (names > form.getFieldValue('price')[name]?.[v]?.last) {
                                                                                return Promise.reject(new Error(selectedLanguage?.StartOfTheRangeMustBeEqualorGreaterthenLastOftheRange));
                                                                            }

                                                                            if (name != 0 && names <= form.getFieldValue('price')[name - 1]?.[v]?.last) {
                                                                                return Promise.reject(new Error(selectedLanguage?.ThisRangeAlreadyExistsIntersectsOrMatchesWithAnotherRange));
                                                                            }
                                                                        },
                                                                    }
                                                                ]}
                                                            >
                                                                <InputNumber
                                                                    disabled={i !== 0}
                                                                    className='form-input'
                                                                    placeholder={selectedLanguage?.Quantity}
                                                                    min={1}
                                                                    style={{ width: '100%' }}
                                                                    step={1}
                                                                    onChange={(e) => setInputValue(name, 'start', e)}
                                                                    precision={0}
                                                                    type='number'
                                                                    pattern='[0-9]*'
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label={index === 0 && i === 0 ? selectedLanguage?.Last : ''}
                                                                {...restField}
                                                                name={[name, v, 'last']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        validator: async (_, names) => {
                                                                            if (names < form.getFieldValue('price')[name]?.[v]?.start) {
                                                                                return Promise.reject(new Error(selectedLanguage?.LastOfTheRangeMustBeEqualOrGreaterThenStartOfTheRange));
                                                                            }
                                                                        }
                                                                    }
                                                                ]}
                                                            >
                                                                <InputNumber
                                                                    disabled={i !== 0}
                                                                    className='form-input'
                                                                    placeholder={selectedLanguage?.Quantity}
                                                                    min={1}
                                                                    style={{ width: '100%' }}
                                                                    step={1}
                                                                    onChange={(e) => setInputValue(name, 'last', e)}
                                                                    precision={0}
                                                                    type='number'
                                                                    pattern='[0-9]*'
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label={index === 0 && i === 0 ? selectedLanguage?.Price : ''}
                                                                {...restField}
                                                                name={[name, v, 'price']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: selectedLanguage?.PleaseInputYourPrice,
                                                                    }
                                                                ]}
                                                            >
                                                                <InputNumber
                                                                    className='form-input'
                                                                    placeholder={selectedLanguage?.InputyourPriceHere}
                                                                    min={0.01}
                                                                    style={{ width: '100%' }}
                                                                    step={0.01}
                                                                    precision={2}
                                                                    // defaultValue={0.01}
                                                                    type='number'
                                                                    pattern='[0-9]*'
                                                                />
                                                            </Form.Item>
                                                            <MinusCircleOutlined style={name === 0 && i === 0 ? { bottom: -35, position: 'relative' } : i > 0 ? { display: 'none' } : {}} onClick={() => remove(name)} />
                                                        </Space>
                                                    </>
                                                ))
                                            ))}
                                            {fields?.length <= 3 ?
                                                <Form.Item>
                                                    <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                                        {selectedLanguage?.AddRange}
                                                    </Button>
                                                </Form.Item>
                                                :
                                                null
                                            }
                                        </>
                                    )}
                                </Form.List>
                                :
                                !showGroupPrice && showagePrice ?
                                    <>
                                        <label >{selectedLanguage?.Price}</label>
                                        <Form.List name='price' initialValue={edit?.price || [{ [ageOptions[0]]: { start: 1, last: 3, price: 0.5 }, [ageOptions[1]]: { start: 1, last: 3, price: 0.5 }, [ageOptions[2]]: { start: 1, last: 3, price: 0.5 }, [ageOptions[3]]: { start: 1, last: 3, price: 0.5 } }]}>
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }, index) => (
                                                        <Space
                                                            key={key}
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                marginBottom: 8
                                                                // width:'100%'
                                                            }}
                                                            align='baseline'
                                                        >
                                                            {ageOptions?.map((v) => (
                                                                <Form.Item
                                                                    label={index === 0 ? (`( ${v} )`) : ''}
                                                                    {...restField}
                                                                    name={[name, v, 'price']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: selectedLanguage?.PleaseInputYourPrice,
                                                                        }
                                                                    ]}
                                                                >
                                                                    <InputNumber
                                                                        className='form-input'
                                                                        placeholder={selectedLanguage?.InputyourPriceHere}
                                                                        defaultValue={edit?.price || 0.01}
                                                                        min={0}
                                                                        style={{ width: '100%' }}
                                                                        step={0.01}
                                                                        precision={2}
                                                                        // defaultValue={0.01}
                                                                        type='number'
                                                                        pattern='[0-9]*'
                                                                    />
                                                                </Form.Item>
                                                            ))}
                                                        </Space>
                                                    ))}
                                                </>
                                            )}
                                        </Form.List>
                                    </>
                                    :
                                    showGroupPrice && !showagePrice ?
                                        <Form.List name='price' initialValue={edit?.price || [{ start: 1, last: 3, price: 0.5 }]}>
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }, index) => (
                                                        <Space
                                                            key={key}
                                                            style={{
                                                                display: 'flex',
                                                                marginBottom: 8,
                                                            }}
                                                            align='baseline'
                                                        >
                                                            <Form.Item
                                                                label={index === 0 ? selectedLanguage?.Start : ''}
                                                                {...restField}
                                                                name={[name, 'start']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        validator: async (_, names) => {
                                                                            if (names > form.getFieldValue('price')[name]?.last) {
                                                                                return Promise.reject(new Error(selectedLanguage?.StartOfTheRangeMustBeEqualorGreaterthenLastOftheRange));
                                                                            }
                                                                            if (name != 0 && names <= form.getFieldValue('price')[name - 1]?.last) {
                                                                                return Promise.reject(new Error(selectedLanguage?.ThisRangeAlreadyExistsIntersectsOrMatchesWithAnotherRange));
                                                                            }
                                                                        },
                                                                    }
                                                                ]}
                                                            >
                                                                <InputNumber
                                                                    className='form-input'
                                                                    placeholder={selectedLanguage?.Quantity}
                                                                    min={1}
                                                                    style={{ width: '100%' }}
                                                                    step={1}
                                                                    precision={0}
                                                                    type='number'
                                                                    pattern='[0-9]*'
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label={index === 0 ? selectedLanguage?.Last : ''}
                                                                {...restField}
                                                                name={[name, 'last']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        validator: async (_, names) => {
                                                                            if (names < form.getFieldValue('price')[name]?.start) {
                                                                                return Promise.reject(new Error(selectedLanguage?.LastOfTheRangeMustBeEqualOrGreaterThenStartOfTheRange));
                                                                            }
                                                                        }
                                                                    }
                                                                ]}
                                                            >
                                                                <InputNumber
                                                                    className='form-input'
                                                                    placeholder={selectedLanguage?.Quantity}
                                                                    min={1}
                                                                    style={{ width: '100%' }}
                                                                    step={1}
                                                                    precision={0}
                                                                    // defaultValue={0.01}
                                                                    type='number'
                                                                    pattern='[0-9]*'
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label={index === 0 ? selectedLanguage?.Price : ''}
                                                                {...restField}
                                                                name={[name, 'price']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: selectedLanguage?.PleaseInputYourPrice,
                                                                    }
                                                                ]}
                                                            >
                                                                <InputNumber
                                                                    className='form-input'
                                                                    placeholder={selectedLanguage?.InputyourPriceHere}
                                                                    min={0.01}
                                                                    style={{ width: '100%' }}
                                                                    step={0.01}
                                                                    precision={2}
                                                                    // defaultValue={0.01}
                                                                    type='number'
                                                                    pattern='[0-9]*'
                                                                />
                                                            </Form.Item>
                                                            <MinusCircleOutlined style={name === 0 ? { bottom: -35, position: 'relative' } : {}} onClick={() => remove(name)} />
                                                        </Space>
                                                    ))}
                                                    {fields?.length <= 3 ?
                                                        <Form.Item>
                                                            <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                                                {selectedLanguage?.AddRange}
                                                            </Button>
                                                        </Form.Item>
                                                        :
                                                        null
                                                    }
                                                </>
                                            )}
                                        </Form.List>
                                        :
                                        <Form.List name='price' initialValue={edit?.price || [{ price: 0.5 }]}>
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }, index) => (
                                                        <Space
                                                            key={key}
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                marginBottom: 8
                                                                // width:'100%'
                                                            }}
                                                            align='baseline'
                                                        >
                                                            <Form.Item
                                                                label={index === 0 ? selectedLanguage?.Price : ''}
                                                                {...restField}
                                                                name={[name, 'price']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: selectedLanguage?.PleaseInputYourPrice,
                                                                    }
                                                                ]}
                                                            >
                                                                <InputNumber
                                                                    className='form-input'
                                                                    placeholder={selectedLanguage?.InputyourPriceHere}
                                                                    defaultValue={edit?.price || 0.01}
                                                                    min={0}
                                                                    style={{ width: '100%' }}
                                                                    step={0.01}
                                                                    precision={2}
                                                                    // defaultValue={0.01}
                                                                    type='number'
                                                                    pattern='[0-9]*'
                                                                />
                                                            </Form.Item>
                                                        </Space>
                                                    ))}
                                                </>
                                            )}
                                        </Form.List>
                            }
                            <Form.Item name='tax' label={selectedLanguage?.Tax} initialValue={edit?.tax || 0}>
                                <Radio.Group>
                                    <Radio value={0}>0%</Radio>
                                    <Radio value={9}>9%</Radio>
                                    <Radio value={21}>21%</Radio>
                                </Radio.Group>
                            </Form.Item>


                            <Form.Item
                                name='cancelHours'
                                label={selectedLanguage?.label_CancelBefore}
                                hasFeedback
                                initialValue={edit?.cancelHours === 0 ? 0 : (edit?.cancelHours || 24)}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.requiredMessage_PleaseEnterCancellationHours
                                    }
                                ]}
                            >
                                <InputNumber
                                    className='form-input'
                                    placeholder={selectedLanguage?.placeholder_cancellationHours}
                                    defaultValue={edit?.cancelHours === 0 ? 0 : (edit?.cancelHours || 24)}
                                    min={0}
                                    style={{ width: '100%' }}
                                    step={1}
                                    max={24}
                                    precision={0}
                                    type='number'
                                    pattern='[0-9]*'
                                />
                            </Form.Item>
                            <Form.Item
                                name='joinDiscount'
                                valuePropName='checked'
                                initialValue={edit?.joinDiscount || false}
                                onChange={(e) => { setShowMaxDiscount(e?.target?.checked) }}
                            >
                                <Checkbox>
                                    {selectedLanguage?.JoinedDiscount}
                                </Checkbox>
                            </Form.Item>

                            {showMaxDiscount ?
                                <Form.Item
                                    name='maxDiscount'
                                    label={selectedLanguage?.MaximumDiscount}
                                    hasFeedback
                                    initialValue={edit?.maxDiscount || 0.5}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.PleaseInputMaximumDiscount
                                        }
                                    ]}
                                >
                                    <InputNumber
                                        className='form-input'
                                        placeholder={selectedLanguage?.InputYourMaximumDiscountHere}
                                        defaultValue={edit?.maxDiscount || 0.5}
                                        min={0.5}
                                        style={{ width: '100%' }}
                                        step={0.5}
                                        precision={2}
                                        // defaultValue={0.01}
                                        type='number'
                                        pattern='[0-9]*'
                                    />
                                </Form.Item>
                                : null
                            }
                        </>
                        : null}

                    <Form.Item
                        name='file'
                        label={selectedLanguage?.File}
                    >
                        <Upload
                            name='file'
                            multiple={false}
                            beforeUpload={() => false}
                            accept='image/png, image/jpeg'
                            onChange={normFile}
                            fileList={fileList}
                        >
                            <Button icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                        </Upload>
                        {edit?.fileName && !fileList?.length ? <span><FileOutlined />&nbsp;{edit?.fileName}</span> : null}
                    </Form.Item>
                    <Form.Item
                        name='otherFiles'
                        label={selectedLanguage?.Product_OtherFiles}
                    >
                        <Upload
                            name='otherFiles'
                            multiple={true}
                            beforeUpload={() => false}
                            maxCount={10}
                            accept='image/png, image/jpeg'
                            onChange={normOtherFile}
                            fileList={otherFileList}
                            showUploadList={true}
                        >
                            <Button icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                        </Upload>
                        {edit?.fileName && !otherFileList?.length ?
                            edit?.otherImages?.map((v, i) => {
                                let imgURl = v
                                let splitImgURl = imgURl.split('-')
                                let lastWord = splitImgURl.slice(-1)
                                return (
                                    <>
                                        <span><FileOutlined />&nbsp;{lastWord?.length ? lastWord[0] : v}</span>
                                        <br />
                                    </>
                                )
                            })
                            : null}
                    </Form.Item>

                    <Form.Item
                        name='doubleChances'
                        valuePropName='checked'
                        initialValue={edit?.doubleChances || false}
                    >
                        <Checkbox>
                            {selectedLanguage?.label_doubleChances}
                        </Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type='primary'
                            className='form-button'
                            block
                            htmlType='submit'
                            loading={loading}
                        >
                            {edit?._id ? selectedLanguage?.Update : selectedLanguage?.Create}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

AddProducts.propTypes = {
    onCancel: PropTypes.func.isRequired
}

export default AddProducts