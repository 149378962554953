import { Modal } from "antd";
import React from "react";


const LoginAlert = (props) => {
    const { open, onCancel, selectedLanguage, history, allPaths } = props
    const gotoLogin = () => {
        history?.push(allPaths?.LOGIN)
    }
    const gotoRegister = () => {
        history?.push(allPaths?.SIGNUP)
    }
    return (
        <Modal
            open={open}
            onCancel={onCancel}
            footer={null}
            className='pop-info-modal'
            style={{ top: '10px' }}
        >
            <p className='new-p-question'>{selectedLanguage?.txt_Youneedtologin}</p>
            <p> {selectedLanguage?.loginAlert_Text}</p>
            <br />
            <br />
            <p className="login-alert-paragraph">{selectedLanguage?.txt_Youhaveanaccount} <span onClick={gotoLogin}>{selectedLanguage?.txtx_Loginhere}</span></p>
            <p className="login-alert-paragraph-border"> <span>{selectedLanguage?.txt_OR}</span></p>
            <p className="login-alert-paragraph">{selectedLanguage?.txt_Createanaccount} <span onClick={gotoRegister}>{selectedLanguage?.txt_Registerhere}</span></p>
        </Modal>
    )
}

export default LoginAlert 