import React, { useEffect } from "react";
import { NewFooter } from "../../../NewComponents";
import VisiterHomeContent from "./VisiterHomeContent";
import { useDispatch, useSelector } from "react-redux";
import NewVisiterHeader from "./VisiterHeader"; 
import { useHistory } from "react-router-dom";
import { removeUser } from "../../../Redux/actions/authActions";
import { assignLanguage } from "../../../utils/helpers";


const VisiterHome = () => {
    const dispatch = useDispatch()
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = assignLanguage(language?.language)
    const history = useHistory()
    useEffect(() => {
        dispatch(removeUser())
    }, [])
    return (
        <div className="new-main-layout-div">
            <div className="visiter-home-main-div ">
                <div className="visiter-home-main-div-scroll new-layout-scroll-class">
                    <NewVisiterHeader language={language} selectedLanguage={selectedLanguage} />
                    <VisiterHomeContent language={language} selectedLanguage={selectedLanguage} />
                    <NewFooter history={history} language={language} selectedLanguage={selectedLanguage} />
                </div>
            </div>
        </div>
    )
}

export default VisiterHome