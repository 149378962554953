import { DeleteOutlined, EditTwoTone } from '@ant-design/icons'
import { Button, Popconfirm, Spin, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ADMIN } from '../../../../utils/apis'
import { assignMessage, convertTitle, errorMessage, successNotification } from '../../../../utils/helpers'
import AddTypes from './AddTypes'

const Types = (props) => {
    const { user, language, selectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const [showAddType, setShowAddType] = useState(false)
    const [productTypes, setProductTypes] = useState([])
    const [edit, setEdit] = useState({})
    const [spin, setSpin] = useState({})
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };

    useEffect(() => {
        getProductsTypes()
    }, [])

    const getProductsTypes = () => {
        setLoading(true)
        axios.get(`${ADMIN?.GET_PRODUCT_TYPES}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    setProductTypes(data?.data || [])
                } else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
            })
    }

    const deleteType = (_id) => {
        setSpin({ [_id]: true })
        let deleteProduct = {
            _id,
            userId: user?._id
        }
        axios.post(`${ADMIN?.DELETE_PRODUCT_TYPES}`, deleteProduct)
            .then((res) => {
                const { data } = res
                setSpin({})
                getProductsTypes()
                if (data?.success) {
                    successNotification(assignMessage(data?.message, language?.language))
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setSpin({})
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.Name}</p>,
            dataIndex: 'typeName',
            key: 'typeName',
            sorter: (a, b) => a?.typeName?.length - b?.typeName?.length,
            showSorterTooltip: false,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.FeeCost}</p>,
            dataIndex: 'feeCost',
            sorter: (a, b) => a?.feeCost - b?.feeCost,
            showSorterTooltip: false,

        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Actions}</p>,
            key: 'actions',
            align: 'center',
            render: (e) => <span>
                <EditTwoTone onClick={() => setEdit(e)} />&nbsp;&nbsp;&nbsp;
                <Popconfirm
                    title={`${selectedLanguage?.Delete} ${convertTitle(e?.typeName)}?`}
                    okText={selectedLanguage?.Delete}
                    cancelText={selectedLanguage?.Cancel}
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => deleteType(e?._id)}
                >
                    <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
                    {spin[e?._id] ? <span className='demo-loading-container'>
                        &nbsp;<Spin />
                    </span> : null}
                </Popconfirm>
            </span>
        }
    ]

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote3}</p>
            </div>
            <div className='new-exam-screen-upper'>
                {showAddType || edit?._id ?
                    <AddTypes
                        edit={edit}
                        onCancel={() => (setShowAddType(false), setEdit({}))}
                        getProductsTypes={getProductsTypes}
                        {...props}
                    />
                    :
                    <div style={{ background: 'white', padding: '15px' }}>
                        <h3 className='new-exam-title'>{selectedLanguage?.Types}</h3>
                        <div style={{ textAlign: 'right', width: '99%' }}>
                            <Button
                                type='primary'
                                className='btn-end'
                                onClick={() => setShowAddType(true)}
                            >
                                {selectedLanguage?.AddType}
                            </Button>
                        </div>
                        <div className='new-layout-table-upper-div'>
                            <Table
                                style={{ marginTop: '30px' }}
                                className='new-layout-table'
                                locale={customLocale}
                                columns={columns}
                                dataSource={productTypes}
                                loading={loading}
                                pagination={{ hideOnSinglePage: true }}
                                scroll={{ x: 600 }}
                            />
                        </div>
                    </div>}
            </div>
        </div>
    )
}

export default Types

