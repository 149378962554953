import { Button, Modal, Table } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { ADMIN } from '../../../utils/apis'
import { createGiftCard } from '../../../utils/helpers'

const GiftNotifications = (props) => {
    const { user, selectedLanguage, language } = props
    const isEnglish = language?.language == 'english'
    const [notifications, setNotifications] = useState([])
    const [loading, setLoading] = useState(false)
    const [detail, setDetail] = useState({})
    const [showDetails, setShowDetails] = useState(false)
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };

    useEffect(() => {
        getNotification()
    }, [])

    const getNotification = () => {
        setLoading(true)
        axios.get(`${ADMIN.GET_ADMIN_NOTIFICATIONS}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setNotifications(data?.data)
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
            })
    }

    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_BuUser}</p>,
            dataIndex: ['userId', 'email'],
            key: 'email',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a?.email?.length - b?.email?.length,
            showSorterTooltip: false,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Notification}</p>,
            dataIndex: 'notificationMessage',
            key: 'notificationMessage'
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Created}</p>,
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p>{moment(e?.created).utc().format('YYYY-MM-DD')}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Actions}</p>,
            key: 'actions',
            render: (e) => (
                <>
                    <Button onClick={() => {
                        setDetail(e?.userId)
                        setShowDetails(true)
                    }} > {selectedLanguage?.button_ViewDetail}</Button >
                    &nbsp; &nbsp; &nbsp;
                    <Button onClick={() => createGiftCard(e?.giftCards)} > {selectedLanguage?.txt_GeneratePDF}</Button>
                </>
            )
        }
    ]

    const handleOk = () => {
        setShowDetails(false)
    }

    return (
        <div >
            <div className='new-layout-table-upper-div'>
                <Table
                    style={{ marginTop: '30px' }}
                    className='new-layout-table'
                    locale={customLocale}
                    loading={loading}
                    dataSource={notifications}
                    columns={columns}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 800 }}
                />
            </div> 
            
            <Modal
                className='pop-info-modal'
                style={{ top: '10px' }}
                onCancel={handleOk}
                open={showDetails}
                onOk={handleOk}
                footer={null}
            >
                <p className='new-p-question'>{selectedLanguage?.UserDetail}</p>
                <ul>
                    <li>{selectedLanguage?.txt_fullName}: {detail?.fullName ? detail?.fullName : '-------'} </li>
                    <li>{selectedLanguage?.txt_email}: {detail?.email ? detail?.email : '-------'} </li>
                    <li>{selectedLanguage?.txt_bankAccount}: {detail?.bankAccount ? detail?.bankAccount : '-------'}</li>
                    <li>{selectedLanguage?.txt_IBAN}: {detail?.IBAN ? detail?.IBAN : '-------'}</li>
                    <li>{selectedLanguage?.txt_Address}: {detail?.streetNumber ? detail?.streetNumber : '-------'}</li>
                </ul>
            </Modal>
        </div>
    )
}

export default GiftNotifications