import { Button, Select, Table } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN } from '../../../utils/apis'
import { allAdminData, assignMessage, checkSelectOption, convertTitle, createPaymentReportPdf, errorMessage, errorNotification, successMessage } from '../../../utils/helpers'
const { Option } = Select

const PaymentReport = (props) => {
    const { user, adminActions, language, selectedLanguage } = props
    const isEnglish = language?.language === 'english'
    const buUsers = useSelector(state => state?.adminReducer?.buUsers || [])
    const [loading, setLoading] = useState(false)
    const [reports, setReports] = useState([])
    const [selectedUser, setSelectedUser] = useState(buUsers?.[0]?._id || null)
    const [selectedUserDetail, setSelectedUserDetail] = useState(buUsers?.[0] || null)
    const [payDate, setPayDate] = useState({})
    const [spin, setSpin] = useState(false)
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    useEffect(() => {
        allAdminData?.getBusinessUser(adminActions, user?._id)
    }, [])

    useEffect(() => {
        getPaymentReport()
        setSelectedUserDetail(buUsers?.filter((v) => v?._id === selectedUser)[0])
    }, [selectedUser])

    const getPaymentReport = () => {
        setLoading(true)
        let obj = {
            _id: user?._id,
            userId: selectedUser
        }
        axios.post(ADMIN.GET_PAYMENT_REPORT, obj)
            .then((res) => {
                const { data } = res
                setReports(data?.data || [])
                if (data?.data?.length === 1) {
                    setPayDate({
                        date1: data?.data[0]?.date1,
                        date2: data?.data[0]?.date2
                    })
                } else if (data?.data?.length > 1) {
                    setPayDate({
                        date1: data?.data[-1]?.date1,
                        date2: data?.data[0]?.date2
                    })
                }
                setLoading(false)
            })
            .catch((e) => {
                console.log('e', e)
                setLoading(false)
                errorNotification(assignMessage(e?.message, language?.language))

            })
    }

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.ReportNumber}</p>,
            dataIndex: ['reportNumber'],
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.DownloadReport}</p>,
            key: 'download',
            render: (e) => <a onClick={() => {
                createPaymentReportPdf(e, selectedUserDetail, 'download')
            }
            }>{selectedLanguage?.Download}</a>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.CreateDate}</p>,
            dataIndex: 'created',
            key: 'created',
            render: (e) => <>{moment(e)?.format('YYYY-MM-DD')}</>
        }
    ]

    const payment = () => {
        if (!payDate?.date1) {
            return errorMessage(selectedLanguage?.NoReportisGenerated)
        }
        setSpin(true)
        let obj = {
            adminId: user?._id,
            date1: payDate?.date1,
            date2: payDate?.date2,
            userId: selectedUser
        }
        axios.post(ADMIN.UPDATE_PAYMENT_STATUS, obj)
            .then((res) => {
                const { data } = res
                setSpin(false)
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                } else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                console.log('e', e)
                setSpin(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }
    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote3}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>
                    <h3 className='new-exam-title'>{selectedLanguage?.txt_payment_reports}</h3>
                    <Select
                        style={{ padding: '0px', width: '200px', margin: '15px', marginRight: '0px' }}
                        showArrow
                        allowClear={false}
                        defaultValue={selectedUser}
                        onChange={setSelectedUser}
                        className='padding-none-class-for-showsearch-selct'
                        placeholder={selectedLanguage?.SelectUser}
                        showSearch
                        optionFilterProp='children'
                        filterOption={checkSelectOption}
                    >
                        {buUsers?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.fullName)} ${v?.email}`}</Option>)}
                    </Select>
                    <Button type='primary' style={{ marginLeft: '15px' }} onClick={payment} loading={spin}>{selectedLanguage?.Pay}</Button>
                    <div className='new-layout-table-upper-div'>
                        <Table
                            style={{ marginTop: '30px' }}
                            className='new-layout-table'
                            locale={customLocale}
                            loading={loading}
                            columns={columns}
                            dataSource={reports}
                            pagination={{ hideOnSinglePage: true }}
                            scroll={{ x: 800 }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentReport