import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { Table, Input, Button, Space } from 'antd'
import { allUserData, createInvoicePdf } from '../../utils/helpers'
import { GET } from '../../utils/apis'
import ViewCard from '../Admin/Cards/ViewCard'
import axios from 'axios'

const Invoices = (props) => {
    const { user, userActions, selectedLanguage } = props
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const [showInvoice, setShowInvoice] = useState(false)
    const [invoice, setInvoice] = useState({})
    const searchInput = useRef(null)
    const [invoices, setInvoices] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        allUserData?.getAllInvoice(userActions, user?._id)
        getInoices()
    }, [])

    const getInoices = () => {
        setLoading(true)
        axios.get(`${GET.GET_USER_DOUBLE_CHANCE_INVOICE}/${user._id}`)
            .then((res) => {
                const { data } = res
                // console.log('data', data?.data)
                setInvoices(data?.data || [])
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
            })
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        {selectedLanguage?.txt_Search}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                        {selectedLanguage?.txt_Reset}
                    </Button>
                    <Button
                        type='link'
                        size='small'
                        onClick={() => {
                            confirm({ closeDropdown: false })
                            setSearchText(selectedKeys[0])
                            setSearchedColumn(dataIndex)
                        }}
                    >
                        {selectedLanguage?.txt_Filter}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100)
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const viewInvoice = (fileUrl) => {
        setInvoice({
            designName: 'Invoice',
            fileUrl
        })

        setShowInvoice(true)
    }

    const onCancel = () => {
        setInvoice({})
        setShowInvoice(false)
    }

    let columns = [
        {
            title: selectedLanguage?.title_Name,
            dataIndex: 'subscriptionName',
            key: 'subscriptionName',
            // sorter: true,
            ...getColumnSearchProps('subscriptionName')
        },
        {
            title: selectedLanguage?.TotalAmount,
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            // sorter: true,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {e}</p>
        },
        {
            title: selectedLanguage?.ViewInvoice,
            key: 'invoice',
            render: (e) => <a onClick={() => {
                createInvoicePdf(e, user, 'view', viewInvoice)
            }}>{selectedLanguage?.View}</a>
        },
        {
            title: selectedLanguage?.DownloadInvoice,
            key: 'download',
            render: (e) => <a onClick={() => {
                createInvoicePdf(e, user, 'download', viewInvoice)
            }
            }>{selectedLanguage?.Download}</a>
        },
        {
            title: selectedLanguage?.Date,
            dataIndex: 'created',
            key: 'created'
        }
    ]

    return (
        <>
            {/* <div className='user-table'> */}
            <Table
                loading={loading}
                columns={columns}
                dataSource={invoices}
                pagination={{ hideOnSinglePage: true }}
                scroll={{ x: 800 }}
            />
            {/* </div> */}
            {showInvoice ? <ViewCard card={invoice} onCancel={onCancel} showData={showInvoice} {...props}/> : null}
        </>
    )
}

export default Invoices