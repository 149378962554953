import { DeleteFilled } from '@ant-design/icons'
import { Button, Modal, Popconfirm, Space, Spin, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { allPaths } from '../../utils/constants'
import { allUserData } from '../../utils/helpers'

const BuDayDealsQuestions = (props) => {
    const { user, userActions, selectedLanguage, lanKorCondition, history } = props
    const allQuestions = useSelector(state => state?.userReducer?.guestDaydealsQuestion || [])
    console.log('allQuestions', allQuestions)
    useEffect(() => {
        allUserData?.getGuestDayDealsQuestion(userActions, user?._id)
    }, [])


    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.Created}</p>,
            dataIndex: 'created',
            key: 'Created',
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_Title}</p>,
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a?.title?.localeCompare(b?.title),
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.View}</p>,
            render: (e) => {
                return (
                    <Space>
                        <Button
                            type='primary'
                            target={'_blank'}
                            onClick={() => {
                                history.push({
                                    pathname: allPaths?.FORM,
                                    state: { formData: e }
                                })
                            }} >
                            {selectedLanguage?.btn_Open}
                        </Button>
                        &nbsp;&nbsp;
                    </Space>
                )
            }
        },
    ]

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote10}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>
                    <h3 className='new-exam-title'>{'Daydeals Question'}</h3>

                    <div className='new-layout-table-upper-div'>
                        <Table
                            className='new-layout-table'
                            columns={columns}
                            dataSource={allQuestions}
                            pagination={{ hideOnSinglePage: true }}
                            scroll={{ x: 'auto' }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuDayDealsQuestions