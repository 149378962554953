import { ScanOutlined } from '@ant-design/icons'
import { Button, Form, Input, InputNumber, Modal, Select, Tooltip, Typography } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { QrReader } from 'react-qr-reader'
import { POST } from '../../utils/apis'
import { ageOptions, constraints } from '../../utils/constants'
import { assignMessage, checkSelectOption, convertRoundNumber, errorMessage, stringLimiter, successMessage, validateTime, warningMessage } from '../../utils/helpers'

const { Title } = Typography
const { Option } = Select

const BuyProduct = (props) => {
    const { onCancel, product, user, calcSoldProducts, language, selectedLanguage } = props
    const isEnglish = language?.language === 'english'
    const [loading, setLoading] = useState(false)
    const [showMore, setShowMore] = useState(false)
    const [useCoupon, setUseCoupon] = useState(false)
    const [visibleQR, setVisibleQR] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)
    const [coupon, setCoupon] = useState('')
    const [QRCode, setQRCode] = useState(null)
    const [validateLoading, setValidateLoading] = useState(false)
    const [validateData, setValidateData] = useState({})
    const [totalPrice, setTotalPrice] = useState(0)
    const [priceAge, setPriceAge] = useState({})
    const [dayQuantity, setDayQuantity] = useState(product?.reservation?.settings?.reduce((sum, { maximumProduct }) => sum + maximumProduct, 0) || 0)
    const [form] = Form.useForm()

    useEffect(() => {
        if (QRCode) {
            setCoupon(QRCode)
            setQRCode(null)
            setVisibleQR(false)
        }
    }, [QRCode])

    const removeUndefinedKeys = (obj) => {
        Object.keys(obj).forEach(key => obj[key] ? {} : delete obj[key])
        return obj
    }

    const onFinish = (values) => {

        if (useCoupon && (coupon !== validateData?.couponCode)) {
            return warningMessage(selectedLanguage?.warningMessage_ValidateyourCouponPlease)
        }

        if (product?.agePrice) {
            values.ageQuantity = removeUndefinedKeys(values.quantity)

            let sumOfQuantity = Object.values(values.quantity).reduce((cur, acc) => cur + acc, 0)

            if (sumOfQuantity > (quantityOptions()?.maxProduct || noneQuantityOptions()?.maximumProduct)) {
                return errorMessage(`${selectedLanguage?.txt_MaxQuantityis} ${(quantityOptions()?.maxProduct || noneQuantityOptions()?.maximumProduct)}`)
            }

            if (!sumOfQuantity) {
                return errorMessage(selectedLanguage?.warningMessage_EnterQuantity)
            }
            values.quantity = sumOfQuantity
            values.agePrice = priceAge
            values.price = totalPrice
        }

        if (product?.groupPrice && !product?.agePrice) {
            let finalPrice = []
            finalPrice = product?.price?.filter((v, i) => (values?.quantity === v?.start || values?.quantity === v?.last || (values?.quantity > v?.start && values?.quantity < v?.last)))

            if (!finalPrice?.length) {
                finalPrice = values?.quantity < product?.price[0]?.start ? [product?.price[0]] : [product?.price[product?.price?.length - 1]]
            }
            values.price = finalPrice?.[0]?.price
        } else if (!product?.groupPrice && !product?.agePrice) {
            values.price = product?.price[0]?.price
        }
        // return true


        if (validateData?._id && !(validateData?.amount < (values?.price * values?.quantity))) {
            return warningMessage(selectedLanguage?.warningMessage_CouponAmountshouldbeLess)
        }
        setLoading(true)
        values.age = product?.agePrice
        values.product = product?._id
        values.buser = product?.userId?._id
        values.userId = user?._id
        values.productName = product?.name
        values.doubleChances = product?.doubleChances
        values.reservationId = product?.reservation?._id
        values.feeCost = product?.productType?.feeCost
        values.reservationTime = values.reservationTime || product?.reservation?.interval

        useCoupon && (values.coupon = values?.coupon)
        useCoupon && (values.couponId = validateData?._id)
        useCoupon && (values.couponAmount = validateData?.amount)

        console.log('values===>', values)


        axios.post(POST.GUEST_BUY_PRODUCT, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    window.location.href = data?.redirectUrl
                    return
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const timeRange = () => {
        if (product?.timeRange?.length === 3) {
            return product?.timeRange[0] + ', ' + product?.timeRange[1] + `${selectedLanguage?.txt_and}` + product?.timeRange[2]
        } else if (product?.timeRange?.length === 2) {
            return product?.timeRange[0] + `${selectedLanguage?.txt_and}` + product?.timeRange[1]
        } else {
            return product?.timeRange[0]
        }
    }

    const dateOptions = () => {
        if (product?.reservation?.interval !== 'none') {
            return product?.reservation?.settings.map((v, i) => {
                return {
                    date: moment(v?.date).format('YYYY-MM-DD'),
                    availableProduct: v?.maximumProduct,
                    shiftsAvailableProduct: v?.shifts?.reduce((sum, { maxProduct }) => sum + maxProduct, 0),
                    _id: v?._id
                }
            })?.filter((v, i) => v?.availableProduct && v?.shiftsAvailableProduct && !moment(v?.date).utcOffset(0, true).isBefore(moment().add(1, 'days'), 'day'))
        } else {
            return product?.reservation?.settings.map((v, i) => {
                return {
                    date: moment(v?.date).format('YYYY-MM-DD'),
                    availableProduct: v?.maximumProduct,
                    _id: v?._id
                }
            })?.filter((v, i) => v?.availableProduct && !moment(v?.date).utcOffset(0, true).isBefore(moment().add(1, 'days'), 'day'))
        }

    }
    const timeOptions = () => {
        let tempData = _.find(product?.reservation?.settings, { _id: selectedDate })
        return tempData?.shifts?.map((v, i) => {
            return {
                startTime: v?.startTime,
                availableProduct: v?.maxProduct,
                _id: v?._id
            }
        })?.filter((v, i) => v?.availableProduct)
    }

    const quantityOptions = () => {
        let tempData = _.find(product?.reservation?.settings, { _id: selectedDate })
        let quantity = product?.reservation?.interval !== 'none' ? _.find(tempData?.shifts, { _id: selectedTime }) : tempData
        return quantity
    }
    const noneQuantityOptions = () => {
        let tempData = _.find(product?.reservation?.settings, { _id: selectedDate })
        return tempData
    }

    const handleCouponInput = (e) => {
        let newCode = e?.target?.value?.replace(/[^A-Za-z0-9]/g, '')
        newCode = newCode?.replace(/(.{3})/g, '$1-')
        newCode = newCode?.replace(/-$/, '')
        form.setFieldsValue({
            coupon: newCode
        })
        setCoupon(newCode)
    }
    const scanQr = async qr => {
        let code = qr?.text

        if (code) {
            setQRCode(code)
        }
    }

    const handleCancel = () => {
        setVisibleQR(false)
        setQRCode(null)
        setCoupon(coupon)
    }
    const validateCoupon = () => {
        setValidateLoading(true)
        let obj = {
            userId: product?.userId?._id,
            couponCode: coupon,
            productId: product?._id,
            maxDiscount: product?.maxDiscount,
            joinDiscount: product?.joinDiscount,

        }

        axios.post(POST.VALIDATE_COUPON, obj)
            .then((res) => {
                const { data } = res
                setValidateLoading(false)
                if (data?.success) {
                    setValidateData(data?.data)
                    setTotalPrice(totalPrice - data?.data?.amount)
                    successMessage(assignMessage(data?.message, language?.language))
                }
                else {
                    setValidateData({})
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                console.log('e', e)
                setValidateLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const getPrice = (couponData) => {
        let quantity = form.getFieldValue('quantity')
        let total = 0
        if (product?.groupPrice) {
            let finalPrice = []
            finalPrice = product?.price?.filter((v, i) => (quantity === v?.start || quantity === v?.last || (quantity > v?.start && quantity < v?.last)))

            if (!finalPrice?.length) {
                finalPrice = quantity < product?.price[0]?.start ? [product?.price[0]] : [product?.price[product?.price?.length - 1]]
            }
            total = finalPrice?.[0]?.price * quantity
        } else {
            total = product?.price?.[0]?.price * quantity
        }

        if (couponData?._id) {
            total = total - couponData?.amount
        }
        setTotalPrice(total)

    }
    const getAgePrice = (couponData) => {
        let quantity = form.getFieldValue('quantity')
        quantity = removeUndefinedKeys(quantity)
        let total = 0
        let sumOfQuantity = Object.values(quantity).reduce((cur, acc) => cur + acc, 0)

        if (sumOfQuantity > (quantityOptions()?.maxProduct || noneQuantityOptions()?.maximumProduct)) {
            return errorMessage(`${selectedLanguage?.txt_MaxQuantityis} ${(quantityOptions()?.maxProduct || noneQuantityOptions()?.maximumProduct)}`)
        }

        let totalPrices = []
        let agePrice = {}
        Object.keys(quantity).forEach((x) => {
            let finalPrice = []
            finalPrice = product?.price?.filter((v, i) => (quantity[x] === v?.[x]?.start || quantity[x] === v?.[x]?.last || (quantity?.[x] > v?.[x]?.start && quantity?.[x] < v?.[x]?.last)))
            if (!finalPrice?.length) {
                finalPrice = quantity?.[x] < product?.price[0]?.[x]?.start ? [product?.price[0]] : [product?.price[product?.price?.length - 1]]
            }
            total = finalPrice?.[0]?.[x]?.price * quantity?.[x]
            agePrice[x] = finalPrice?.[0]?.[x]?.price
            totalPrices.push(total)
        })
        let totalAmount = totalPrices?.reduce((c, a) => c + a, 0)
        if (couponData?._id) {
            totalAmount = totalAmount - couponData?.amount
        }
        setPriceAge(agePrice)
        setTotalPrice(totalAmount)
    }

    const checkPrice = (coupon) => {
        if (coupon) {
            setValidateData({})
        }
        setUseCoupon(!coupon)

        if (product?.agePrice) {
            getAgePrice({})
        } else {
            getPrice({})
        }
    }
    return (
        <div >
            {visibleQR ?
                <Modal
                    title={<center><Title level={4}>{selectedLanguage?.ActivateQR}</Title></center>}
                    open={visibleQR}
                    onCancel={handleCancel}
                    footer={[
                        <Button key='back' onClick={handleCancel} className='form-input'>
                            {selectedLanguage?.Cancel}
                        </Button>
                    ]}
                >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <h4>{selectedLanguage?.ScanyourQr}</h4>
                        <QrReader
                            delay={2000}
                            onError={e => errorMessage(selectedLanguage?.WebCamNotFound)}
                            constraints={constraints}
                            onResult={scanQr}
                            style={{ width: '100%' }}
                        />
                    </div>
                </Modal> : null}


            <div >
                {showMore ?

                    <div className='main-div-new-style'>
                        <div className='div-flex-back-name'>
                            <div className='screen-back-icion' onClick={() => { setShowMore(false) }}>
                                <IoIosArrowBack size={32} />
                            </div>
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <div className='product_img' style={{
                                display: ' flex',
                                justifyContent: 'flex-start',
                                flexWrap: 'wrap'
                            }}>
                                {product?.otherImages?.length ? product?.otherImages?.map((v, i) => {
                                    return (
                                        <div style={{
                                            width: '31.5%',
                                            margin: '0.5%',
                                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                        }}>
                                            <img
                                                style={{
                                                    borderRadius: '4px',
                                                    width: '99%',
                                                    height: '99%',
                                                }} alt='example' src={v} />
                                        </div>
                                    )
                                }) : null
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <div className='main-div-new-style'>
                        <div className='div-flex-back-name'>
                            <div className='screen-back-icion' onClick={onCancel}>
                                <IoIosArrowBack size={32} />
                            </div>
                            <div className='height-35'>
                                <p className='screen-heading'>{stringLimiter(product?.name) || '-----'}</p>
                            </div>
                        </div>
                        <div className='buyProduct_imges_main_div'>
                            <div className='buyProduct_1st_img_div'>
                                <img onClick={() => { setShowMore(true) }} alt='example' className='buyProduct_1st_img' src={product?.imageUrl} />
                            </div>
                            <div className='buyProduct_2nd_img_div'>
                                <div className='buyProduct_2nd_img_div_inner_flex'>
                                    <div className='img_div_inner_flex_1st' >
                                        <img onClick={() => { setShowMore(true) }} className='img_div_img' alt='example' src={product?.otherImages[0]} />
                                        <img onClick={() => { setShowMore(true) }} className='img_div_img' alt='example' src={product?.otherImages[1]} />
                                    </div>
                                    <img onClick={() => { setShowMore(true) }} className='img_div_inner_flex_2nd' alt='example' src={product?.otherImages[2]} />
                                </div>
                            </div>
                        </div>
                        {/* ________________________INFO________________________ */}
                        <div style={{ margin: '10px' }}>
                            <p style={{
                                fontSize: 18,
                                fontWeight: 'bold',
                                color: 'black',
                                textAlign: 'start',
                                margin: '0px'
                            }}>{product?.name || '------'}</p>
                            <p style={{
                                color: 'grey',
                                textAlign: 'start',
                                lineBreak: 'anywhere',
                            }}>
                                {product?.description || '-------------------------------'}
                            </p>
                            <p style={{
                                fontSize: 18,
                                fontWeight: 'bold',
                                color: 'black',
                                margin: '0px'
                            }}>{selectedLanguage?.Text_OtherDetails || '------'}</p>
                            <div>
                                <div>{selectedLanguage?.Type}: {product?.productType?.typeName}</div>
                                <div>{selectedLanguage?.TimeRange}: {timeRange()}</div>
                                {!product?.groupPrice && !product?.agePrice ?
                                    <div>{selectedLanguage?.Price}: €{' ' + product?.price?.[0]?.price}</div>
                                    : !product?.agePrice && product?.groupPrice ?
                                        <div>{selectedLanguage?.Price}:
                                            <ul>
                                                {product?.price?.map((v, i) => {
                                                    return (<li> {`(${v?.start}-${v?.last}): € ${v?.price}`} </li>)
                                                })}
                                            </ul>
                                        </div>
                                        : !product?.groupPrice && product?.agePrice ?
                                            <div>
                                                <div>
                                                    {selectedLanguage?.Price}:
                                                </div>
                                                <ul>
                                                    {ageOptions?.map((v) =>
                                                        (<li>{v} yrs: € {product?.price[0]?.[v]?.price}</li>)
                                                    )}
                                                </ul>
                                            </div>
                                            :
                                            <div>
                                                <div>
                                                    {selectedLanguage?.Price}:
                                                </div>
                                                <ul>
                                                    {ageOptions?.map((v) =>
                                                    (<li>{v} yrs:
                                                        <ul> {
                                                            product?.price?.map((y) => y?.[v])?.map((x) => (
                                                                <li> {`(${x?.start}-${x?.last}): € ${x?.price}`} </li>
                                                            ))
                                                        }
                                                        </ul>
                                                    </li>)
                                                    )}
                                                </ul>
                                            </div>
                                }
                                <div>{selectedLanguage?.JoinedDiscount} : {product?.joinDiscount ? selectedLanguage?.True : selectedLanguage?.False}</div>
                                {
                                    product?.joinDiscount ?
                                        <div>{selectedLanguage?.MaximumDiscount}: €{' ' + product?.maxDiscount}</div>
                                        : null
                                }
                                <div>{selectedLanguage?.Tax}: {product?.tax}%</div>
                                <div>{selectedLanguage?.Sold}: {calcSoldProducts(product?.reservation?.settings || [])}</div>
                                <div>{selectedLanguage?.Country}: {product?.userId?.country}</div>
                                <div>{selectedLanguage?.Provincie}: {product?.userId?.provincie}</div>
                                <div>{selectedLanguage?.City}: {product?.userId?.city}</div>
                                <div>{selectedLanguage?.BusinessName} : {product?.userId?.businessName}</div>
                            </div>
                            <br />
                            <Form
                                name='form'
                                form={form}
                                onFinish={onFinish}
                                layout={'vertical'}
                            >
                                <div className='form-two-input-flex'>
                                    <div className='form-two-input-flex-inner-input'>
                                        <Form.Item
                                            name='reservationDate'
                                            label={selectedLanguage?.ReservationDate}
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: true,
                                                    message: selectedLanguage?.PleaseSelectDate
                                                }
                                            ]}
                                        >
                                            <Select
                                                className='form-input-new-style'
                                                showSearch
                                                optionFilterProp='children'
                                                filterOption={checkSelectOption}
                                                onChange={(e) => { setSelectedDate(e?.toString()); setSelectedTime(null); form.setFieldsValue({ reservationTime: null, quantity: null }) }} placeholder={selectedLanguage?.SelectDate}>
                                                {dateOptions()?.map((v, i) => {
                                                    return (
                                                        <Option key={i} value={v?._id}>{v?.date + ` ${selectedLanguage?.ProductsAvailable}:` + v?.availableProduct}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className='form-two-input-flex-inner-input'>

                                        {product?.reservation?.interval !== 'none' ?
                                            < Form.Item
                                                name='reservationTime'
                                                label={selectedLanguage?.ReservationTime}
                                                hasFeedback
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: selectedLanguage?.PleaseSelectReservationDate
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    className='form-input-new-style'
                                                    showSearch
                                                    optionFilterProp='children'
                                                    filterOption={checkSelectOption}
                                                    value={selectedTime} disabled={!form?.getFieldValue('reservationDate')}
                                                    onChange={(e) => { setSelectedTime(e?.toString()); form.setFieldValue('quantity', null) }}
                                                    placeholder={selectedLanguage?.SelectTime}>
                                                    {timeOptions()?.map((v, i) => {
                                                        return (
                                                            <Option key={i} value={v?._id}>{validateTime(v?.startTime) + ` ${selectedLanguage?.ProductsAvailable}:` + v?.availableProduct}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Form.Item>
                                            : null
                                        }
                                    </div>
                                </div>
                                {!product?.agePrice ?
                                    <div className='form-two-input-flex-inner-input' style={{ width: '100%', }}>
                                        <Form.Item
                                            name='quantity'
                                            label={selectedLanguage?.Quantity}
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: true,
                                                    message: selectedLanguage?.PleaseEnterQuantity
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                disabled={(product?.reservation?.interval !== 'none' && !selectedTime) || !dayQuantity}
                                                className='form-input-new-style'
                                                placeholder={selectedLanguage?.InputYourQuantityHere}
                                                min={0}
                                                precision={0}
                                                max={quantityOptions()?.maxProduct || noneQuantityOptions()?.maximumProduct}
                                                style={{ width: '100%' }}
                                                step={1}
                                                type='number'
                                                pattern='[0-9]*'
                                                onChange={() => getPrice(validateData)}
                                            />
                                        </Form.Item>
                                    </div>
                                    :
                                    ageOptions?.map((v) => (
                                        <div className='form-two-input-flex-inner-input' style={{ width: '100%', }}>
                                            <Form.Item
                                                name={['quantity', v]}
                                                label={selectedLanguage?.Quantity + ' ' + `for ${v} yrs`}
                                                hasFeedback
                                            >
                                                <InputNumber
                                                    disabled={(product?.reservation?.interval !== 'none' && !selectedTime) || !dayQuantity}
                                                    className='form-input-new-style'
                                                    placeholder={selectedLanguage?.InputYourQuantityHere}
                                                    min={0}
                                                    precision={0}
                                                    max={quantityOptions()?.maxProduct || noneQuantityOptions()?.maximumProduct}
                                                    style={{ width: '100%' }}
                                                    step={1}
                                                    type='number'
                                                    pattern='[0-9]*'
                                                    onChange={() => getAgePrice(validateData)}
                                                />
                                            </Form.Item>
                                        </div>
                                    ))
                                }
                                {(product?.reservation?.interval !== 'none' && selectedTime) ? <p>{selectedLanguage?.MaxQuantityIs} :{quantityOptions()?.maxProduct}</p> : (product?.reservation?.interval === 'none' && noneQuantityOptions()?.maximumProduct) ? <p>{selectedLanguage?.MaxQuantityIs} :{noneQuantityOptions()?.maximumProduct}</p> : null}

                                <Button style={{ left: -5 }} type='link' onClick={() => checkPrice(useCoupon)}> {useCoupon ? selectedLanguage?.DontWantToUseCoupon : selectedLanguage?.WantToUseCoupon}  </Button>
                                {useCoupon ?
                                    <>
                                        <div className='form-two-input-flex-inner-input' style={{ width: '100%', padding: '8px' }}>
                                            <Form.Item
                                                name='coupon'
                                                label={selectedLanguage?.CouponCode}
                                                hasFeedback
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: selectedLanguage?.error_Entercouponcodeplease
                                                    }
                                                ]}
                                            >
                                                <Input.Group compact>
                                                    <Input
                                                        suffix={
                                                            <Tooltip title={selectedLanguage?.ScanyourCoupon}>
                                                                <Button onClick={() => setVisibleQR(true)} type='primary' icon={<ScanOutlined />} />
                                                            </Tooltip>
                                                        }
                                                        style={{ maxWidth: 300 }}
                                                        className='form-input-new-style'
                                                        autoFocus
                                                        // required
                                                        maxLength={11}
                                                        value={coupon}
                                                        onChange={handleCouponInput}
                                                        placeholder='e.g: 456-786-ab6'
                                                    />
                                                </Input.Group>
                                            </Form.Item>
                                        </div>

                                        <Button disabled={!coupon?.length || validateData?.couponCode === coupon} onClick={validateCoupon} loading={validateLoading} style={{ top: -15 }} type='primary'> {selectedLanguage?.Validate} </Button>

                                        {validateData?._id ? <p>{selectedLanguage?.YourCouponamountIs} : € {validateData?.amount}</p> : null}
                                    </>
                                    : null
                                }

                                <br />

                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', background: 'white' }}>
                                    <div>
                                        <Form.Item>
                                            <Button
                                                style={{ height: '40px', width: '160px', marginTop: '10px' }}
                                                className='addproduct-text'
                                                htmlType='submit'
                                                type='primary'
                                                loading={loading}
                                            >
                                                {selectedLanguage?.Buy}
                                            </Button>
                                        </Form.Item>
                                    </div>
                                    <div>
                                        <p style={{ textAlign: 'start' }}>{selectedLanguage?.warningMessage_TotalAmountIs}
                                            <br />
                                            € {convertRoundNumber(totalPrice) || null}</p>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                }
            </div >
        </div >
    )
}

export default BuyProduct