import { Button, Image, Select, Table } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN } from '../../../utils/apis'
import { allAdminData, checkSelectOption, convertTitle, validateTime } from '../../../utils/helpers'
import Details from '../../Financial/Details'

const { Option } = Select


const AdminVouchers = (props) => {
    const { user, adminActions, selectedLanguage } = props
    const buUsers = useSelector(state => state?.adminReducer?.buUsers || [])
    const [loading, setLoading] = useState(false)
    const [vouchers, setVoucher] = useState([])
    const [details, setDetails] = useState({})
    const [selectedUser, setSelectedUser] = useState(buUsers?.[0]?._id || null)
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };

    useEffect(() => {
        allAdminData?.getBusinessUser(adminActions, user?._id)
    }, [])

    useEffect(() => {
        getVouchers()
    }, [selectedUser])

    const getVouchers = () => {
        setLoading(true)
        axios.get(`${ADMIN.GET_ADMIN_VOUCHER}/${selectedUser}`)
            .then((res) => {
                const { data } = res
                setVoucher(data?.data || [])
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.Image}</p>,
            align: 'center',
            dataIndex: ['product', 'imageUrl'],
            render: (e) =>
                <Image src={e} style={{ marginRight: 10, width: 'auto', height: 60 }} shape='square' />
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.ProductName}</p>,
            dataIndex: ['product', 'name'],
            key: 'voucherName',
            sorter: (a, b) => a?.product?.name?.length - b?.product?.name?.length,
            showSorterTooltip: false,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.TotalAmount}</p>,
            dataIndex: ['invoice', 'totalAmount'],
            align: 'center',
            sorter: (a, b) => a?.invoice?.totalAmount - b?.invoice?.totalAmount,
            showSorterTooltip: false,
            render: (e) => <p style={{ marginBottom: 0 }}>€ {e}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Status}</p>,
            align: 'center',
            render: (e) => <>{e?.isCancel ? <p style={{ color: 'red', marginBottom: 0 }}>{selectedLanguage?.status_CANCELLED}</p> : e?.isClaim ? <p style={{ color: '#4CAF50', marginBottom: 0 }}>{selectedLanguage?.Claimed}</p> : <p style={{ color: '#007AFF', marginBottom: 0 }}>{selectedLanguage?.Reserved}</p>}</>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.VoucherDate}</p>,
            dataIndex: 'voucherDate',
            sorter: (a, b) => moment(a?.voucherDate).unix() - moment(b?.voucherDate).unix(),
            showSorterTooltip: false,
            render: (e) => <>{moment(e).format('YYYY-MM-DD')}</>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.VoucherTime}</p>,
            dataIndex: 'voucherTime',
            align: 'center',
            render: (e) => validateTime(e)
        },
        {
            key: 'action',
            render: (e) => (<Button onClick={() => setDetails(e)}>{selectedLanguage?.Details}</Button>)
        }

    ]

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote7}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>
                    <h3 className='new-exam-title'>{selectedLanguage?.Vouchers}</h3>
                    {details?._id ?
                        <Details onBack={() => setDetails({})} details={details} buUser={details?.buUser} invoice={details?.invoice} product={details?.product}  {...props} />
                        :
                        <>
                            <Select
                                showArrow
                                allowClear={false}
                                defaultValue={selectedUser}
                                onChange={(e) => setSelectedUser(e)}
                                className='form-input'
                                placeholder={selectedLanguage?.SelectUser}
                                style={{ width: 200, margin: '12px', marginBottom: '0px', marginTop: '20px' }}
                                showSearch
                                optionFilterProp='children'
                                filterOption={checkSelectOption}
                            >
                                {buUsers?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.fullName)} ${v?.email}`}</Option>)}
                            </Select>
                            <br />
                            <div className='new-layout-table-upper-div'>
                                <Table
                                    style={{ marginTop: '30px' }}
                                    className='new-layout-table'
                                    columns={columns}
                                    dataSource={vouchers}
                                    locale={customLocale}
                                    loading={loading}
                                    pagination={{ hideOnSinglePage: true }}
                                    scroll={{ x: 800 }}
                                />
                            </div>
                        </>
                    }
                </div>
            </div>
        </div >
    )
}

export default AdminVouchers