import { Table } from 'antd'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { allUserData } from '../../utils/helpers'
import NoData from "../../assets/noDataImae.png"



// joined
const JoinedTable = (props) => {
    const { user, userActions, history, selectedLanguage, language } = props
    const joined = useSelector(state => state?.userReducer?.joined || [])
    const customLocale = {
         emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle:selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    useEffect(() => {
        allUserData?.getJoinedGroup(userActions, user?._id)
    }, [])

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.tableColumn_Group_Name}</p>,
            dataIndex: 'groupName',
            key: 'name',
            sorter: true,
            showSorterTooltip: false,
            ellipsis: true,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Private}</p>,
            dataIndex: 'privateGroup',
            key: 'privateGroup',
            sorter: true,
            showSorterTooltip: false,
            ellipsis: true,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}> {e ? selectedLanguage?.True : selectedLanguage?.False}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.table_Restaurant}</p>,
            dataIndex: 'postalCode',
            key: 'postalCode',
            ellipsis: true,
            render: (e) => {
                return (<>
                    <p>{e[0]?.postalcodeStart}-{e[0]?.postalcodeEnd}</p>
                    {e[1] ? <> <br />
                        <p>{e[1]?.postalcodeStart}-{e[1]?.postalcodeEnd}</p> </> : null}
                </>)
            }
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.tableColumn_TotalGroupMembers}</p>,
            dataIndex: 'totalMember',
            key: 'totalMember',
            sorter: true,
            ellipsis: true,
            showSorterTooltip: false,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Created}</p>,
            dataIndex: 'created',
            key: 'created',
            sorter: true,
            showSorterTooltip: false,
            ellipsis: true,
            render: (e) => <span>{moment(e).utcOffset(0, true).format('DD-MM-YYYY')}</span>

        },
    ]

    const data = joined.map((v, i) => {
        return {
            _id: v?._id,
            groupId: v?.groupId?._id,
            groupName: v?.groupId?.name,
            totalMember: v?.groupId?.userId?.length,
            userId: v?.userId?._id,
            privateGroup: v?.privateGroup,
            created: v?.created,
            postalCode: v?.groupId?.postalCode

        }
    })

    return (
        <div className='new-layout-table-upper-div' style={{marginLeft:'0px',marginRight:'0px'}}>
            <Table
                className='new-layout-table'
                locale={customLocale}
                columns={columns}
                dataSource={data}
                pagination={{ hideOnSinglePage: true }}
                scroll={{ x: 800 }}
            />
        </div>


    )
}

export default JoinedTable