import { Button, Descriptions, Modal, Popconfirm, Spin, Table } from 'antd';
import axios from 'axios';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { POST } from '../../utils/apis';
import { assignMessage, convertTitle, errorMessage, successMessage } from '../../utils/helpers';
import NoData from "../../assets/noDataImae.png"

const Draws = (props) => {
    const { user, userActions, drawData, loading1, selectedLanguage, language } = props
    const [loading, setLoading] = useState(false)
    const [lotteryInfo, setLotteryInfo] = useState({})
    const [isModalVisible, setIsModalVisible] = useState(false)

    const showModal = () => {
        setIsModalVisible(true);
    };
    const customLocale = {
         emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle:selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };

    // useEffect(() => {
    //     // getAllUserData(user?._id, userActions)
    //     setfilteredArray(_.uniqBy(makArray, 'text'))

    // }, [drawData])

    const request = (e) => {
        setLoading(true)
        let valObj = { guestId: `${user?._id}`, userId: e?.userId?._id, lotteryCode: e?.lotteryCode, }

        axios.post(`${POST.WITH_DRAW_REQUEST}`, valObj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setLoading(false)
                    successMessage(assignMessage(data?.message, language?.language))
                    // console.log('dataaaaget', data)
                } else {
                    setLoading(false)
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
            })
    }


    const columns = [
        // {
        //     title: 'Lottery Name',
        //     dataIndex: 'name',
        //     key: 'name',
        // },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_Prize}</p>,
            dataIndex: 'prize',
            key: 'prize',
            // sorter: (a, b) => a.prize - b.prize,
            render: (e) => <img src={`${e}`} style={{ marginRight: 10, width: 86, height: 60 }} />
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_LotteryCode}</p>,
            dataIndex: 'lotteryCode',
            key: 'lotteryCode'
        },
        // {
        //     title: selectedLanguage?.title_LotteryType,
        //     dataIndex: 'lotteryType',
        //     key: 'lotteryType',
        //     sorter: (a, b) => a.lotteryType - b.lotteryType,
        // },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_WithdrawDate}</p>,
            dataIndex: 'withDraw',
            key: 'withDraw',
            sorter: (a, b) => a.withDraw - b.withDraw,
            showSorterTooltip: false,
            render: (e) => <a>{e ? selectedLanguage?.True : selectedLanguage?.False}</a>

            // filters: filteredArray,
            // onFilter: (value, record) => record.date.indexOf(value) === 0
        }
        ,
        {
            // title: 'Action',
            // dataIndex: 'ac',
            key: 'userId',
            render: (e) => (
                <>
                    <Button onClick={() => {
                        showModal()
                        // console.log(e, 'ar')
                        setLotteryInfo(e)
                    }}>
                        {selectedLanguage?.button_Detail}
                    </Button>
                    &nbsp;&nbsp;&nbsp;

                    {
                        e?.withDraw ?
                            <Button onClick={() => {
                            }}>
                                {selectedLanguage?.Claimed}
                            </Button> :
                            <Popconfirm
                                title={selectedLanguage?.popupMessage_WithDrawthisLottery}
                                okText={selectedLanguage?.button_DrawRequest}
                                cancelText={selectedLanguage?.Cancel}
                                // okButtonProps={{
                                //     type: 'primary',
                                //     className: 'form-button'
                                // }}
                                cancelButtonProps={{ style: { borderRadius: 4 } }}
                                onConfirm={() => { request(e) }}
                            >
                                <a>{selectedLanguage?.button_WithDraw}</a>
                            </Popconfirm>
                    }
                </>
            )
        }
    ]
    const dataSource = drawData.map((info, i) => {
        return {
            userId: info.userId,
            name: info?.name,
            isClaimed: info?.isClaimed,
            lotteryCode: info?.lotteryCode,
            prize: info?.prize,
            withDraw: info?.withDraw,
            // lotteryType: info?.lotteryType === 'physicalLottery	' ? 'Physical Lottery' : 'Online Lottery',
            date: moment(info?.date).utcOffset(0, true).format('DD-MM-YYYY'),
        }
    })

    // const makArray = dataSource.map((v, i) => {
    //     return {
    //         text: v?.date,
    //         value: v?.date,
    //     }
    // })
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    // console.log('drawData',drawData)
    return (
        <>
            <div className='new-layout-table-upper-div'>
                <Table
                    className='new-layout-table'
                    locale={customLocale}
                    loading={loading1}
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 800 }}
                />
            </div>

            {
                isModalVisible ?
                    <Modal
                        title={<p style={{ color: '#ec671b' }
                        } > {selectedLanguage?.title_LotteryInformation}</p >}
                        open={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={null} >
                        <Descriptions title=''>
                            <div key={123}>
                                <Descriptions.Item label={selectedLanguage?.title_LotteryCode}>{`${selectedLanguage?.title_LotteryCode} : ${lotteryInfo.lotteryCode}`}</Descriptions.Item><br />
                                <Descriptions.Item label={selectedLanguage?.UserName}>{`${selectedLanguage?.UserName} : ${lotteryInfo.userId?.fullName}`}</Descriptions.Item><br />
                                <Descriptions.Item label={selectedLanguage?.StreetNumber}>{`${selectedLanguage?.StreetNumber} : ${lotteryInfo.userId?.streetNumber}`}</Descriptions.Item><br />
                                <Descriptions.Item label={selectedLanguage?.PostalCode}>{`${selectedLanguage?.PostalCode} : ${lotteryInfo.userId?.postalCodeCity}`}</Descriptions.Item><br />
                                <Descriptions.Item label={selectedLanguage?.Country}>{`${selectedLanguage?.Country} : ${lotteryInfo.userId?.country}`}</Descriptions.Item><br />
                                <Descriptions.Item label={selectedLanguage?.TelNo}>{`${selectedLanguage?.TelNo} : ${lotteryInfo.userId?.telNo}`}</Descriptions.Item><br />
                                {/* <Descriptions.Item label='Email'>{`Email : ${lotteryInfo.userId?.email}`}</Descriptions.Item><br /> */}
                            </div>

                        </Descriptions>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                style={{ height: '3s0px', width: '80px', marginTop: '10px', marginRight: '2px' }}
                                className='addproduct-text'
                                type='primary'
                                onClick={handleOk}>{selectedLanguage?.Cancel}</Button>
                            <Button
                                style={{ height: '30px', width: '80px', marginTop: '10px', marginLeft: '2px' }}
                                className='addproduct-text'
                                type='primary'
                                onClick={handleCancel} >{selectedLanguage?.Ok}</Button>
                        </div>
                    </Modal > : null}
        </>
    )
}

export default Draws