import { DeleteFilled } from '@ant-design/icons'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Button, DatePicker, Image, Popconfirm, Table } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { ADMIN } from '../../../utils/apis'
import DoubleChance from './DoubleChance'
import LotteryCards from './LotteryCards'
import LotteryCodes from './LotteryCodes'
import { assignMessage, errorMessage, successMessage } from '../../../utils/helpers'
import { TfiMenu } from 'react-icons/tfi'
const { RangePicker } = DatePicker

const Lottery = (props) => {
    const { user, userActions, language, selectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const [showDraw, setShowDraw] = useState(false)
    const [showDOC, setshowDOC] = useState(false)
    const [lotteryCards, setLotteryCards] = useState(false)
    const [lotteryPrize, setLotteryPrize] = useState([])
    const [filteredArray, setfilteredArray] = useState([])
    const [filteredArray2, setfilteredArray2] = useState([])
    const [filteredArray3, setfilteredArray3] = useState([])
    const [tempLoading, setTempLoading] = useState(false)
    const [filteredArray4, setfilteredArray4] = useState([])
    const [dateRange, setDateRange] = useState({
        date1: moment().add(-1, 'months').format('YYYY-MM-DD'),
        date2: moment().format('YYYY-MM-DD')
    })
    const [anchorEl, setAnchorEl] = useState(null)
    const open = anchorEl;
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    useEffect(() => {
        setfilteredArray(_.uniqBy(buNameArray, 'text'))
        setfilteredArray2(_.uniqBy(buemailArray, 'text'))
        setfilteredArray3(_.uniqBy(winnerArray, 'text'))
        setfilteredArray4(_.uniqBy(withDrawArray, 'text'))
        // console.log('makArray2',makArray2 )
    }, [lotteryPrize])

    useEffect(() => {
        getLotteriesData()
    }, [dateRange])

    const getLotteriesData = (e) => {
        setLoading(true)
        let obj = { date1: dateRange?.date1, date2: dateRange?.date2 }

        axios.post(ADMIN.EACH_MONTH_LOTTETY_PRIZE, obj)
            .then((res) => {
                const { data } = res
                setLotteryPrize(data?.data || [])
                // console.log('Admin', data?.data)
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
            })
    }


    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_Prize}</p>,
            dataIndex: 'prize',
            key: 'prize',
            defaultSortOrder: 'descend',
            showSorterTooltip: false,
            // sorter: (a, b) => a.prize - b.prize,
            render: (e) => {
                return <Image src={`${e}`} style={{ marginRight: 10, width: 86, height: 60 }} />
            }
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.BusinessName}</p>,
            dataIndex: 'businessName',
            key: 'businessName',
            filters: filteredArray,
            onFilter: (value, record) => record.businessName.indexOf(value) === 0
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.tableColumn_UserEmail}</p>,
            dataIndex: 'UserEmail',
            key: 'UserEmail',
            filters: filteredArray2,
            onFilter: (value, record) => record.UserEmail.indexOf(value) === 0
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_LotteryWinner}</p>,
            dataIndex: 'guestName',
            key: 'guestName',
            filters: filteredArray3,
            onFilter: (value, record) => record.guestName.indexOf(value) === 0
        },
        // {
        //     title: selectedLanguage?.title_LotteryType,
        //     dataIndex: 'lotteryType',
        //     key: 'lotteryType',
        //     defaultSortOrder: 'descend',
        // },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_LotteryCode}</p>,
            dataIndex: 'lotteryCode',
            key: 'lotteryCode'
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_WithdrawDate}</p>,
            dataIndex: 'date',
            key: 'date',
        }
    ]

    const dataSource2 = lotteryPrize.map((info, i) => {
        return {
            key: info?._id,
            businessName: info?.userId?.businessName,
            UserEmail: info?.userId?.email,
            guestName: info?.guest?.fullName ? info?.guest?.fullName : selectedLanguage?.status_waiting,
            name: info?.name,
            lotteryCode: info?.lotteryCode,
            prize: info?.withDraw ? info?.prize : info?.prize,
            // lotteryType: info?.lotteryType === 'physicalLottery	' ? 'Physical Lottery' : 'Online Lottery',
            date: moment(info?.date).utcOffset(0, true).format('DD-MM-YYYY'),
        }
    })

    const buNameArray = lotteryPrize.map((v, i) => {
        return {
            text: v?.userId?.businessName,
            value: v?.userId?.businessName
        }
    })
    const buemailArray = lotteryPrize.map((v, i) => {
        return {
            text: v?.userId?.email,
            value: v?.userId?.email
        }
    })
    const winnerArray = lotteryPrize.map((v, i) => {
        return {
            text: v?.guest?.fullName ? v?.guest?.fullName : selectedLanguage?.status_waiting,
            value: v?.guest?.fullName ? v?.guest?.fullName : selectedLanguage?.status_waiting
        }
    })
    const withDrawArray = lotteryPrize.map((v, i) => {
        return {
            text: moment(v?.date).utcOffset(0, true).format('DD-MM-YYYY'),
            value: moment(v?.date).utcOffset(0, true).format('DD-MM-YYYY')
        }
    })

    const createReportRange = (d) => {
        setDateRange({
            date1: d[0].format('YYYY-MM-DD'),
            date2: d[1].format('YYYY-MM-DD')
        })
        getLotteriesData()
    }


    const disabledDate = (current) => {

        let customDate = moment().format('YYYY-MM-DD')
        return current && current > moment(customDate, 'YYYY-MM-DD').add(1, 'day')
    }

    const updateLotterydate = () => {
        setTempLoading(true)
        let obj = { adminId: user?._id }
        axios.post(ADMIN.UPDATE_LOTTERY_WITHDRAW_DATE, obj)
            .then((res) => {
                const { data } = res
                setTempLoading(false)
                if (data.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                } else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setTempLoading(false)
            })
    }
    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote5}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>
                    {lotteryCards ? < LotteryCards {...props} setLotteryCardssss={() => setLotteryCards(false)} />
                        :
                        <div className='flex-column menu-main'>
                            {!showDOC && !lotteryCards ?
                                <RangePicker
                                    defaultValue={[moment().add(-1, 'months'), moment()]}
                                    onChange={createReportRange}
                                    style={{ width: '200px', margin: '15px' }}
                                // disabledDate={disabledDate}
                                /> : null}

                            {!showDraw && !showDOC && !lotteryCards ?
                                <>
                                    <div className='teacher-button-menu-end'>
                                        <IconButton
                                            aria-label='more'
                                            id='long-button'
                                            aria-controls={open ? 'long-menu' : undefined}
                                            aria-expanded={open ? 'true' : undefined}
                                            aria-haspopup='true'
                                            onClick={handleClick}
                                        >
                                            <TfiMenu size={28} color='#ec671b' />
                                        </IconButton>
                                        <Menu
                                            id='long-menu'
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                        >
                                            <MenuItem key={1} >
                                                <Button
                                                    type='primary'
                                                    style={{ width: '290px' }}
                                                    onClick={() => { setShowDraw(true); handleClose() }}
                                                >
                                                    {selectedLanguage?.button_AllLotteryCards}
                                                </Button>
                                            </MenuItem>
                                            <MenuItem key={2}>
                                                <Button
                                                    type='primary'
                                                    style={{ width: '290px' }}
                                                    onClick={() => { setshowDOC(true); handleClose() }}
                                                >
                                                    {selectedLanguage?.label_doubleChances}
                                                </Button>
                                            </MenuItem>
                                            <MenuItem key={3}>
                                                <Button
                                                    type='primary'
                                                    style={{ width: '290px' }}
                                                    onClick={() => { setLotteryCards(true); handleClose() }}
                                                >
                                                    {selectedLanguage?.button_LotteryCards}
                                                </Button>
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                    <div className='teacher-button-menu-end' style={{ marginTop: '0px' }}>
                                        <Popconfirm
                                            loading={tempLoading}
                                            placement="leftBottom"
                                            title={<div style={{ maxWidth: 250 }}> {selectedLanguage?.txt_Areyousureyouwanttochangethe}<b>{selectedLanguage?.txt_AllPrizesWithdrawDate}</b>?</div>}
                                            okText="Yes"
                                            cancelText="No"
                                            onConfirm={() => { updateLotterydate(); handleClose() }}>
                                            <Button
                                                style={{ width: '290px' }}
                                                type='primary'
                                                loading={tempLoading} >
                                                {selectedLanguage?.txt_UPDATELOTTERYWITHDRAWDATE}
                                            </Button>
                                        </Popconfirm>
                                    </div>
                                </>
                                :
                                <div className='teacher-button-menu-end'>
                                    <Button
                                        type='primary'
                                        style={{ width: '100px' }}
                                        onClick={() => {
                                            setShowDraw(false)
                                            setshowDOC(false)
                                            setLotteryCards(false)
                                        }} >
                                        {selectedLanguage?.Back}
                                    </Button>
                                </div>
                            }
                            <div >

                                {showDraw ?
                                    <LotteryCodes {...props} dateRange={dateRange} />
                                    : showDOC ? <DoubleChance {...props} dateRange={dateRange} />
                                        :
                                        <>
                                            <h3 className='new-exam-title'>{selectedLanguage?.title_LotteryWinners}</h3>
                                            <div className='new-layout-table-upper-div'>
                                                <Table
                                                    style={{ marginTop: '30px' }}
                                                    className='new-layout-table'
                                                    locale={customLocale}
                                                    loading={loading}
                                                    dataSource={dataSource2}
                                                    columns={columns}
                                                    pagination={{ hideOnSinglePage: true }}
                                                    scroll={{ x: 800 }}
                                                />
                                            </div>
                                        </>
                                }
                            </div>
                        </div>}
                </div>
            </div>
        </div>
    )
}

export default Lottery