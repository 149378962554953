import { Button, DatePicker, Form, Input, InputNumber, Select, TimePicker } from 'antd'
import axios from 'axios'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { POST } from '../../../../utils/apis'
import { intervalOptions } from '../../../../utils/constants'
import { assignMessage, checkSelectOption, checkTimeSlots, errorMessage, inputPlace, requiredMessage, successNotification, toMinutes } from '../../../../utils/helpers'

const format = 'HH:mm'

const { Option } = Select
const { RangePicker } = DatePicker

const AddReservation = (props) => {
    const { onCancel, setReservationData, productDetail, selectedUser, allProduct, setReservationsetting, language, selectedLanguage } = props
    const isEnglish = language?.language === 'english'
    const [loading, setLoading] = useState(false)
    const [productIds, setProductIds] = useState([])
    const [interval, setInterval] = useState('none')
    const [timeRange, setTimeRange] = useState({
        time1: moment().format(format),
        time2: moment().utc().add(7, 'days').format(format)
    });
    const [dateRange, setDateRange] = useState({
        date1: moment().add(1, 'days').format('YYYY-MM-DD'),
        date2: moment().utc().add(7, 'days').format('YYYY-MM-DD')
    })
    const [isError, setIsError] = useState(false)
    const onFinish = (values) => {
        if (interval != 'none' && (!checkTimeSlots(timeRange, interval) || (timeRange?.time1 == timeRange?.time2))) {
            return errorMessage('Please Select correct Time Slots according to Intervals.')
        }

        values.interval = interval
        values.timeRange = timeRange
        values.dateRange = dateRange
        values.userId = selectedUser

        if (values?.maximumProducts > 200) {
            errorMessage(selectedLanguage?.ValueCannotBeGreaterThan200)
            return setIsError(true)
        }

        setLoading(true)
        axios.post(POST?.CREATE_RESERVATION, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(assignMessage(data?.message, language?.language))
                    setReservationData(data?.data)
                    setReservationsetting(true)
                    return setTimeout(() => {
                        onCancel()
                    }, 300)
                } else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }
    const getLatestData = (d) => {
        let obj = {
            date1: d[0]?.format('YYYY-MM-DD'),
            date2: d[1]?.format('YYYY-MM-DD')
        }

        setDateRange(obj)
    }

    const onChangeProductSelect = (id) => {
        setProductIds(id)
    }
    const onChangeIntervalSelect = (id) => {
        setInterval(id)
    }
    return (
        <div style={{ background: 'white', padding: '15px' }}>
            <h3 className='new-exam-title'>{selectedLanguage?.CreateReservation}</h3>

            <div style={{ textAlign: 'right', width: '98.5%' }}>
                <Button
                    type='primary'
                    className='btn-end'
                    onClick={onCancel}

                >
                    {selectedLanguage?.Cancel}
                </Button>
            </div>
            <div style={{ background: 'white', padding: '15px' }}>
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                >
                    <Form.Item
                        name='name'
                        label={selectedLanguage?.ReservationName}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.PleaseInputYourReservationName
                            }
                        ]}
                    >
                        <Input
                            className='form-input'
                            placeholder={selectedLanguage?.InputYourReservationName}
                        />
                    </Form.Item>
                    <Form.Item
                        name='products'
                        label={selectedLanguage?.Products}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.PleaseInputYourProducts
                            }
                        ]}
                        initialValue={[productDetail?._id] || null}
                    >
                        <Select
                            showSearch
                            disabled
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                            mode='multiple' onChange={onChangeProductSelect} placeholder={selectedLanguage?.SelectProducts}>
                            {allProduct?.map((v, i) => <Option value={v?._id} key={i}>{v?.name}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='dateRange'
                        label={selectedLanguage?.DateRange}
                        hasFeedback
                        initialValue={[moment(dateRange?.date1), moment(dateRange?.date2)]}
                    >
                        <RangePicker
                            className='form-input-new-style'
                            style={{ width: '100%' }}
                            onChange={getLatestData}
                            allowClear={false}
                        />
                    </Form.Item>
                    <Form.Item
                        name='interval'
                        label={selectedLanguage?.SelectInterval}
                        hasFeedback
                        initialValue={interval}
                    >
                        <Select
                            style={{ width: '100%' }}
                            onChange={onChangeIntervalSelect}
                            options={intervalOptions}
                            showSearch
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                        />
                    </Form.Item>
                    <Form.Item
                        name='timeRange'
                        label={selectedLanguage?.TimeSlots}
                        hasFeedback
                        rules={[
                            {
                                required: (interval == 'none' ? false : true),
                                message: selectedLanguage?.PleaseTimeSlots
                            }
                        ]}
                    >
                        <TimePicker.RangePicker
                            format={format}
                            disabled={interval == 'none' ? true : false}
                            // use12Hours={true}
                            style={{ width: '100%' }}
                            placeholder={selectedLanguage?.SelectTimeSlot}
                            onChange={(value, dateString) => {
                                setTimeRange({
                                    time1: dateString[0],
                                    time2: dateString[1]
                                })
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name='maximumProducts'
                        label={selectedLanguage?.DayMaximumProduct}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.PleaseInputYourDayMaximumProduct
                            }
                        ]}
                    >
                        <InputNumber
                            className='form-input'
                            placeholder={selectedLanguage?.InputYourDayMaximumProduct}
                            min={0}
                            max={200}
                            style={{ width: '100%' }}
                            type='number'
                            pattern="[0-9]*"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type='primary'
                            className='form-button'
                            block
                            htmlType='submit'
                            loading={loading}
                        >
                            {selectedLanguage?.Create}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

AddReservation.propTypes = {
    onCancel: PropTypes.func.isRequired
}

export default AddReservation