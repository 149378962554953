import { CheckCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const DragDropStudent = (props) => {
    const { imgUrl, spots, sequence, currentQuestionObj, quesChng, declareAnswer, marked } = props
    const [draggedSpots, setDraggedSpots] = useState(currentQuestionObj?.answerGiven || {})
    const [draggedSeqSpots, setDraggedSeqSpots] = useState([])

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        handleResize()
        setTimeout(() => {
            handleResize()
        }, 1000)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [imgUrl, spots, draggedSpots, draggedSeqSpots, marked])

    useEffect(() => {
        if (currentQuestionObj?._id && currentQuestionObj?.answerType === 'dragDropSequence') {
            setDraggedSeqSpots(currentQuestionObj?.answerGiven || [])
        } else if (currentQuestionObj?._id && currentQuestionObj?.answerType === 'dragDrop') {
            setDraggedSpots(currentQuestionObj?.answerGiven || {})
        }
    }, [currentQuestionObj])
    useEffect(() => {
        if (currentQuestionObj?._id && currentQuestionObj?.answerType === 'dragDropSequence') {
            createSeqResult()
        } else if (currentQuestionObj?._id && currentQuestionObj?.answerType === 'dragDrop') {
            createResult()
        }
    }, [quesChng])

    const createSeqResult = () => {
        declareAnswer(currentQuestionObj, draggedSeqSpots)
        setDraggedSeqSpots([])
    }
    const createResult = () => {
        declareAnswer(currentQuestionObj, draggedSpots)
        setDraggedSpots({})
    }

    const handleResize = () => {
        const image = document.getElementById('image1')
        const imageWidth = image?.clientWidth || 10
        const circleHeight = (imageWidth) * 0.1

        const dragCircle = document.getElementById(`dragCircle`)
        const checkIcon = document.getElementById(`checkIcon`)
        if (dragCircle) {
            dragCircle.style.fontSize = circleHeight + 'px'
        }
        if (checkIcon) {
            checkIcon.style.fontSize = circleHeight + 'px'
        }

        spots.forEach((v, i) => {
            const circle = document.getElementById(`spot${v?.id}`)
            if (circle) {
                circle.style.height = circleHeight + 'px'
            }
            const fontNumber = document.getElementById(`fontNumber${i + 1}`)

            const seqdragCircle = document.getElementById(`dragCircle${i + 1}`)

            if (seqdragCircle) {
                seqdragCircle.style.height = circleHeight + 'px'
                seqdragCircle.style.width = circleHeight + 'px'
            }
            if (fontNumber) {
                fontNumber.style.fontSize = circleHeight * 0.5 + 'px'
            }
        })
    }

    const SequenceDraggableCircle = ({ id, noMargin }) => {
        const [{ isDragging }, drag] = useDrag({
            type: 'circle',
            item: { id },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        })

        const style = {
            position: 'absolute',
            opacity: isDragging ? 0 : 1,
            width: '10%',
            marginLeft: noMargin ? 0 : `${6 * (id - 1)}%`,
            backgroundColor: 'darkOrange',
            cursor: 'move',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            zIndex: 'auto',
            borderWidth: 'thick',
            borderColor: 'white',
            borderStyle: 'solid',
            transform: 'translate(0%, 0%)',
        }

        return (
            <div ref={drag} className='circle' id={`dragCircle${id}`} style={style}>
                <h1 id={`fontNumber${id}`} className='spotNum'>{id}</h1>
            </div>
        )
    }

    const DraggableCircle = ({ id }) => {
        const [{ isDragging }, drag] = useDrag({
            type: 'circle',
            item: { id },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        })
        return (
            <CheckCircleOutlined style={{
                backgroundColor: 'white',
                opacity: isDragging ? 0 : 1,
                cursor: 'move',
                position: 'absolute',
                borderRadius: '50%',
                transform: 'translate(0%, 0%)',
                color: 'orange',
                left: '10%'
            }} ref={drag} id={'dragCircle'} />
        )
    }

    const DraggableCircle2 = ({ id }) => {
        const [{ isDragging }, drag] = useDrag({
            type: 'circle',
            item: { id },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        })

        return (
            <CheckCircleOutlined id='checkIcon' style={{
                fontSize: '100%',
                backgroundColor: 'white',
                opacity: isDragging ? 0 : 1,
                cursor: 'move',
                position: 'absolute',
                borderRadius: '50%',
                transform: 'translate(-50%, 0%)',
                color: 'orange'
            }} ref={drag} />
        )
    }
    const DropZone = (data) => {
        const { spot } = data
        const [{ canDrop, isOver }, drop] = useDrop({
            accept: 'circle',
            drop: (item, monitor) => {
                setDraggedSpots(spot)
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            })
        })
        const isActive = canDrop && isOver
        const backgroundColor = isActive ? 'lightgreen' : 'orange'
        const style = {
            position: 'absolute',
            opacity: draggedSpots?.id === spot.id ? 1 : canDrop ? 0.5 : 0,
            left: spot.x,
            top: spot.y,
            width: '10%',
            backgroundColor,
            borderRadius: '50%',
            zIndex: 2,
        }
        return (
            <div id={`spot${spot?.id}`} ref={drop} style={style}>
                {draggedSpots?.id === spot.id && (
                    <DraggableCircle2 id={2} />
                )}
            </div>
        )
    }

    const SequenceDropZone = (data) => {
        const { spot } = data
        const [{ canDrop, isOver }, drop] = useDrop({
            accept: 'circle',
            drop: (item, monitor) => {
                handleSequenceDrop(item, spot)
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            })
        })

        let collectedSpot = draggedSeqSpots.find((v) => v?.spot === spot?.id + 1)
        const isActive = canDrop && isOver
        const backgroundColor = isActive ? 'lightgreen' : 'orange'
        const style = {
            position: 'absolute',
            opacity: collectedSpot?.circle ? 1 : canDrop ? 0.5 : 0,
            left: spot.x,
            top: spot.y,
            width: '10%',
            backgroundColor,
            borderRadius: '50%',
            zIndex: 2,
        }
        return (
            <div id={`spot${spot?.id}`} ref={drop} style={style}>
                {collectedSpot?.circle && (
                    <SequenceDraggableCircle id={collectedSpot?.circle} noMargin={true} />
                )}
            </div>
        )
    }

    const handleSequenceDrop = (item, spot) => {
        let newArray = draggedSeqSpots
        newArray = newArray.filter((v) => v?.circle !== item.id)
        newArray = newArray.filter((v) => v?.spot !== spot?.id + 1)
        newArray.push({ spot: spot?.id + 1, circle: item.id })
        setDraggedSeqSpots(newArray)
    }

    return (
        <>
            <div style={{ width: window.innerWidth < 700 ? '100%' : '30%', position: 'relative' }}>
                <img src={imgUrl} id='image1' width={'100%'} alt='' />
                {sequence ?
                    spots?.length && spots?.map((spot) => (
                        <SequenceDropZone
                            key={spot.id}
                            spot={spot}
                        />
                    )) : spots.map((spot) => (
                        <DropZone
                            key={spot.id}
                            spot={spot}
                        />
                    ))
                }
            </div>
            <br />
            {!draggedSpots?.x && !sequence ?
                <div style={{ height: '6vh', position: 'relative' }}>
                    <DraggableCircle id={1} />
                </div>
                : !draggedSpots?.x && sequence ?
                    <div style={{ height: '6vh', position: 'relative', display: 'flex' }}>
                        {spots?.map((x, i) => {
                            let condition = draggedSeqSpots.find((v) => v?.circle === i + 1)?.circle
                            if (!condition) {
                                return (<SequenceDraggableCircle key={i} id={i + 1} />)
                            }
                        })}
                    </div>
                    : null
            }
            <br />
            <br />
        </>
    )
}

export default DragDropStudent
