import { Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ADMIN } from '../../../utils/apis'

const DoubleChance = (props) => {
    const { user, userActions, dateRange, selectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const [doubleChance, setdoubleChance] = useState([])
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    useEffect(() => {
        getLotteriesData()
    }, [dateRange])

    const getLotteriesData = (e) => {
        setLoading(true)
        let obj = { date1: dateRange?.date1, date2: dateRange?.date2 }

        axios.post(ADMIN.GET_DOUBLE_OF_CHANCE_GUEST, {})
            .then((res) => {
                const { data } = res
                setdoubleChance(data?.data || [])
                setLoading(false)
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
            })
    }

    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.GuestName}</p>,
            dataIndex: 'fullName',
            key: 'fullName',
            sorter: (a, b) => a.fullName - b.fullName,
            showSorterTooltip: false,

        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Email}</p>,
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Country}</p>,
            dataIndex: 'country',
            key: 'country',
            sorter: (a, b) => a.country - b.country,
            showSorterTooltip: false,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.label_doubleChances}</p>,
            dataIndex: 'doubleChances',
            key: 'doubleChances',
            sorter: (a, b) => a.doubleChances - b.doubleChances,
            showSorterTooltip: false,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}> {e ? selectedLanguage?.True : selectedLanguage?.False}</p>
        }
    ]
    return (
        <div>
            <h3 className='new-exam-title'>{selectedLanguage?.label_doubleChances}</h3>
            <div className='new-layout-table-upper-div'>
                <Table
                    style={{ marginTop: '30px' }}
                    className='new-layout-table'
                    loading={loading}
                    dataSource={doubleChance}
                    locale={customLocale}
                    columns={columns}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 800 }}
                />
            </div>
        </div>
    )
}

export default DoubleChance