import { UploadOutlined } from '@ant-design/icons'
import { Button, Form, Input, Select, Upload } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { POST } from '../../utils/apis'
import { assignMessage, checkSelectOption, errorMessage, successNotification, warningMessage } from '../../utils/helpers'
const { Option } = Select

const AddGiftCards = (props) => {
    const { onCancel, edit, user, remainingCards, getCards, selectedLanguage, language } = props
    const [loading, setLoading] = useState(false)
    const [quantity, setQuantity] = useState(null)
    const [fileList, setFileList] = useState([])
    const selectAmount = [5, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100]

    const onFinish = (values) => {
        values.file = fileList
        edit?._id && (values._id = edit?._id)
        values.userId = user?._id

        if (!values._id && !values?.file?.length) {
            return warningMessage(selectedLanguage?.PleaseUploadImage)
        }
        setLoading(true)

        let formData = new FormData()
        if (values?.file?.length) {
            formData.append('file', values?.file[0])
        }
        if (values?._id) {
            formData.append('_id', values?._id)
        }
        formData.append('seriesName', values?.seriesName)
        formData.append('quantity', Number(values?.quantity))
        formData.append('amount', Number(values?.amount))
        formData.append('userId', values?.userId)

        axios.post(POST.SET_PRIZES_OF_GIFT_CARDS, formData)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(assignMessage(data?.message, language?.language))
                    onCancel()
                    getCards()
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))

                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
                console.log('e', e)
            })
    }


    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage(selectedLanguage?.YouCanOnlyUploadImages)
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    return (
        <>
            <div >
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                    requiredMark={false}
                >
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input '>
                            <Form.Item
                                name='seriesName'
                                label={selectedLanguage?.title_SeriesName}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.errorMessage_PleaseInputSeriesName
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.errorMessage_PleaseInputSeriesName}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input '>
                            <Form.Item
                                name='quantity'
                                label={selectedLanguage?.Quantity}
                                rules={[
                                    {
                                        required: true,
                                        validator: async (_) => {
                                            if (Number(quantity) > remainingCards) {
                                                return Promise.reject(new Error(selectedLanguage?.errorMessage_YoudonthaveenoughGiftCards))
                                            }
                                            if (!quantity) {
                                                return Promise.reject(new Error(selectedLanguage?.PleaseEnterQuantity))
                                            }
                                        },
                                    }
                                ]}
                            >
                                <div>
                                    <Input
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.PleaseEnterQuantity}
                                        value={quantity}
                                        max={remainingCards}
                                        maxLength={remainingCards}
                                        onChange={(e) => setQuantity(e.target.value?.replace(/[^0-9]/g, ''))}
                                    />
                                    {selectedLanguage?.errorMessage_YoureremainingCardis} {remainingCards}
                                </div>
                            </Form.Item>
                        </div>
                    </div>


                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input '>
                            <Form.Item
                                name='amount'
                                label={selectedLanguage?.Amount}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.errorMessage_PleaseSelectAmount
                                    }
                                ]}
                            >
                                <Select
                                    showArrow
                                    allowClear
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.placeholder_PleaseSelectAmount}
                                    showSearch
                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}
                                >
                                    {selectAmount?.map((v, i) => <Option value={v} key={i}>{v}</Option>)}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input '>
                            <Form.Item
                                name='file'
                                label={selectedLanguage?.Image}
                            >
                                <Upload
                                    name='file'
                                    multiple={false}
                                    beforeUpload={() => false}
                                    accept='image/png, image/jpeg'
                                    onChange={normFile}
                                    fileList={fileList}
                                >
                                    <Button icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                </Upload>
                            </Form.Item>
                        </div>
                    </div>

                    <Form.Item>
                        <Button
                            style={{ height: '40px', width: '160px', marginTop: '10px' }}
                            className='addproduct-text'
                            type='primary'
                            htmlType='submit'
                            loading={loading}
                        >
                            {selectedLanguage?.Create}
                        </Button>
                    </Form.Item>
                </Form>
            </div >
        </>

    )
}

export default AddGiftCards
