import { EditOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Input, Popconfirm, Space, Spin, Table } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useSelector } from 'react-redux'
import { ADMIN } from '../../../utils/apis'
import { allPaths } from '../../../utils/constants'
import countryCodes from '../../../utils/countryCodes'
import { allAdminData, convertTitle, successNotification } from '../../../utils/helpers'
import AddSubAdmins from './AddSubAdmins'

const SubAdmins = (props) => {
    const { user, adminActions, language, selectedLanguage, history } = props
    const [spin, setSpin] = useState({})
    const [edit, setEdit] = useState({})
    const [showCreateAdmin, setShowCreateAdmin] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const searchInput = useRef(null)
    const subAdmins = useSelector(state => state?.adminReducer?.subAdmins) || []
    const isEnglish = language?.language === 'english'
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    useEffect(() => {
        allAdminData.getSubAdmins(adminActions, user?._id)
        if (user?.adminType === 'sub') {
            history.push(allPaths?.ADMIN_USERS)
        }
    }, [])

    const activateUser = (_id, active) => {
        setSpin({ [_id]: true })
        let obj = {
            _id,
            active,
            adminId: user?._id
        }
        // return true
        axios.post(`${ADMIN.ACTIVATESUBADMINS}`, obj)
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successNotification(active ? selectedLanguage?.Activate : selectedLanguage?.Deactivate)
                    allAdminData.getSubAdmins(adminActions, user?._id)
                }
            })
            .catch((e) => {
                console.log('e', e)
                setSpin({})
            })
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`${selectedLanguage?.Search} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        {selectedLanguage?.Search}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                        {selectedLanguage?.txt_Reset}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100)
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.FullName}</p>,
            dataIndex: 'userName',
            key: 'userName',
            showSorterTooltip: false,
            sorter: (a, b) => a?.userName?.length - b?.userName?.length,
            ...getColumnSearchProps('userName')
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Share}</p>,
            dataIndex: 'share',
            key: 'share',
            align: 'center',

            showSorterTooltip: false,
            sorter: (a, b) => a?.share - b?.share,
            render: (e) => <p>{e} %</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Country}</p>,
            dataIndex: 'country',
            ellipsis: true,
            align: 'center',
            render: (e) => {
                return (
                    <p>{(_.find(countryCodes, { code: e }))?.[isEnglish ? 'name' : 'nameNL']}</p>
                )
            }

        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.table_Restaurant}</p>,
            key: 'postalCode',
            ellipsis: true,
            align: 'center',
            render: (e) => {
                return (<>
                    <p>{e?.postalCode[0]?.postalcodeStart}-{e?.postalCode[0]?.postalcodeEnd}</p>
                    {e?.postalCode[1] && <> <br />
                        <p>{e?.postalCode[1]?.postalcodeStart}-{e?.postalCode[1]?.postalcodeEnd}</p> </>}
                </>)
            }

        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Actions}</p>,
            key: 'actions',
            showSorterTooltip: false,
            sorter: (a, b) => a?.active - b?.active,
            align: 'center',
            width: 180,
            render: (e) =>
                // <Space wrap>
                <>
                    <Button onClick={() => setEdit(e)} icon={<EditOutlined />} shape="circle" />
                    &nbsp;&nbsp;
                    <Popconfirm
                        title={(!e?.active ? selectedLanguage?.Activate : selectedLanguage?.Deactivate) + ' ' + `${convertTitle(e?.userName)}?`}
                        okText={selectedLanguage?.Yes}
                        cancelText={selectedLanguage?.No}
                        okButtonProps={{
                            type: 'primary',
                            className: 'form-button'
                        }}
                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                        onConfirm={() => activateUser(e?._id, !e?.active)}
                    >
                        {spin[e?._id] ? <span className='demo-loading-container'>
                            &nbsp;<Spin />
                        </span> : e?.active ?
                            <Button type='primary' danger>{selectedLanguage?.Deactivate}</Button> :
                            <Button type='primary'>{selectedLanguage?.Activate}</Button>
                        }
                    </Popconfirm >
                </>
        }
    ]

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote7}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>
                    {showCreateAdmin || edit?._id ?
                        <AddSubAdmins edit={edit} onCancel={() => (setShowCreateAdmin(false), setEdit({}))} {...props} />
                        :
                        <>
                            <h3 className='new-exam-title'>{selectedLanguage?.txt_subAdmin}</h3>
                            <div style={{ textAlign: 'right', width: '99%', marginTop: '10px' }}>
                                <Button
                                    type='primary'
                                    onClick={() => setShowCreateAdmin(true)}>
                                    {selectedLanguage?.Button_addSubAdmin}
                                </Button>
                            </div>
                            <br />
                            <div className='new-layout-table-upper-div'>
                                <Table
                                    style={{ marginTop: '30px' }}
                                    className='new-layout-table'
                                    locale={customLocale}
                                    columns={columns}
                                    dataSource={subAdmins}
                                    pagination={{ hideOnSinglePage: true }}
                                    scroll={{ x: 800 }}
                                />
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default SubAdmins