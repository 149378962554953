import { UploadOutlined } from '@ant-design/icons'
import { Button, Form, Input, Upload } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { ADMIN } from '../../../utils/apis'
import { assignMessage, errorMessage, inputPlace, requiredMessage, successNotification, warningMessage } from '../../../utils/helpers'

const AddGiftCards = (props) => {
    const { onCancel, language, selectedLanguage, edit, user, getCards } = props
    const isEnglish = language?.language === 'english'
    const [loading, setLoading] = useState(false)
    const [quantity, setQuantity] = useState(null)
    const [amount, setAmount] = useState(0)
    const [fileList, setFileList] = useState([])

    const onFinish = (values) => {
        // console.log('values',values)
        values.file = fileList
        edit?._id && (values._id = edit?._id)
        values.adminId = user?._id

        if (!values._id && !values?.file?.length) {
            return warningMessage(selectedLanguage?.txt_PleaseUploadImage)
        }
        setLoading(true)

        let formData = new FormData()
        if (values?.file?.length) {
            formData.append('file', values?.file[0])
        }
        if (values?._id) {
            formData.append('_id', values?._id)
        }
        formData.append('seriesName', values?.seriesName)
        formData.append('quantity', Number(values?.quantity))
        formData.append('amount', Number(values?.amount))
        formData.append('adminId', values?.adminId)

        console.log('values', values)
        axios.post(ADMIN.CREATE_ADMIN_GIFT_CARDS, formData)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(assignMessage(data?.message, language?.language))
                    console.log('data', data)
                    onCancel()
                    getCards()
                }
                // errorMessage(data.message)
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
                console.log('e', e)
            })
    }


    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage(selectedLanguage?.YouCanOnlyUploadImages)
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    return (
        <div >
            <h3 className='new-exam-title'>{selectedLanguage?.txt_AddGiftCards}</h3>
            <div style={{ padding: '15px' }}>
                <div style={{ textAlign: 'right', width: '100%' }}>
                    <Button
                        type='primary'
                        onClick={onCancel}
                    >
                        {selectedLanguage?.txt_Cancel}
                    </Button>
                </div>

                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                    requiredMark={false}
                >
                    <Form.Item
                        name='seriesName'
                        label={selectedLanguage?.txt_SeriesName}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.txt_PleaseInputSeriesName
                            }
                        ]}
                    >
                        <Input
                            className='form-input'
                            placeholder={selectedLanguage?.txt_PleaseInputSeriesNamehere}
                        />
                    </Form.Item>

                    <Form.Item
                        name='quantity'
                        label={selectedLanguage?.txt_Quantity}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.txt_Please_Quantity
                            }
                        ]}
                    >
                        <div>
                            <Input
                                className='form-input'
                                placeholder={selectedLanguage?.txt_Imput_Quantity}
                                value={quantity}
                                onChange={(e) => setQuantity(e.target.value?.replace(/[^0-9]/g, ''))}
                            />
                        </div>
                    </Form.Item>

                    <Form.Item
                        name='amount'
                        label={selectedLanguage?.txt_Amount}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.txt_Please_Amount
                            }
                        ]}
                        initialValue={0}
                    >
                        <div>
                            <Input
                                className='form-input'
                                placeholder={selectedLanguage?.txt_Imput_Amount}
                                type='number'
                                max={1000}
                                min={0}
                                step={0}
                                maxLength={4}
                                value={amount}
                                onChange={(e) => setAmount(e.target.value?.replace(/[^0-9]/g, ''))}
                            />
                        </div>
                    </Form.Item>

                    <Form.Item
                        name='file'
                        label={selectedLanguage?.txt_ImageImage}
                    >
                        <Upload
                            name='file'
                            multiple={false}
                            beforeUpload={() => false}
                            accept='image/png, image/jpeg'
                            onChange={normFile}
                            fileList={fileList}
                        >
                            <Button icon={<UploadOutlined />}>{selectedLanguage?.txt_ClickToUpload}</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type='primary'
                            className='form-button'
                            block
                            htmlType='submit'
                            loading={loading}
                        >
                            {selectedLanguage?.txt_Create}
                        </Button>

                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default AddGiftCards