import CloseIcon from '@mui/icons-material/Close'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import IconButton from '@mui/material/IconButton'
import { Button, DatePicker, Spin, Table } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { POST } from '../../utils/apis'
import { assignMessage, errorMessage, successMessage } from '../../utils/helpers'
import NoData from "../../assets/noDataImae.png"

const { RangePicker } = DatePicker

const WithDrawRequest = (props) => {

    const { user, onCancel, selectedLanguage, language } = props
    var dateObj = new Date()
    const todayDate = dateObj.toLocaleDateString('en-CA').slice(0, 7)

    const [showCreateLottery, setShowCreateLottery] = useState(false)
    const [edit, setEdit] = useState({})
    const [loading, setLoading] = useState(false)
    const [withDrawForm, setWithDrawForm] = useState([])
    const [filteredArray, setfilteredArray] = useState([])
    const [dateRange, setDateRange] = useState({
        date1: moment().add(-1, 'months').format('YYYY-MM-DD'),
        date2: moment().format('YYYY-MM-DD')
    })
    const customLocale = {
         emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle:selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };

    useEffect(() => {
        setfilteredArray(_.uniqBy(makArray, 'text'))
    }, [withDrawForm])

    useEffect(() => {
        getForms()
    }, [dateRange])

    const makArray = withDrawForm.map((v, i) => {
        return {
            text: moment(v?.date).format('DD-MM-YYYY'),
            value: moment(v?.date).format('DD-MM-YYYY'),
        }
    })
    const approveStatus = (e) => {
        let obj = {
            _id: e?.key,
            isPaid: true,
            userId: user?._id,
            lotteryCode: e?.lotteryCode
        }
        setLoading(true)
        axios.post(POST.ACCEPT_WITH_DRAW_REQUEST, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    getForms()
                    successMessage(assignMessage(data?.message, language?.language))
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
            })
    }

    const getForms = () => {
        setLoading(true)
        let valObj = { userId: `${user?._id}`, date1: dateRange?.date1, date2: dateRange?.date2 }

        axios.post(`${POST.GET_WITH_DRAW_REQUEST_USER}`, valObj)
            .then((res) => {
                const { data } = res
                setWithDrawForm(data?.data)
                setLoading(false)
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
            })
    }

    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.GuestName}</p>,
            dataIndex: 'fullname',
            key: 'fullname',
            // render: (e) => console.log('a', e)
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_GuestEmail}</p>,
            dataIndex: 'email',
            key: 'email',
            // render: (e) => console.log('a', e)
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_LotteyCode}</p>,
            dataIndex: 'lotteryCode',
            key: 'lotteryCode',
            // render: text => <a>{text}</a>
        },

        {
            title: <p className='table-title-p'>{selectedLanguage?.Approved}</p>,
            dataIndex: 'isApproved',
            key: 'isApproved',
            render: e => <a>{e ? selectedLanguage?.True : selectedLanguage?.False}</a>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Created}</p>,
            dataIndex: 'created',
            key: 'created',
            filters: filteredArray,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Status}</p>,
            key: 'action',
            render: (e) => (

                e?.isApproved === true ?
                    <> < IconButton style={{ color: 'green' }} disabled >
                        <DoneAllIcon />
                    </IconButton ></> :
                    <> < IconButton style={{ color: 'red' }} onClick={() => { approveStatus(e) }} >
                        <CloseIcon />
                    </IconButton >
                    </>

            )
        }

    ]

    const showEdit = (e) => {
        setEdit(e)
        setShowCreateLottery(true)
    }

    const dataSource = withDrawForm.map((info, i) => {
        return {
            key: info?._id,
            fullname: info?.guestId?.fullName,
            email: info?.guestId?.email,
            lotteryCode: info?.lotteryCode,
            isApproved: info?.isApproved,
            created: moment(info?.created).utcOffset(0, true).format('DD-MM-YYYY'),
        }
    })
    const createReportRange = (d) => {
        setDateRange({
            date1: d[0].format('YYYY-MM-DD'),
            date2: d[1].format('YYYY-MM-DD')
        })
        getForms()
    }
    const disabledDate = (current) => {
        let customDate = moment().format('YYYY-MM-DD')
        return current && current > moment(customDate, 'YYYY-MM-DD').add(1, 'day')
    }
    return (
        <>
            <div>
                <div className='flex-mobile' style={{ display: 'flex' }}>
                    <RangePicker
                        defaultValue={[moment().add(-1, 'months'), moment()]}
                        onChange={createReportRange}
                        style={{
                            marginBottom: 10,
                            marginTop: 20,
                            width: '250px',
                            height: '45px',
                            borderRadius: '6px',
                            boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                            marginLeft: '8px'
                        }}
                    />
                </div>

                <div className='new-layout-table-upper-div'>
                    <Table
                        className='new-layout-table'
                        locale={customLocale}
                        loading={loading ? <Spin /> : ""}
                        columns={columns}
                        dataSource={dataSource}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: 800 }}
                    />
                </div>
                {/* <div className='MobileTable sidebar-scroll_class' style={{ overflow: 'scroll', width: '100%' }} >
                    <div style={{ marginTop: '10px', marginBottom: '5px', width: '100%', minWidth: '550px', height: `calc(100vh - 310px)` }}>
                        <div style={{
                            width: '100%',
                            height: '60px',
                            background: 'white',
                            borderTopRightRadius: '4px',
                            borderTopLeftRadius: '4px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            padding: '5px',
                            alignItems: 'center',
                            border: '1px solid #C2C2C2',
                            borderBottom: '0px solid '

                        }}>

                            <p className='tableValue' style={{ margin: '0px', width: '25%' }}>
                                {selectedLanguage?.GuestName}
                            </p>
                            <p className='tableValue' style={{ margin: '0px', width: '20%' }}>
                                {selectedLanguage?.title_LotteyCode}
                            </p>
                            <p className='tableValue' style={{ margin: '0px', width: '15%' }}>
                                {selectedLanguage?.Approved}
                            </p>
                            <p className='tableValue' style={{ margin: '0px', width: '15%' }}>
                                {selectedLanguage?.Created}
                            </p>
                            <p className='tableValue' style={{ margin: '0px', width: '15%' }}>
                                {selectedLanguage?.Status}
                            </p>
                        </div>
                        <div
                            className='sidebar-scroll_class'
                            style={{
                                width: '100%',
                                background: 'white',
                                borderBottomRightRadius: '4px',
                                borderBottomLeftRadius: '4px',
                                justifyContent: 'space-between',
                                padding: '5px',
                                alignItems: 'center',
                                border: '1px solid #C2C2C2',
                                height: `calc(100vh - 366px)`,
                                overflow: 'scroll'
                            }}>
                            {loading ?
                                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                                    <Spin />
                                </div> :
                                withDrawForm?.length ?
                                    withDrawForm?.map((v, i) => {
                                        return (
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '50px',
                                                    background: 'white',
                                                    borderTopRightRadius: '4px',
                                                    borderTopLeftRadius: '4px',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    padding: '5px',
                                                    alignItems: 'center',
                                                    borderBottom: '1px solid #C2C2C2',
                                                    margin: '0px',
                                                    padding: '0px'
                                                }}>
                                                <p className='tableValue' style={{ width: '25%' }}>
                                                    {v?.guestId?.fullName}
                                                </p>
                                                <p className='tableValue' style={{ width: '20%' }}>
                                                    {v?.lotteryCode}
                                                </p>
                                                <p className='tableValue' style={{ width: '15%' }}>
                                                    {v?.isApproved ? selectedLanguage?.True : selectedLanguage?.False}
                                                </p>
                                                <p className='tableValue' style={{ width: '15%' }}>
                                                    {moment(v?.created).utcOffset(0, true).format('DD-MM-YYYY')}
                                                </p>
                                                <p className='tableValue' style={{ width: '15%' }}>
                                                    {v?.isApproved === true ?
                                                        <> < IconButton style={{ color: 'green' }} disabled >
                                                            <DoneAllIcon />
                                                        </IconButton ></> :
                                                        <> < IconButton style={{ color: 'red' }} onClick={() => { approveStatus(v) }} >
                                                            <CloseIcon />
                                                        </IconButton >
                                                        </>
                                                    }
                                                </p>
                                            </div>
                                        )
                                    }) :
                                    <div style={{ height: '160px', textAlign: 'center' }}>
                                        <img src={NoData} style={{ height: '150px', width: '150px' }} />
                                    </div>
                            }

                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default WithDrawRequest