import { Button, Select, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GET } from '../../../utils/apis'
import { allAdminData, assignMessage, checkSelectOption, convertNumber, convertTitle, createBuInvoicePdf, createInvoicePdf, errorMessage } from '../../../utils/helpers'
import ViewCard from '../Cards/ViewCard'
const { Option } = Select

const AdminBuInvoices = (props) => {
    const { user, adminActions, language, selectedLanguage } = props
    const buUsers = useSelector(state => state?.adminReducer?.buUsers || [])
    const [loading, setLoading] = useState(false)
    const [showInvoice, setShowInvoice] = useState(false)
    const [invoice, setInvoice] = useState({})
    const [weeklyInvoices, setWeeklyInvoices] = useState([])
    const [otherInvoices, setOtherInvoices] = useState([])
    const [selectedUser, setSelectedUser] = useState(buUsers?.[0]?._id || null)
    const [selectedUserDetail, setSelectedUserDetail] = useState(buUsers?.[0] || null)
    const [showOtherInvoice, setShowOtherInvoice] = useState(false)
    const isEnglish = language?.language === 'english'

    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };

    useEffect(() => {
        allAdminData?.getBusinessUser(adminActions, user?._id)
    }, [])

    useEffect(() => {
        getInvoices()
        setSelectedUserDetail(buUsers?.filter((v) => v?._id === selectedUser)[0])
    }, [selectedUser])

    const getInvoices = () => {
        setLoading(true)

        axios.get(`${GET.GET_BU_INVOICES}/${selectedUser}`)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setOtherInvoices(data?.otherInvoices || [])
                    setWeeklyInvoices(data?.data || [])
                    setLoading(false)
                } else {
                    setLoading(false)
                    return errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                console.log('e', e)
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const viewInvoice = (fileUrl) => {
        setInvoice({
            designName: 'Invoice',
            fileUrl
        })

        setShowInvoice(true)
    }

    const onCancel = () => {
        setInvoice({})
        setShowInvoice(false)
    }

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.ProductType}</p>,
            dataIndex: ['productType', 'typeName'],

        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.TotalAmount}</p>,
            dataIndex: 'invoiceData',
            align: 'center',
            render: (e) => {
                let sum = (e?.reduce((sum, { quantity }) => sum + quantity, 0) * e[0]?.feeCost)
                let tax = 0.21
                let totalValue = convertNumber(sum + (sum * tax))
                return (<>€ {totalValue || '-'}</>)
            }
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.ViewInvoice}</p>,
            key: 'invoice',
            render: (e) => <a onClick={() => {
                createBuInvoicePdf(e, selectedUserDetail, 'view', viewInvoice)
            }}>{selectedLanguage?.View}</a>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.DownloadInvoice}</p>,
            key: 'download',
            render: (e) => <a onClick={() => {
                createBuInvoicePdf(e, selectedUserDetail, 'download', viewInvoice)
            }
            }>{selectedLanguage?.Download}</a>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.CreateDate}</p>,
            dataIndex: 'created',
            key: 'created'
        }
    ]
    let otherColumns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.InvoiceName}</p>,
            dataIndex: ['invoiceName'],
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.TotalAmount}</p>,
            dataIndex: 'totalAmount',
            align: 'center',
            sorter: (a, b) => a?.totalAmount - b?.totalAmount,
            showSorterTooltip: false,
            render: (e) => <>€ {e}</>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.ViewInvoice}</p>,
            key: 'invoice',
            render: (e) => <a onClick={() => {
                createInvoicePdf(e, user, 'view', viewInvoice)
            }}>{selectedLanguage?.View}</a>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.DownloadInvoice}</p>,
            key: 'download',
            render: (e) => <a onClick={() => {
                createInvoicePdf(e, user, 'download', viewInvoice)
            }}>{selectedLanguage?.Download}</a>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.CreateDate}</p>,
            dataIndex: 'created',
            key: 'created'
        }
    ]

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote3}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>

                    <h3 className='new-exam-title'>{selectedLanguage?.UserInvoices}</h3>

                    <Select
                        showArrow
                        allowClear={false}
                        defaultValue={selectedUser}
                        onChange={(e) => setSelectedUser(e)}
                        className='form-input'
                        placeholder={selectedLanguage?.SelectUser}
                        style={{ width: 200, margin: '12px', marginBottom: '0px', marginTop: '20px' }}
                        showSearch
                        optionFilterProp='children'
                        filterOption={checkSelectOption}
                    >
                        {buUsers?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.fullName)} ${v?.email}`}</Option>)}
                    </Select>

                    <div style={{ textAlign: 'right', width: '100%' }}>
                        {!showOtherInvoice ?
                            <Button
                                type='primary'
                                className='btn-end invite-guest'
                                onClick={() => setShowOtherInvoice(true)}
                            >
                                {selectedLanguage?.OtherInvoices}
                            </Button>
                            :
                            <Button
                                type='primary'
                                className='btn-end invite-guest'
                                onClick={() => setShowOtherInvoice(false)}
                            >
                                {selectedLanguage?.WeeklyInvoices}
                            </Button>
                        }
                    </div>

                    <div className='new-layout-table-upper-div'>
                        <Table
                            style={{ marginTop: '30px' }}
                            className='new-layout-table'
                            locale={customLocale}
                            loading={loading}
                            columns={showOtherInvoice ? otherColumns : columns}
                            dataSource={showOtherInvoice ? otherInvoices : weeklyInvoices}
                            pagination={{ hideOnSinglePage: true }}
                            scroll={{ x: 800 }}
                        />
                    </div>

                    {showInvoice ? <ViewCard card={invoice} onCancel={onCancel} showData={showInvoice} {...props} /> : null}
                </div>
            </div>
        </div>
    )
}

export default AdminBuInvoices