import { Button, DatePicker, Form, Input, InputNumber, Select, TimePicker } from 'antd'
import axios from 'axios'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { POST } from '../../utils/apis'
import { intervalOptions } from '../../utils/constants'
import { allBUserData, assignMessage, checkSelectOption, checkTimeSlots, errorMessage, successNotification } from '../../utils/helpers'

const format = 'HH:mm'

const { Option } = Select
const { RangePicker } = DatePicker

const AddReservation = (props) => {
    const { onCancel, setReservationData, productDetail, user, allProduct, userActions, setReservationsetting, language, selectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const [productIds, setProductIds] = useState([])
    const [interval, setInterval] = useState('none')
    const [timeRange, setTimeRange] = useState({
        time1: moment().format(format),
        time2: moment().utc().add(7, 'days').format(format)
    });
    const [dateRange, setDateRange] = useState({
        date1: moment().add(1, 'days').format('YYYY-MM-DD'),
        date2: moment().utc().add(7, 'days').format('YYYY-MM-DD')
    })
    const [isError, setIsError] = useState(false)
    const onFinish = (values) => {
        if (interval != 'none' && (!checkTimeSlots(timeRange, interval) || (timeRange?.time1 == timeRange?.time2))) {
            return errorMessage('Please Select correct Time Slots according to Intervals.')
        }

        values.interval = interval
        values.timeRange = timeRange
        values.dateRange = dateRange
        values.userId = user?._id

        if (values?.maximumProducts > 200) {
            errorMessage(selectedLanguage?.ValueCannotBeGreaterThan200)
            return setIsError(true)
        }
        console.log('values', values)
        setLoading(true)
        axios.post(POST?.CREATE_RESERVATION, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    allBUserData?.getAllBuProducts(userActions, user?._id)
                    successNotification(assignMessage(data?.message, language?.language))
                    setReservationData(data?.data)
                    setReservationsetting(true)
                    onCancel()
                } else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }
    const getLatestData = (d) => {
        let obj = {
            date1: d[0]?.format('YYYY-MM-DD'),
            date2: d[1]?.format('YYYY-MM-DD')
        }

        setDateRange(obj)
    }

    const onChangeProductSelect = (id) => {
        setProductIds(id)
    }
    const onChangeIntervalSelect = (id) => {
        setInterval(id)
    }
    return (
        <div className='main-div-new-style'>
            <p className='new-exam-title'>{selectedLanguage?.CreateReservation}</p>
            <p className='new-exam-title' style={{ fontSize: '18px', }}>{selectedLanguage?.label_PleaseFillTheBelowFields}</p>
            <div style={{ textAlign: 'right', width: '100%' }}>
                <Button
                    style={{ height: '40px', width: '160px', marginTop: '10px' }}
                    className='addproduct-text'
                    type='primary'
                    onClick={onCancel}
                >
                    {selectedLanguage?.Cancel}
                </Button>
            </div>
            <div style={{ marginTop: '20px' }}>
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                >
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='name'
                                label={selectedLanguage?.ReservationName}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourReservationName
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.InputYourReservationName}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='products'
                                label={selectedLanguage?.Products}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourProducts
                                    }
                                ]}
                                initialValue={[productDetail?._id] || null}
                            >
                                <Select
                                    className='form-input-new-style'
                                    showSearch
                                    disabled
                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}
                                    mode='multiple'
                                    onChange={onChangeProductSelect} placeholder={selectedLanguage?.SelectProducts}>
                                    {allProduct?.map((v, i) => <Option value={v?._id} key={i}>{v?.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='dateRange'
                                label={selectedLanguage?.DateRange}
                                hasFeedback
                                initialValue={[moment(dateRange?.date1), moment(dateRange?.date2)]}
                            >
                                <RangePicker
                                    className='form-input-new-style'
                                    style={{ width: '100%' }}
                                    onChange={getLatestData}
                                    allowClear={false}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='interval'
                                label={selectedLanguage?.SelectInterval}
                                hasFeedback
                                initialValue={interval}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}
                                    className='form-input-new-style'
                                    style={{ width: '100%' }}
                                    onChange={onChangeIntervalSelect}
                                    options={intervalOptions}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='timeRange'
                                label={selectedLanguage?.TimeSlots}
                                hasFeedback
                                rules={[
                                    {
                                        required: (interval == 'none' ? false : true),
                                        message: selectedLanguage?.PleaseTimeSlots
                                    }
                                ]}
                            >
                                <TimePicker.RangePicker
                                    className='form-input-new-style'
                                    format={format}
                                    disabled={interval == 'none' ? true : false}
                                    style={{ width: '100%' }}
                                    placeholder={selectedLanguage?.SelectTimeSlot}
                                    onChange={(value, dateString) => {
                                        setTimeRange({
                                            time1: dateString[0],
                                            time2: dateString[1]
                                        })
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                            <Form.Item
                                name='maximumProducts'
                                label={selectedLanguage?.DayMaximumProduct}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourDayMaximumProduct
                                    }
                                ]}
                            >
                                <InputNumber
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.InputYourDayMaximumProduct}
                                    min={0}
                                    max={200}
                                    style={{ width: '100%' }}
                                    type='number'
                                    pattern="[0-9]*"
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <Form.Item>
                        <Button
                            style={{ height: '40px', width: '160px', marginTop: '10px', marginLeft: '5px' }}
                            className='addproduct-text'
                            htmlType='submit'
                            type='primary'
                            loading={loading}
                        >
                            {selectedLanguage?.Create}
                        </Button>
                    </Form.Item>
                </Form>
            </div >
        </div >
    )
}

AddReservation.propTypes = {
    onCancel: PropTypes.func.isRequired
}

export default AddReservation