import { DeleteFilled } from '@ant-design/icons'
import { Button, Modal, Popconfirm, Space, Spin, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { POST } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import { allBUserData, successNotification } from '../../utils/helpers'

const BuDayDealsQuestions = (props) => {
    const { user, userActions, selectedLanguage, lanKorCondition, history } = props
    const [loading, setLoading] = useState(false)
    const [spin, setSpin] = useState({})
    const allQuestions = useSelector(state => state?.userReducer?.buDaydealsQuestion || [])
    console.log('allQuestions', allQuestions)
    useEffect(() => {
        allBUserData?.getAllBuDaydealsQuestion(userActions, user?._id)
    }, [])

    const deleteForm = (_id) => {
        setSpin({ [_id]: true })
        let valObj = { _id, userId: `${user?._id}` }
        axios.post(`${POST.DELETE_DAYDEALS_QUESTION}`, valObj)
            .then(async (res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    await allBUserData?.getAllBuDaydealsQuestion(userActions, user?._id)
                    successNotification(selectedLanguage?.SuccessfullyDeleted)
                }
            })
            .catch((e) => {
                setSpin({})
            })
    }

    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_Questions}</p>,
            dataIndex: 'question',
            key: 'question',
            sorter: (a, b) => a?.question?.localeCompare(b?.question),
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_Title}</p>,
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a?.title?.localeCompare(b?.title),
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Product}</p>,
            dataIndex: ['product', 'name'],
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.View}</p>,
            render: (e) => {
                return (
                    <Space>
                        <Button
                            type='primary'
                            target={'_blank'}
                            onClick={() => {
                                history.push({
                                    pathname: allPaths?.FORM,
                                    state: { formData: e }
                                })
                            }} >
                            {selectedLanguage?.btn_Open}
                        </Button>
                        &nbsp;&nbsp;
                    </Space>
                )
            }
        },
        {
            key: <p className='table-title-p'>{selectedLanguage?.txt_Action}</p>,
            render: (e) => (
                <>
                    <Popconfirm
                        title={selectedLanguage?.title_DeletethisForm}
                        okText={selectedLanguage?.Delete}
                        cancelText={selectedLanguage?.Cancel}
                        okButtonProps={{
                            type: 'primary',
                            className: 'form-button'
                        }}
                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                        onConfirm={() => deleteForm(e?._id)}
                    >
                        {spin[e?._id] ?
                            <Spin /> :
                            <Button type='link' disabled={e?.admin} icon={<DeleteFilled />} danger />
                        }
                    </Popconfirm>
                </>
            )
        }
    ]

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote10}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>
                    <h3 className='new-exam-title'>{selectedLanguage?.Button_CreateQuestion}</h3>

                    <div className='new-layout-table-upper-div'>
                        <Table
                            className='new-layout-table'
                            columns={columns}
                            dataSource={allQuestions}
                            pagination={{ hideOnSinglePage: true }}
                            scroll={{ x: 'auto' }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuDayDealsQuestions