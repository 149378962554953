import React, { useState } from 'react'
import { Tabs } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { ChangePassword, UserSettings } from '../../Components'


const { TabPane } = Tabs
const Settings = (props) => {
    const { language, selectedLanguage, history } = props

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote10}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>
                    <h3 className='new-exam-title'>{selectedLanguage?.txt_Profilesetting}</h3>
                    <br />
                    <Tabs className='new-new-my-tabs' defaultActiveKey='1' centered>
                        <TabPane tab={<p className='my-menu-new-tab-p'>{selectedLanguage?.Profile}</p>} key='1'>
                            <UserSettings {...props} />
                        </TabPane>
                        <TabPane tab={<p className='my-menu-new-tab-p'>{selectedLanguage?.Security}</p>} key='2'>
                            <ChangePassword {...props} />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default Settings