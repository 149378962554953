import { Button, Modal, Table } from 'antd'
import HTML2Canvas from 'html2canvas'
import moment from 'moment'
import QRCode from 'qrcode.react'
import React, { useEffect, useState } from 'react'
import NoData from "../../assets/noDataImae.png"
import { errorMessage } from '../../utils/helpers'
const AllCoupons = (props) => {
    const { user, couponsDetails, loading, language, selectedLanguage } = props
    const [couponCode, setCouponCode] = useState('')
    const [visible, setVisible] = useState(false)
    const [download, setDownload] = useState(false)

    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    useEffect(() => {
        if (download) {
            androidDownloadQR()
        }
    }, [download])

    const disableModal = () => setVisible(false)

    const androidDownloadQR = async () => {
        let id = download ? 'qrCodeDownload' : 'qrCode'
        const canvas = document.getElementById(id)

        await HTML2Canvas(canvas, { allowTaint: true, useCORS: true })
            .then((data) => {
                const pngUrl = data.toDataURL()
                const a = document.createElement('a')
                a.href = pngUrl
                a.download = 'coupon-code.png'

                a.style.display = 'none'
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)

                setDownload(false)
            })
            .catch((e) => {
                setDownload(false)
                console.log('e', e)
                errorMessage(selectedLanguage?.txt_downloaderror)
            })
    }

    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.Coupon}</p>,
            dataIndex: 'couponCode',
            key: 'couponCode',
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.CouponQRCode}</p>,
            dataIndex: 'couponCode',
            key: 'couponCode',
            render: (e) => (
                <span>
                    <Button type='link' onClick={() => (setCouponCode(e), setVisible(true))}>{selectedLanguage?.View}</Button>
                    <Button type='link' onClick={() => (setCouponCode(e), setDownload(true))}>{selectedLanguage?.Download}</Button>
                </span>
            )
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Description}</p>,
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Claim}</p>,
            dataIndex: 'isClaimed',
            key: 'isClaimed',
            sorter: (a, b) => a.isClaimed - b.isClaimed,
            showSorterTooltip: false,
            render: (e) => <>{e ? selectedLanguage?.True : selectedLanguage?.False}</>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.NoOfProducts}</p>,
            dataIndex: 'products',
            key: 'products',
            align: 'center',
            sorter: (a, b) => a?.products?.length - b?.products?.length,
            showSorterTooltip: false,
            render: (e) => <>{e?.length}</>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Amount}</p>,
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => a?.amount - b?.amount,
            showSorterTooltip: false,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {e}</p>
        }
    ]
    return (
        <>
            <Modal
                open={visible}
                title={selectedLanguage?.CouponCode}
                onCancel={disableModal}
                footer={[
                    <Button key='back' onClick={disableModal}>
                        {selectedLanguage?.Close}
                    </Button>
                ]}
            >
                <div
                    style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: 10 }}
                >
                    <div id='qrCode' style={{ textAlign: 'center' }}>
                        <QRCode
                            value={couponCode}
                            size={250}
                            level={'H'}
                            includeMargin={true}
                        />
                        <p>{couponCode}</p>
                    </div>
                    <a onClick={androidDownloadQR}> {selectedLanguage?.DownloadQR} </a>
                </div>
            </Modal>
            <div
                style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
            >
                <div
                    style={{ textAlign: 'center' }}
                    id='qrCodeDownload'
                    className='fixed-qr'
                >
                    <QRCode
                        value={couponCode}
                        size={250}
                        level={'H'}
                        includeMargin={true}
                    />
                    <p>{couponCode}</p>
                </div>
            </div>

            <div className='new-layout-table-upper-div'>
                <Table
                    className='new-layout-table'
                    locale={customLocale}
                    loading={loading}
                    dataSource={couponsDetails}
                    columns={columns}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 800 }}
                />
            </div>
        </>
    )
}

export default AllCoupons