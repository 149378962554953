import { Button, Table } from 'antd'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import countryCodes from '../../../../utils/countryCodes'
import { generalData } from '../../../../utils/helpers'
import EditTax from './EditTax'

const TaxSettings = (props) => {
    const { language, selectedLanguage, generalActions } = props
    const [loading, setLoading] = useState(false)
    const [edit, setEdit] = useState({})
    const isEnglish = language?.language === 'english'
    const taxes = useSelector(state => state?.generalReducer?.taxes || [])
    const customLocale = {
         emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle:selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    useEffect(() => {
        generalData?.getCountryTax(generalActions)
    }, [])

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Countryname}</p>,
            dataIndex: 'countryName',
            render: (e) => <p>{(_.find(countryCodes, { code: e }))?.name}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.ttx_Default}</p>,
            dataIndex: 'default',
            align: 'center',
            sorter: (a, b) => a?.default - b?.default,
            showSorterTooltip: false,
        },
        {
            key: 'actions',
            align: 'center',
            render: (e) => <Button type='link' onClick={() => setEdit(e)}>{selectedLanguage?.txt_Edit}</Button>
        }
    ]

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote3}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>
                    {edit?._id ?
                        <EditTax
                            edit={edit}
                            onCancel={() => (setEdit({}))}
                            getProductsTypes={() => generalData?.getCountryTax(generalActions)}
                            {...props}
                        />
                        :
                        <>

                            <h3 className='new-exam-title'>{selectedLanguage?.p_txt_tax_setting}</h3>
                            <br />
                            <div className='new-layout-table-upper-div'>
                                <Table
                                    className='new-layout-table'
                                    locale={customLocale}
                                    columns={columns}
                                    dataSource={taxes}
                                    loading={loading}
                                    pagination={{ hideOnSinglePage: true }}
                                    scroll={{ x: 400 }}
                                />
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default TaxSettings