import { Modal, Spin } from 'antd';
import React from 'react';

const GuestDetails = (props) => {
    const { onCancel, details, selectedLanguage } = props
    return (
        <>
            <Modal
                open={details?._id}
                onOk={onCancel}
                onCancel={onCancel}
                okText={selectedLanguage?.Ok}
                cancelText={selectedLanguage?.Cancel}
                className='pop-info-modal'
                style={{ top: '10px' }}
            >
                <p>{selectedLanguage?.txt_UserDetails}</p>
                <div>{selectedLanguage?.FullName}: {details?.fullName}</div>
                <div>{selectedLanguage?.Email}: {details?.email}</div>
                <div>{selectedLanguage?.TelNo}: {details?.telNo}</div>
                <div>{selectedLanguage?.Label_BankName}: {details?.bankAccount}</div>
                <div>{selectedLanguage?.IBANNumber}: {details?.IBAN}</div>
            </Modal>
        </>
    );
};

export default GuestDetails;