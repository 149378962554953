import React, { useState, useRef } from 'react'
import { Form, Input, Button, Select, Upload, Image } from 'antd'
import { UploadOutlined, FileOutlined } from '@ant-design/icons'
import { requiredMessage, inputPlace, successMessage, errorMessage, warningMessage, convertNumber, assignMessage, checkSelectOption } from '../../utils/helpers'
import axios from 'axios'
import { AUTH } from '../../utils/apis'
import countryCodes from '../../utils/countryCodes'
import { provincie } from '../../utils/constants'
import { IoIosArrowBack } from "react-icons/io"

const { Option } = Select

const UserSettings = (props) => {
    const { user, authActions, language, selectedLanguage, history } = props
    const [loading, setLoading] = useState(false)
    const [btnText, setBtnText] = useState(selectedLanguage?.UpdateProfile)
    const [fileList, setFileList] = useState([])
    // const [receiptFileList, setReceiptFileList] = useState([])
    const [country, setCountry] = useState(user?.country || 'Netherlands')
    const [countryCode, setCountryCode] = useState(countryCodes.filter(v => v?.code === user?.country)?.[0]?.dial_code || '+31')
    const formRef = useRef(null)
    const isEnglish = language?.language === 'english'

    console.log('user', user)

    const onFinish = (values) => {
        values.file = fileList
        values._id = user._id

        console.log('values', values)

        if (!values._id && !values?.file?.length && user?.userType != 'guest') {
            return warningMessage(selectedLanguage?.PleaseUploadLogo)
        }

        let formData = new FormData()

        if (values?.file?.length) {
            formData.append('logo', values?.file[0])
        }

        for (var [k, v] of Object.entries(values)) {
            if (k && v) {
                formData.append(k, v)
            }
        }

        setLoading(true)
        setBtnText(selectedLanguage?.Updating)
        axios.post(AUTH.UPDATE_SETTINGS, formData)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setBtnText(selectedLanguage?.UpdateProfile)
                if (data.success) {
                    successMessage(selectedLanguage?.ProfileUpdatedSuccessfully)
                    authActions.loginUser(data.user)
                    return window.location.reload()
                }
                errorMessage(assignMessage(data?.message, language?.language))
            })
            .catch((e) => {
                setLoading(false)
                setBtnText(selectedLanguage?.UpdateProfile)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const updateCountry = (e) => {
        let selectedCode = countryCodes.filter(v => v?.code === e)[0].dial_code

        setCountryCode(selectedCode)

        formRef.current.setFieldsValue({ prefix: selectedCode })
    }

    const prefixSelector = () => {
        return (
            <Form.Item name='prefix' noStyle>
                <Select
                    disabled
                    style={{
                        minWidth: 80,
                        maxWidth: window.innerWidth > 500 ? 'auto' : 120
                    }}
                    defaultValue={countryCode}
                    value={countryCode}
                    showSearch
                    optionFilterProp='children'
                    filterOption={checkSelectOption}
                >
                    {countryCodes?.map((v, i) => <Option value={v?.dial_code} key={i}>{`${v?.name} ${v?.dial_code}`}</Option>)}
                </Select>
            </Form.Item >
        )
    }

    const normFile = (e, setState) => {
        setState([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage(selectedLanguage?.YouCanOnlyUploadImages)
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setState([...[e?.file]]) : setState([])
        return e && [e.file]
    }


    return (
        <div>
            <div className='inner-div-pf'>
                <br />
                <p className='p-pf'>
                    {selectedLanguage?.ttx_wellcome} {(user?.fullName)}
                </p>
            </div>
            <div>
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                    requiredMark={false}
                    ref={formRef}
                >
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='email'
                                label={selectedLanguage?.Email}
                                initialValue={user?.email}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.InputYourEmailHere}
                                    disabled
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='fullName'
                                label={selectedLanguage?.FullName}
                                initialValue={user?.fullName}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseinputYourFullName
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.InputyourFullNameHere}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    {user?.userType == 'guest' ? null :
                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='file'
                                    label={selectedLanguage?.Logo}
                                >
                                    <Upload
                                        name='file'
                                        multiple={false}
                                        beforeUpload={() => false}
                                        accept='image/*'
                                        onChange={(e) => normFile(e, setFileList)}
                                        fileList={fileList}
                                    >
                                        <Button icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                    </Upload>
                                    {user?.logo ?
                                        <div style={{ display: 'flex', marginTop: 10, alignItems: 'center' }}>
                                            <Image src={user?.logoUrl} height={80} />
                                            {/* &nbsp;&nbsp;<FileOutlined />&nbsp;{user?.logo} */}
                                        </div> : null}
                                </Form.Item>
                            </div>

                        </div>
                    }

                    {
                        user?.userType == 'guest' ? null :
                            <div className='form-input-single-input'>
                                <Form.Item
                                    name='businessName'
                                    label={selectedLanguage?.BusinessName}
                                    initialValue={user?.businessName}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: user?.userType == 'guest' ? false : true,
                                            message: selectedLanguage?.PleaseInputYourBusinessName
                                        }
                                    ]}
                                >
                                    <Input
                                        className='form-input-new-style'
                                        placeholder={selectedLanguage?.InputYourBusinessNameHere}
                                    />
                                </Form.Item>
                            </div>
                    }


                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='streetNumber'
                                label={selectedLanguage?.StreetNumber}
                                initialValue={user?.streetNumber}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseIputYourStreetNumber
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.InputYourStreetNumberHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='postalCodeCity'
                                label={selectedLanguage?.PostalCode}
                                initialValue={user?.postalCodeCity}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourPostalCode
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style  inputTellAAA'
                                    placeholder={selectedLanguage?.InputYourPostalCodeHere}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='city'
                                label={selectedLanguage?.City}
                                initialValue={user?.city ? user?.city : ''}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourCity
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.InputYourCityHere}
                                />
                            </Form.Item>
                        </div>


                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='provincie'
                                label={selectedLanguage?.Provincie}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourProvincie
                                    }
                                ]}
                                initialValue={user?.provincie ? user?.provincie : null}
                            >
                                <Select
                                    className='form-input-new-style'
                                    showArrow
                                    showSearch
                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}
                                    placeholder={selectedLanguage?.SelectProvincie}
                                >
                                    {provincie?.map((v, i) => <Option value={v} key={i}>{v}</Option>)}
                                </Select>
                            </Form.Item>
                        </div >
                    </div >



                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='country'
                                label={selectedLanguage?.Country}
                                initialValue={user?.country}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseSelectCountry
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}
                                    showArrow
                                    allowClear={false}
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.SelectCountry}
                                    onChange={updateCountry}
                                >
                                    {countryCodes?.map((v, i) => <Option value={v?.code} key={i}>{language?.language === 'english' ? v?.name : v?.nameNL}</Option>)}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='remark'
                                label={selectedLanguage?.Remark}
                                initialValue={user?.remark}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourRemark
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.InputYourRemarkHere}
                                />
                            </Form.Item>
                        </div >
                    </div >

                    <div className='form-input-single-input'>
                        <Form.Item
                            name='telNo'
                            label={selectedLanguage?.TelNo}
                            initialValue={user?.telNo}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourTelNo
                                }
                            ]}
                        >
                            <Input
                                addonBefore={prefixSelector()}
                                onBeforeInput={(e) => {
                                    const inputValue = e.clipboardData?.getData('text') || e.data
                                    if (!/^\d*$/.test(inputValue) || e.target.value.length > 12) {
                                        e.preventDefault()
                                    }
                                }}
                                className='form-input-new-style'
                                type='number'
                                pattern='[0-9]*'
                                placeholder={selectedLanguage?.TelNoExample + '-' + `612345678`}
                            />
                        </Form.Item>
                    </div>


                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='bankAccount'
                                label={selectedLanguage?.NameonBankAccount}
                                initialValue={user?.bankAccount ? user?.bankAccount : ''}
                                hasFeedback
                                rules={[
                                    {
                                        required: false,
                                        message: selectedLanguage?.PleaseEnterNameonyourBankAccount
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'

                                    placeholder={selectedLanguage?.WhatisnameonyourBankaccount}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='IBAN'
                                label={selectedLanguage?.IBANNumber}
                                initialValue={user?.IBAN}
                                hasFeedback
                                rules={[
                                    {
                                        required: false,
                                        message: selectedLanguage?.PleaseEnterYourIBANNumber
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.EnterYourIBANNumber}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    {/* </>
                } */}

                    <Form.Item>
                        <Button
                            style={{ height: '40px', width: '160px', marginTop: '10px', marginLeft: '2px' }}
                            className='addproduct-text'
                            htmlType='submit'
                            type='primary'
                            loading={loading}
                        >
                            {btnText}
                        </Button>
                    </Form.Item>
                </Form >
            </div >
        </div >
    )
}

export default UserSettings