import { Button, Spin, Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ADMIN } from '../../../utils/apis';
import { assignMessage, createGiftCard, errorMessage } from '../../../utils/helpers';
import AddCoupons from './AddCoupons';
import BuCoupons from './BuCoupons';

const AdminCoupons = (props) => {
    const { user, adminActions, language, selectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const [createCoupons, setCreateCoupons] = useState(false)
    const [adminCoupons, setAdminCoupons] = useState([])
    const [showBuCoupons, setShowBuCoupons] = useState(false)
    const [spin, setSpin] = useState({})

    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };

    const isEnglish = language?.language === 'english'
    // console.log('adminCoupons',adminCoupons)
    useEffect(() => {
        getAdminCoupons()
    }, [])

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.Description}</p>,
            key: 'id',
            dataIndex: 'id',
            ellipsis: true,

        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Actions}</p>,
            key: 'actions',
            render: (e) => {
                const spinFunc = () => {
                    let newSpin = { ...spin }
                    newSpin[e?.id] = false
                    setSpin(newSpin)
                }
                return (<>
                    <div style={{ paddingTop: 15, marginTop: -5, display: 'flex', flexDirection: 'column' }}>
                        {spin?.[e?.id] ? <Spin /> :
                            <a onClick={() => (setSpin({ [e?.id]: true, ...spin }), createGiftCard(e?.value, spinFunc))}>{selectedLanguage?.ShowTotalPdfs}</a>
                        }
                    </div>
                </>)
            }
        }
    ]

    const getAdminCoupons = () => {
        setLoading(true)
        axios.get(`${ADMIN.GET_ADMIN_COUPONS}/${user?._id}`)
            .then((res) => {
                const { data } = res
                let tableData = Object.entries(data?.data)
                tableData = tableData?.map((v) => {
                    return {
                        id: v[0],
                        value: v[1]
                    }
                })
                setLoading(false)
                setAdminCoupons([...tableData])
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }
    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote9}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>

                    {showBuCoupons ?
                        <BuCoupons setShowBuCoupons={setShowBuCoupons} {...props} /> :
                        <div >
                            {createCoupons ?
                                <AddCoupons getAdminCoupons={getAdminCoupons} onCancel={() => setCreateCoupons(false)} {...props} /> :
                                <>
                                    <h3 className='new-exam-title'>{selectedLanguage?.AdminCoupons}</h3>
                                    <br />
                                    <div style={{ textAlign: 'right', width: '99%' }}>
                                        <Button
                                            type='primary'
                                            onClick={() => setShowBuCoupons(true)}
                                        >
                                            {selectedLanguage?.BuCoupons}
                                        </Button>
                                        &nbsp;
                                        <Button
                                            type='primary'
                                            onClick={() => setCreateCoupons(true)}
                                        >
                                            {selectedLanguage?.CreateCoupons}
                                        </Button>
                                    </div>
                                    <div className='new-layout-table-upper-div'>
                                        <Table
                                            style={{ marginTop: '30px' }}
                                            className='new-layout-table'
                                            locale={customLocale}
                                            loading={loading}
                                            dataSource={adminCoupons}
                                            columns={columns}
                                            pagination={{ hideOnSinglePage: true }}
                                            scroll={{ x: 800 }}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default AdminCoupons