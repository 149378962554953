import { HeartFilled, HeartOutlined } from '@ant-design/icons';
import { Card, Input, Spin } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AiFillStar } from 'react-icons/ai';
import { TbAdjustmentsHorizontal } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../Redux/actions/authActions';
import NoCards from '../../assets/NoCards.png';
import { GET, POST } from '../../utils/apis';
import { ageOptions, allPaths } from '../../utils/constants';
import { assignMessage, calculateReviewAverage, convertTitle, errorMessage, stringLimiter, successMessage, successNotification } from '../../utils/helpers';
import ProductReview from '../GuestReview/ProductReview';
import BuyProduct from '../Home/BuyProduct';
import ShowShops from '../Home/ShowShops';
const GuestDayDealsProduct = (props) => {
    const { user, filter, history, getUser, location, language, selectedLanguage } = props
    const DayDealsProduct = location?.state?.data
    console.log('data', location?.state?.data)
    const isEnglish = language?.language === 'english'
    const userId = new URLSearchParams(location.search).get('userId') || null
    const [loading, setLoading] = useState(false)
    const [recommendloading, setRecommendloading] = useState(false)
    const [promotionloading, setPromotionloading] = useState(false)
    const [shopsloading, setShopsloading] = useState(false)
    const [allProduct, setAllProduct] = useState([])
    const [filteredProduct, setFilteredProduct] = useState([])
    const [filterOpen, setFilterOpen] = useState(false)
    const [filterValue, setFilterValue] = useState(filter)
    const [buyProduct, setBuyProduct] = useState({})
    const [favouriteSpin, setFavouriteSpin] = useState({})
    const [reviewModal, setReviewModal] = useState({})
    const [ProgressLoading, setProgressLoading] = useState(false)
    const [userData, setUserData] = useState(user)
    const [recommendedProduct, setRecommendedProduct] = useState([])
    const [promotionProduct, setPromotionProduct] = useState([])
    const [shops, setShops] = useState([])
    const [recommendedCity, setRecommendedCity] = useState(user?.city)
    const [shopData, setShopData] = useState({})
    const cities = useSelector(state => state.generalReducer?.cities)
    const dispatch = useDispatch()
    const { Search } = Input;

    useEffect(() => {
        if (userId) {
            setLoading(true)
            axios.get(`${GET.PAYMENT_STATUS}/${userId}`)
                .then((res) => {
                    const { data } = res
                    getUser()
                    setLoading(false)
                    history?.replace(allPaths.HOME)
                    if (data?.success) {
                        getProducts()
                        return successNotification(assignMessage(data?.message, language?.language))
                    }
                    errorMessage(assignMessage(data?.message, language?.language))
                })
                .catch((e) => {
                    setLoading(false)
                    console.log('e', e)
                    errorMessage(assignMessage(e?.message, language?.language))
                })
        }
    }, [])


    const checkDateofReservation = (settings) => {
        let lastSetting = settings[settings?.length - 1]
        let date = moment(lastSetting?.date).utcOffset(0, true)
        return !date.isBefore(moment().add(1, 'days'), 'day')
    }

    const gotoReview = (v) => {
        setProgressLoading(true)
        setReviewModal(v)
        setTimeout(() => {
            setProgressLoading(false)
        }, 2000);
    }
    const closeReviewModal = (v) => {
        setReviewModal({})
    }

    const getProducts = () => {

    }



    const calcSoldProducts = (arr) => {
        return arr?.reduce((sum, { soldProduct }) => sum + soldProduct, 0)
    }


    const optionsCities = cities?.length && cities?.map((v, i) => {
        return {
            value: convertTitle(v)
        }
    })

    const getPrice = (product) => {
        if (!product?.agePrice) {
            return product?.price?.[0]?.price
        } else {
            return product?.price?.[0]?.[ageOptions[2]]?.price
        }
    }
    return (
        <>
            <div className='home-div-bg-img'>
                <div className='exam-transaprent-div'>
                    <p className='screen-qoute'> {selectedLanguage?.Quote7}</p>
                </div>
            </div>
            <div style={{ background: 'white' }}>
                {!buyProduct?._id && !shopData?._id ?
                    <div>
                        <div className='cards-main-div-guest-all-crds' >
                            <div style={{ marginTop: '20px', marginBottom: '10px' }}>
                                <h1 className='new-exam-title'>{selectedLanguage?.Products}</h1>
                                <div
                                    className='sidebar-scroll_class'
                                    style={{
                                        marginTop: 8,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        overflowX: 'scroll'
                                    }}>
                                    {DayDealsProduct?._id ?
                                        <div
                                            style={{
                                                height: 285,
                                                width: '290px',
                                                minWidth: '290px',
                                                borderRadius: '4px',
                                                margin: '4px',
                                                marginRight: '10px',
                                                boxShadow: ' 0 4px 5px 0 rgba(0, 0, 0, 0.3)',
                                                cursor: 'pointer'
                                            }}>
                                            <div style={{ textAlign: 'center', margin: '8px', position: 'relative' }}                                                >
                                                <img
                                                    onClick={() => {
                                                        DayDealsProduct?.userId?.supplierType === 'shops' ?
                                                            setShopData(DayDealsProduct) :
                                                            setBuyProduct(DayDealsProduct)
                                                    }}
                                                    src={DayDealsProduct?.imageUrl} style={{ height: '130px', width: '100%', maxWidth: '100%', borderRadius: '4px' }} />
                                            </div>
                                            <div style={{ textAlign: 'center', margin: '8px' }}>
                                                <div className='div-flex-back-name' style={{ justifyContent: 'space-between', height: '30px' }}>
                                                    <div onClick={() => {
                                                        DayDealsProduct?.userId?.supplierType === 'shops' ?
                                                            setShopData(DayDealsProduct) :
                                                            setBuyProduct(DayDealsProduct)
                                                    }} >
                                                        <p className='guest-card-tect-string'
                                                            style={{
                                                                fontSize: 18,
                                                                color: 'black',
                                                            }}>{stringLimiter(DayDealsProduct?.name, 14) || '------'}</p>
                                                        <p className='guest-card-tect-string'
                                                            style={{
                                                                fontSize: 13,
                                                                color: '#7A7A7A',
                                                                width: '100px',
                                                            }}>{DayDealsProduct?.userId?.city || '------'}</p>

                                                        <p className='guest-card-tect-string'
                                                            style={{
                                                                fontSize: 13,
                                                                color: '#7A7A7A',
                                                                width: '100px'
                                                            }}>{DayDealsProduct?.promote ? selectedLanguage?.text_Sponsored : ''}</p>

                                                    </div>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'flex-end',
                                                            height: '100px',
                                                            marginTop: '-8px',
                                                        }}
                                                        onClick={() => { gotoReview(DayDealsProduct) }} >
                                                        <button className='guest-card-review-box'>
                                                            {DayDealsProduct?.review?.length ?
                                                                <AiFillStar size={15} color='white' />
                                                                : null}
                                                            {!DayDealsProduct?.isReviewHide ? DayDealsProduct?.review?.length ? calculateReviewAverage(DayDealsProduct?.review) : 0 : 0}
                                                        </button>
                                                        <p className='card_desc1' style={{ textAlign: 'end' }}>
                                                            {!DayDealsProduct?.isReviewHide ?
                                                                DayDealsProduct?.review?.length ?
                                                                    calculateReviewAverage(DayDealsProduct?.review) < 4.5 ?
                                                                        selectedLanguage?.Bad + ',' :
                                                                        calculateReviewAverage(DayDealsProduct?.review) < 5.9 ?
                                                                            selectedLanguage?.Poor + ',' :
                                                                            calculateReviewAverage(DayDealsProduct?.review) < 6.9 ?
                                                                                selectedLanguage?.Adeqate + ',' :
                                                                                calculateReviewAverage(DayDealsProduct?.review) < 8.9 ?
                                                                                    selectedLanguage?.Good + ',' :
                                                                                    selectedLanguage?.Excellent + ',' : null : null}
                                                            {!DayDealsProduct?.isReviewHide ?
                                                                DayDealsProduct?.review?.length ?
                                                                    DayDealsProduct?.review?.length !== 1 ?
                                                                        DayDealsProduct?.review?.length > 999 ?
                                                                            ' ' +
                                                                            `${DayDealsProduct?.review?.length?.slice}`(0, 1) +
                                                                            '.' +
                                                                            `${DayDealsProduct?.review?.length}`?.slice(1, 2) +
                                                                            'k' + ' ' + selectedLanguage?.Reviews :
                                                                            ' ' + DayDealsProduct?.review?.length + ' ' + selectedLanguage?.Reviews :
                                                                        ' ' + DayDealsProduct?.review?.length + ' ' + selectedLanguage?.Review :
                                                                    selectedLanguage?.NoReviewesYet :
                                                                selectedLanguage?.NoReviewesYet}
                                                        </p>
                                                    </div>
                                                </div>
                                                <br />
                                                <br />
                                                <div onClick={() => {
                                                    DayDealsProduct?.userId?.supplierType === 'shops' ?
                                                        setShopData(DayDealsProduct) :
                                                        setBuyProduct(DayDealsProduct)
                                                }}>
                                                    <p style={{
                                                        color: 'grey',
                                                        textAlign: 'start',
                                                        lineHeight: 1.4,
                                                        height: '40px',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        lineBreak: 'anywhere',
                                                        marginBottom: 0
                                                    }}
                                                        className='card_sold'
                                                    >
                                                        {DayDealsProduct?.description || '-------------------------------'}
                                                    </p>
                                                </div>
                                                <span onClick={() => {
                                                    DayDealsProduct?.userId?.supplierType === 'shops' ?
                                                        setShopData(DayDealsProduct) :
                                                        setBuyProduct(DayDealsProduct)
                                                }}>
                                                    <div className='card_sold_price'>
                                                        <p className='card_sold' style={{ color: '#ec671b' }}>{selectedLanguage?.Sold}:{calcSoldProducts(DayDealsProduct?.reservation?.settings || [])}</p>
                                                        <p className='card_sold' style={{ color: '#ec671b' }}>€{' ' + getPrice(DayDealsProduct)}</p>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        :
                                        <div style={{ height: '280px', width: '100%' }}>
                                            <div style={{ textAlign: 'center' }}>
                                                <img style={{ height: '200px', width: '200px', padding: '20px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }} src={NoCards} />
                                            </div>
                                        </div>
                                    }
                                </div >
                            </div >
                        </div >
                    </div >
                    :
                    shopData?._id ?
                        <div className='flex-column menu-main'>
                            <ShowShops product={shopData} onCancel={() => setShopData({})} {...props} />
                        </div> :
                        <div className='flex-column menu-main'>
                            <BuyProduct calcSoldProducts={calcSoldProducts} product={buyProduct} getProducts={getProducts} onCancel={() => setBuyProduct({})} {...props} />
                        </div>
                }
                <ProductReview ProgressLoading={ProgressLoading} reviewModal={reviewModal} closeReviewModal={closeReviewModal}  {...props} />
            </div>
        </>

    )
}

export default GuestDayDealsProduct