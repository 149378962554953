import { Image, Select, Table } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN } from '../../../utils/apis'
import { allAdminData, checkSelectOption, convertTitle } from '../../../utils/helpers'
const { Option } = Select

const BuGiftCards = (props) => {
    const { user, adminActions, selectedLanguage } = props
    const buUsers = useSelector(state => state?.adminReducer?.buUsers || [])
    const [selectedUser, setSelectedUser] = useState(buUsers?.[0]?._id || null)
    const [requests, setRequests] = useState([])
    const [loading, setLoading] = useState(false)
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    useEffect(() => {
        allAdminData?.getBusinessUser(adminActions, user?._id)
        if (buUsers?.length) {
            getRequests()
        }
    }, [selectedUser])

    const getRequests = () => {
        setLoading(true)
        axios.get(`${ADMIN.GET_ALL_GIFT_CARDS_FOR_ADMIN}/${selectedUser}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setRequests(data?.data)
            }).catch((err) => {
                setLoading(false)
            })
    }

    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Image}</p>,
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            render: (e) => <Image src={e} style={{ marginRight: 10, width: 86, height: 60 }} />
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_UserName}</p>,
            dataIndex: 'userId',
            key: 'userId',
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.ttx_GiftCardCode}</p>,
            dataIndex: 'giftCardCode',
            key: 'giftCardCode',
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Amount}</p>,
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => a.amount - b.amount,
            showSorterTooltip: false,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {e}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Code}</p>,
            dataIndex: 'giftCardCode',
            key: 'giftCardCode',
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_IsUsed}</p>,
            dataIndex: 'isUsed',
            key: 'isUsed',
            sorter: (a, b) => a.isUsed - b.isUsed,
            showSorterTooltip: false,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? selectedLanguage?.True : selectedLanguage?.False}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_isAdmin}</p>,
            dataIndex: 'isAdmin',
            key: 'isAdmin',
            sorter: (a, b) => a.isAdmin - b.isAdmin,
            showSorterTooltip: false,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? selectedLanguage?.True : selectedLanguage?.False}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_created}</p>,
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p>{moment(e).utc().format('YYYY-MM-DD')}</p>
        },
    ]

    let mapdata = requests?.map((v, i) => {
        return {
            userId: v?.isAdmin ? v?.adminId?.userName : v?.userId.fullName,
            giftCardCode: v?.giftCardCode,
            imageUrl: v?.imageUrl,
            isUsed: v?.isUsed,
            amount: v?.amount,
            isAdmin: v?.isAdmin,
            created: v?.created,
        }
    })

    return (
        <div>
            <Select
                // showSearch
                showArrow
                allowClear
                style={{ width: '200px', margin: '15px' }}
                defaultValue={selectedUser}
                onChange={(e) => setSelectedUser(e)}
                className='form-input'
                placeholder={selectedLanguage.txt_SelectUser}
                showSearch
                optionFilterProp='children'
                filterOption={checkSelectOption}
            >
                {buUsers?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.fullName)} ${v?.email}`}</Option>)}
            </Select>

            <div className='new-layout-table-upper-div'>
                <Table
                    style={{ marginTop: '30px' }}
                    className='new-layout-table'
                    locale={customLocale}
                    loading={loading}
                    dataSource={mapdata}
                    columns={columns}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 800 }}
                />
            </div>
        </div>
    )
}

export default BuGiftCards