import { FileOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { AutoComplete, Button, Checkbox, Form, Input, InputNumber, Radio, Select, Switch, Upload } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PreviewStudentQuestion } from '../../Components'
import { ADMIN, POST } from '../../utils/apis'
import { checkSelectOption, errorMessage, generalData, getDateDifference, successMessage } from '../../utils/helpers'
const { Option } = Select

const AddBuDayDealsQuestion = (props) => {
    const { user, selectedLanguage, generalActions, userActions, adminActions } = props
    const buDaydealsProducts = useSelector(state => state.userReducer.buDaydealsProducts) || []
    const buDaydealsApprovedProducts = buDaydealsProducts?.filter((e) => e?.isApproved)
    const [form] = Form.useForm()
    const [ansType, setAnsType] = useState('inputNumber')
    const [ansList, setansList] = useState('')
    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState([])
    const [titleFileList, setTitleFileList] = useState([])
    const [previewQuestion, setPreviewQuestion] = useState(false)

    var error1
    var error2
    const normFile = (e) => {
        if (e?.file?.size > 50000000) {
            return errorMessage(selectedLanguage?.backendMessage_FileSizeshouldbelessthen50MB)
        }
        return e?.fileList[0];
    }
    const normFile2 = (e) => {
        if (e?.file?.size > 50000000) {
            return errorMessage(selectedLanguage?.backendMessage_FileSizeshouldbelessthen50MB)
        }
        return setFileList(e?.fileList)
    }
    const normFile3 = (e) => {
        if (e?.file?.size > 50000000) {
            return errorMessage(selectedLanguage?.backendMessage_FileSizeshouldbelessthen50MB)
        }

        return setTitleFileList(e?.fileList);
    }
    const onChange = e => {
        setAnsType(e.target.value)
    }


    const onFinish = async (values) => {

        setLoading(true)
        values.answerType = ansType
        if (ansType === 'input') {
            values.answerList = values?.answerList1
        }
        if (ansType === 'inputNumber') {
            values.answerList = values?.numAnswerList
        }
        else if (ansType === 'radio') {
            values.answerList = values?.answerList2
        }
        else if (ansType === 'multiple') {
            values.answerList = values?.answerList3
        }
        else if (ansType === 'hotspot') {
            values.answerList = values?.answerList5
        }
        else if (ansType === 'rating') {
            values.answerList = values?.answerList6
        }
        else if (ansType === 'thesis') {
            values.answerList = values?.answerList7
        }
        else {
            values.answerList = values?.answerList4
        }

        let checkCorrectAnsCond = _.find(values?.answerList, { 'isTrue': true })

        if (values?.answerType !== 'thesis' && values?.answerType !== 'input' && values?.answerType !== 'inputNumber' && values?.answerType !== 'upload' && !checkCorrectAnsCond) {
            setLoading(false)
            return errorMessage(selectedLanguage?.errorMessage_PleaseSelectaCorrectAnswer)
        }

        values.userId = user?._id



        if (fileList[0]?.originFileObj) {
            let formData = new FormData()
            formData.append('file', fileList[0]?.originFileObj)
            await axios.post(ADMIN.GET_FILE_URL, formData)
                .then((res) => {
                    const { data } = res
                    if (data.success) {
                        values.file = data?.data
                    } else {
                        return errorMessage(selectedLanguage?.errorMessage_FileUploadError)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    return errorMessage(e?.message)

                })
        } else if (fileList[0]?.url) {
            let fileObj = {
                mediaType: fileList[0]?.mediaType,
                name: fileList[0]?.name,
                url: fileList[0]?.perUrl,
            }
            values.file = fileObj
        }

        if (titleFileList[0]?.originFileObj) {
            let formData = new FormData()
            formData.append('file', titleFileList[0]?.originFileObj)
            await axios.post(ADMIN.GET_FILE_URL, formData)
                .then((res) => {
                    const { data } = res
                    if (data.success) {
                        values.titleFile = data?.data
                    } else {
                        return errorMessage(selectedLanguage?.errorMessage_FileUploadError)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    return errorMessage(e?.message)


                })
        } else if (titleFileList[0]?.url) {
            let fileObj = {
                mediaType: titleFileList[0]?.mediaType,
                name: titleFileList[0]?.name,
                url: titleFileList[0]?.perUrl,
            }
            values.titleFile = fileObj
        }


        for (var i = 0; i < values?.answerList?.length; i++) {
            if (ansType !== 'dragDrop' && ansType !== 'dragDropSequence' && values?.answerList[i]?.file?.originFileObj) {
                let fileData = new FormData()
                fileData.append('file', values?.answerList[i]?.file?.originFileObj)
                await axios.post(ADMIN.GET_FILE_URL, fileData)
                    .then((res) => {
                        const { data } = res
                        if (data.success) {
                            values.answerList[i].file = data?.data
                        } else {
                            return errorMessage(selectedLanguage?.errorMessage_FileUploadError)
                        }
                    })
                    .catch((e) => {
                        console.log(e)
                        return errorMessage(e?.message)

                    })
            } else if (ansType !== 'dragDrop' && ansType !== 'dragDropSequence' && values?.answerList[i]?.file?.url) {
                let fileObj = {
                    mediaType: values?.answerList[i]?.file?.mediaType,
                    name: values?.answerList[i]?.file?.name,
                    url: values?.answerList[i]?.file?.perUrl,
                }
                values.answerList[i].file = fileObj
                // values.titleFile = titleFileList[0]?.url
            }
        }


        console.log(values, '======')

        await axios.post(POST?.ADD_QUESTION_WITH_PRODUCT, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successMessage(data?.message)
                    form.resetFields()
                } else {
                    errorMessage(data?.message)
                }
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
                return errorMessage(e?.message)
            })
    }

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        })


    const handlePreview2 = async (file) => {
        if (file?.fileList?.[0]?.uid && !file?.fileList?.[0]?.url && !file?.fileList?.[0]?.preview) {
            file.fileList[0].preview = await getBase64(file?.fileList?.[0]?.originFileObj)
        }
        return (file?.fileList?.[0]?.url || file?.fileList?.[0]?.preview || '')
    }

    const quesData = () => {
        let allData = form.getFieldsValue()

        allData.spots = allData?.spots?.map((v, i) => {
            return {
                id: i,
                x: v?.x + '%',
                y: v?.y + '%',
                correct: v?.correct
            }
        })

        allData.titleFile = titleFileList?.length ? (titleFileList?.[0]?.preview || titleFileList?.[0]?.url) : null
        allData.file = fileList?.length ? (fileList?.[0]?.preview || fileList?.[0]?.url) : null
        allData.answerType = ansType

        allData.answerList = (allData?.answerList1?.length && allData?.answerList1) || (allData?.answerList2?.length && allData?.answerList2) || (allData?.answerList3?.length && allData?.answerList3) || (allData?.answerList4?.length && allData?.answerList4) || (allData?.answerList5?.length && allData?.answerList5) || (allData?.answerList6?.length && allData?.answerList6) || (allData?.answerList7?.length && allData?.answerList7) || (allData?.numAnswerList?.length && allData?.numAnswerList)

        return allData
    }
    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote10}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>
                    <h3 className='new-exam-title'>{selectedLanguage?.button_AllQuestion}</h3>
                    <div className='crt-question-div'>
                        {
                            previewQuestion && <PreviewStudentQuestion onCancelPreview={() => setPreviewQuestion(false)} previewQuestion={previewQuestion} quesData={quesData} {...props} />
                        }
                        <Form
                            form={form}
                            onFinish={onFinish}
                            name='QuestionsForm'
                            layout='vertical'
                            requiredMark={true}
                        >
                            <Form.Item
                                name='title'
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.requiredMessage_pleaseEnteraTitle,
                                    }
                                ]}
                                label={selectedLanguage?.title_Title}
                                required
                            >
                                <Input.TextArea autoSize={{ minRows: 2 }} placeholder={selectedLanguage?.title_Title} />
                            </Form.Item>

                            <Form.Item
                                label={selectedLanguage?.title_FileforTitle}
                                name='titleFile'
                                style={{ width: '100%' }}
                                valuePropName='file'
                                getValueFromEvent={normFile3}
                            >
                                <Upload
                                    className='questionFileUpload'
                                    multiple={false}
                                    maxCount={1}
                                    beforeUpload={() => false}
                                    fileList={titleFileList}
                                    onChange={handlePreview2}
                                    accept='image/*, video/*, .pdf'
                                    action='/upload.do'
                                    style={{ width: '100%' }}
                                >
                                    <Button type='primary'
                                        style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                    <br />
                                </Upload>
                            </Form.Item>

                            <Form.Item
                                name='time'
                                label={selectedLanguage?.label_Time}
                                initialValue={8}
                            >
                                <InputNumber
                                    controls={false}
                                    addonAfter='s'
                                    style={{ width: '100%', maxWidth: 200 }}
                                    placeholder={selectedLanguage?.txt_Time_inSeconds}
                                    type='number'
                                    max={300}
                                    min={8}
                                    step={1}
                                />
                            </Form.Item>


                            <Form.Item
                                name='product'
                                label={'Select Product'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Product...',
                                    },
                                ]}
                            >
                                <Select
                                    showArrow
                                    allowClear
                                    placeholder={'Select Product here...'}
                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}
                                >
                                    {buDaydealsApprovedProducts?.map((v, i) => {
                                        return (
                                            <Option value={v?._id} key={`product${i}`}>{v?.name} - {getDateDifference(v?.created)}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>


                            <Form.Item
                                name='question'
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.errorMessage_questionerequired,
                                    }
                                ]}
                                label={selectedLanguage?.title_Question}
                                required
                            >
                                <Input.TextArea autoSize={{ minRows: 2 }} placeholder={selectedLanguage?.ttxt_WhatiscapitalofNeatherland} />
                            </Form.Item>

                            <Form.Item
                                label={selectedLanguage?.File}
                                name='file'
                                style={{ width: '100%' }}
                                valuePropName='file'
                                getValueFromEvent={normFile2}
                            >
                                <Upload
                                    className='questionFileUpload'
                                    multiple={false}
                                    maxCount={1}
                                    beforeUpload={() => false}
                                    fileList={fileList}
                                    onChange={handlePreview2}
                                    accept='image/*, video/*, .pdf'

                                    action='/upload.do'
                                    style={{ width: '100%' }}
                                >
                                    <Button type='primary' style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.button_clickToUpload}</Button>
                                    <br />
                                </Upload>
                            </Form.Item>

                            <Form.Item label={selectedLanguage?.label_SelectTypeofAnswer}>
                                <Radio.Group onChange={onChange} value={ansType}>
                                    <Radio value={'inputNumber'}>{selectedLanguage?.ttx_NumericInput}</Radio>
                                    <Radio value={'radio'}>{selectedLanguage?.radioLabel_RadioOptions}</Radio>
                                    <Radio value={'multiple'}>{selectedLanguage?.radioLabel_SelectMultipleoptions}</Radio>
                                    <Radio value={'single'}>{selectedLanguage?.radioLabel_Selectsingleoptions}</Radio>
                                    <Radio value={'thesis'}>{selectedLanguage?.txt_Thesis}</Radio>
                                    <Radio value={'hotspot'}>{selectedLanguage?.txt_Hotspot}</Radio>
                                </Radio.Group>
                            </Form.Item>

                            {ansType === 'input' || ansType === 'upload' ? null :
                                ansType === 'inputNumber' ?
                                    <Form.List
                                        name='numAnswerList'
                                        initialValue={ansList?.length ? ansList.slice(0, 1) : [{ isTrue: true, option: 0, explaination: '' }]}
                                    >
                                        {(fields, { add, remove }, { errors }) => (
                                            <>
                                                {fields.map((field, name, index) => (
                                                    <Form.Item
                                                        required={false}
                                                        key={`inputNumber${field.key}`}
                                                    >
                                                        <div style={{ display: 'flex' }}>
                                                            <Form.Item
                                                                {...field}
                                                                name={[name, 'isTrue']}
                                                                valuePropName='checked'
                                                                noStyle
                                                                initialValue={true}
                                                            >
                                                                <Switch disabled style={{ marginRight: 5, marginTop: 5 }} checkedChildren='T' unCheckedChildren='F' />
                                                            </Form.Item>

                                                            <Form.Item
                                                                {...field}
                                                                name={[name, 'option']}
                                                                validateTrigger={['onChange', 'onBlur']}
                                                                noStyle
                                                            >
                                                                <InputNumber type='number' placeholder='Option' style={{ width: '100%' }} />
                                                            </Form.Item>
                                                        </div>

                                                        <Form.Item
                                                            {...field}
                                                            name={[name, 'explaination']}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    whitespace: true,
                                                                    message: selectedLanguage?.errorMessage_PleaseinputExplaination,
                                                                }
                                                            ]}
                                                            noStyle
                                                        >
                                                            <Input.TextArea autoSize={{ minRows: 1 }} placeholder={selectedLanguage?.txt_explanation} style={{ width: '100%', marginTop: 5 }} />
                                                        </Form.Item>

                                                    </Form.Item>
                                                ))}
                                            </>
                                        )}
                                    </Form.List> :
                                    ansType === 'radio' ?
                                        <Form.List
                                            name='answerList2'
                                            rules={[
                                                {
                                                    validator: async (_, names) => {
                                                        if (!names || names.length < 2) {
                                                            return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options))
                                                        }
                                                    }
                                                }
                                            ]}
                                            initialValue={ansList}
                                        >
                                            {(fields, { add, remove }, { errors }) => (
                                                <>
                                                    {fields.map((field, name, index) => (
                                                        <Form.Item
                                                            required={false}
                                                            key={`answerList2${field.key}`}
                                                        >
                                                            <div style={{ display: 'flex' }}>
                                                                <Form.Item
                                                                    {...field}
                                                                    name={[name, 'isTrue']}
                                                                    valuePropName='checked'
                                                                    noStyle
                                                                    initialValue={false}
                                                                >
                                                                    <Switch style={{ marginRight: 5, marginTop: 5 }} checkedChildren='T' unCheckedChildren='F' />
                                                                </Form.Item>

                                                                <Form.Item
                                                                    {...field}
                                                                    name={[name, 'option']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            whitespace: true,
                                                                            message: selectedLanguage?.errorMessage_Pleaseinputoptionsordeletethisfield,
                                                                        },
                                                                    ]}
                                                                    noStyle
                                                                >
                                                                    <Input.TextArea autoSize={{ minRows: 1 }} placeholder='Option' style={{ width: '100%' }} />
                                                                </Form.Item>
                                                            </div>

                                                            <Form.Item
                                                                {...field}
                                                                name={[name, 'explaination']}
                                                                validateTrigger={['onChange', 'onBlur']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        whitespace: true,
                                                                        message: selectedLanguage?.errorMessage_PleaseinputExplaination,
                                                                    },
                                                                ]}
                                                                noStyle
                                                            >
                                                                <Input.TextArea autoSize={{ minRows: 1 }} placeholder='explanation' style={{ width: '100%', marginTop: 5 }} />
                                                            </Form.Item>

                                                            <div style={{ display: 'flex' }}>
                                                                <div style={{ width: '100%' }}>
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[name, 'file']}
                                                                        style={{ width: '100%' }}
                                                                        valuePropName='file'
                                                                        getValueFromEvent={normFile}

                                                                    >
                                                                        <Upload
                                                                            className='questionFileUpload'
                                                                            multiple={false}
                                                                            maxCount={1}
                                                                            beforeUpload={() => false}
                                                                            accept='image/*, video/*, .pdf'
                                                                            onChange={handlePreview2}
                                                                            action='/upload.do'
                                                                            style={{ width: '100%' }}
                                                                        >
                                                                            <Button disabled={ansList[name]?.file?.url} style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                                                            <br />
                                                                        </Upload>
                                                                    </Form.Item>
                                                                    {ansList[name]?.file?.url ? <a target='blank' href={ansList[name]?.file?.url}><FileOutlined />&nbsp;{ansList[name]?.file?.name}</a> : null}
                                                                </div>
                                                                &nbsp;
                                                                {fields.length > 1 ? (
                                                                    <MinusCircleOutlined
                                                                        style={{ marginTop: 8 }}
                                                                        className='dynamic-delete-button'
                                                                        onClick={() => remove(field.name)}
                                                                    />
                                                                ) : null}
                                                            </div>
                                                        </Form.Item>
                                                    ))}
                                                    <Form.Item>
                                                        <Button
                                                            type='dashed'
                                                            onClick={() => add()}
                                                            style={{ width: '100%' }}
                                                            icon={<PlusOutlined />}
                                                        >
                                                            {selectedLanguage?.button_AddField}
                                                        </Button>
                                                        <Form.ErrorList errors={errors} />
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List> :
                                        ansType === 'multiple' ?
                                            <Form.List
                                                name='answerList3'
                                                rules={[
                                                    {
                                                        validator: async (_, names) => {
                                                            if (!names || names.length < 2) {
                                                                return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options))
                                                            }
                                                        }
                                                    }
                                                ]}
                                                initialValue={ansList}
                                            >
                                                {(fields, { add, remove }, { errors }) => (
                                                    <>
                                                        {fields.map((field, name, index) => (
                                                            <Form.Item
                                                                required={false}
                                                                key={`answerList3${field.key}`}
                                                            >
                                                                <div style={{ display: 'flex' }}>
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[name, 'isTrue']}
                                                                        valuePropName='checked'
                                                                        noStyle
                                                                        initialValue={false}
                                                                    >
                                                                        <Switch style={{ marginRight: 5, marginTop: 5 }} checkedChildren='T' unCheckedChildren='F' />
                                                                    </Form.Item>

                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[name, 'option']}
                                                                        validateTrigger={['onChange', 'onBlur']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                whitespace: true,
                                                                                message: selectedLanguage?.errorMessage_Pleaseinputoptionsordeletethisfield,
                                                                            },
                                                                        ]}
                                                                        noStyle
                                                                    >
                                                                        <Input.TextArea autoSize={{ minRows: 1 }} placeholder='Option' style={{ width: '100%' }} />
                                                                    </Form.Item>
                                                                </div>

                                                                <Form.Item
                                                                    {...field}
                                                                    name={[name, 'explaination']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            whitespace: true,
                                                                            message: selectedLanguage?.errorMessage_PleaseinputExplaination,
                                                                        },
                                                                    ]}
                                                                    noStyle
                                                                >
                                                                    <Input.TextArea autoSize={{ minRows: 1 }} placeholder='explanation' style={{ width: '100%', marginTop: 5 }} />
                                                                </Form.Item>

                                                                <div style={{ display: 'flex' }}>
                                                                    <div style={{ width: '100%' }}>
                                                                        <Form.Item
                                                                            {...field}
                                                                            name={[name, 'file']}
                                                                            style={{ width: '100%' }}
                                                                            valuePropName='file'
                                                                            getValueFromEvent={normFile}

                                                                        >
                                                                            <Upload
                                                                                className='questionFileUpload'
                                                                                multiple={false}
                                                                                maxCount={1}
                                                                                beforeUpload={() => false}
                                                                                accept='image/*, video/*, .pdf'
                                                                                onChange={handlePreview2}

                                                                                action='/upload.do'
                                                                                style={{ width: '100%' }}
                                                                            >
                                                                                <Button disabled={ansList[name]?.file?.url} style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                                                                <br />
                                                                            </Upload>
                                                                        </Form.Item>
                                                                        {ansList[name]?.file?.url ? <a target='blank' href={ansList[name]?.file?.url}><FileOutlined />&nbsp;{ansList[name]?.file?.name}</a> : null}
                                                                    </div>
                                                                    &nbsp;
                                                                    {fields.length > 1 ? (
                                                                        <MinusCircleOutlined
                                                                            style={{ marginTop: 8 }}
                                                                            className='dynamic-delete-button'
                                                                            onClick={() => remove(field.name)}
                                                                        />
                                                                    ) : null}
                                                                </div>
                                                            </Form.Item>
                                                        ))}
                                                        <Form.Item>
                                                            <Button
                                                                type='dashed'
                                                                onClick={() => add()}
                                                                style={{ width: '100%' }}
                                                                icon={<PlusOutlined />}
                                                            >
                                                                {selectedLanguage?.button_AddField}
                                                            </Button>
                                                            <Form.ErrorList errors={errors} />
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List> :
                                            ansType === 'hotspot' ?
                                                <Form.List
                                                    name='answerList5'
                                                    rules={[
                                                        {
                                                            validator: async (_, names) => {

                                                                if (!names || names.length < 2) {
                                                                    return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options))
                                                                }
                                                            }
                                                        }
                                                    ]}
                                                    initialValue={ansList}
                                                >
                                                    {(fields, { add, remove }, { errors }) => (
                                                        <>
                                                            {fields.map((field, name, index) => (
                                                                <Form.Item
                                                                    key={`answerList5${field.key}`}
                                                                >
                                                                    <div style={{ display: 'flex' }}>
                                                                        <Form.Item
                                                                            {...field}
                                                                            name={[name, 'isTrue']}
                                                                            valuePropName='checked'
                                                                            noStyle
                                                                            initialValue={false}
                                                                        >
                                                                            <Switch style={{ marginRight: 5, marginTop: 5 }} checkedChildren='T' unCheckedChildren='F' />
                                                                        </Form.Item>
                                                                        <div style={{ width: '100%' }}>
                                                                            <Form.Item
                                                                                required
                                                                                {...field}
                                                                                name={[name, 'file']}
                                                                                style={{ width: '100%' }}
                                                                                valuePropName='file'
                                                                                getValueFromEvent={normFile}
                                                                                rules={[
                                                                                    {
                                                                                        validator: async (_, names) => {

                                                                                            if (!names) {
                                                                                                return Promise.reject(new Error('Please Upload file'))
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                ]}
                                                                            >
                                                                                <Upload
                                                                                    className='questionFileUpload'
                                                                                    multiple={false}
                                                                                    maxCount={1}
                                                                                    beforeUpload={() => false}
                                                                                    accept='image/*'
                                                                                    onChange={handlePreview2}
                                                                                    action='/upload.do'
                                                                                    style={{ width: '100%' }}
                                                                                >
                                                                                    <Button disabled={ansList[name]?.file?.url} style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                                                                    <br />
                                                                                </Upload>
                                                                            </Form.Item>
                                                                            {ansList[name]?.file?.url ? <a target='blank' href={ansList[name]?.file?.url}><FileOutlined />&nbsp;{ansList[name]?.file?.name}</a> : null}

                                                                            <Form.Item
                                                                                {...field}
                                                                                name={[name, 'explaination']}
                                                                                validateTrigger={['onChange', 'onBlur']}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        whitespace: true,
                                                                                        message: selectedLanguage?.errorMessage_PleaseinputExplaination,
                                                                                    },
                                                                                ]}
                                                                                noStyle
                                                                            >
                                                                                <Input.TextArea autoSize={{ minRows: 1 }} placeholder='explanation' style={{ width: '100%', marginTop: 5 }} />
                                                                            </Form.Item>

                                                                        </div>
                                                                        &nbsp;
                                                                        {fields.length > 1 ? (
                                                                            <MinusCircleOutlined
                                                                                style={{ marginTop: 8 }}
                                                                                className='dynamic-delete-button'
                                                                                onClick={() => remove(field.name)}
                                                                            />
                                                                        ) : null}

                                                                    </div>
                                                                </Form.Item>
                                                            ))}
                                                            {fields.length < 4 &&
                                                                <Form.Item>
                                                                    <Button
                                                                        type='dashed'
                                                                        onClick={() => add()}
                                                                        style={{ width: '100%' }}
                                                                        icon={<PlusOutlined />}
                                                                    >
                                                                        {selectedLanguage?.button_AddField}
                                                                    </Button>
                                                                    <Form.ErrorList errors={errors} />
                                                                </Form.Item>}
                                                        </>
                                                    )}
                                                </Form.List> :
                                                ansType === 'thesis' ?
                                                    <Form.List
                                                        name='answerList7'
                                                        rules={[
                                                            {
                                                                validator: async (_, names) => {
                                                                    if (!names || names.length < 2) {
                                                                        return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options))
                                                                    }
                                                                }
                                                            }
                                                        ]}
                                                        initialValue={ansList}
                                                    >
                                                        {(fields, { add, remove }, { errors }) => (
                                                            <>
                                                                <Form.Item
                                                                    required={false}
                                                                    key={`answerList7${0}`}
                                                                    label={`${selectedLanguage?.txt_Thesis} 1`}
                                                                >
                                                                    <div style={{ display: 'flex' }}>
                                                                        <Form.Item
                                                                            name={[0, 'isTrue']}
                                                                            valuePropName='checked'
                                                                            noStyle
                                                                            initialValue={false}
                                                                        >
                                                                            <Switch
                                                                                style={{ marginRight: 5, marginTop: 5 }}
                                                                                checkedChildren='T' unCheckedChildren='F' />
                                                                        </Form.Item>

                                                                        <Form.Item
                                                                            name={[0, 'option']}
                                                                            validateTrigger={['onChange', 'onBlur']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    whitespace: true,
                                                                                    message: `${selectedLanguage?.erorr_messae_Please_enter_txt_Thesis} ${1}`,
                                                                                },
                                                                            ]}
                                                                            noStyle
                                                                        >
                                                                            <Input.TextArea autoSize={{ minRows: 1 }} placeholder={`${selectedLanguage?.txt_Thesis} ${1}`} style={{ width: '100%' }} />
                                                                        </Form.Item>
                                                                    </div>
                                                                </Form.Item>

                                                                <Form.Item
                                                                    required={false}
                                                                    key={`answerList7${1}`}
                                                                    label={`${selectedLanguage?.txt_Thesis} 2`}
                                                                >
                                                                    <div style={{ display: 'flex' }}>
                                                                        <Form.Item
                                                                            name={[1, 'isTrue']}
                                                                            valuePropName='checked'
                                                                            noStyle
                                                                            initialValue={false}
                                                                        >
                                                                            <Switch
                                                                                style={{ marginRight: 5, marginTop: 5 }}
                                                                                checkedChildren='T' unCheckedChildren='F' />
                                                                        </Form.Item>

                                                                        <Form.Item
                                                                            name={[1, 'option']}
                                                                            validateTrigger={['onChange', 'onBlur']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    whitespace: true,
                                                                                    message: `${selectedLanguage?.erorr_messae_Please_enter_txt_Thesis} ${2}`,
                                                                                },
                                                                            ]}
                                                                            noStyle
                                                                        >
                                                                            <Input.TextArea autoSize={{ minRows: 1 }} placeholder={`${selectedLanguage?.txt_Thesis} ${2}`} style={{ width: '100%' }} />
                                                                        </Form.Item>
                                                                    </div>
                                                                </Form.Item>
                                                            </>
                                                        )}
                                                    </Form.List> :
                                                    <Form.List
                                                        name='answerList4'
                                                        rules={[
                                                            {
                                                                validator: async (_, names) => {
                                                                    if (!names || names.length < 2) {
                                                                        return Promise.reject(new Error(selectedLanguage?.errorMessage_Atleast2options))
                                                                    }
                                                                }
                                                            }
                                                        ]}
                                                        initialValue={ansList}
                                                    >
                                                        {(fields, { add, remove }, { errors }) => (
                                                            <>
                                                                {fields.map((field, name, index) => (
                                                                    <Form.Item
                                                                        required={false}
                                                                        key={`answerList4${field.key}`}
                                                                    >
                                                                        <div style={{ display: 'flex' }}>
                                                                            <Form.Item
                                                                                {...field}
                                                                                name={[name, 'isTrue']}
                                                                                valuePropName='checked'
                                                                                noStyle
                                                                                initialValue={false}
                                                                            >
                                                                                <Switch style={{ marginRight: 5, marginTop: 5 }} checkedChildren='T' unCheckedChildren='F' />
                                                                            </Form.Item>

                                                                            <Form.Item
                                                                                {...field}
                                                                                name={[name, 'option']}
                                                                                validateTrigger={['onChange', 'onBlur']}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        whitespace: true,
                                                                                        message: selectedLanguage?.errorMessage_Pleaseinputoptionsordeletethisfield,
                                                                                    },
                                                                                ]}
                                                                                noStyle
                                                                            >
                                                                                <Input.TextArea autoSize={{ minRows: 1 }} placeholder='Option' style={{ width: '100%' }} />
                                                                            </Form.Item>
                                                                        </div>

                                                                        <Form.Item
                                                                            {...field}
                                                                            name={[name, 'explaination']}
                                                                            validateTrigger={['onChange', 'onBlur']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    whitespace: true,
                                                                                    message: selectedLanguage?.errorMessage_PleaseinputExplaination,
                                                                                },
                                                                            ]}
                                                                            noStyle
                                                                        >
                                                                            <Input.TextArea autoSize={{ minRows: 1 }} placeholder='explanation' style={{ width: '100%', marginTop: 5 }} />
                                                                        </Form.Item>

                                                                        <div style={{ display: 'flex' }}>
                                                                            <div style={{ width: '100%' }}>
                                                                                <Form.Item
                                                                                    {...field}
                                                                                    name={[name, 'file']}
                                                                                    style={{ width: '100%' }}
                                                                                    valuePropName='file'
                                                                                    getValueFromEvent={normFile}

                                                                                >
                                                                                    <Upload
                                                                                        className='questionFileUpload'
                                                                                        multiple={false}
                                                                                        maxCount={1}
                                                                                        beforeUpload={() => false}
                                                                                        accept='image/*, video/*, .pdf'
                                                                                        onChange={handlePreview2}

                                                                                        action='/upload.do'
                                                                                        style={{ width: '100%' }}
                                                                                    >
                                                                                        <Button disabled={ansList[name]?.file?.url} style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                                                                        <br />
                                                                                    </Upload>
                                                                                </Form.Item>
                                                                                {ansList[name]?.file?.url ? <a target='blank' href={ansList[name]?.file?.url}><FileOutlined />&nbsp;{ansList[name]?.file?.name}</a> : null}
                                                                            </div>
                                                                            &nbsp;
                                                                            {fields.length > 1 ? (
                                                                                <MinusCircleOutlined
                                                                                    style={{ marginTop: 8 }}
                                                                                    className='dynamic-delete-button'
                                                                                    onClick={() => remove(field.name)}
                                                                                />
                                                                            ) : null}
                                                                        </div>
                                                                    </Form.Item>
                                                                ))}
                                                                <Form.Item>
                                                                    <Button
                                                                        type='dashed'
                                                                        onClick={() => add()}
                                                                        style={{ width: '100%' }}
                                                                        icon={<PlusOutlined />}
                                                                    >
                                                                        {selectedLanguage?.button_AddField}
                                                                    </Button>
                                                                    <Form.ErrorList errors={errors} />
                                                                </Form.Item>
                                                            </>
                                                        )}
                                                    </Form.List>

                            }

                            <Form.Item >
                                <Button
                                    type='primary'
                                    size='large'
                                    style={{ float: 'right' }}
                                    onClick={() => setPreviewQuestion(true)}>
                                    {selectedLanguage?.Button_PreviewQuestions}
                                </Button>
                                <br />
                                <br />
                                <Button
                                    type='primary'
                                    htmlType='submit'
                                    block
                                    size='large'
                                    loading={loading}
                                >
                                    {selectedLanguage?.button_Submit}
                                </Button>
                            </Form.Item>
                        </Form>

                    </div >
                </div >
            </div >
        </div >
    )
}

export default AddBuDayDealsQuestion