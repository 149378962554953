import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input, InputNumber, Select, Space } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React, { useState } from 'react'
import { POST } from '../../../../utils/apis'
import { assignMessage, errorMessage, successNotification } from '../../../../utils/helpers'
const { Option } = Select

const EditTax = (props) => {
    const { onCancel, edit, user, getProductsTypes, language, selectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const optionsValue = Form.useWatch('options', form)
    const isEnglish = language?.language === 'english'

    const onFinish = (values) => {

        values.options = _.uniq(optionsValue)
        values._id = edit?._id

        setLoading(true)

        axios.post(POST.EDIT_COUNTRY_TAX, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    successNotification(assignMessage(data?.message, language?.language))
                    getProductsTypes()
                    onCancel()
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                console.log('e', e)
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const getDefaultOptions = () => {
        return _.uniq(optionsValue)
    }

    return (
        <>
            <h3 className='new-exam-title'>{selectedLanguage?.title_UPDATE_TAX_SETTING}</h3>
            <div style={{ textAlign: 'right', width: '99%' }}>
                <Button
                    style={{ borderRadius: 4, marginTop: 6 }}
                    type='primary'
                    onClick={onCancel}
                >
                    {selectedLanguage?.Cancel}
                </Button>
            </div>
            <div style={{ background: 'white', padding: '15px' }}>
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                    form={form}
                >
                    <Form.Item
                        name='countryName'
                        label={selectedLanguage?.Country}
                        initialValue={edit?.countryName}
                    >
                        <Input
                            disabled
                            className='form-input'
                        />
                    </Form.Item>
                    <Form.List name='options' initialValue={edit?.options}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                        key={key}
                                        style={{
                                            display: 'flex',
                                            marginBottom: 8,
                                        }}
                                        align='baseline'
                                    >
                                        <Form.Item
                                            {...restField}
                                            label={name === 0 && selectedLanguage?.label_Options}
                                            name={[name]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: selectedLanguage?.errorMessage_Option,
                                                },
                                            ]}
                                        >
                                            <InputNumber type='number' min={0} placeholder='e.g 0' />
                                        </Form.Item>
                                        {name !== 0 &&
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        }
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                        {selectedLanguage?.button_AddField}
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                    <Form.Item
                        name='default'
                        label={selectedLanguage?.label_Default}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.placeholder_Default
                            }
                        ]}
                        initialValue={edit?.default}
                    >
                        <Select >
                            {getDefaultOptions()?.map((v, i) => {
                                return (
                                    <Option key={i} value={v}>{v}%</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type='primary'
                            className='form-button'
                            block
                            htmlType='submit'
                            loading={loading}
                        >
                            {selectedLanguage?.Update}
                        </Button>

                    </Form.Item>
                </Form>
            </div>
        </>
    )
}

export default EditTax