import { DeleteOutlined, EditTwoTone, SearchOutlined, ShareAltOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Popconfirm, Select, Space, Spin, Table, Tooltip } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Highlighter from 'react-highlight-words'
import { useSelector } from 'react-redux'
import { ADMIN } from '../../../utils/apis'
import { allAdminData, assignMessage, checkSelectOption, convertTitle, errorMessage, successMessage, successNotification } from '../../../utils/helpers'
import AddGroup from './AddGroup'

const { Option } = Select

const AdminGroup = (props) => {
    const { user, adminActions, selectedLanguage, language } = props
    const isEnglish = language?.language === 'english'
    // const buUsers = useSelector(state => state?.adminReducer?.buUsers || [])
    const [buUsers, setBuUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
    const [spin, setSpin] = useState({})
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const [invitedUsers, setInvitedUsers] = useState(false)
    const [invitedUserData, setInvitedUserData] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [invitationObj, setInvitationObj] = useState({})
    const [loading, setLoading] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [edit, setEdit] = useState({})
    const searchInput = useRef(null)
    const groups = useSelector(state => state?.adminReducer?.groups || [])
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    useEffect(() => {
        allAdminData?.getAllGroups(adminActions, user?._id)
    }, [showForm, spin])

    const showModal = (e) => {
        setIsModalVisible(true)
        let obj = {
            userEmail: null,
            adminId: e && e?.adminId,
            groupId: e && e?._id,
            privateGroup: e && e?.private
        }
        setInvitationObj(obj)
    }

    const handleOk = () => {
        setLoading(true)
        const new_obj = { ...invitationObj, userEmail: selectedUser }
        axios.post(`${ADMIN.INVITE_USER_IN_GROUP}`, new_obj)
            .then((res) => {
                const { data } = res
                // console.log('DATA', data)
                if (data?.success) {
                    successNotification(selectedLanguage?.warningMessage_Successfully_Invited)
                    setIsModalVisible(false)
                    setLoading(false)
                    setSelectedUser(null)
                }
                else {
                    setLoading(false)
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                console.log('e', e)
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
                setIsModalVisible(false)
            })
    }

    const handleCancel = () => {
        setIsModalVisible(false)
        setSelectedUser(null)
    }

    const getInvitedUsers = (e) => {
        axios.get(`${ADMIN.GET_INVITED_USERS}/${e}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setInvitedUsers(true)
                    setInvitedUserData(data?.data || [])
                }
            })
            .catch((e) => {
                console.log('e..', e)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        {selectedLanguage?.txt_Search}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                        {selectedLanguage?.txt_Reset}
                    </Button>
                    <Button
                        type='link'
                        size='small'
                        onClick={() => {
                            confirm({ closeDropdown: false })
                            setSearchText(selectedKeys[0])
                            setSearchedColumn(dataIndex)
                        }}
                    >
                        {selectedLanguage?.txt_Filter}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100)
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const deleteCard = (_id) => {
        setSpin({ [_id]: true })
        axios.post(`${ADMIN.DELETE_GROUP}`, { adminId: user?._id, _id: _id })
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successNotification(selectedLanguage?.SuccessfullyDeleted)
                    adminActions?.addAllGroups(data?.data || [])
                }
            })
            .catch((e) => {
                setSpin({})
            })
    }
    const showEdit = (e) => {
        console.log('e', e)
        setEdit(e)
        setShowForm(true)
    }

    const getAllBuUsers = (e) => {
        setLoading(true)
        let obj = {
            postalCode: e?.postalCode,
            groupId: e?._id,
            adminId: user?._id
        }

        axios.post(ADMIN?.GET_BU_USERS, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setBuUsers(data?.data)
                    setLoading(false)
                    showModal(e)
                }
            })
            .catch((e) => {
                console.log('e', e)
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const deleteInvite = (e) => {
        setLoading(true)
        let obj = {
            _id: e?._id,
            groupId: e?.groupId,
            userId: e?.userId?._id
        }
        axios.post(ADMIN?.DELETE_INVITED_USER, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setLoading(false)
                    getInvitedUsers(e?.groupId)
                    successMessage(selectedLanguage?.SuccessfullyDeleted)
                }
            })
            .catch((e) => {
                console.log('e', e)
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }


    let columns1 = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.label_userName}</p>,
            dataIndex: 'userId',
            key: 'userId',
            sorter: true,
            showSorterTooltip: false,
            ellipsis: true,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e?.fullName}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.tableColumn_UserEmail}</p>,
            dataIndex: 'userId',
            key: 'userId',
            sorter: true,
            showSorterTooltip: false,
            // ellipsis: true,
            render: (e) => <p>{e?.email}</p>
        },

        {
            title: <p className='table-title-p'>{selectedLanguage?.tableColumn_Invited}</p>,
            dataIndex: 'inviteSent',
            key: 'inviteSent',
            sorter: true,
            showSorterTooltip: false,
            ellipsis: true,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? selectedLanguage?.True : selectedLanguage?.False}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.tableColumn_InviteAccepted}</p>,
            dataIndex: 'inviteAccepted',
            key: 'inviteAccepted',
            sorter: true,
            showSorterTooltip: false,
            ellipsis: true,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? selectedLanguage?.True : selectedLanguage?.False}</p>
        },
        {
            // title: 'Actions',
            key: 'actions',
            render: (e) => <span>
                {e?.inviteAccepted ? <Tooltip title={selectedLanguage?.popupMessage_UserJoinedGroup}><p>{selectedLanguage?.popupMessage_cantdelete}</p></Tooltip> : <Popconfirm
                    title={`${selectedLanguage?.Delete} ?`}
                    okText={selectedLanguage?.Delete}
                    cancelText={selectedLanguage?.Cancel}
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => deleteInvite(e)}
                >
                    <a href='#'><DeleteOutlined style={{ color: 'red' }} /> {selectedLanguage?.Delete}</a>
                    {spin[e?._id] ? <span className='demo-loading-container'>
                        &nbsp;<Spin />
                    </span> : null}
                </Popconfirm>}
            </span>
        }
    ]

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.tableColumn_Group_Name}</p>,
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            showSorterTooltip: false,
            // ellipsis: true,
            ...getColumnSearchProps('name')
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.table_Restaurant}</p>,
            // dataIndex: 'postalCode',
            key: 'postalCode',
            ellipsis: true,
            render: (e) => {
                return (<>
                    <p>{e?.postalCode[0]?.postalcodeStart}-{e?.postalCode[0]?.postalcodeEnd}</p>
                    {e?.postalCode[1] ? <> <br />
                        <p>{e?.postalCode[1]?.postalcodeStart}-{e?.postalCode[1]?.postalcodeEnd}</p> </> : null}
                </>)
            }

        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Private}</p>,
            dataIndex: 'private',
            key: 'private',
            ellipsis: true,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? selectedLanguage?.True : selectedLanguage?.False}</p>

        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Password}</p>,
            dataIndex: 'password',
            key: 'password',
            render: (e) => {
                var pass = e ? e : null
                return (
                    <span>
                        {e ? <CopyToClipboard text={pass}
                            onCopy={() => successMessage(selectedLanguage?.SuccessfullyCopiedtoClipboard)}>
                            <a>{selectedLanguage?.tableColumn_CopyPassword}</a>
                        </CopyToClipboard> : 'null'}

                    </span>
                )
            }

        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.tableColumn_TotalUsers}</p>,
            dataIndex: 'userId',
            key: 'userId',
            ellipsis: true,
            render: (e) => {
                let uniqueChars = [...new Set(e)]
                return <p>{uniqueChars && uniqueChars ? uniqueChars?.length : 0}</p>
            }
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.CreatedAt}</p>,
            dataIndex: 'created',
            key: 'created',
            ellipsis: true
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.tableColumn_ViewInvitedUsers}</p>,
            dataIndex: '_id',
            key: '_id',
            render: (e) => <Button onClick={() => getInvitedUsers(e)}>{selectedLanguage?.button_show}</Button>
        },
        {
            // title: 'Actions',
            key: 'actions',
            render: (e) => <span>
                <a onClick={() => showEdit(e)}><EditTwoTone /></a>&nbsp;
                <Popconfirm
                    title={`${selectedLanguage?.Delete} ${convertTitle(e?.name)}?`}
                    okText={selectedLanguage?.Delete}
                    cancelText={selectedLanguage?.Cancel}
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => deleteCard(e?._id)}
                >
                    <a href='#'><DeleteOutlined style={{ color: 'red' }} /></a>
                    {spin[e?._id] ? <span className='demo-loading-container'>
                        &nbsp;<Spin />
                    </span> : null}
                </Popconfirm>
                &nbsp;
                <a onClick={() => {
                    getAllBuUsers(e)
                }
                }><ShareAltOutlined /></a>

            </span>
        }
    ]


    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote3}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>
                    {showForm ?
                        <AddGroup edit={edit} onCancel={() => (setShowForm(false), setEdit(null))} {...props} /> :
                        <div>
                            {!showForm ? <h3 className='new-exam-title'>{selectedLanguage?.title_Groups}</h3> : null}
                            <div style={{ textAlign: 'right', width: '99%' }}>
                                {invitedUsers ?
                                    <Button
                                        type='primary'
                                        className='btn-end'
                                        onClick={() => setInvitedUsers(false)}
                                        style={{ width: '140px' }}
                                    >
                                        {selectedLanguage?.Back}
                                    </Button> :
                                    <Button
                                        type='primary'
                                        className='btn-end'
                                        style={{ width: '140px' }}
                                        onClick={() => setShowForm(true)}
                                    >
                                        {selectedLanguage?.button_AddGroup}
                                    </Button>}
                            </div>
                            <div >
                                {invitedUsers ?
                                    <div className='new-layout-table-upper-div'>
                                        <Table
                                            style={{ marginTop: '30px' }}
                                            className='new-layout-table'
                                            locale={customLocale}
                                            columns={columns1}
                                            dataSource={invitedUserData}
                                            // dataSource={groups}
                                            pagination={{ hideOnSinglePage: true }}
                                            scroll={{ x: 800 }} />
                                    </div>
                                    :
                                    <>     <div className='new-layout-table-upper-div'>
                                        <Table
                                            style={{ marginTop: '30px' }}
                                            className='new-layout-table'
                                            locale={customLocale}
                                            columns={columns}
                                            dataSource={groups}
                                            pagination={{ hideOnSinglePage: true }}
                                            scroll={{ x: 1100 }}
                                        />
                                    </div>
                                        <Modal title={selectedLanguage?.title_InviteUserbyEmail}
                                            open={isModalVisible}
                                            onOk={handleOk}
                                            onCancel={handleCancel}
                                            okText={selectedLanguage?.title_okText}
                                            footer={[
                                                <Button key='back' onClick={handleCancel} className='form-input'>
                                                    {selectedLanguage?.Cancel}
                                                </Button>,
                                                <Button
                                                    key='ok'
                                                    onClick={handleOk}
                                                    className='form-input'
                                                    type='primary'
                                                    loading={loading}
                                                >
                                                    {selectedLanguage?.button_InviteUser}
                                                </Button>
                                            ]}>
                                            <Select
                                                style={{ width: '100%' }}
                                                showArrow
                                                allowClear
                                                value={selectedUser}
                                                onChange={(e) => setSelectedUser(e)}
                                                className='form-input'
                                                placeholder={selectedLanguage?.SelectUser}
                                                showSearch
                                                optionFilterProp='children'
                                                filterOption={checkSelectOption}
                                            >
                                                {buUsers?.map((v, i) => <Option value={v?.email} key={i}>{`${convertTitle(v?.fullName)} ${v?.email}`}</Option>)}
                                            </Select>
                                            <br />
                                            <br />
                                            <p>{!buUsers.length ? selectedLanguage?.title_NoUsersExist : selectedLanguage?.button_InviteUser}</p>
                                        </Modal>
                                    </>
                                }
                            </div>
                        </div>}
                </div>
            </div>
        </div>
    )
}

export default AdminGroup