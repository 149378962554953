import { Button, Image } from 'antd'
import React, { useState } from 'react'
import { IoIosArrowBack } from "react-icons/io"

const ShowShops = (props) => {
    const { onCancel, product, language, selectedLanguage } = props
    const [showMore, setShowMore] = useState(false)
    
    return (
        <div style={{ paddingBottom: 40 }}>
            {showMore ?
                <div className='main-div-new-style'>
                    <div style={{ textAlign: 'right', width: '98.5%' }}>
                        <Button
                            type='primary'
                            onClick={onCancel}
                        >
                            {selectedLanguage?.Cancel}
                        </Button>
                    </div>

                    <div style={{ marginTop: '10px' }}>
                        <div className='product_img' style={{
                            display: ' flex',
                            justifyContent: 'flex-start',
                            flexWrap: 'wrap'
                        }}>
                            {product?.otherImages?.length ? product?.otherImages?.map((v, i) => {
                                return (
                                    <div style={{
                                        width: '32%',
                                        margin: '0.5%',
                                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                    }}>
                                        <img
                                            style={{
                                                borderRadius: '4px',
                                                width: '99%',
                                                height: '99%',
                                            }} alt="example" src={v} />
                                    </div>
                                )
                            }) : null
                            }
                        </div>
                    </div>
                </div>
                :
                <div className='main-div-new-style'>
                    <h3 className='new-exam-title'>{product?.name || '----'}</h3>
                    <br />
                    <div style={{ textAlign: 'right', width: '98.5%' }}>
                        <Button
                            type='primary'
                            onClick={onCancel}
                        >
                            {selectedLanguage?.Cancel}
                        </Button>
                    </div>
                    <div className='buyProduct_imges_main_div'>
                        <div className='buyProduct_1st_img_div'>
                            <img onClick={() => { setShowMore(true) }} alt="example" className='buyProduct_1st_img' src={product?.imageUrl} />
                        </div>
                        <div className='buyProduct_2nd_img_div'>
                            <div className='buyProduct_2nd_img_div_inner_flex'>
                                <div className='img_div_inner_flex_1st' >
                                    <img onClick={() => { setShowMore(true) }} className='img_div_img' alt="example" src={product?.otherImages[0]} />
                                    <img onClick={() => { setShowMore(true) }} className='img_div_img' alt="example" src={product?.otherImages[1]} />
                                </div>
                                <img onClick={() => { setShowMore(true) }} className='img_div_inner_flex_2nd' alt="example" src={product?.otherImages[2]} />
                            </div>
                        </div>
                    </div>
                    <div style={{ margin: '10px' }}>
                        <p style={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            color: 'black',
                            margin: '0px'
                        }}>{product?.name || '------'}</p>
                        <p style={{
                            color: 'grey',
                            textAlign: 'start',
                            lineBreak: 'anywhere',
                        }}>
                            {product?.description || '-------------------------------'}
                        </p>
                        <p style={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            color: 'black',
                            margin: '0px'
                        }}>{selectedLanguage?.Text_OtherDetails || '------'}</p>
                        <div>
                            <div>{selectedLanguage?.Country}: {product?.userId?.country}</div>
                            <div>{selectedLanguage?.Provincie}: {product?.userId?.provincie}</div>
                            <div>{selectedLanguage?.City}: {product?.userId?.city}</div>
                            <div>{selectedLanguage?.StreetNumber}: {product?.userId?.streetNumber}</div>
                            <div>{selectedLanguage?.PostalCode}: {product?.userId?.postalCodeCity}</div>
                            <div>{selectedLanguage?.TelNo}: {product?.userId?.telNo}</div>
                            <div>{selectedLanguage?.BusinessName} : {product?.userId?.businessName}</div>
                            <div>{selectedLanguage?.Email} : {product?.userId?.email}</div>
                        </div>
                    </div>
                </div>}
        </div >
    )
}

export default ShowShops