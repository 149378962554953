import React, { useEffect } from 'react';

const DragDropQuiz = (props) => {
    const { imgUrl, spots, sequence } = props
    useEffect(() => {
        window.addEventListener('resize', handleResize)
        handleResize()
        setTimeout(() => {
            handleResize()
        }, 1000)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [imgUrl, spots,sequence])

    const handleResize = () => {
        const image = document.querySelector('.image img')
        const imageWidth = image.clientWidth
        const circleHeight = imageWidth * 0.1 // 10% of the image width
        spots.forEach((v) => {
            const circle = document.getElementById(v?.id)
            circle.style.height = circleHeight + 'px'
        })
    }

    const DropZone = (data) => {
        const { spot } = data

        let backgroundColor = spot?.correct ? 'lightgreen' : 'red'
        const style = {
            position: 'absolute',
            opacity: 0.7,
            left: spot.x,
            top: spot.y,
            width: '10%',
            backgroundColor,
            borderRadius: '50%',
            zIndex: 'auto'
        }
        return (
            <div className='circle' id={spot?.id} style={style}></div>
        )
    }
    const SequenceDropZone = (data) => {
        const { spot } = data

        let backgroundColor = 'darkOrange'
        const style = {
            position: 'absolute',
            opacity: 0.7,
            left: spot.x,
            top: spot.y,
            width: '10%',
            backgroundColor,
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            borderWidth:'thick',
            zIndex: 'auto',
            borderColor:'white',
            borderStyle:'solid'
        }
        return (
            <div className='circle' id={spot?.id} style={style}>
                <h1 style={{ fontSize: window.innerWidth * 0.03 }} className='spotNum'>{spot?.id + 1}</h1>
            </div>
        )
    }

    return (
        <div className='image' style={{ width: '100%', position: 'relative' }}>
            <img src={imgUrl} width={'100%'} alt='' />
            {sequence ? spots.map((spot) => (
                <SequenceDropZone
                    key={spot.id}
                    spot={spot}
                />
            )) : spots.map((spot) => (
                <DropZone
                    key={spot.id}
                    spot={spot}
                />
            ))}
        </div>
    );
};


export default DragDropQuiz;
