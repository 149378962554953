import moment from 'moment'
import React from 'react'
import { validateTime } from '../../utils/helpers'

const BuDetails = (props) => {
    const { product, user, details, buUser, invoice, selectedLanguage } = props
    let ages = invoice?.age ? Object.keys(invoice?.ageQuantity) : []
    return (
        <div style={{ paddingBottom: 40, paddingTop: '20px' }}>
            <div className='detail-card-main-div'>
                <div className='detail-card-inner-div'>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                        }} >
                        <img style={{ height: '40vh', maxWidth: '100%' }}
                            alt='example' src={product?.imageUrl} />
                    </div>
                </div>
                <div className='detail-card-inner-div'>
                    <div style={{ borderBottom: '2px solid rgba(0, 0, 0, 0.3)' }}>
                        <div style={{ padding: '20px', paddingBottom: '0px' }}>
                            <div className='div-flex-back-name' style={{ justifyContent: 'space-between', height: '30px' }}>
                                <div>
                                    <p style={{
                                        fontSize: 18,
                                        fontWeight: 'bold',
                                        color: 'black',
                                        width: '100%',
                                        textAlign: 'start',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>{product?.name || '------'}</p>
                                </div>
                                <div>
                                    <p style={{ color: '#ec671b' }}>{selectedLanguage?.Tax}: {product?.tax}%</p>
                                </div>
                            </div>
                            <div>
                                <p style={{
                                    color: 'grey',
                                    textAlign: 'start',
                                    lineHeight: 1.4,
                                    height: '42px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    lineBreak: 'anywhere',
                                    marginBottom: 0
                                }}>
                                    {product?.description || '-------------------------------'}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{ padding: '20px', textAlign: 'left' }}>
                        <h3
                            style={{
                                fontSize: 18,
                                fontWeight: 'bold',
                                color: 'black',
                            }}
                        >{selectedLanguage?.Text_OtherDetails}</h3>
                        {invoice?.age ?
                            <div>
                                <div>{selectedLanguage?.Price}:
                                    <ul>
                                        {ages?.map((v, i) => {
                                            return (<li> {`(${v}) yrs: € ${invoice?.agePrice?.[0]?.[v]}`} </li>)
                                        })}
                                    </ul>
                                </div>
                                <div>{selectedLanguage?.Quantity}:
                                    <ul>
                                        {ages?.map((v, i) => {
                                            return (<li> {`(${v}) yrs: ${invoice?.ageQuantity?.[v]}`} </li>)
                                        })}
                                    </ul>
                                </div>
                            </div>

                            :
                            <>
                                <div>{selectedLanguage?.Price}: €{' ' + invoice?.price}</div>
                                <div>{selectedLanguage?.Quantity}: {' ' + invoice?.quantity}</div>
                            </>
                        }
                        {invoice?.couponId?._id &&
                            <div>
                                <div>{selectedLanguage?.Coupon + ' ' + selectedLanguage?.Amount}: €{' ' + invoice?.couponId?.amount}</div>
                                <div>{selectedLanguage?.CouponCode}: {invoice?.couponId?.couponCode}</div>
                            </div>
                        }
                        <div>{selectedLanguage?.AmountPaid}: €{' ' + invoice?.totalAmount}</div>
                        <div>{selectedLanguage?.ReservedDate}: {' ' + moment(details?.voucherDate).format('YYYY-MM-DD')}</div>
                        <div>{selectedLanguage?.ReservedTime}: {' ' + validateTime(details?.voucherTime)}</div>
                        <div>{selectedLanguage?.GuestName}: {details?.userId?.fullName}</div>
                        <div>{selectedLanguage?.GuestTelNo}: {details?.userId?.telNo}</div>
                        <div>{selectedLanguage?.Country}: {buUser?.country}</div>
                        <div>{selectedLanguage?.Provincie}: {buUser?.provincie}</div>
                        <div>{selectedLanguage?.City}: {buUser?.city}</div>
                        <div>{selectedLanguage?.BusinessName}: {buUser?.businessName}</div>
                        <div>{selectedLanguage?.BusinessTelNo}: {buUser?.telNo}</div>
                        <div>{selectedLanguage?.VoucherCode}: {details?.voucherCode}</div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default BuDetails