import { SearchOutlined } from '@ant-design/icons'
import { Button, Input, Space, Spin, Table } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { GET } from '../../utils/apis'
import NoData from "../../assets/noDataImae.png"

const SettledUsers = (props) => {
    const { user, selectedLanguage, language } = props
    const [requests, setRequests] = useState([])
    const [loading, setLoading] = useState(false)
    const [cities, setCities] = useState([])

    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const searchInput = useRef(null)

    const [filteredInfo, setFilteredInfo] = useState({})
    const [sortedInfo, setSortedInfo] = useState({})
    const customLocale = {
         emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle:selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    useEffect(() => {
        getRequests()
    }, [])

    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters)
        setSortedInfo(sorter)
    }
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }
    const handleReset = (clearFilters) => {
        clearFilters()
        setSearchText('')
    }
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size='small'
                        style={{
                            width: 90,
                        }}
                    >
                        {selectedLanguage?.txt_Search}
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size='small'
                        style={{
                            width: 90,
                        }}
                    >
                        {selectedLanguage?.txt_Reset}
                    </Button>
                    <Button
                        type='link'
                        size='small'
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            })
                            setSearchText(selectedKeys[0])
                            setSearchedColumn(dataIndex)
                        }}
                    >
                        {selectedLanguage?.txt_Filter}
                    </Button>
                    <Button
                        type='link'
                        size='small'
                        onClick={() => {
                            close()
                        }}
                    >
                        {selectedLanguage?.txt_close}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    })

    const getRequests = () => {
        setLoading(true)
        axios.get(`${GET.GET_GIFT_CARD_USERS}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setCities(data?.data.map((v, i) => { return { text: v?.city?.toLowerCase(), value: v?.city?.toLowerCase() } }) || [])
                setRequests(data?.data)
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
            })
    }

    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.FullName}</p>,
            dataIndex: 'fullName',
            key: 'fullName',
            showSorterTooltip: false,
            sorter: (a, b) => a.fullName.length - b.fullName.length
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.BusinessName}</p>,
            dataIndex: 'businessName',
            key: 'businessName',
            sorter: (a, b) => a.businessName.length - b.businessName.length,
            showSorterTooltip: false,
            ...getColumnSearchProps('businessName')
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Email}</p>,
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('email')
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.PostalCode}</p>,
            dataIndex: 'postalCodeCity',
            key: 'postalCodeCity',
            ...getColumnSearchProps('postalCodeCity')
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_Phone}</p>,
            dataIndex: 'telNo',
            key: 'telNo'
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.City}</p>,
            dataIndex: 'city',
            key: 'city',
            render: (e) => `${e?.toLowerCase()}`,
            filters: _.uniqWith(cities, _.isEqual),
            filteredValue: filteredInfo?.city || null,
            onFilter: (value, record) => {
                return record.city.toLowerCase().includes(value?.toLowerCase())
            },
            sorter: (a, b) => a.city.length - b.city.length,
            showSorterTooltip: false,
            ellipsis: true
        }
    ]
    return (
        <div className='new-layout-table-upper-div'>
            <Table
                className='new-layout-table'
                locale={customLocale}
                loading={loading}
                dataSource={requests}
                columns={columns}
                pagination={{ hideOnSinglePage: true }}
                scroll={{ x: 800 }}
                onChange={handleChange}

            />
        </div>
    )
}

export default SettledUsers