import React, { useEffect, useState } from "react";
import ReactFlagsSelect from 'react-flags-select';
import { setLanguage } from '../../Redux/actions/languageAction'
import { useDispatch, useSelector } from 'react-redux'

const LoginLanguageSelect = () => {
    const [selected, setSelected] = useState('GB');
    const dispatch = useDispatch()
    const language = useSelector(state => state?.languageReducer)

    useEffect(() => {
        language?.language === 'english' ? setSelected('GB') :
            language?.language === 'egypt' ? setSelected('EG') :
                setSelected('NL')
    })

    const onchangeLanguage = (v) => {
        setSelected(v)
        if (v === 'GB') {
            dispatch(setLanguage('english'))
        }
        else if (v === 'EG') {
            dispatch(setLanguage('egypt'))
        }
        else {
            dispatch(setLanguage('netherlands'))
        }
    }

    return (
        <div
            style={{
                height: 50,
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: ' center',
                paddingRight: '8%',
                backgroundColor: '#FBFBFB'
            }}>
            <ReactFlagsSelect
                className='countries-select'
                fullWidth={false}
                showOptionLabel={false}
                showSelectedLabel={false}
                countries={["NL", "GB", "EG"]}
                selected={selected}
                onSelect={(v) => onchangeLanguage(v)}
            />
        </div >
    )
}
export default LoginLanguageSelect