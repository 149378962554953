import { Button, Form, Input, Select } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ADMIN, GET, POST } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import { assignMessage, checkSelectOption, errorMessage, successNotification } from '../../utils/helpers'
const { Option } = Select

const AddGiftCards = (props) => {
    const { onCancel, user, getUser, language, location, selectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const [quantity, setQuantity] = useState(null)
    const [cardImages, setCardImages] = useState([])
    const [giftCardSubscription, setGiftCardSubscription] = useState({})
    const userId = new URLSearchParams(location.search).get('userId') || null
    const selectAmount = [5, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100]

    const getGiftCardImages = () => {
        axios.get(`${ADMIN?.GET_GIFT_CARD_IMAGES}`)
            .then((res) => {
                const { data } = res
                setCardImages(data?.data?.filter((v) => v.active === true))
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
            })
    }

    const getGiftCardsSubscription = () => {
        axios.get(GET.GIFT_CARD_SUBSCRIPTION)
            .then((res) => {
                const { data } = res
                let giftCards = data?.data[0]
                setGiftCardSubscription(giftCards || {})
            })
            .catch((e) => {
                console.log('e', e)
            })
    }


    const onFinish = (values) => {
        setLoading(true)
        values.userId = user?._id
        values.imageUrl = cardImages[0]?.imageUrl
        values.giftCardsRate = giftCardSubscription?.amount
        values.giftCardsPrice = Number(values?.cardAmount)
        values.giftCardsQuantity = Number(values?.giftCardsQuantity)
        values.subscriptionName = giftCardSubscription?.subscriptionName
        values._id = giftCardSubscription?._id
        values.subscriptionTypes = giftCardSubscription?.subscriptionTypes
        axios.post(POST.GUEST_PURCHASE_GIFT_CARDS, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    window.location.href = data?.redirectUrl
                    return
                }
                errorMessage(assignMessage(data?.message, language?.language))
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
                console.log('e', e)
            })
    }

    useEffect(() => {
        getGiftCardImages()
        getGiftCardsSubscription()
        // getPayementStatus()
    }, [])

    return (
        <>
            <div >
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                    requiredMark={false}
                >
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='seriesName'
                                label={selectedLanguage?.title_SeriesName}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.errorMessage_PleaseInputSeriesName
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.selectedLanguage?.errorMessage_PleaseInputSeriesName}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='giftCardsQuantity'
                                label={selectedLanguage?.Quantity}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourQuantity
                                    }
                                ]}
                            >
                                <div>
                                    <Input
                                        className='form-input-new-style'
                                        placeholder='e.g. 2'
                                        value={quantity}
                                        onChange={(e) => setQuantity(e.target.value?.replace(/[^0-9]/g, ''))}
                                    />
                                </div>
                            </Form.Item>
                        </div>
                    </div>
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='cardAmount'
                                label={selectedLanguage?.Amount}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.errorMessage_PleaseSelectAmount
                                    }
                                ]}
                            >
                                <Select
                                    showArrow
                                    allowClear
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.placeholder_PleaseSelectAmount}
                                    showSearch
                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}
                                >
                                    {selectAmount?.map((v, i) => <Option value={v} key={i}>{v}</Option>)}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                    <Form.Item>
                        <Button style={{ height: '40px', width: '160px', marginTop: '10px' }}
                            className='addproduct-text'
                            type='primary'
                            htmlType='submit'
                            loading={loading}
                        >
                            {selectedLanguage?.Create}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    )
}

export default AddGiftCards