import { Button, Checkbox, Form, Modal, Select } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addFilter } from '../../Redux/actions/userActions';
import { GET } from '../../utils/apis';
import { productTimeRange } from '../../utils/constants';
import { checkSelectOption, convertTitle } from '../../utils/helpers';
const { Option } = Select

const FilterModal = (props) => {
    const { user, filter, filterOpen, setFilterOpen, onCancel, getProducts, setFilterValue, selectedLanguage, language, cities } = props
    const [form] = Form.useForm()
    const [productTypes, setProductTypes] = useState([])
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const isEnglish = language?.language === 'english'

    useEffect(() => {
        getProductTypes()
    }, [])

    const handleCancel = () => {
        setFilterOpen(false)
    }
    const getProductTypes = () => {
        axios.get(`${GET?.GET_PRODUCT_TYPES}`)
            .then((res) => {
                const { data } = res
                setProductTypes(data?.data || [])
            })
            .catch((e) => {
                console.log('e', e)
            })
    }

    const onFinish = (values) => {
        setLoading(true)
        setFilterValue(values)
        dispatch(addFilter(values))
        setTimeout(() => {
            setFilterValue(values)
            setLoading(false)
            getProducts()
            handleCancel()
        }, 300)
    }

    const resetFormFields = () => {
        form.setFieldsValue({
            timeRange: Array.isArray(filter?.timeRange) ? filter?.timeRange : [],
            productType: Array.isArray(filter?.productType) ? filter?.productType : [],
            city: filter?.city || null,
        })
    }

    return (
        <Modal
            // title={selectedLanguage?.FilterProducts}
            title={null}
            open={filterOpen}
            footer={null}
            className='pop-info-modal'
            style={{ top: '10px' }}
            onCancel={handleCancel}>
            <p className='new-p-question'>{selectedLanguage?.FilterProducts}</p>
            <Form
                name='form'
                form={form}
                onFinish={onFinish}
                layout={'vertical'}
            >
                {/* <div className='form-input-single-input'>
                    <Form.Item
                        name='timeRange'
                        label={selectedLanguage?.TimeRange}
                        hasFeedback
                        initialValue={filter?.timeRange || []}
                    >
                        <Select
                            className='form-input-new-style-for-filetr'
                            showSearch
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                            mode='multiple' placeholder={selectedLanguage?.SelectTimeRange}>
                            {productTimeRange?.map((v, i) => {
                                return (
                                    <Option key={i} value={v}>{v}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </div> */}
                <div className='form-input-single-input'>
                    <Form.Item
                        name='productType'
                        label={selectedLanguage?.Type}
                        hasFeedback
                        initialValue={filter?.productType || []}
                    >
                        <Select
                            className='form-input-new-style-for-filetr'
                            showSearch
                            optionFilterProp='children'
                            filterOption={checkSelectOption}
                            mode='multiple' placeholder={selectedLanguage?.SelectType}>
                            {productTypes?.map((v, i) => {
                                return (
                                    <Option key={i} value={v?._id}>{v?.typeName}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </div>
                <div className='form-input-single-input'>
                    <Form.Item
                        name='city'
                        label={selectedLanguage?.City}
                        hasFeedback
                        initialValue={filter?.city}
                    >
                        <Select
                            className='form-input-new-style-for-filetr'
                            showSearch
                            optionFilterProp='children'
                            filterOption={checkSelectOption} allowClear={false} mode='multiple' placeholder={selectedLanguage?.SelectCities}>
                            {cities?.length && cities?.map((v, i) => {
                                return (
                                    <Option key={i} value={v}>{convertTitle(v)}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </div>
                <div className='form-input-single-input '>
                    <div className=' form-input-new-style' style={{ padding: '10px' }}>
                        <Form.Item
                            name='favourites'
                            valuePropName='checked'
                            initialValue={filter?.favourites || false}
                        >
                            <Checkbox>
                                {selectedLanguage?.Favourites}
                            </Checkbox>
                        </Form.Item>
                    </div>
                </div>
                <Form.Item>
                    <Button
                        style={{ height: '40px', width: '100%', marginTop: '10px' }}
                        className='addproduct-text'
                        htmlType='submit'
                        type='primary'
                        loading={loading}
                    >
                        {selectedLanguage?.Update}
                    </Button>
                    <Button
                        style={{ height: '40px', width: '100%', marginTop: '10px' }}
                        className='addproduct-text'
                        type='primary'
                        onClick={() => { resetFormFields(); onCancel() }}
                    >
                        {selectedLanguage?.Cancel}
                    </Button>
                </Form.Item>
            </Form>
        </Modal >
    )
}

export default FilterModal;