import { LANGUAGE } from '../types'

const setLanguage = (language) => {
    return {
        type: LANGUAGE,
        language
    }
}
export {
    setLanguage,
}