import { Button, Image, Table } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { ADMIN } from '../../../utils/apis'
import { assignMessage, successMessage, successNotification } from '../../../utils/helpers'

const GiftCardImages = (props) => {
    const { user, userActions, selectedLanguage, language } = props
    const isEnglish = language?.language === 'english'
    const [requests, setRequests] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectionType, setSelectionType] = useState([])
    const [spin, setSpin] = useState({})
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    useEffect(() => {
        getKey()
    }, [requests])

    useEffect(() => {
        getRequests()
    }, [])
    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Name}</p>,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Image}</p>,
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            render: (e) => <Image src={e} style={{ marginRight: 10, width: 86, height: 60 }} />
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Created}</p>,
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p>{moment(e).utc().format('YYYY-MM-DD')}</p>
        }
        ,
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Action}</p>,
            key: 'action',
            render: (e) => (<Button onClick={() => deleteImages(e?._id)}>{selectedLanguage?.Delete} </Button>)
        }
    ]

    const requestsFilter = requests.map((v, i) => {
        return {
            name: v?.name,
            key: v?._id,
            imageUrl: v?.imageUrl,
            _id: v?._id,
            active: v?.active,
            enabled: false
        }
    })

    let idArray = []

    requestsFilter?.filter((a, i) => {
        if (a.active == true) {
            idArray.push(a.key)
        }
    })
    const getKey = () => {
        setSelectionType(idArray)
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectionType(`${selectedRowKeys[0]}`)

            let updateAddId = selectedRows[0]._id

            setLoading(true)

            let val = { _id: updateAddId, adminId: `${user?._id}` }

            axios.post(`${ADMIN?.SET_ACTIVE_GIFT_CARD_IMAGE}`, val)
                .then((res) => {
                    const { data } = res
                    setSpin({})
                    if (data?.success) {
                        successNotification(assignMessage(data?.message, language?.language))
                        getRequests()
                    }
                    setLoading(false)
                })
                .catch((e) => {
                    console.log(
                        'error', e
                    )
                    setSpin({})
                    setLoading(false)
                })
        }
    }

    const getRequests = () => {
        setLoading(true)
        axios.get(`${ADMIN?.GET_GIFT_CARD_IMAGES}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setRequests(data?.data)
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
            })
    }

    const deleteImages = (e) => {
        let obj = { _id: e, adminId: user?._id }
        console.log('OBJ', obj
        )
        axios.post(`${ADMIN.DELETE_GIFT_CARD_IMAGES}`, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                successMessage(assignMessage(data?.message, language?.language))
                getRequests()
            }).catch((err) => {
                setLoading(false)
            })
    }

    return (
        <div className='new-layout-table-upper-div'>
            <Table
                style={{ marginTop: '30px' }}
                className='new-layout-table'
                locale={customLocale}
                rowSelection={{
                    type: 'radio',
                    selectedRowKeys: selectionType,
                    ...rowSelection
                }}
                loading={loading}
                dataSource={requestsFilter}
                columns={columns}
                pagination={{ hideOnSinglePage: true }}
                scroll={{ x: 800 }}
            />
        </div>
    )
}

export default GiftCardImages