import { Button, Spin, Table } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import NoData from "../../assets/noDataImae.png"
import { GET } from '../../utils/apis'
import { allUserData, assignMessage, createInvoicePdf, errorMessage } from '../../utils/helpers'
import ViewCard from '../Admin/Cards/ViewCard'
const Invoices = (props) => {
    const { user, userActions, language, selectedLanguage } = props
    const [showInvoice, setShowInvoice] = useState(false)
    const [invoice, setInvoice] = useState({})
    const [refundInvoice, setRefundInvoice] = useState([])
    const [loading, setLoading] = useState(false)
    const invoices = useSelector(state => state?.userReducer?.invoices || [])
    const [showRefundInvoices, setShowRefundInvoices] = useState(false)
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    useEffect(() => {
        allUserData?.getAllInvoice(userActions, user?._id)
        getRefundInvoices()
    }, [])

    const viewInvoice = (fileUrl) => {
        setInvoice({
            designName: 'Invoice',
            fileUrl
        })

        setShowInvoice(true)
    }

    const onCancel = () => {
        setInvoice({})
        setShowInvoice(false)
    }

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.ProductName}</p>,
            sorter: true,
            showSorterTooltip: false,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e?.reservationId?.name || e?.invoiceName}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.TotalAmount}</p>,
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            sorter: true,
            showSorterTooltip: false,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {e}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.ViewInvoice}</p>,
            key: 'invoice',
            render: (e) => <a onClick={() => {
                createInvoicePdf(e, user, 'view', viewInvoice)
            }}>{selectedLanguage?.View}</a>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.DownloadInvoice}</p>,
            key: 'download',
            render: (e) => <>
                <a onClick={() => {
                    createInvoicePdf(e, user, 'download', viewInvoice)
                }
                }>{selectedLanguage?.Download}</a>
            </>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.SubscriptionDate}</p>,
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p>{moment(e).format('YYYY-MM-DD')}</p>

        }
    ]

    let refundColumns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.Name}</p>,
            dataIndex: ['reservationId', 'name'],
            sorter: true,
            showSorterTooltip: false,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.TotalAmount}</p>,
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            sorter: true,
            showSorterTooltip: false,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {e}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.ViewInvoice}</p>,
            key: 'invoice',
            render: (e) => <a onClick={() => {
                createInvoicePdf(e, user, 'view', viewInvoice)
            }}>{selectedLanguage?.View}</a>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.DownloadInvoice}</p>,
            key: 'download',
            render: (e) => <a onClick={() => {
                createInvoicePdf(e, user, 'download', viewInvoice)
            }
            }>{selectedLanguage?.Download}</a>
        },
        {
            title: <p className='table-title-p'>{'Status'}</p>,
            align: 'center',
            render: (e) => <>{e?.adminPaid ?
                <p style={{ color: 'red', marginBottom: 0 }}>{selectedLanguage?.Paid}</p>
                : !e?.payable ? <p style={{ color: '#4CAF50', marginBottom: 0 }}>{selectedLanguage?.status_UNPAYABLE}</p>
                    : <p style={{ color: '#007AFF', marginBottom: 0 }}>{selectedLanguage?.Pending}</p>}</>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.SubscriptionDate}</p>,
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p>{moment(e).format('YYYY-MM-DD')}</p>
        }
    ]

    let getRefundInvoices = () => {
        axios.get(`${GET.GET_REFUND_INVOICES}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setRefundInvoice(data?.data || [])
                setLoading(false)
            })
            .catch((e) => {
                console.log('e', e)
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote1}</p>
            </div>
            <div style={{ background: 'white', padding: '15px' }}>
                <p className='new-exam-title' >{showRefundInvoices ? selectedLanguage?.Text_RefundInvoices : selectedLanguage?.Invoices}</p>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '99%' }}>
                    {!showRefundInvoices ?
                        <Button
                            style={{ height: '40px', width: '160px', marginTop: '10px' }}
                            className='addproduct-text'
                            type='primary'
                            onClick={() => setShowRefundInvoices(true)}
                        >
                            {selectedLanguage?.Text_RefundInvoices}
                        </Button> :
                        <Button
                            style={{ height: '40px', width: '160px', marginTop: '10px' }}
                            className='addproduct-text'
                            type='primary'
                            onClick={() => setShowRefundInvoices(false)}
                        >
                            {selectedLanguage?.Back}
                        </Button>
                    }
                </div>
                <br />
                <br />
                <div className='new-layout-table-upper-div'>
                    <Table
                        className='new-layout-table'
                        locale={customLocale}
                        columns={showRefundInvoices ? refundColumns : columns}
                        loading={loading}
                        dataSource={showRefundInvoices ? refundInvoice : invoices}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: 800 }}
                    />
                </div>
                {showInvoice ? <ViewCard card={invoice} onCancel={onCancel} showData={showInvoice} {...props}/> : null}
            </div>
        </div >
    )
}

export default Invoices