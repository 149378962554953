import { Image, Spin, Table } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { GET } from '../../utils/apis'
import NoData from "../../assets/noDataImae.png"

const MyGiftCards = (props) => {
    const { user, selectedLanguage, language } = props
    const [requests, setRequests] = useState([])
    const [loading, setLoading] = useState(false)
    const customLocale = {
         emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle:selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    useEffect(() => {
        getRequests()
    }, [])

    const getRequests = () => {
        setLoading(true)
        axios.get(`${GET.GET_ALL_BUUSER_GIFT_CARDS}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setRequests(data?.data)
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
            })
    }

    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.Image}</p>,
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            render: (e) => <Image src={e} style={{ marginRight: 10, width: 86, height: 60 }} />
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Code}</p>,
            dataIndex: 'giftCardCode',
            key: 'giftCardCode',
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Amount}</p>,
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => a.amount - b.amount,
            showSorterTooltip: false,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {e}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Claimed}</p>,
            dataIndex: 'isClaimed',
            key: 'isClaimed',
            sorter: (a, b) => a.isClaimed - b.isClaimed,
            showSorterTooltip: false,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? selectedLanguage?.True : selectedLanguage?.False}</p>
        },
        // {
        //     title: 'isAdmin',
        //     dataIndex: 'isAdmin',
        //     key: 'isAdmin',
        //     render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? 'True' : 'False'}</p>
        // },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_SeriesName}</p>,
            dataIndex: 'seriesName',
            key: 'seriesName',
            sorter: (a, b) => a.seriesName.length - b.seriesName.length,
            showSorterTooltip: false,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Created}</p>,
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p>{moment(e).utc().format('YYYY-MM-DD')}</p>
        }
    ]

    return (
        <div className='new-layout-table-upper-div'>
            <Table
                className='new-layout-table'
                locale={customLocale}
                loading={loading}
                dataSource={requests}
                columns={columns}
                pagination={{ hideOnSinglePage: true }}
                scroll={{ x: 800 }}
            />
        </div>
    )
}

export default MyGiftCards