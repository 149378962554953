import { Button, Modal, Spin, Table } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { GET } from '../../utils/apis'
import NoData from "../../assets/noDataImae.png"

const Requests = (props) => {
    const { user, selectedLanguage, language } = props
    const [requests, setRequests] = useState([])
    const [loading, setLoading] = useState(false)
    const [showDetails, setShowDetails] = useState(false)
    const [giftCardDetail, setGiftCardDetail] = useState({})
    const customLocale = {
         emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle:selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    useEffect(() => {
        getRequests()
    }, [])

    const getRequests = () => {
        setLoading(true)
        axios.get(`${GET.GET_USER_GIFT_CARD_CLAIM_REQUEST}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setRequests(data?.data)
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
            })
    }

    const handleOk = () => {
        setShowDetails(false)
    }

    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.button_GiftCardCode}</p>,
            dataIndex: 'giftCardCode',
            key: 'giftCardCode',
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_RequesterEmail}</p>,
            dataIndex: ['requesterId', 'email'],
            key: 'UserEmail',
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_RequesterName}</p>,
            dataIndex: ['requesterId', 'fullName'],
            key: 'fullName',
        },
        {
            // title: 'Actions',
            key: 'actions',
            render: (e) => (
                <>
                    <Button onClick={() => {
                        setGiftCardDetail(e?.giftCard)
                        setShowDetails(true)
                    }}>{selectedLanguage?.button_ViewDetail}</Button >
                    &nbsp; &nbsp; &nbsp;
                    {
                        e?.isApproved == true ?
                            <Button disabled>{selectedLanguage?.status_Accepted}</Button>
                            :
                            <Button disabled>{selectedLanguage?.Pending}</Button>
                    }
                </>
            )
        }
    ]

    return (
        <>
            <div className='new-layout-table-upper-div'>
                <Table
                    className='new-layout-table'
                    locale={customLocale}
                    loading={loading}
                    dataSource={requests}
                    columns={columns}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 800 }}
                />
            </div>

            <Modal
                className='pop-info-modal'
                style={{ top: '10px' }}
                open={showDetails}
                onOk={handleOk}
                onCancel={handleOk}
                footer={null}
            >
                <p className='new-p-question'>{selectedLanguage?.title_GiftCardDetails}</p>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <ul>
                        <li><b>{selectedLanguage?.title_SeriesName}:</b> {giftCardDetail?.seriesName ? giftCardDetail?.seriesName : '-------'}</li>
                        <li><b>{selectedLanguage?.Code}: </b> {giftCardDetail?.giftCardCode ? giftCardDetail?.giftCardCode : '-------'}</li>
                        <li><b>{selectedLanguage?.Amount}: </b> {giftCardDetail?.amount ? giftCardDetail?.amount : '-------'} </li>
                        <li><b>{selectedLanguage?.title_Admin}: </b> {giftCardDetail?.giftCardCode ? `${giftCardDetail?.isAdmin}` : '-------'}</li>
                        <li><b>{selectedLanguage?.CreatedAt}: </b>{giftCardDetail?.created ? moment(giftCardDetail?.created)?.format('YYYY-MM-DD') : '-------'} </li>
                    </ul>
                </div>
            </Modal>
        </>
    )
}

export default Requests