import { SearchOutlined } from '@ant-design/icons'
import { Button, Input, Select, Space, Spin, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useSelector } from 'react-redux'
import { ADMIN, GET } from '../../../utils/apis'
import { allAdminData, assignMessage, checkSelectOption, convertTitle, createInvoicePdf, errorMessage, successNotification } from '../../../utils/helpers'
import ViewCard from '../Cards/ViewCard'
import GuestDetails from './GuestDetails'
const { Option } = Select

const AdminGuestInvoices = (props) => {
    const { user, adminActions, language, selectedLanguage } = props
    const gUsers = useSelector(state => state?.adminReducer?.gUsers || [])
    const [loading, setLoading] = useState(false)
    const [showInvoice, setShowInvoice] = useState(false)
    const [invoice, setInvoice] = useState({})
    const [invoices, setInvoices] = useState([])
    const [selectedUser, setSelectedUser] = useState(gUsers?.[0]?._id || null)
    const [selectedUserDetail, setSelectedUserDetail] = useState(gUsers?.[0] || null)
    const isEnglish = language?.language === 'english'
    const [refundInvoice, setRefundInvoice] = useState([])
    const [showRefundInvoices, setShowRefundInvoices] = useState(false)
    const [details, setDetails] = useState({})
    const [spin, setSpin] = useState({})
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    useEffect(() => {
        allAdminData?.getGuestUser(adminActions, user?._id)
    }, [])

    useEffect(() => {
        getInvoices()
        getRefundInvoices()
        setSelectedUserDetail(gUsers?.filter((v) => v?._id === selectedUser)[0])
    }, [selectedUser])

    const getInvoices = () => {
        setLoading(true)
        axios.get(`${GET.INVOICES}/${selectedUser}`)
            .then((res) => {
                const { data } = res
                setInvoices(data?.data)
                setLoading(false)
            })
            .catch((e) => {
                console.log('e', e)
                setLoading(false)
            })
    }

    const getRefundInvoices = () => {

        axios.get(`${GET.GET_REFUND_INVOICES}/${selectedUser}`)
            .then((res) => {
                const { data } = res
                setRefundInvoice(data?.data || [])
                setLoading(false)
            })
            .catch((e) => {
                console.log('e', e)
                setLoading(false)
                // errorMessage()
            })
    }

    const changeRefundStatus = (_id) => {

        setSpin({ [_id]: true })
        let obj = {
            _id,
            adminId: user?._id
        }
        axios.post(ADMIN?.CHANGE_REFUND_STATUS, obj)
            .then((res) => {
                const { data } = res
                setSpin({})
                getRefundInvoices()
                if (data?.success) {
                    successNotification(assignMessage(data?.message, language?.language))
                } else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                console.log('e', e)
                setSpin({})
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const viewInvoice = (fileUrl) => {
        setInvoice({
            designName: 'Invoice',
            fileUrl
        })

        setShowInvoice(true)
    }

    const onCancel = () => {
        setInvoice({})
        setShowInvoice(false)
    }

    let refundColumns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.Name}</p>,
            dataIndex: ['reservationId', 'name'],
            sorter: true,
            showSorterTooltip: false,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.TotalAmount}</p>,
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            sorter: (a, b) => a?.totalAmount - b?.totalAmount,
            showSorterTooltip: false,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {e}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.ViewInvoice}</p>,
            key: 'invoice',
            render: (e) => <a onClick={() => {
                createInvoicePdf(e, selectedUserDetail, 'view', viewInvoice)
            }}>{selectedLanguage?.View}</a>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.DownloadInvoice}</p>,
            key: 'download',
            render: (e) => <a onClick={() => {
                createInvoicePdf(e, selectedUserDetail, 'download', viewInvoice)
            }
            }>{selectedLanguage?.Download}</a>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.txt_Status}</p>,
            align: 'center',
            render: (e) => <>{e?.adminPaid ? <p style={{ color: '#4CAF50', marginBottom: 0 }}>{selectedLanguage?.Paid}</p> : !e?.payable ? <p style={{ color: 'red', marginBottom: 0 }}>{selectedLanguage?.status_UNPAYABLE}</p> : spin[e?._id] ? <Spin /> :
                <Button onClick={() => changeRefundStatus(e?._id)}>{selectedLanguage?.Pay}</Button>}</>
        },
        {
            align: 'center',
            render: (e) => <Button onClick={() => setDetails(e?.userId)}>{selectedLanguage?.Details}</Button>
        },

    ]

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.Name}</p>,
            // dataIndex: ['reservationId', 'name'],
            // sorter: (a, b) => a?.reservationId?.name?.length - b?.reservationId?.name?.length,
            // showSorterTooltip: false,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e?.reservationId?.name || e?.invoiceName}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.TotalAmount}</p>,
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            sorter: (a, b) => a?.totalAmount - b?.totalAmount,
            showSorterTooltip: false,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {e}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.ViewInvoice}</p>,
            key: 'invoice',
            render: (e) => <a onClick={() => {
                createInvoicePdf(e, selectedUserDetail, 'view', viewInvoice)
            }}>{selectedLanguage?.View}</a>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.DownloadInvoice}</p>,
            key: 'download',
            render: (e) => <a onClick={() => {
                createInvoicePdf(e, selectedUserDetail, 'download', viewInvoice)
            }
            }>{selectedLanguage?.Download}</a>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.SubscriptionDate}</p>,
            dataIndex: 'created',
            key: 'created'
        }
    ]

    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote1}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>

                    {
                        details?._id ?
                            <GuestDetails onCancel={() => setDetails({})} details={details} {...props} /> :
                            null
                    }
                    <h3 className='new-exam-title'>{selectedLanguage?.GuestInvoices}</h3>

                    <Select
                        showArrow
                        allowClear={false}
                        defaultValue={selectedUser}
                        onChange={(e) => setSelectedUser(e)}
                        className='form-input'
                        placeholder={selectedLanguage?.SelectUser}
                        style={{ width: 200, margin: '12px', marginBottom: '0px', marginTop: '20px' }}
                        showSearch
                        optionFilterProp='children'
                        filterOption={checkSelectOption}
                    >
                        {gUsers?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.fullName)} ${v?.email}`}</Option>)}
                    </Select>

                    <div style={{ textAlign: 'right', width: '100%' }}>
                        {!showRefundInvoices ?
                            <Button
                                type='primary'
                                className='btn-end invite-guest'
                                onClick={() => setShowRefundInvoices(true)}
                            >
                                {selectedLanguage?.Text_RefundInvoices}
                            </Button> :
                            <Button
                                type='primary'
                                className='btn-end invite-guest'
                                onClick={() => setShowRefundInvoices(false)}
                            >
                                {selectedLanguage?.OtherInvoices}
                            </Button>
                        }
                    </div>

                    <div className='new-layout-table-upper-div'>
                        <Table
                            style={{ marginTop: '30px' }}
                            className='new-layout-table'
                            locale={customLocale}
                            loading={loading}
                            columns={showRefundInvoices ? refundColumns : columns}
                            dataSource={showRefundInvoices ? refundInvoice : invoices}
                            pagination={{ hideOnSinglePage: true }}
                            scroll={{ x: 800 }}
                        />
                    </div>

                    {showInvoice ? <ViewCard card={invoice} onCancel={onCancel} showData={showInvoice} {...props}/> : null}
                </div>
            </div>
        </div>
    )
}

export default AdminGuestInvoices