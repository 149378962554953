import { Button } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { IoIosArrowBack } from "react-icons/io";
import { GET } from '../../utils/apis';
import { allPaths } from '../../utils/constants';
import { assignMessage, errorMessage, successNotification } from '../../utils/helpers';
import AddCoupons from './AddCoupons';
import AllCoupons from './AllCoupons';

const Coupons = (props) => {
    const { user, location, history, language, selectedLanguage } = props
    const isEnglish = language?.language === 'english'
    const userId = new URLSearchParams(location.search).get('userId') || null
    const [loading, setLoading] = useState(false)
    const [createCoupons, setCreateCoupons] = useState(false)
    const [couponsDetails, setCouponsDetails] = useState([])

    useEffect(() => {
        getAdminCoupons()
    }, [])

    useEffect(() => {
        if (userId) {
            setLoading(true)
            axios.get(`${GET.PAYMENT_STATUS}/${userId}`)
                .then((res) => {
                    const { data } = res
                    setLoading(false)
                    history?.replace(allPaths.COUPON)
                    if (data?.success) {
                        getAdminCoupons()
                        return successNotification(assignMessage(data?.message, language?.language))
                    }
                    errorMessage(assignMessage(data?.message, language?.language))
                })
                .catch((e) => {
                    setLoading(false)
                    console.log('e', e)
                    errorMessage(assignMessage(e?.message, language?.language))
                })
        }
    }, [])

    const getAdminCoupons = () => {
        setLoading(true)

        axios.get(`${GET.GET_ALL_USER_COUPONS}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setCouponsDetails(data?.allData)
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }
    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote7}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>
                    <div className='main-div-new-style' >

                        <p className='new-exam-title'>{createCoupons ? selectedLanguage?.AddCoupons : selectedLanguage?.Coupon}</p>

                        {createCoupons ?
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '99%' }}>
                                <Button
                                    style={{ height: '40px', width: '160px', marginTop: '10px' }}
                                    className='addproduct-text'
                                    type='primary'
                                    onClick={() => setCreateCoupons(false)}
                                >
                                    {selectedLanguage?.Back}
                                </Button>
                            </div>
                            : null}

                        {
                            createCoupons ?
                                <AddCoupons onCancel={() => setCreateCoupons(false)} {...props} /> :
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '99%' }}>
                                        <Button
                                            style={{ height: '40px', width: '160px', marginTop: '10px' }}
                                            className='addproduct-text'
                                            type='primary'
                                            onClick={() => setCreateCoupons(true)}
                                        >
                                            {selectedLanguage?.CreateCoupons}
                                        </Button>
                                    </div>
                                    <div style={{ marginTop: "20px" }}>
                                        <AllCoupons loading={loading} couponsDetails={couponsDetails} {...props} />
                                    </div >
                                </>
                        }
                    </div >
                </div >
            </div >
        </div >
    )
}

export default Coupons