import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Select, Spin, Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ADMIN, GET } from '../../../utils/apis';
import { allAdminData, assignMessage, checkSelectOption, convertTitle, createBuOrderReport, createGiftCard, errorMessage, successNotification } from '../../../utils/helpers';
import AllCoupons from '../../Coupons/AllCoupons';
import ViewCard from '../Cards/ViewCard';
const { Option } = Select

const BuCoupons = (props) => {
    const { user, adminActions, setShowBuCoupons, language, selectedLanguage } = props
    const buUsers = useSelector(state => state?.adminReducer?.buUsers || [])
    const [loading, setLoading] = useState(false)
    const [coupons, setCoupons] = useState([])
    const [couponsDetails, setCouponsDetails] = useState([])
    const [allCoupons, setAllCoupons] = useState(false)
    const [selectedUser, setSelectedUser] = useState(buUsers?.[0]?._id || null)
    const [spin, setSpin] = useState({})
    const [printSpin, setPrintSpin] = useState({})
    const [invoice, setInvoice] = useState({})
    const [selectedUserDetail, setSelectedUserDetail] = useState(buUsers?.[0] || null)
    const [showInvoice, setShowInvoice] = useState(false)
    const isEnglish = language?.language === 'english'

    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };

    useEffect(() => {
        allAdminData?.getBusinessUser(adminActions, user?._id)

        getAdminCoupons()
    }, [])


    useEffect(() => {
        getAdminCoupons()
        setSelectedUserDetail(buUsers?.filter((v) => v?._id === selectedUser)[0])
    }, [selectedUser])

    const getAdminCoupons = () => {
        setLoading(true)
        axios.get(`${GET.GET_ALL_USER_COUPONS}/${selectedUser}`)
            .then((res) => {

                const { data } = res
                let tableData = Object.entries(data?.data)
                tableData = tableData?.map((v) => {
                    return {
                        id: v[0],
                        value: v[1]
                    }
                })
                setLoading(false)
                setCouponsDetails(data?.allData)
                setCoupons([...tableData])
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const changePrintStatus = (_id) => {
        setPrintSpin({ [_id]: true })
        let obj = {
            _id,
            adminId: user?._id
        }

        axios.post(ADMIN?.UPDATE_PRINT_STATUS, obj)
            .then((res) => {
                const { data } = res
                setPrintSpin({})
                if (data?.success) {
                    getAdminCoupons()
                    successNotification(assignMessage(data?.message, language?.language))
                } else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                console.log('e', e)
                setPrintSpin({})
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const viewInvoice = (fileUrl) => {
        setInvoice({
            designName: 'Invoice',
            fileUrl
        })

        setShowInvoice(true)
    }

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.Description}</p>,
            key: 'id',
            dataIndex: 'id',
            ellipsis: true,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.PrintStatus}</p>,
            dataIndex: 'value',
            key: 'value',
            align: 'center',
            render: (e) => <> {e?.[0]?.orderId?.isPrint ? <CheckCircleFilled style={{ color: 'green' }} /> :
                <>{printSpin[e?.[0]?.orderId?._id] ? <Spin /> :
                    <Button onClick={() => changePrintStatus(e?.[0]?.orderId?._id)}>{selectedLanguage?.Print}</Button>
                }</>
            }</>,
            showSorterTooltip: false,
            sorter: (a, b) => a?.value[0]?.orderId?.isPrint - b?.value[0]?.orderId?.isPrint,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.ViewPDFReport}</p>,
            dataIndex: 'value',
            key: 'value',
            render: (e) => <a onClick={() => {
                createBuOrderReport(e?.[0]?.orderId, selectedUserDetail, 'view', viewInvoice)
            }}>{selectedLanguage?.View}</a>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.DownloadPDFReport}</p>,
            dataIndex: 'value',
            key: 'value',
            render: (e) => <a onClick={() => {
                createBuOrderReport(e?.[0]?.orderId, selectedUserDetail, 'download', viewInvoice)
            }
            }>{selectedLanguage?.Download}</a>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Actions}</p>,
            key: 'actions',
            render: (e) => {
                const spinFunc = () => {
                    let newSpin = { ...spin }
                    newSpin[e?.id] = false
                    setSpin(newSpin)
                }
                return (<>
                    <div style={{ paddingTop: 15, marginTop: -5, display: 'flex', flexDirection: 'column' }}>
                        {spin?.[e?.id] ? <Spin /> :
                            <a onClick={() => (setSpin({ [e?.id]: true, ...spin }), createGiftCard(e?.value, spinFunc))}>{selectedLanguage?.ShowTotalPdfs}</a>
                        }
                    </div>
                </>)
            }
        }
    ]

    const onCancel = () => {
        setInvoice({})
        setShowInvoice(false)
    }

    return (
        <div >
            <h3 className='new-exam-title'>{selectedLanguage?.BuCoupons}</h3>

            <Select
                showSearch
                optionFilterProp='children'
                filterOption={checkSelectOption}
                showArrow
                allowClear={false}
                defaultValue={selectedUser}
                onChange={setSelectedUser}
                className='form-input'
                placeholder={selectedLanguage?.SelectUser}
                style={{ margin: '15px', width: '200px' }}
            >
                {buUsers?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.fullName)} ${v?.email}`}</Option>)}
            </Select>

            <div style={{ textAlign: 'right', width: '98.5%' }}>

                {allCoupons ?
                    <Button
                        type='primary'
                        onClick={() => setAllCoupons(false)}
                    >
                        {selectedLanguage?.Back}
                    </Button>

                    :
                    <>
                        <Button
                            type='primary'
                            onClick={() => setAllCoupons(true)}
                        >
                            {selectedLanguage?.AllCoupons}
                        </Button>
                        &nbsp;
                        <Button
                            type='primary'
                            onClick={() => setShowBuCoupons(false)}
                        >
                            {selectedLanguage?.AdminCoupons}
                        </Button>
                    </>}
            </div>

            {allCoupons ?
                <AllCoupons couponsDetails={couponsDetails} {...props} />
                : <div className='new-layout-table-upper-div'>
                    <Table
                        style={{ marginTop: '30px' }}
                        className='new-layout-table'
                        locale={customLocale}
                        loading={loading}
                        dataSource={coupons}
                        columns={columns}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: 800 }}
                    />
                </div>
            }
            {showInvoice ? <ViewCard card={invoice} onCancel={onCancel} showData={showInvoice} {...props} /> : null}

        </div >
    )
}

export default BuCoupons