import React, { useState } from "react";
import { Button, Form, Input, InputNumber, Select } from "antd";
import axios from 'axios'
import { ADMIN } from './../../../../utils/apis'
import { assignMessage, checkSelectOption, errorMessage, getAdminFaq, successMessage, successNotification } from '../../../../utils/helpers'

const { TextArea } = Input;
const { Option } = Select

const AddAdminFaq = (props) => {
    const { selectedLanguage, user, language, edit, onCancel, adminActions } = props
    const isEnglish = language?.language === 'english'
    const [loading, setLoading] = useState(false)

    const [type, setType] = useState(edit?._id ? edit?.type : 'How it works?')

    const onFinish = (values) => {
        edit?._id && (values._id = edit?._id)
        values.adminId = user?._id
        console.log('value', values, '=====')
        setLoading(true)
        axios.post(edit?._id ? ADMIN.EDIT_FAQ_QUESTION : ADMIN.ADD_NEW_FAQ, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    getAdminFaq(adminActions)

                    edit?._id && (onCancel())
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            }
            )
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }


    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote1}</p>
            </div>

            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>
                    <h3 className='new-exam-title'>
                        {edit?._id ? selectedLanguage?.txt_UpdateFaq : selectedLanguage?.txt_CreateFaq}
                    </h3>
                    <div style={{ textAlign: 'right', width: '99%' }}>
                        {edit?._id &&
                            <Button
                                style={{ borderRadius: 4, marginTop: 6 }}
                                type="primary"
                                onClick={onCancel}
                            >
                                {selectedLanguage?.txt_cancel}
                            </Button>}
                    </div>
                    <div style={{ background: 'white', padding: '15px' }}>
                        <Form
                            name='form'
                            onFinish={onFinish}
                            layout={'vertical'}
                        >
                            <Form.Item
                                name='type'
                                label={selectedLanguage?.txt_SelectType}
                                initialValue={type}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourTypeName
                                    }
                                ]}
                            >
                                <Select
                                    defaultValue={type}
                                    onChange={(e) => { setType(e) }}
                                    style={{ width: '100%', marginBottom: '20px', marginTop: '5px' }}
                                    className='form-input-new-style '
                                    placeholder={selectedLanguage?.txt_SelectType}
                                    showSearch
                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}
                                >
                                    <Option value={'How it works?'}>{selectedLanguage?.txthow_its_work}</Option>
                                    <Option value={"FAQ's"}>{selectedLanguage?.txtxFAQ}</Option>
                                </Select>
                            </Form.Item>

                            {type === "FAQ's" &&
                                <>
                                    <Form.Item
                                        name='titleEng'
                                        initialValue={edit?.titleEng}
                                        label={selectedLanguage?.txt_TitleinEnglish}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.txt_PleaseEnterTitleInEnglish
                                            }
                                        ]}
                                    >
                                        <Input
                                            className='form-input-new-style'
                                            placeholder={selectedLanguage?.txt_EnterTitleinenglishhere}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        initialValue={edit?.titleNl}
                                        name='titleNl'
                                        label={selectedLanguage?.txt_TitleinDutch}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.txt_PleaseEnterTitleInDutch
                                            }
                                        ]}
                                    >
                                        <Input
                                            className='form-input-new-style'
                                            placeholder={selectedLanguage?.txt_EnterTitleindutchhere}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        initialValue={edit?.titleEgp}
                                        name='titleEgp'
                                        label={selectedLanguage?.txt_TitleinEgypt}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.txt_PleaseentertitleinEgypt
                                            }
                                        ]}
                                    >
                                        <Input
                                            className='form-input-new-style'
                                            placeholder={selectedLanguage?.txt_EntertitleinEgypt}
                                        />
                                    </Form.Item>
                                </>}
                            <Form.Item
                                name='catagrie'
                                initialValue={edit?.catagrie}
                                label={selectedLanguage?.SelectCatagrie}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.txt_PleaseSelectCatagrie
                                    }
                                ]}
                            >
                                <Select
                                    style={{ width: '100%', marginBottom: '20px', marginTop: '5px' }}
                                    className='form-input-new-style-select-minhi'
                                    mode="multiple"
                                    placeholder={selectedLanguage?.SelectCatagriehere}
                                >
                                    <Option value={"guest"}>{selectedLanguage?.txt_Guest}</Option>
                                    <Option value={'business'}>{selectedLanguage?.txt_BusinessUser}</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='questionEng'
                                initialValue={edit?.questionEng}
                                label={selectedLanguage?.txt_QuestioninEnglish}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.txt_please_enter_Question_in_English
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.txt_Enter_Question_in_English_here}
                                />
                            </Form.Item>
                            <Form.Item
                                name='questionNl'
                                initialValue={edit?.questionNl}
                                label={selectedLanguage?.txt_Question_in_Dutch}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.txt_please_enter_Question_in_Dutch
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.txt_Enter_Question_in_Dutch_here}
                                />
                            </Form.Item>

                            <Form.Item
                                initialValue={edit?.questionEgp}
                                name='questionEgp'
                                label={selectedLanguage?.txt_QuestioninEgypt}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.txt_PleaseenterQuestioninEgypt
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.txt_EnterquestioninEgypt}
                                />
                            </Form.Item>

                            <Form.Item
                                name='answerEng'
                                initialValue={edit?.answerEng}
                                label={selectedLanguage?.txt_Answer_in_English}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.txt_Please_Enter_Answer_in_English
                                    }
                                ]}
                            >
                                <TextArea
                                    rows={4}
                                    placeholder={selectedLanguage?.txt_EnterAnswerinEnglishhere}
                                />
                            </Form.Item>

                            <Form.Item
                                name='answerNl'
                                initialValue={edit?.answerNl}
                                label={selectedLanguage?.txt_Answer_in_Dutch}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.txt_Please_Enter_Answer_in_Ducth
                                    }
                                ]}
                            >
                                <TextArea
                                    rows={4}
                                    placeholder={selectedLanguage?.txt_EnterAnswerinDucthhere}
                                />
                            </Form.Item>

                            <Form.Item
                                initialValue={edit?.answerEgp}
                                name='answerEgp'
                                label={selectedLanguage?.txt_AnswerinEgypt}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.txt_PleaseenterAnswerinEgypt
                                    }
                                ]}
                            >
                                <TextArea
                                    rows={4}
                                    placeholder={selectedLanguage?.txt_EnterAnswerinEgypt}
                                />
                            </Form.Item>


                            <Form.Item
                                name='priority'
                                initialValue={edit?.priority}
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.txt_priority}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.txt_pleaseenterPriority
                                    }
                                ]}
                            >
                                <InputNumber
                                    step={1}
                                    type='number'
                                    style={{ width: '100%' }}
                                    // className='form-input-new-style'
                                    placeholder={selectedLanguage?.txt_Enterpriorityhere}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type='primary'
                                    className='form-button'
                                    block
                                    htmlType='submit'
                                    loading={loading}
                                >
                                    {edit?._id ? selectedLanguage?.txt_Update : selectedLanguage?.Create}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddAdminFaq                                              