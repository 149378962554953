import { InfoCircleOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Form, Input, Row } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { ADMIN } from '../../../utils/apis'
import { assignMessage, errorMessage, inputPlace, requiredMessage, successNotification } from '../../../utils/helpers'

const AddGroup = (props) => {
    const { onCancel, edit, user, selectedLanguage, language } = props
    const isEnglish = language?.language === 'english'
    const [loading, setLoading] = useState(false)
    const [formComplete, setformComplete] = useState(false)
    const [secondRange, setsecondRange] = useState(edit?.postalCode?.length > 1 ? true : false)
    const [startPostal, setstartPostal] = useState(edit?._id ? edit?.postalCode[0]?.postalcodeStart : '')
    const [endPostal, setendPostal] = useState(edit?._id ? edit?.postalCode[0]?.postalcodeEnd : '')
    const [startPostal2, setstartPostal2] = useState(edit?.postalCode?.length > 1 ? edit?.postalCode[1]?.postalcodeStart : '')
    const [endPostal2, setendPostal2] = useState(edit?.postalCode?.length > 1 ? edit?.postalCode[1]?.postalcodeEnd : '')
    const [groupPassword, setGroupPassword] = useState(edit?.private ? edit?.private : false)

    const onChange = (e) => {
        setGroupPassword(e.target.checked)
    }

    const onFinish = (values) => {
        setLoading(true)
        values.adminId = user?._id
        delete values?.postalCode
        delete values?.postalCode2

        if (secondRange) {
            values.postalCode = [{ postalcodeStart: startPostal, postalcodeEnd: endPostal }, { postalcodeStart: startPostal2, postalcodeEnd: endPostal2 }]
        } else {
            values.postalCode = [{ postalcodeStart: startPostal, postalcodeEnd: endPostal }]
        }

        if (_.isEmpty(edit)) {
            axios.post(ADMIN.CREATE_GROUP, values)
                .then((res) => {
                    const { data } = res
                    setLoading(false)
                    if (data.success) {
                        successNotification(assignMessage(data?.message, language?.language))
                        setLoading(false)
                        return setTimeout(() => {
                            onCancel()
                        }, 300)
                    }
                    errorMessage(assignMessage(data?.message, language?.language))
                })
                .catch((e) => {
                    setLoading(false)
                    errorMessage(assignMessage(e?.message, language?.language))
                })
        } else {
            values._id = edit?._id
            axios.post(ADMIN.EDIT_GROUP, values)
                .then((res) => {
                    const { data } = res
                    setLoading(false)
                    if (data.success) {
                        successNotification(assignMessage(data?.message, language?.language))
                        return setTimeout(() => {
                            onCancel()
                        }, 300)
                    }
                    errorMessage(assignMessage(data?.message, language?.language))
                })
                .catch((e) => {
                    setLoading(false)
                    errorMessage(assignMessage(e?.message, language?.language))
                })
        }
    }

    return (
        <div  >
            <h3 className='new-exam-title'>{edit?._id ? selectedLanguage?.Update : selectedLanguage?.Create} {selectedLanguage?.title_Group}</h3>
            <div style={{ textAlign: 'right', width: '99%' }}>
                <Button
                    style={{ width: '140px' }}
                    className='btn-end'
                    type='primary'
                    onClick={onCancel}
                >
                    {selectedLanguage?.Cancel}
                </Button>
            </div>
            <div style={{ background: 'white', padding: '15px', paddingTop: '25px' }}>
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                >
                    <Form.Item
                        name='name'
                        label={selectedLanguage?.tableColumn_Group_Name}
                        hasFeedback
                        initialValue={edit?.name}
                        rules={[
                            {
                                required: true,
                                message: requiredMessage(selectedLanguage?.tableColumn_Group_Name)
                            }
                        ]}
                    >
                        <Input
                            className='form-input'
                            placeholder={inputPlace(selectedLanguage?.tableColumn_Group_Name)}
                        />
                    </Form.Item>
                    <label>{selectedLanguage?.PostalCode}</label>
                    <Form.Item
                        name='postalCode'
                        label={selectedLanguage?.label_FirstRange}
                        hasFeedback
                        initialValue={groupPassword}
                        rules={[
                            {
                                required: true,
                                validator: async (_, names) => {
                                    if (formComplete && startPostal.length != 6) {
                                        return Promise.reject(new Error(selectedLanguage?.errorMessage_LengthofPostalCode));
                                    }
                                    if (formComplete && endPostal.length != 6) {
                                        return Promise.reject(new Error(selectedLanguage?.errorMessage_LengthofPostalCode));
                                    }
                                    if (formComplete && startPostal.localeCompare(endPostal, undefined, { numeric: true, sensitivity: 'base' }) != -1) {
                                        return Promise.reject(new Error(selectedLanguage?.errorMessage_StartoftheRange));
                                    }
                                },
                            },
                        ]}

                    >
                        <Row gutter={24}>

                            <Col className='gutter-row' span={8}>
                                <center><label >{selectedLanguage?.Start}</label></center>
                                <Input
                                    disabled={edit?.invitedUsersId?.length ? true : false}
                                    value={startPostal}
                                    onChange={(e) => setstartPostal(e.target.value.toUpperCase().replace(/[^a-z0-9]/gi, ''))}
                                    maxLength={6}
                                    // pattern= 
                                    className='form-input gutter-box'
                                    placeholder={selectedLanguage?.placeholder_StartRange}
                                // style='text-transform:uppercase'
                                />
                            </Col>
                            <Col className='gutter-row' span={8}>
                                <center><label >{selectedLanguage?.label_End}</label></center>
                                <Input
                                    disabled={edit?.invitedUsersId?.length ? true : false}
                                    onChange={(e) => setendPostal(e.target.value.toUpperCase().replace(/[^a-z0-9]/gi, ''))}
                                    value={endPostal}
                                    maxLength={6}
                                    className='form-input gutter-box'
                                    placeholder={selectedLanguage?.label_EndRange}
                                />
                            </Col>
                            {!secondRange && !edit?.invitedUsersId?.length ? <PlusCircleOutlined
                                style={{ marginTop: 35 }}
                                className='dynamic-delete-button'
                                onClick={() => setsecondRange(true)}
                            /> : null}

                        </Row>
                    </Form.Item>

                    {secondRange ?
                        <Form.Item
                            name='postalCode2'
                            label={selectedLanguage?.label_secondRange}
                            hasFeedback
                            initialValue={groupPassword}
                            rules={[
                                {
                                    required: true,
                                    validator: async (_, names) => {

                                        let checkStart = (startPostal2.localeCompare(startPostal, undefined, { numeric: true, sensitivity: 'base' }) == 1 && startPostal2.localeCompare(endPostal, undefined, { numeric: true, sensitivity: 'base' }) == -1) || (startPostal.localeCompare(startPostal2, undefined, { numeric: true, sensitivity: 'base' }) == 1 && startPostal.localeCompare(endPostal2, undefined, { numeric: true, sensitivity: 'base' }) == -1);

                                        let checkEnd = (endPostal2.localeCompare(startPostal, undefined, { numeric: true, sensitivity: 'base' }) == 1 && endPostal2.localeCompare(endPostal, undefined, { numeric: true, sensitivity: 'base' }) == -1) || (endPostal.localeCompare(startPostal2, undefined, { numeric: true, sensitivity: 'base' }) == 1 && endPostal.localeCompare(endPostal2, undefined, { numeric: true, sensitivity: 'base' }) == -1);

                                        if (formComplete && (startPostal2 == startPostal || startPostal2 == endPostal || checkStart || checkEnd)) {
                                            return Promise.reject(new Error(selectedLanguage?.errorMessage_BothRangesMustNotIntersect));
                                        }
                                        if (formComplete && (endPostal2 == startPostal || endPostal2 == endPostal)) {
                                            return Promise.reject(new Error(selectedLanguage?.errorMessage_BothRangesMustNotIntersect));
                                        }
                                        if (formComplete && startPostal2.length != 6) {
                                            return Promise.reject(new Error(selectedLanguage?.errorMessage_LengthofPostalCode));
                                        }
                                        if (formComplete && endPostal2.length != 6) {
                                            return Promise.reject(new Error(selectedLanguage?.errorMessage_LengthofPostalCode));
                                        }
                                        if (formComplete && startPostal2.localeCompare(endPostal2, undefined, { numeric: true, sensitivity: 'base' }) != -1) {
                                            return Promise.reject(new Error(selectedLanguage?.errorMessage_StartoftheRange));
                                        }
                                    }
                                }
                            ]}
                        >
                            <Row gutter={24}>
                                <Col className='gutter-row' span={8}>
                                    <center><label >{selectedLanguage?.Start}</label></center>
                                    <Input
                                        disabled={edit?.invitedUsersId?.length ? true : false}
                                        value={startPostal2}
                                        onChange={(e) => setstartPostal2(e.target.value.toUpperCase().replace(/[^a-z0-9]/gi, ''))}
                                        maxLength={6}
                                        // pattern= 
                                        className='form-input gutter-box'
                                        placeholder={selectedLanguage?.placeholder_StartRange}
                                    // style='text-transform:uppercase'
                                    />
                                </Col>
                                <Col className='gutter-row' span={8}>
                                    <center><label >{selectedLanguage?.label_End}</label></center>
                                    <Input
                                        disabled={edit?.invitedUsersId?.length ? true : false}
                                        onChange={(e) => setendPostal2(e.target.value.toUpperCase().replace(/[^a-z0-9]/gi, ''))}
                                        value={endPostal2}
                                        maxLength={6}
                                        className='form-input gutter-box'
                                        placeholder={selectedLanguage?.label_EndRange}
                                    />
                                </Col>
                                {secondRange && !edit?.invitedUsersId?.length ?
                                    <MinusCircleOutlined
                                        // disabled={true}
                                        style={{ marginTop: 35 }}
                                        className='dynamic-delete-button'
                                        onClick={() => {
                                            setsecondRange(false);
                                            setstartPostal2();
                                            setendPostal2()
                                        }}
                                    /> : null}
                            </Row>
                        </Form.Item>
                        : null
                    }

                    {edit && edit?.invitedUsersId?.length ?
                        <Row><p> <InfoCircleOutlined />{selectedLanguage?.text_uneditableRange}</p></Row>
                        : null
                    }
                    <Form.Item
                        label={`${selectedLanguage?.Private}?`}
                        name='private'
                        onChange={onChange}
                        initialValue={groupPassword}
                        valuePropName='checked'>

                        <Checkbox>{selectedLanguage?.Private}</Checkbox>

                    </Form.Item>

                    {groupPassword ?
                        <Form.Item
                            name='password'
                            label={selectedLanguage?.Password}
                            hasFeedback
                            initialValue={edit?.password}
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage(selectedLanguage?.Password)
                                }
                            ]}
                        >
                            <Input.Password
                                autoComplete='new-password'
                                className='form-input'
                                placeholder={inputPlace(selectedLanguage?.Password)}
                            />
                        </Form.Item> : null
                    }
                    <Form.Item>
                        <Button
                            type='primary'
                            className='form-button'
                            onClick={() => setformComplete(true)}
                            block
                            htmlType='submit'
                            loading={loading}
                        >
                            {edit?._id ? selectedLanguage?.Update : selectedLanguage?.Create}
                        </Button>

                    </Form.Item>
                </Form>
            </div>
        </div >

    )
}

AddGroup.propTypes = {
    onCancel: PropTypes.func.isRequired
}

export default AddGroup