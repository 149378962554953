import { UploadOutlined } from '@ant-design/icons'
import { Button, Form, Input, Select, Upload } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import LoginLanguageSelect from '../../Components/LoginLanguageSelect/LoginLanguageSelect'
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import EgyptLanguage from '../../Languages/EgyptLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import { removeUser } from '../../Redux/actions/authActions'
import CitydealsLogo from "../../assets/CitydealsLogo.png"
import { AUTH } from '../../utils/apis'
import { allPaths, provincieLanguage, supplierType } from '../../utils/constants'
import countryCodes from '../../utils/countryCodes'
import { assignLanguage, assignMessage, checkSelectOption, errorMessage, successNotification, warningMessage } from '../../utils/helpers'
const { Option } = Select

const Signup = (props) => {
    const { history } = props
    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState([])
    const [country, setCountry] = useState('NL')
    const [countryCode, setCountryCode] = useState('+31')
    const formRef = useRef(null)
    const dispatch = useDispatch()
    const path = history?.location?.pathname
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = assignLanguage(language?.language)
    const isEnglish = language?.language === 'english'

    useEffect(() => {
        dispatch(removeUser())
    }, [])

    const onFinish = (values) => {

        values.userType = path === allPaths?.BU_SIGNUP ? 'business' : 'guest'

        values.file = fileList

        if (!values?.file?.length && path == allPaths?.BU_SIGNUP) {
            return warningMessage(selectedLanguage?.PleaseUploadLogo)
        }

        let formData = new FormData()

        if (values?.file?.length) {
            formData.append('file', values?.file[0])
        }

        for (var [k, v] of Object.entries(values)) {
            if (k && v) {
                formData.append(k, v)
            }
        }

        setLoading(true)
        axios.post(AUTH.SIGNUP, formData)
            .then((res) => {
                const { data } = res
                console.log('data', 'data', data)
                setLoading(false)
                if (data.success) {
                    successNotification(
                        selectedLanguage?.SuccessfullySignupwehavesentyouAverificationLinkToyouremailPleaseverifyYourEmailAndLogin)
                    return setTimeout(() => {
                        history.push(path === allPaths?.BU_SIGNUP ? allPaths?.BU_LOGIN : allPaths?.LOGIN)
                    }, 300)
                }
                errorMessage(assignMessage(data?.message, language?.language))
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(selectedLanguage?.OopsSomethingWentWrong)
            })
    }

    const updateCountry = (e) => {
        let selectedCode = countryCodes.filter((v) => v?.code === e)[0].dial_code

        setCountryCode(selectedCode)

        formRef.current.setFieldsValue({ prefix: selectedCode })
    }

    const prefixSelector = () => {
        return (
            <Form.Item name='prefix' noStyle>
                <Select
                    disabled
                    style={{
                        minWidth: 80,
                        maxWidth: window.innerWidth > 500 ? 'auto' : 120,
                    }}
                    defaultValue={countryCode}
                    value={countryCode}
                    showSearch
                    optionFilterProp='children'
                    filterOption={checkSelectOption}
                >
                    {countryCodes?.map((v, i) => (
                        <Option
                            value={v?.dial_code}
                            key={i}
                        >{`${v?.name} ${v?.dial_code}`}</Option>
                    ))}
                </Select>
            </Form.Item>
        )
    }

    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage(selectedLanguage?.YouCanOnlyUploadImages)
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    return (
        <div style={{ height: '100%', background: '#FBFBFB' }}>
            <LoginLanguageSelect />
            <div className='auth-screen-main-div'>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                    <img style={{ height: '110px', width: '135px' }} src={CitydealsLogo} />
                </div>
                <p className='heading head-center'>{path === allPaths?.BU_SIGNUP ? selectedLanguage?.BusinessRegister : selectedLanguage?.Register}</p>
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                    requiredMark={false}
                    ref={formRef}
                >

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='fullName'
                                label={selectedLanguage?.FullName}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseinputYourFullName
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.InputyourFullNameHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='email'
                                label={selectedLanguage?.Email}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourEmail,
                                    },
                                    {
                                        type: 'email',
                                        message: selectedLanguage?.EmailIsNotValid,
                                    },
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.InputYourEmailHere}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    {path != allPaths?.BU_SIGNUP ? null :
                        <>
                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='file'
                                        label={selectedLanguage?.Logo}
                                    >
                                        <Upload
                                            name='file'
                                            multiple={false}
                                            beforeUpload={() => false}
                                            accept='image/png, image/jpeg'
                                            onChange={normFile}
                                            fileList={fileList}
                                        >
                                            <Button icon={<UploadOutlined />}>{selectedLanguage?.ClickToUpload}</Button>
                                        </Upload>
                                    </Form.Item>
                                </div>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='businessName'
                                        label={selectedLanguage?.BusinessName}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: path == allPaths?.BU_SIGNUP ? true : false,
                                                message: selectedLanguage?.PleaseInputYourBusinessName
                                            }
                                        ]}
                                    >
                                        <Input
                                            className='form-input-new-style'
                                            placeholder={selectedLanguage?.InputYourBusinessNameHere}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </>
                    }


                    {path === allPaths?.BU_SIGNUP ?
                        <div className='form-input-single-input'>
                            <Form.Item
                                name='supplierType'
                                label={selectedLanguage?.form_supplierType}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.requiredMessage_pleaseSelectSupplierType
                                    }
                                ]}
                            >
                                <Select
                                    className='form-input-new-style'
                                    showArrow
                                    showSearch
                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}
                                    placeholder={selectedLanguage?.label_selectSupplierType}
                                >
                                    {supplierType?.map((v, i) => <Option value={v?.val} key={i}>{language?.language === 'english' ? v?.name : v?.nameNl}</Option>)}
                                </Select>
                            </Form.Item>
                        </div>
                        : null
                    }

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='streetNumber'
                                label={selectedLanguage?.StreetNumber}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseIputYourStreetNumber
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.InputYourStreetNumberHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='postalCodeCity'
                                label={selectedLanguage?.PostalCode}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourPostalCode
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.InputYourPostalCodeHere}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='country'
                                label={selectedLanguage?.Country}
                                hasFeedback
                                initialValue={country}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourCity
                                    }
                                ]}
                            >
                                <Select
                                    showArrow
                                    allowClear={false}
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.InputYourCityHere}
                                    onChange={updateCountry}
                                    showSearch
                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}
                                >
                                    {countryCodes?.map((v, i) => <Option value={v?.code} key={i}>{language?.language === 'english' ? v?.name : v?.nameNL}</Option>)}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='city'
                                label={selectedLanguage?.City}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourCity
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.InputYourCityHere}
                                />
                            </Form.Item>
                        </div >
                    </div >

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='provincie'
                                label={selectedLanguage?.Provincie}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourProvincie
                                    }
                                ]}
                            >
                                <Select
                                    className='form-input-new-style'
                                    showArrow
                                    placeholder={selectedLanguage?.SelectProvincie}
                                    showSearch
                                    optionFilterProp='children'
                                    filterOption={checkSelectOption}
                                >
                                    {provincieLanguage?.map((v, i) => <Option value={v?.name} key={i}>{language?.language === 'english' ? v?.name : v?.nameNl}</Option>)}
                                </Select>
                            </Form.Item>
                        </div >
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='telNo'
                                label={selectedLanguage?.TelNo}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourTelNo
                                    }
                                ]}
                            >
                                <Input
                                    addonBefore={prefixSelector()}
                                    onBeforeInput={(e) => {
                                        const inputValue = e.clipboardData?.getData('text') || e.data
                                        if (!/^\d*$/.test(inputValue) || e.target.value.length > 12) {
                                            e.preventDefault()
                                        }
                                    }}
                                    className='form-input-new-style'
                                    type='number'
                                    pattern='[0-9]*'
                                    placeholder={selectedLanguage?.TelNo}
                                />
                            </Form.Item>
                        </div >
                    </div >

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='password'
                                label={selectedLanguage?.Password}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourPassword
                                    },
                                    {
                                        min: 6,
                                        message: selectedLanguage?.PasswordMustBeAtLeastCharacters,
                                    }
                                ]}
                            >
                                <Input.Password
                                    className='form-input-new-style'
                                    placeholder={selectedLanguage?.InputYourPasswordHere}
                                />
                            </Form.Item>
                        </div>

                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                label={selectedLanguage?.ConfirmPassword}
                                name='confirm'
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.PleaseInputYourConfirmPassword,
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve()
                                            }

                                            return Promise.reject(
                                                selectedLanguage?.TheTwoPasswordsThatYouEntereddoNotMatch
                                            )
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    placeholder={selectedLanguage?.InputYourConfirmPasswordHere}
                                    className='form-input-new-style'
                                />
                            </Form.Item>
                        </div>
                    </div>


                    <div className='form-input-single-input'>
                        <Form.Item
                            name='remark'
                            label={selectedLanguage?.Remark}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.PleaseInputYourRemark
                                }
                            ]}
                        >
                            <Input
                                className='form-input-new-style'
                                placeholder={selectedLanguage?.InputYourRemarkHere}
                            />
                        </Form.Item>
                    </div>

                    <Form.Item>
                        <Button
                            type='primary'
                            className='addproduct-text form-input-new-style'
                            style={{ height: '45px', width: '99%', marginTop: '10px' }}
                            htmlType='submit'
                            loading={loading}
                        >
                            {selectedLanguage?.Register}
                        </Button>
                    </Form.Item>
                </Form >
                <div style={{ textAlign: 'center' }}>
                    <p>
                        {selectedLanguage?.AlreadyHaveAccount}&nbsp;
                        {path === allPaths?.BU_SIGNUP ? <span>
                            <Link to={allPaths?.BU_LOGIN}>{selectedLanguage?.login_BusinessLogin}</Link>
                            <br />
                            <Link to={allPaths?.BU_FORGOT}>{selectedLanguage?.login_ForgotPassword}</Link>
                        </span> : <span>
                            <Link to={allPaths.LOGIN}>{selectedLanguage?.login_Login}</Link>
                            <br />
                            <Link to={allPaths?.FORGOT}>{selectedLanguage?.login_ForgotPassword}</Link>
                        </span>}
                    </p>
                </div>
            </div >
        </div >

    )
}

export default Signup