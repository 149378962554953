import axios from 'axios'
import momentTz from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import { setCities, setCollapsed, setTaxes } from '../../Redux/actions/generalActions'
import { addAllPayments, addAllVouchers, setRates } from '../../Redux/actions/userActions'
import { allPaths } from '../../utils/constants'
import NewFooter from '../Footer/NewFooter'
import AdminNewHeader from './AdminHeader'
import { allAdminData, assignLanguage, generalData, getAdminFaq, } from '../../utils/helpers'
import { addAdminFaq, addAllBusinessUsers, addAllGroups, addAllGuestUsers, addAllSubAdmins, addAllUsers } from '../../Redux/actions/adminActions'

const AdminNewMenuLayout = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.authReducer.user)
    const language = useSelector(state => state?.languageReducer)
    const inlineCollapsed = useSelector(state => state.generalReducer.inlineCollapsed)
    const selectedLanguage = assignLanguage(language?.language)
    const rates = useSelector(state => state.adminReducer.rates)

    useEffect(() => {
        getAdminFaq(adminActions)
        allAdminData.all(adminActions, user?._id)
        generalData.all(generalActions, false)
    }, [])


    const authActions = {
        loginUser: (e) => dispatch(loginUser(e)),
        removeUser: () => dispatch(removeUser())
    }

    const adminActions = {
        addAllUsers: (e) => dispatch(addAllUsers(e)),
        addAllBusinessUsers: (e) => dispatch(addAllBusinessUsers(e)),
        addAllPayments: (e) => dispatch(addAllPayments(e)),
        addAllVouchers: (e) => dispatch(addAllVouchers(e)),
        addAllGuestUsers: (e) => dispatch(addAllGuestUsers(e)),
        addAllSubAdmins: (e) => dispatch(addAllSubAdmins(e)),
        setRates: (e) => dispatch(setRates(e)),
        addAllGroups: (e) => dispatch(addAllGroups(e)),
        addAdminFaq: (e) => dispatch(addAdminFaq(e)),

    }

    const generalActions = {
        setCollapsed: (e) => dispatch(setCollapsed(e)),
        setCities: (e) => dispatch(setCities(e)),
        setTaxes: (e) => dispatch(setTaxes(e))
    }

    if (!user) {
        return (
            <Redirect to={allPaths?.ADMIN_LOGIN} />
        )
    }

    return (
        <Route
            {...rest}
            render={props =>
                <AddMenu
                    {...props}
                    component={Component}
                    rates={rates}
                    selectedLanguage={selectedLanguage}
                    language={language}
                    user={user}
                    authActions={authActions}
                    generalActions={generalActions}
                    inlineCollapsed={inlineCollapsed}
                    adminActions={adminActions}
                    dispatch={dispatch} />}
        />
    )
}
const AddMenu = ({ component: Component, ...props }) => {
    const { user, dispatch, history, selectedLanguage } = props
    console.log('user======>', user)

    useEffect(() => {
        if (user) {

        }
    }, [props?.location?.pathname])

    if (!user) {
        return (
            <Redirect to={allPaths?.ADMIN_LOGIN} />
        )
    }

    return (
        <div className='new-main-layout-div '>
            <div style={{ height: '100vh', overflow: 'auto' }} className='new-layout-scroll-class'>
                <AdminNewHeader user={user} selectedLanguage={selectedLanguage} {...props} />
                <div className='new-main-component-screen-div'>
                    <Component user={user} history={history}  {...props} />
                </div>
                <NewFooter history={history} selectedLanguage={selectedLanguage} user={user} />
            </div>
        </div>
    )
}


export default AdminNewMenuLayout