import { HeartFilled, HeartOutlined } from '@ant-design/icons';
import { Button, Card, Spin } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AiFillStar } from "react-icons/ai";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch } from 'react-redux';
import NoCards from "../../assets/NoCards.png";
import { loginUser } from '../../Redux/actions/authActions';
import { POST } from '../../utils/apis';
import { ageOptions } from '../../utils/constants';
import { assignMessage, calculateReviewAverage, errorMessage, stringLimiter, successMessage } from '../../utils/helpers';
import ProductReview from '../GuestReview/ProductReview';
import BuyProduct from '../Home/BuyProduct';
import ShowShops from '../Home/ShowShops';
import AddSecretCode from './AddSecretCode';

const GuestPrivProducts = (props) => {
    const { user, userActions, language, selectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const [showSecretCode, setShowSecretCode] = useState(false)
    const [filteredProduct, setFilteredProduct] = useState([])
    const [shops, setShops] = useState([])
    const [productCode, setProductCode] = useState(user?.productCode)
    const [buyProduct, setBuyProduct] = useState({})
    const [favouriteSpin, setFavouriteSpin] = useState({})
    const [userData, setUserData] = useState(user)
    const [reviewModal, setReviewModal] = useState({})
    const [ProgressLoading, setProgressLoading] = useState(false)
    const [shopData, setShopData] = useState({})
    const dispatch = useDispatch()
    const isEnglish = language?.language === 'english'

    useEffect(() => {
        getProducts()
        setTimeout(() => {
            getProducts()
        }, 2000)
    }, [productCode])

    const gotoReview = (v) => {
        setProgressLoading(true)
        setReviewModal(v)
        setTimeout(() => {
            setProgressLoading(false)
        }, 2000);
    }
    const closeReviewModal = (v) => {
        setReviewModal({})
    }

    const getProducts = () => {
        setLoading(true)
        let obj = {
            // productCode: user?.productCode
            productCode: [...productCode]
        }

        axios.post(POST?.GET_PRIV_PRODUCTS, obj)
            .then((res) => {
                const { data } = res
                setFilteredProduct(data?.data.filter((v) => v?.price?.length))
                setShops(data?.data.filter((v) => !v?.price.length))
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                console.log('e', e)
            })
    }
    const calcSoldProducts = (arr) => {
        return arr?.reduce((sum, { soldProduct }) => sum + soldProduct, 0)
    }

    const changeFav = (_id, add) => {
        setFavouriteSpin({ [_id]: true })
        let tempData = { ...userData }
        let obj = {
            favourites: [...userData?.favourites, _id],
            _id: user?._id
        }
        if (!add) {
            obj.favourites = userData?.favourites?.filter((v) => v !== _id)
        }
        tempData.favourites = obj.favourites
        axios.post(POST?.ADD_FAVOURITE_PRODUCT, obj)
            .then((res) => {
                const { data } = res
                setFavouriteSpin({})
                if (data?.success) {
                    setUserData(tempData)
                    dispatch(loginUser(tempData))
                    getProducts()
                    successMessage(add ? selectedLanguage?.SuccessfullyAddedToFavourites : selectedLanguage?.Successfullyremovedfromfavourites)

                } else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setFavouriteSpin({})
                console.log('e', e)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const getPrice = (product) => {
        if (!product?.agePrice) {
            return product?.price?.[0]?.price
        } else {
            return product?.price?.[0]?.[ageOptions[2]]?.price
        }
    }
    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote2}</p>

            </div>
            <div style={{ background: 'white', padding: '10px 0px 10px 0px' }}>
                {showSecretCode ?
                    <div className='flex-column menu-main'>
                        <AddSecretCode
                            onCancel={() => (setShowSecretCode(false))}
                            getProducts={getProducts}
                            setProductCode={setProductCode}
                            {...props} />
                    </div> :
                    buyProduct?._id ?
                        <div className='flex-column menu-main'>
                            <BuyProduct calcSoldProducts={calcSoldProducts} product={buyProduct} onCancel={() => setBuyProduct({})} {...props} />
                        </div> :
                        shopData?._id ?
                            <div className='flex-column menu-main'>
                                <ShowShops product={shopData} onCancel={() => setShopData({})} {...props} />
                            </div> :

                            <div className='cards-main-div-guest-all-crds' style={{ marginTop: '20px' }} >
                                <h3 className='new-exam-title'>{selectedLanguage?.Text_PrivateProducts}</h3>

                                <div style={{ textAlign: 'right', width: '98.5%' }}>
                                    <Button
                                        style={{ height: '40px', width: '160px', marginTop: '10px' }}
                                        className='addproduct-text'
                                        type='primary'
                                        onClick={() => setShowSecretCode(true)}
                                    >
                                        {selectedLanguage?.PrivateCode}
                                    </Button>
                                </div>


                                <div style={{ marginTop: '20px', marginBottom: '10px' }}>
                                    <h1 className='new-exam-title'>{selectedLanguage?.text_Shops}</h1>
                                    <div
                                        className='sidebar-scroll_class'
                                        style={{
                                            marginTop: 8,
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            overflowX: 'scroll'
                                        }}>
                                        {loading ?
                                            [1, 2, 3, 4].map((v, i) => {
                                                return (<Card
                                                    hoverable
                                                    loading={loading}
                                                    bordered={false}
                                                    className='loading-card-style'
                                                />)
                                            }) :
                                            shops?.length ?
                                                shops?.map((v, i) => {
                                                    return (
                                                        <div
                                                            style={{
                                                                height: 265,
                                                                width: '290px',
                                                                minWidth: '290px',
                                                                borderRadius: '4px',
                                                                margin: '4px',
                                                                marginRight: '10px',
                                                                boxShadow: ' 0 4px 5px 0 rgba(0, 0, 0, 0.3)',
                                                                cursor: 'pointer'
                                                            }}>
                                                            <div style={{ textAlign: 'center', margin: '8px', position: 'relative' }}                                                >
                                                                <img onClick={() => setShopData(v)} src={v?.imageUrl} style={{ height: '130px', width: '100%', maxWidth: '100%', borderRadius: '4px' }} />
                                                                <p style={{ position: 'absolute', top: '10px', left: '10px', background: 'white', height: '20px', width: '20px', borderRadius: '100px' }}>
                                                                    {favouriteSpin[v?._id] ?
                                                                        <Spin /> :
                                                                        !userData?.favourites?.includes(v?._id) ?
                                                                            <HeartOutlined onClick={() => changeFav(v?._id, true)} style={{ color: 'red' }} />
                                                                            : <HeartFilled onClick={() => changeFav(v?._id, false)} style={{ color: 'red' }} />
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div style={{ textAlign: 'center', margin: '8px' }}>
                                                                <div className='div-flex-back-name' style={{ justifyContent: 'space-between', height: '30px' }}>
                                                                    <div onClick={() => setShopData(v)} >
                                                                        <p className='guest-card-tect-string'
                                                                            style={{
                                                                                fontSize: 18,
                                                                                color: 'black',
                                                                            }}>{stringLimiter(v?.name, 14) || '------'}</p>
                                                                        <p className='guest-card-tect-string'
                                                                            style={{
                                                                                fontSize: 13,
                                                                                color: '#7A7A7A',
                                                                                width: '100px',
                                                                            }}>{v?.userId?.city || '------'}</p>

                                                                        <p className='guest-card-tect-string'
                                                                            style={{
                                                                                fontSize: 13,
                                                                                color: '#7A7A7A',
                                                                                width: '100px'
                                                                            }}>{v?.promote ? selectedLanguage?.text_Sponsored : ''}</p>
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            alignItems: 'flex-end',
                                                                            height: '100px',
                                                                            marginTop: '-8px',
                                                                        }}
                                                                        onClick={() => { gotoReview(v) }} >
                                                                        <button className='guest-card-review-box'>
                                                                            {v?.review?.length ?
                                                                                <AiFillStar size={15} color='white' />
                                                                                : null}
                                                                            {!v?.isReviewHide ? v?.review?.length ? calculateReviewAverage(v?.review) : 0 : 0}
                                                                        </button>
                                                                        <p className='card_desc1' style={{ textAlign: 'end' }}>
                                                                            {!v?.isReviewHide ?
                                                                                v?.review?.length ?
                                                                                    calculateReviewAverage(v?.review) < 4.5 ?
                                                                                        selectedLanguage?.Bad + ',' :
                                                                                        calculateReviewAverage(v?.review) < 5.9 ?
                                                                                            selectedLanguage?.Poor + ',' :
                                                                                            calculateReviewAverage(v?.review) < 6.9 ?
                                                                                                selectedLanguage?.Adeqate + ',' :
                                                                                                calculateReviewAverage(v?.review) < 8.9 ?
                                                                                                    selectedLanguage?.Good + ',' :
                                                                                                    selectedLanguage?.Excellent + ',' : null : null}
                                                                            {!v?.isReviewHide ?
                                                                                v?.review?.length ?
                                                                                    v?.review?.length !== 1 ?
                                                                                        v?.review?.length > 999 ?
                                                                                            ' ' +
                                                                                            `${v?.review?.length?.slice}`(0, 1) +
                                                                                            '.' +
                                                                                            `${v?.review?.length}`?.slice(1, 2) +
                                                                                            'k' + ' ' + selectedLanguage?.Reviews :
                                                                                            ' ' + v?.review?.length + ' ' + selectedLanguage?.Reviews :
                                                                                        ' ' + v?.review?.length + ' ' + selectedLanguage?.Review :
                                                                                    selectedLanguage?.NoReviewesYet :
                                                                                selectedLanguage?.NoReviewesYet}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <br />
                                                                <div onClick={() => setShopData(v)}>
                                                                    <p style={{
                                                                        color: 'grey',
                                                                        textAlign: 'start',
                                                                        lineHeight: 1.4,
                                                                        height: '40px',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        lineBreak: 'anywhere',
                                                                        marginBottom: 0
                                                                    }}
                                                                        className="card_sold"
                                                                    >
                                                                        {v?.description || '-------------------------------'}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                : <div style={{ height: '280px', width: '100%' }}>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <img style={{ height: '200px', width: '200px', padding: '20px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }} src={NoCards} />
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>

                                <div style={{ marginTop: '20px', marginBottom: '10px' }}>
                                    <h1 className='new-exam-title'>{selectedLanguage?.Products}</h1>
                                    <div
                                        className='sidebar-scroll_class'
                                        style={{
                                            marginTop: 8,
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            overflowX: 'scroll'
                                        }}>
                                        {loading ?
                                            [1, 2, 3, 4].map((v, i) => {
                                                return (<Card
                                                    hoverable
                                                    loading={loading}
                                                    bordered={false}
                                                    className='loading-card-style'
                                                />)
                                            }) :
                                            filteredProduct?.length ?
                                                filteredProduct.map((v, i) => {
                                                    return (
                                                        <div
                                                            style={{
                                                                height: 285,
                                                                width: '290px',
                                                                minWidth: '290px',
                                                                borderRadius: '4px',
                                                                margin: '4px',
                                                                marginRight: '10px',
                                                                boxShadow: ' 0 4px 5px 0 rgba(0, 0, 0, 0.3)',
                                                                cursor: 'pointer'
                                                            }}>
                                                            <div style={{ textAlign: 'center', margin: '8px', position: 'relative' }}                                                >
                                                                <img onClick={() => setBuyProduct(v)} src={v?.imageUrl} style={{ height: '130px', width: '100%', maxWidth: '100%', borderRadius: '4px' }} />
                                                                <p style={{ position: 'absolute', top: '10px', left: '10px', background: 'white', height: '20px', width: '20px', borderRadius: '100px' }}>
                                                                    {favouriteSpin[v?._id] ?
                                                                        <Spin /> :
                                                                        !userData?.favourites?.includes(v?._id) ?
                                                                            <HeartOutlined onClick={() => changeFav(v?._id, true)} style={{ color: 'red' }} />
                                                                            : <HeartFilled onClick={() => changeFav(v?._id, false)} style={{ color: 'red' }} />
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div style={{ textAlign: 'center', margin: '8px' }}>
                                                                <div className='div-flex-back-name' style={{ justifyContent: 'space-between', height: '30px' }}>
                                                                    <div onClick={() => setBuyProduct(v)} >
                                                                        <p className='guest-card-tect-string'
                                                                            style={{
                                                                                fontSize: 18,
                                                                                color: 'black',
                                                                            }}>{stringLimiter(v?.name, 14) || '------'}</p>
                                                                        <p className='guest-card-tect-string'
                                                                            style={{
                                                                                fontSize: 13,
                                                                                color: '#7A7A7A',
                                                                                width: '100px',
                                                                            }}>{v?.userId?.city || '------'}</p>

                                                                        <p className='guest-card-tect-string'
                                                                            style={{
                                                                                fontSize: 13,
                                                                                color: '#7A7A7A',
                                                                                width: '100px'
                                                                            }}>{v?.promote ? selectedLanguage?.text_Sponsored : ''}</p>

                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            alignItems: 'flex-end',
                                                                            height: '100px',
                                                                            marginTop: '-8px',
                                                                        }}
                                                                        onClick={() => { gotoReview(v) }} >
                                                                        <button className='guest-card-review-box'>
                                                                            {v?.review?.length ?
                                                                                <AiFillStar size={15} color='white' />
                                                                                : null}
                                                                            {!v?.isReviewHide ? v?.review?.length ? calculateReviewAverage(v?.review) : 0 : 0}
                                                                        </button>
                                                                        <p className='card_desc1' style={{ textAlign: 'end' }}>
                                                                            {!v?.isReviewHide ?
                                                                                v?.review?.length ?
                                                                                    calculateReviewAverage(v?.review) < 4.5 ?
                                                                                        selectedLanguage?.Bad + ',' :
                                                                                        calculateReviewAverage(v?.review) < 5.9 ?
                                                                                            selectedLanguage?.Poor + ',' :
                                                                                            calculateReviewAverage(v?.review) < 6.9 ?
                                                                                                selectedLanguage?.Adeqate + ',' :
                                                                                                calculateReviewAverage(v?.review) < 8.9 ?
                                                                                                    selectedLanguage?.Good + ',' :
                                                                                                    selectedLanguage?.Excellent + ',' : null : null}
                                                                            {!v?.isReviewHide ?
                                                                                v?.review?.length ?
                                                                                    v?.review?.length !== 1 ?
                                                                                        v?.review?.length > 999 ?
                                                                                            ' ' +
                                                                                            `${v?.review?.length?.slice}`(0, 1) +
                                                                                            '.' +
                                                                                            `${v?.review?.length}`?.slice(1, 2) +
                                                                                            'k' + ' ' + selectedLanguage?.Reviews :
                                                                                            ' ' + v?.review?.length + ' ' + selectedLanguage?.Reviews :
                                                                                        ' ' + v?.review?.length + ' ' + selectedLanguage?.Review :
                                                                                    selectedLanguage?.NoReviewesYet :
                                                                                selectedLanguage?.NoReviewesYet}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <br />
                                                                <div onClick={() => setBuyProduct(v)}>
                                                                    <p style={{
                                                                        color: 'grey',
                                                                        textAlign: 'start',
                                                                        lineHeight: 1.4,
                                                                        height: '40px',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        lineBreak: 'anywhere',
                                                                        marginBottom: 0
                                                                    }}
                                                                        className="card_sold"
                                                                    >
                                                                        {v?.description || '-------------------------------'}
                                                                    </p>
                                                                </div>
                                                                <span onClick={() => setBuyProduct(v)}>
                                                                    <div className='card_sold_price'>
                                                                        <p className='card_sold' style={{ color: '#ec671b' }}>{selectedLanguage?.Sold}:{calcSoldProducts(v?.reservation?.settings || [])}</p>
                                                                        <p className='card_sold' style={{ color: '#ec671b' }}>€{' ' + getPrice(v)}</p>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div style={{ height: '280px', width: '100%' }}>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <img style={{ height: '200px', width: '200px', padding: '20px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }} src={NoCards} />
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                }

                <ProductReview ProgressLoading={ProgressLoading} reviewModal={reviewModal} closeReviewModal={closeReviewModal}  {...props} />
            </div>
        </div>
    )
}

export default GuestPrivProducts