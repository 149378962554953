import { Button, DatePicker, Modal, Spin, Table } from 'antd'
import axios from 'axios'
import HTML2Canvas from 'html2canvas'
import moment from 'moment-timezone'
import QRCode from 'qrcode.react'
import React, { useEffect, useState } from 'react'
import { POST } from '../../utils/apis'
import NoData from "../../assets/noDataImae.png"
import { errorMessage } from '../../utils/helpers'

const { RangePicker } = DatePicker

const LotteryCodes = (props) => {
    const { user, onCancel, selectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [download, setDownload] = useState(false)
    const [lotteryCode, setLotteryCode] = useState('')
    const [code, setCodes] = useState([])
    const [dateRange, setDateRange] = useState({
        date1: moment().add(-1, 'months').format('YYYY-MM-DD'),
        date2: moment().format('YYYY-MM-DD')
    })
    const customLocale = {
         emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle:selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };

    useEffect(() => {
        getAllLotteries()
    }, [dateRange])

    useEffect(() => {
        if (download) {
            downloadQR()
        }
    }, [download])

    const getAllLotteries = () => {
        setLoading(true)
        let valObj = { userId: `${user?._id}`, date1: dateRange?.date1, date2: dateRange?.date2 }
        axios.post(`${POST.GET_USER_ALL_LOTTERIES}`, valObj)
            .then((res) => {
                const { data } = res
                setCodes(data?.data || [])
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
            })
    }

    const disableModal = () => setVisible(false)

    const downloadQR = async () => {
        let id = download ? 'qrCodeDownload' : 'qrCode'
        const canvas = document.getElementById(id)

        await HTML2Canvas(canvas, { allowTaint: true, useCORS: true })
            .then((data) => {
                const pngUrl = data.toDataURL()
                let downloadLink = document.createElement('a')
                downloadLink.href = pngUrl
                downloadLink.download = `lottery-code.png`
                document.body.appendChild(downloadLink)
                downloadLink.click()
                document.body.removeChild(downloadLink)
                setDownload(false)
            })
            .catch((e) => {
                setDownload(false)
                console.log('e', e)
                errorMessage(selectedLanguage?.txt_downloaderror)
            })
    }

    const showQR = () => {
        return (
            <Modal
                open={visible}
                // title={selectedLanguage?.title_LotteryCode}
                onCancel={disableModal}
                className='pop-info-modal'
                style={{ top: '10px' }}
                footer={null}
            >
                <p className='new-p-question'>{selectedLanguage?.title_LotteryCode}</p>
                <div
                    style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: 10 }}>
                    <div id='qrCode' style={{ textAlign: 'center' }}>
                        <QRCode
                            value={lotteryCode}
                            size={250}
                            level={'H'}
                            includeMargin={true}
                        />
                        <p>{lotteryCode}</p>
                    </div>
                    <a onClick={downloadQR}> {selectedLanguage?.DownloadQR} </a>
                </div>
            </Modal>
        )
    }

    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_LotteryType}</p>,
            dataIndex: 'lotteryType',
            key: 'lotteryType',
            sorter: (a, b) => a.lotteryType - b.lotteryType,
            showSorterTooltip: false,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_isClaimed}</p>,
            dataIndex: 'isClaimed',
            key: 'isClaimed',
            sorter: (a, b) => a.isClaimed - b.isClaimed,
            showSorterTooltip: false,
            render: (e) => <span>{e ? selectedLanguage?.True : selectedLanguage?.False}</span>

        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_LotteryCode}</p>,
            dataIndex: 'lotteryCode',
            key: 'lotteryCode'
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_LotteryQRCode}</p>,
            dataIndex: 'lotteryCode',
            key: 'lotteryQRCode',
            render: (e) => (
                <span>
                    <Button type='link' onClick={() => (setLotteryCode(e), setVisible(true))}>{selectedLanguage?.View}</Button>
                    {/* <Button type='link' onClick={() => (setLotteryCode(e), setDownload(true))}>Download</Button> */}
                </span>
            )
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Created}</p>,
            dataIndex: 'created',
            key: 'created'
        }
    ]

    const createReportRange = (d) => {
        setDateRange({
            date1: d[0].format('YYYY-MM-DD'),
            date2: d[1].format('YYYY-MM-DD')
        })
        getAllLotteries()
    }

    const disabledDate = (current) => {
        let customDate = moment().format('YYYY-MM-DD')
        return current && current > moment(customDate, 'YYYY-MM-DD').add(1, 'day')
    }

    return (
        <div>
            {showQR()}
            <div
                style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: 10 }}
            >
                <div
                    style={{ textAlign: 'center' }}
                    id='qrCodeDownload'
                    className='fixed-qr'
                >
                    <QRCode
                        value={lotteryCode}
                        size={250}
                        level={'H'}
                        includeMargin={true}
                    />
                    <p>{lotteryCode}</p>
                </div>
            </div>
            <div className='flex-mobile' style={{ display: 'flex' }}>
                <RangePicker
                    defaultValue={[moment().add(-1, 'months'), moment()]}
                    onChange={createReportRange}
                    style={{
                        marginBottom: 10,
                        marginTop: 20,
                        width: '250px',
                        height: '45px',
                        borderRadius: '6px',
                        boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                        marginLeft: '8px'
                    }}
                />
            </div>
            <div className='new-layout-table-upper-div'>
                <Table
                    className='new-layout-table'
                    locale={customLocale}
                    loading={loading}
                    columns={columns}
                    dataSource={code}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 800 }}
                />
            </div>
        </div>
    )
}

export default LotteryCodes