import { Button, DatePicker, message, Spin, Table } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { POST } from '../../utils/apis'
import { allUserData, assignMessage, errorMessage, successMessage } from '../../utils/helpers'
import AddLottery from './AddLottery'
import Draws from './Draws'
import GuestPrizes from './GuestPrizes'
import Invoice from './Invoice'
import { IoIosArrowBack } from "react-icons/io"
import NoData from "../../assets/noDataImae.png"


const GuestLottery = (props) => {
    const { user, userActions, selectedLanguage, language } = props
    const [loading, setLoading] = useState(false)
    const [showDraw, setShowDraw] = useState(false)
    const [showInvoices, setshowInvoices] = useState(false)
    const [showGuestPrizes, setShowGuestPrizes] = useState(false)
    const [lotterySaving, setLotterySaving] = useState([])
    const [drawData, setDrawData] = useState([])
    const [businessUserID, setbusinessUserID] = useState(null)
    const [guestPrizesData, setGuestPrizesData] = useState([])
    const [dateRange, setDateRange] = useState({
        date1: moment().format('YYYY-MM')
    })
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };


    console.log('shariq----mehmood')
    useEffect(() => {
        getAllSavings()
        showMoreLotteryDetail()
        showGuestPrizesDetail()
    }, [dateRange])


    const getAllSavings = () => {
        let obj = {
            userId: user?._id,
            date1: dateRange?.date1,
        }

        setLoading(true)

        axios.post(`${POST.GET_LOTTERY_SAVINGS}`, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    setLotterySaving(data?.data || {})
                }
            }).catch((e) => {
                console.log('e ', e)
                setLoading(false)
            })
    }

    const confirm = (e) => {
        setLoading(true)

        let obj = { guestId: user?._id }
        axios.post(POST?.REMOVE_DOUBLE_CHANCES_SUBSCRIPTION, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    allUserData?.getActiveSubscription(userActions, user?._id)
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })

    };

    const cancel = (e) => {
        message.error('Click on No');
    };

    const showMoreLotteryDetail = (e) => {
        setLoading(true)
        let obj = {
            _id: user?._id,
            buId: e?.businessUser?._id ? e?.businessUser?._id : businessUserID,
        }

        axios.post(POST?.CLAIM_LOTTERY_DETAIL, obj)
            .then((res) => {
                const { data } = res
                setDrawData(data?.data || [])
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
            })
    }

    const showGuestPrizesDetail = (e) => {
        setLoading(true)

        let obj = {
            userId: e?.businessUser?._id ? e?.businessUser?._id : businessUserID,
            date1: dateRange?.date1,
            date2: dateRange?.date1
        }

        axios.post(POST?.GET_PRIZES_FOR_GUEST, obj)
            .then((res) => {
                const { data } = res
                setGuestPrizesData(data?.data || [])
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                console.log(e)
            })
    }

    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.BusinessName}</p>,
            dataIndex: 'businessName',
            key: 'businessName',
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_BusinessAddress}</p>,
            dataIndex: 'country',
            key: 'country'
        },
        ,
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_TotalLotteries}</p>,
            dataIndex: 'lotteries',
            key: 'lotteries',
            render: (lotteries) => <p style={{ paddingTop: 20, marginTop: -5 }}>{lotteries?.length}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Created}</p>,
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            dataIndex: '_id',
            key: '_id',
            render: (e) =>
                <>
                    <Button
                        onClick={() => { showMoreLotteryDetail(e); setShowDraw(true) }}
                    >
                        {selectedLanguage?.button_show}
                    </Button>
                    <Button
                        onClick={() => { setbusinessUserID(e?.businessUser?._id); showGuestPrizesDetail(e); setShowGuestPrizes(true) }}
                    >
                        {selectedLanguage?.button_prizes}
                    </Button>
                </>
        }
    ]

    const dataSource = lotterySaving?.map((info, i) => {
        return {
            _id: info,
            businessName: info?.businessUser?.businessName,
            country: info?.businessUser?.country,
            lotteries: info?.lotteries,
            created_at: moment(info?.created).format('YYYY-MM-DD'),
        }
    })

    const createReportRange = (d) => {
        setDateRange({
            date1: d?.format('YYYY-MM')
        })
    }

    let currentDate = moment().format('YYYY-MM')

    return (
        <div >
            <h3 className='new-exam-title'>{
                showDraw && !showGuestPrizes ?
                    selectedLanguage?.button_WithDraw :
                    !showDraw && showGuestPrizes && !showInvoices ?
                        selectedLanguage?.button_prizes :
                        selectedLanguage?.title_lottery}</h3>


            {!showDraw && !showInvoices && !showGuestPrizes ?
                null :
                <div style={{ textAlign: 'right', width: '99.5%' }}>
                    <Button
                        style={{ height: '40px', width: '160px', marginTop: '10px' }}
                        className='addproduct-text'
                        type='primary'
                        onClick={() => { setShowDraw(false); setshowInvoices(false); setShowGuestPrizes(false) }}
                    >
                        {selectedLanguage?.Cancel}
                    </Button>
                </div>}




            {!showDraw && !showGuestPrizes ?
                <div className='flex-mobile' style={{ display: 'flex' }} >
                    <DatePicker
                        defaultValue={moment(currentDate, 'YYYY-MM')}
                        style={{
                            margin: 15, 
                            width: '200px',
                            height: '45px',
                            borderRadius: '6px',
                            boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                            marginLeft: '8px'
                        }}
                        picker={'month'}
                        onChange={createReportRange}
                        allowClear={false}
                    />
                </div>
                : null}

            <div style={{ textAlign: 'end' }}>
                {!showDraw && !showInvoices && !showGuestPrizes ?
                    <AddLottery getAllSavings={() => getAllSavings()} {...props} />
                    :
                    null
                }
            </div>

            <div style={{ marginTop: '15px' }} >
                {showDraw && !showGuestPrizes ?
                    <Draws {...props} loading1={loading} drawData={drawData} setShowDraw={() => setShowDraw(false)} />
                    : !showDraw && showGuestPrizes && !showInvoices ?
                        <GuestPrizes {...props} loading={loading} guestPrizesData={guestPrizesData} />
                        : showInvoices ?
                            <Invoice {...props} />
                            :
                            <div className='new-layout-table-upper-div'>
                                <Table
                                    className='new-layout-table'
                                    locale={customLocale}
                                    loading={loading}
                                    dataSource={dataSource}
                                    columns={columns}
                                    pagination={{ hideOnSinglePage: true }}
                                    scroll={{ x: 800 }}
                                />
                            </div>}
            </div>
        </div>
    )
}

export default GuestLottery