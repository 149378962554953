const LOGIN_USER = 'LOGIN_USER'
const REMOVE_USER = 'REMOVE_USER'
const ALL_USERS = 'ALL_USERS'
const ALL_GROUPS = 'ALL_GROUPS'
const ALL_BUSINESS_USERS = 'ALL_BUSINESS_USERS'
const ALL_GUEST_USERS = 'ALL_GUEST_USERS'
const ALL_SEGMENTS = 'ALL_SEGMENTS'
const ALL_CARDS = 'ALL_CARDS'
const ALL_PUBLIC_CARDS = 'ALL_PUBLIC_CARDS'
const COLLAPSED = 'COLLAPSED'
const CITIES = 'CITIES'
const RATES = 'RATES'
const TAXES = 'TAXES'
const MENU_NONE = 'MENU_NONE'
const ALL_GUESTS = 'ALL_GUESTS'
const SEGMENT_MESSAGES = 'SEGMENT_MESSAGES'
const SUBSCRIPTIONS = 'SUBSCRIPTIONS'
const POSTAL_CARD = 'POSTAL_CARD'
const INVOICES = 'INVOICES'
const BU_INVOICES = 'BU_INVOICES'
const PAYMENTS = 'PAYMENTS'
const VOUCHERS = 'VOUCHERS'
const USERS_INVOICES = 'USERS_INVOICES'
const MESSAGES_LIST = 'MESSAGES_LIST'
const USER_SEGMENTS = 'USER_SEGMENTS'
const ALL_SUBSCRIBE_COUPONS = 'ALL_SUBSCRIBE_COUPONS'
const ALL_SUBSCRIBE_LOTTERY = 'ALL_SUBSCRIBE_LOTTERY'
const ALL_SUBSCRIBE_GIFTCARDS = 'ALL_SUBSCRIBE_GIFTCARDS'
const USER_PENDING_GROUPS = 'USER_PENDING_GROUPS'
const USER_JOINED_GROUPS = 'USER_JOINED_GROUPS'
const REPORTS = 'REPORTS'
const FINANCIAL_REPORTS = 'FINANCIAL_REPORTS'
const STOCK_REPORTS = 'STOCK_REPORTS'
const ADMIN_FORM_REPORTS = 'ADMIN_FORM_REPORTS'
const SAVINGS = 'SAVINGS'
const LOTTERIES_SAVINGS = 'LOTTERIES_SAVINGS'
const FORMS = 'FORMS'
const FORM_REPORTS = 'FORM_REPORTS'
const POSTAL_CARDS = 'POSTAL_CARDS'
const GET_COUPONS = 'GET_COUPONS'
const GET_NOTIFICATIONS = 'GET_NOTIFICATION'
const GET_CARDS = 'GET_CARDS'
const GET_ADVERTISEMENT = 'GET_ADVERTISEMENT'
const WALLET = 'WALLET'
const PROVISION_REPORTS = 'PROVISION_REPORTS'
const PRODUCT_FILTER = 'PRODUCT_FILTER'
const LANGUAGE = 'LANGUAGE'
const ALL_SUB_ADMINS = 'ALL_SUB_ADMINS'
const ADMIN_FAQ = 'ADMIN_FAQ'
const USER_FAQ = 'USER_FAQ'
const BU_PRODUCT = 'BU_PRODUCT'
const BU_DAYDEALS_PRODUCT = 'BU_DAYDEALS_PRODUCT'
const BU_DAYDEALS_QUESTION = 'BU_DAYDEALS_QUESTION'
const GUEST_DAYDEALS_QUESTION = 'GUEST_DAYDEALS_QUESTION'

export {
    LOGIN_USER,
    REMOVE_USER,
    ADMIN_FORM_REPORTS,
    USER_PENDING_GROUPS,
    USER_JOINED_GROUPS,
    ALL_GROUPS,
    ALL_USERS,
    ALL_BUSINESS_USERS,
    COLLAPSED,
    ALL_SEGMENTS,
    ALL_CARDS,
    ALL_GUESTS,
    SEGMENT_MESSAGES,
    SUBSCRIPTIONS,
    POSTAL_CARD,
    INVOICES,
    USERS_INVOICES,
    MESSAGES_LIST,
    USER_SEGMENTS,
    REPORTS,
    FINANCIAL_REPORTS,
    STOCK_REPORTS,
    ALL_PUBLIC_CARDS,
    ALL_SUBSCRIBE_COUPONS,
    SAVINGS,
    FORMS,
    FORM_REPORTS,
    POSTAL_CARDS,
    GET_COUPONS,
    GET_NOTIFICATIONS,
    GET_CARDS,
    GET_ADVERTISEMENT,
    LOTTERIES_SAVINGS,
    WALLET,
    ALL_SUBSCRIBE_LOTTERY,
    PROVISION_REPORTS,
    PRODUCT_FILTER,
    VOUCHERS,
    PAYMENTS,
    ALL_GUEST_USERS,
    BU_INVOICES,
    LANGUAGE,
    CITIES,
    RATES,
    ALL_SUB_ADMINS,
    TAXES,
    MENU_NONE,
    ALL_SUBSCRIBE_GIFTCARDS,
    ADMIN_FAQ,
    USER_FAQ,
    BU_PRODUCT,
    BU_DAYDEALS_PRODUCT,
    BU_DAYDEALS_QUESTION,
    GUEST_DAYDEALS_QUESTION,
}
